import * as TYPE from "../actions/PatientMailing";


export function mailing (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_MAILING:
             return action.payload;
        default:
            return state;
    }
}

export function mailingIsLoading (state = false, action){
    switch(action.type){
        case TYPE.MAILING_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function mailingIsError (state = false, action){
    switch(action.type){
        case TYPE.MAILING_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}
