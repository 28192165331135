import axios from 'axios';
import {POSTURL,POSTKEY, POSTDETAILURL} from '../../constants_api'

export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const FETCH_ADDRESS_DETAIL = 'FETCH_ADDRESS_DETAIL';
export const FETCH_MAILING_ADDRESSES = 'FETCH_MAILING_ADDRESSES';
export const FETCH_MAILING_ADDRESS_DETAIL = 'FETCH_MAILING_ADDRESS_DETAIL';

export const ADDRESSES_ARE_LOADING = 'ADDRESSES_ARE_LOADING';
export const ADDRESS_IS_LOADING = 'ADDRESS_IS_LOADING';

export function fetchAddresses(search){
    return (dispatch) => {
        dispatch({type: ADDRESSES_ARE_LOADING, bool :true});
        const url = `${POSTURL}?key=${search.Key}&searchTerm=${search.SearchTerm}`;
        const request = axios.post(url,search);
        request.then((response) => {
            dispatch({type: ADDRESSES_ARE_LOADING, bool: false});
            if(response.data.Items.length>0){
                dispatch({type: FETCH_ADDRESSES, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: ADDRESSES_ARE_LOADING, bool: false});
        });
    }
}
export function fetchAddressDetail(search){
    return (dispatch) => {
        dispatch({type: ADDRESS_IS_LOADING, bool :true});
        const url = `${POSTDETAILURL}?key=${search.Key}&Id=${search.Id}`;
        const request = axios.get(url);
        request.then((response) => {
            dispatch({type: ADDRESS_IS_LOADING, bool: false});
            if(response.data.Items.length>0){
                    dispatch({type: FETCH_ADDRESS_DETAIL, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: ADDRESS_IS_LOADING, bool: false});
        });
    }
}

export function fetchMailingAddresses(search){
    return (dispatch) => {
        dispatch({type: ADDRESSES_ARE_LOADING, bool :true});
        const url = `${POSTURL}?key=${search.Key}&searchTerm=${search.SearchTerm}`;
        const request = axios.post(url,search);
        request.then((response) => {
            dispatch({type: ADDRESSES_ARE_LOADING, bool: false});
            if(response.data.Items.length>0){
                dispatch({type: FETCH_MAILING_ADDRESSES, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: ADDRESSES_ARE_LOADING, bool: false});
        });
    }
}
export function fetchMailingAddressDetail(search){
    return (dispatch) => {
        dispatch({type: ADDRESS_IS_LOADING, bool :true});
        const url = `${POSTDETAILURL}?key=${search.Key}&Id=${search.Id}`;
        const request = axios.get(url);
        request.then((response) => {
            dispatch({type: ADDRESS_IS_LOADING, bool: false});
            if(response.data.Items.length>0){
                    dispatch({type: FETCH_MAILING_ADDRESS_DETAIL, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: ADDRESS_IS_LOADING, bool: false});
        });
    }
}