import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid, Hidden
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchPractitionerDetail, editPractitionerDetail} from '../actions/PractitionerDetail';
import {createTestPDF} from '../actions/DosagePrescription';
import {Field, reduxForm} from 'redux-form';
import {renderTextField, renderImageUpload} from '../Components/Forms';


class PractitionerDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            pd_in_use:0,
            user_id:0,
            officeAddress:'',
            officeCity:'',
            officeProvince:'',
            officePostcode:'',
            officeTel:'',
            officeFax:'',
            officeEmail:'',
            mln:'',
            signature:null,
            initials:null,
            signatureSrc:'',
            initialsSrc:'',
            occupation:'',
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleSignatureChange=this.handleSignatureChange.bind(this);
        this.handleInitialsChange=this.handleInitialsChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    }

    componentDidMount() {
        if(this.props.practitioner_id>0){
            this.props.fetchPractitionerDetail(this.props.practitioner_id);
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.practitionerDetail!==prevProps.practitionerDetail){
            this.setState({
                officeAddress:this.props.practitionerDetail.officeAddress,
                officeCity:this.props.practitionerDetail.officeCity,
                officeProvince:this.props.practitionerDetail.officeProvince,
                officePostcode:this.props.practitionerDetail.officePostcode,
                officeTel:this.props.practitionerDetail.officeTel,
                officeFax:this.props.practitionerDetail.officeFax,
                officeEmail:this.props.practitionerDetail.officeEmail,
                mln:this.props.practitionerDetail.mln,
                signature:this.props.practitionerDetail.signature,
                initials:this.props.practitionerDetail.initials,
                occupation:this.props.practitionerDetail.occupation,
            });
            const formData = {
                "officeAddress":this.props.practitionerDetail.officeAddress,
                "officeCity":this.props.practitionerDetail.officeCity,
                "officeProvince":this.props.practitionerDetail.officeProvince,
                "officePostcode":this.props.practitionerDetail.officePostcode,
                "officeTel":this.props.practitionerDetail.officeTel,
                "officeFax":this.props.practitionerDetail.officeFax,
                "officeEmail":this.props.practitionerDetail.officeEmail,
                "mln":this.props.practitionerDetail.mln,
                "signature":this.props.practitionerDetail.signature,
                "initials":this.props.practitionerDetail.initials,
                "occupation":this.props.practitionerDetail.occupation,
            };
            this.props.initialize(formData);
        }
        if(this.props.location!==prevProps.location){
            this.props.fetchPractitionerDetail(this.props.practitioner_id);
        }

    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({pd_in_use : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onSubmit (values){
        var pd = {
            user_id: this.props.practitioner_id,
            officeAddress:values.officeAddress,
            officeCity:values.officeCity,
            officeProvince:values.officeProvince,
            officePostcode:values.officePostcode,
            officeTel:values.officeTel,
            officeFax:values.officeFax,
            officeEmail:values.officeEmail,
            mln:values.mln,
            signature:this.state.signature,
            initials:this.state.initials,
            occupation:values.occupation,
        }
        this.props.editPractitionerDetail(pd);

    }


    //Render Functions



    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push(`/practitioner/${this.props.practitioner_id}`)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push(`/practitioner/${this.props.practitioner_id}`)}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    handleSignatureChange = (event) => {
        this.setState({signatureSrc: event.target.files[0]})
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            var image = new Image();
            image.onload = function(imageEvent) {
                let maxHeight = 35;
                let maxWidth = 150;
                let hr = image.height/maxHeight;
                let wr = image.width/maxWidth;
                let h = image.height;
                let w = image.width;
                if(hr>1 && hr>wr){
                    h=maxHeight;
                    w=parseInt(w/hr,10);
                }
                if(wr>1 && wr>hr){
                    w=maxWidth;
                    h=parseInt(h/wr,10);
                }
                var canvas = document.createElement('canvas');
                canvas.width=w;
                canvas.height=h;
                canvas.getContext('2d').drawImage(image, 0,0,w,h);
                var dataUrl = canvas.toDataURL('image/jpeg');
                this.setState({ signature:dataUrl});
            }.bind(this);
            image.src=reader.result;
        }.bind(this);
    }

    handleInitialsChange = (event) => {
        this.setState({initialsSrc: event.target.files[0]})
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            var image = new Image();
            image.onload = function(imageEvent) {
                let maxHeight = 26;
                let maxWidth = 100;
                let hr = image.height/maxHeight;
                let wr = image.width/maxWidth;
                let h = image.height;
                let w = image.width;
                if(hr>1 && hr>wr){
                    h=maxHeight;
                    w=parseInt(w/hr,10);
                }
                if(wr>1 && wr>hr){
                    w=maxWidth;
                    h=parseInt(h/wr,10);
                }
                var canvas = document.createElement('canvas');
                canvas.width=w;
                canvas.height=h;
                canvas.getContext('2d').drawImage(image, 0,0,w,h);
                var dataUrl = canvas.toDataURL('image/jpeg');
                this.setState({ initials:dataUrl});
            }.bind(this);
            image.src=reader.result;
        }.bind(this);
    }


    render(){
        const { handleSubmit } = this.props;
        return(<Fragment>
                <Typography variant="h5" color="inherit"><u>Practitioner Details</u></Typography>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                autoFocus
                                name= 'officeAddress'
                                component={renderTextField}
                                label= 'Office Address'
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'officeCity'
                                component={renderTextField}
                                label= 'Office City'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'officeProvince'
                                component={renderTextField}
                                label= 'Office Province'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'officePostcode'
                                component={renderTextField}
                                label= 'Office Postcode'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'officeTel'
                                component={renderTextField}
                                label= 'Office Tel'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'officeFax'
                                component={renderTextField}
                                label= 'Office Fax'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'officeEmail'
                                component={renderTextField}
                                label= 'Office Email'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Field
                                name= 'mln'
                                component={renderTextField}
                                label= 'License Number'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Field
                                name= 'occupation'
                                component={renderTextField}
                                label= 'Occupation'
                                margin='normal'
                                onChange={this.handleChange}
                                multiline
                                fullWidth
                            />
                        </Grid>
                        <Hidden xsDown>
                            <Grid item sm={8}>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name='signatureSrc'
                                fname='signatureSrc'
                                component={renderImageUpload}
                                onChange={this.handleSignatureChange}
                                label='Upload Signature Image'
                                margin='normal'
                                preview={this.state.signature}
                                width='150px'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name='initialsSrc'
                                fname='initialsSrc'
                                component={renderImageUpload}
                                onChange={this.handleInitialsChange}
                                label='Upload Initials Image'
                                margin='normal'
                                preview={this.state.initials}
                                width='100px'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button type="submit" color='secondary' variant='contained'>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button onClick={()=>{createTestPDF(this.props.practitioner_id)}} variant='contained'>Test Pdf</Button>
                        </Grid>

                    </Grid>
                </form>
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}

            </Fragment>
        );
    }
}



const validate = values => {
    const errors = {}
    const requiredFields = [
        'name',
        'ranking',
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })

    return errors
}

function mapStateToProps(state){
    return(
        {
            practitionerDetail: state.practitionerDetail,
            isLoading: state.pdIsLoading,
            isError: state.pdIsError
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPractitionerDetail, editPractitionerDetail}, dispatch);
}


export default reduxForm({
    validate,
    form: 'editPDForm'
})(connect(mapStateToProps,mapDispatchToProps)(PractitionerDetail));

