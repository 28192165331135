import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_APPTSTATUS = 'FETCH_APPTSTATUS';
export const FETCH_APPTSTATUS_DETAIL = 'FETCH_APPTSTATUS_DETAIL';
export const ADD_APPTSTATUS = 'ADD_APPTSTATUS';
export const EDIT_APPTSTATUS = 'EDIT_APPTSTATUS';
export const APPTSTATUS_IS_LOADING = 'APPTSTATUS_IS_LOADING';
export const APPTSTATUS_IS_ERROR = 'APPTSTATUS_IS_ERROR';
export const APPTSTATUS_NEEDS_REFRESH = 'APPTSTATUS_NEEDS_REFRESH';

export function fetchApptStatus(params){
    return (dispatch) => {
        dispatch({type: APPTSTATUS_IS_LOADING, bool :true});
        dispatch({type: APPTSTATUS_IS_ERROR, bool:false});
        dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appt_status/list?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_APPTSTATUS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchApptStatusDetail(status_id){
    return (dispatch) => {
        dispatch({type: APPTSTATUS_IS_LOADING, bool :true});
        dispatch({type: APPTSTATUS_IS_ERROR, bool:false});
        dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appt_status/fetch`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_APPTSTATUS_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addApptStatus(status){
    return (dispatch) => {
        dispatch({type: APPTSTATUS_IS_LOADING, bool :true});
        dispatch({type: APPTSTATUS_IS_ERROR, bool:false});
        dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}appt_status/add`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_APPTSTATUS, status_id: response.data.status_id})
                dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
        });
    }
}

export function deleteApptStatus(id){
    return (dispatch) => {
        dispatch({type: APPTSTATUS_IS_LOADING, bool :true});
        dispatch({type: APPTSTATUS_IS_ERROR, bool:false});
        dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}appt_status/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const status_id = {status_id:id};
        const request = axios.post(url,status_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
        });
    }
}

export function editApptStatus(status){
    return (dispatch) => {
        dispatch({type: APPTSTATUS_IS_LOADING, bool :true});
        dispatch({type: APPTSTATUS_IS_ERROR, bool:false});
        dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}appt_status/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: APPTSTATUS_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: APPTSTATUS_IS_ERROR, bool:true});
            dispatch({type: APPTSTATUS_IS_LOADING, bool: false});
        });
    }
}