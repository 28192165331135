import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS';
export const APPTS_IS_LOADING = 'APPTS_IS_LOADING';
export const APPTS_IS_ERROR = 'APPTS_IS_ERROR';
export const APPTS_NEEDS_REFRESH = 'APPTS_NEEDS_REFRESH';
export const FETCH_SLOTS = 'FETCH_SLOTS';
export const SLOTS_IS_LOADING = 'SLOTS_IS_LOADING';
export const SLOTS_IS_ERROR = 'SLOTS_IS_ERROR';
export const SLOTS_NEEDS_REFRESH = 'SLOTS_NEEDS_REFRESH';
export const APPT_BOOKED = 'APPT_BOOKED';
export const FETCH_PATIENT_APPOINTMENTS = 'FETCH_PATIENT_APPOINTMENTS';
export const FETCH_DATE_APPOINTMENTS = 'FETCH_DATE_APPOINTMENTS';
export const APPT_CANCELLED = 'APPT_CANCELLED';


export function fetchPractitionerCalendar(params){
    return (dispatch) => {
        dispatch({type: APPTS_IS_LOADING, bool :true});
        dispatch({type: APPTS_IS_ERROR, bool:false});
        dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appointment/getPractitionerSchedule?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_APPOINTMENTS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: APPTS_IS_ERROR, bool:true});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function clearAppointment(params){
    return (dispatch) => {
        dispatch({type: APPTS_IS_LOADING, bool: true});
        const url = `${API_URL}appointment/clearAppointment?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            if(response.data.Success){
                dispatch({type: APPTS_IS_LOADING, bool: false});
                dispatch({type: APPTS_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: APPTS_IS_ERROR, bool:true});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addAppointmentSlot(params){
    return (dispatch) => {
        dispatch({type: APPTS_IS_LOADING, bool: true});
        const url = `${API_URL}appointment/addAppointmentSlot?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            if(response.data.Success){
                dispatch({type: APPTS_IS_LOADING, bool: false});
                dispatch({type: APPTS_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: APPTS_IS_ERROR, bool:true});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function getFreeAppointmentSlots(params){
    return (dispatch) => {
        dispatch({type: APPT_BOOKED, bool:false});
        dispatch({type: SLOTS_IS_LOADING, bool :true});
        dispatch({type: SLOTS_IS_ERROR, bool:false});
        dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appointment/getFree?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: SLOTS_IS_LOADING, bool: false});
            dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_SLOTS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: SLOTS_IS_ERROR, bool:true});
            dispatch({type: SLOTS_IS_LOADING, bool: false});
            dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function bookAppointmentSlot(params){
    return (dispatch) => {
        dispatch({type: SLOTS_IS_LOADING, bool :true});
        dispatch({type: SLOTS_IS_ERROR, bool:false});
        dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
        dispatch({type: APPT_BOOKED, bool:false});
        const url = `${API_URL}appointment/book?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: SLOTS_IS_LOADING, bool: false});
            dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
            if(response.data.Success && response.data.answer){
                dispatch({type: APPT_BOOKED, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: SLOTS_IS_ERROR, bool:true});
            dispatch({type: SLOTS_IS_LOADING, bool: false});
            dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function cancelAppointmentSlot(params){
    return (dispatch) => {
        dispatch({type: SLOTS_IS_LOADING, bool :true});
        dispatch({type: SLOTS_IS_ERROR, bool:false});
        dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
        dispatch({type: APPT_CANCELLED, bool:false});
        const url = `${API_URL}appointment/cancel?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: SLOTS_IS_LOADING, bool: false});
            dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
            if(response.data.Success && response.data.answer){
                dispatch({type: APPT_CANCELLED, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: SLOTS_IS_ERROR, bool:true});
            dispatch({type: SLOTS_IS_LOADING, bool: false});
            dispatch({type: SLOTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function getPatientAppointments(params){
    return (dispatch) => {
        dispatch({type: APPTS_IS_LOADING, bool :true});
        dispatch({type: APPTS_IS_ERROR, bool:false});
        dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appointment/getPatientAppointments?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PATIENT_APPOINTMENTS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: APPTS_IS_ERROR, bool:true});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function getDateAppointments(params){
    return (dispatch) => {
        dispatch({type: APPTS_IS_LOADING, bool :true});
        dispatch({type: APPTS_IS_ERROR, bool:false});
        dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appointment/getDateAppointments?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_DATE_APPOINTMENTS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: APPTS_IS_ERROR, bool:true});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        });
    }
}

export function editAppointmentNotes(params){
    return (dispatch) => {
        dispatch({type: APPTS_IS_LOADING, bool :true});
        dispatch({type: APPTS_IS_ERROR, bool:false});
        dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appointment/editNotes?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: true});
        }).catch((err)=>{
            dispatch({type: APPTS_IS_ERROR, bool:true});
            dispatch({type: APPTS_IS_LOADING, bool: false});
            dispatch({type: APPTS_NEEDS_REFRESH, bool: false});
        });
    }
}
