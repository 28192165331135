import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_DOSAGES = 'FETCH_DOSAGES';
export const FETCH_DOSAGE_DETAIL = 'FETCH_DOSAGE_DETAIL';
export const ADD_DOSAGE = 'ADD_DOSAGE';
export const DOSAGE_IS_LOADING = 'DOSAGE_IS_LOADING';
export const DOSAGE_IS_ERROR = 'DOSAGE_IS_ERROR';
export const DOSAGE_NEEDS_REFRESH = 'DOSAGE_NEEDS_REFRESH';
export const A_DOSAGE_NEEDS_REFRESH = 'A_DOSAGE_NEEDS_REFRESH';

export function fetchDosagesPatient(params){
    return (dispatch) => {
        dispatch({type: DOSAGE_IS_LOADING, bool :true});
        dispatch({type: DOSAGE_IS_ERROR, bool:false});
        dispatch({type: DOSAGE_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}dosage/listByPatient`;
        let token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DOSAGE_IS_LOADING, bool: false});
            dispatch({type: DOSAGE_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_DOSAGES, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: DOSAGE_IS_ERROR, bool:true});
            dispatch({type: DOSAGE_IS_LOADING, bool: false});
            dispatch({type: DOSAGE_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchDosageDetail(params){
    return (dispatch) => {
        const p = {...params, bool:false};
        dispatch({type: A_DOSAGE_NEEDS_REFRESH, payload:p});
        const url = `${API_URL}dosage/fetch?XDEBUG_SESSION_START=PHPSTORM`;
        let token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            if(response.data.Success){
                dispatch({type: FETCH_DOSAGE_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
        });
    }
}

export function addDosage(dosage){
    return (dispatch) => {
        dispatch({type: DOSAGE_IS_LOADING, bool :true});
        dispatch({type: DOSAGE_IS_ERROR, bool:false});
        dispatch({type: DOSAGE_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}dosage/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,dosage, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DOSAGE_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_DOSAGE, dosage_id: response.data.dosage_id})
                dispatch({type: DOSAGE_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: DOSAGE_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: DOSAGE_IS_ERROR, bool:true});
            dispatch({type: DOSAGE_IS_LOADING, bool: false});
        });
    }
}

export function deleteDosage(id){
    return (dispatch) => {
        dispatch({type: DOSAGE_IS_LOADING, bool :true});
        dispatch({type: DOSAGE_IS_ERROR, bool:false});
        dispatch({type: DOSAGE_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}dosage/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const dosage_id = {dosage_id:id};
        const request = axios.post(url,dosage_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DOSAGE_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: DOSAGE_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: DOSAGE_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: DOSAGE_IS_ERROR, bool:true});
            dispatch({type: DOSAGE_IS_LOADING, bool: false});
        });
    }
}

