import React, {Component, } from 'react';
import {connect} from 'react-redux';
import {Card,  CardHeader,  CardContent, CardActions,
    Grid,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton,
    CircularProgress,
    Typography,
    Collapse,
    Hidden,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {Field, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {fetchPractitionerSchedules as fetchData, deletePractitionerSchedule as deleteData, addPractitionerSchedule as addData, applySchedule} from '../actions/PractitionerSchedules';
import {updatePage} from '../actions/Common';
import {renderTextField, renderNewSelect, } from "../Components/Forms";
import jwtDecode from "jwt-decode";
import {timeSlots} from '../constants_api';


class PractitionerScheduleList extends Component{

    constructor(props){
        super(props);

        this.state= {
            deleteDialogOpen: false,
            data_id_inuse:0,
            isError:false,
            showMore:[],
            editPath:'practitioner',
            practitioner_id: null,
            newDialog: false,
            formTitle: 'Add New',
        };

        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.addSchedule = this.addSchedule.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showDetail=this.showDetail.bind(this);
        this.applySchedule=this.applySchedule.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        let practitioner_id=0;
        if(typeof this.props.practitioner_id === 'undefined' || !this.props.practitioner_id){
            let jwt = jwtDecode(localStorage.getItem('token'));
            this.setState({practitioner_id:jwt.user_id});
            practitioner_id=jwt.user_id;
        } else {
            this.setState({practitioner_id:this.props.practitioner_id});
            practitioner_id=this.props.practitioner_id;
        }
        this.props.fetchData({practitioner_id:practitioner_id});
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.isError!==prevProps.isError){
            this.setState({isError:this.props.isError});
        }
        if (this.props.needsRefresh) {
            this.props.fetchData({practitioner_id:this.state.practitioner_id});
        }
    }

    applySchedule(){
        this.props.applySchedule({schedule_id:7, startdate:'2018-06-25'});
    }

    addSchedule(values){
        this.setState({newDialog:false});
        values.practitioner_id = this.state.practitioner_id;
        values.timezone="America/Toronto";
        this.props.addData(values);
    }

    editForm(data){
        this.setState({
            newDialog:true,
            formTitle:'Edit'
        });
        const formData = {
            "schedule_name":data.schedule_name,
            "schedule_description":data.schedule_description,
            "d1_start":data.d1_start,
            "d1_end":data.d1_end,
            "d2_start":data.d2_start,
            "d2_end":data.d2_end,
            "d3_start":data.d3_start,
            "d3_end":data.d3_end,
            "d4_start":data.d4_start,
            "d4_end":data.d4_end,
            "d5_start":data.d5_start,
            "d5_end":data.d5_end,
            "d6_start":data.d6_start,
            "d6_end":data.d6_end,
            "d7_start":data.d7_start,
            "d7_end":data.d7_end,
        };
        this.props.initialize(formData);
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({data_id_inuse : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        this.props.deleteData({ps_id:id});
    }

    showMore(id){
        let show = this.state.showMore;
        show[id]=!show[id];
        this.setState({showMore:show});
    }


    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }

    laterThan (value,allValues, props, name){
        let a = name.split('_');
        let b = a[0] + '_start';
        let val = allValues[b];
        return val && value<val? `Must be later than ${val}` : undefined;
    }

    onTextFieldChange = item => ({target : {value}}) => {
        this.setState({
            data : {
                ...this.state.data,
                [item]:value
            }
        });

    }

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete this schedule ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.data_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleTimeChange = (nextname) => (event) =>{
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.state[nextname]==='undefined'){
            this.setState({[nextname]:event.target.value});
        }
    }

    showDetail(id){
        if(typeof this.state.showMore[id]==='undefined'){
            return false;
        } else {
            return this.state.showMore[id];
        }
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList(){
        if(typeof this.props.data === 'undefined' || this.props.data===null){
            return(<div></div>);
        }

        return (
            this.props.data.map((data)=>{
                return(
                    <Grid item xs={12} key={data.ps_id}>
                        <Card style={{background:'#eafff3'}}>
                            <CardHeader
                                title={data.schedule_name}
                                subheader={data.p_firstname}
                                action={
                                    <IconButton onClick={() => {
                                            this.onDeleteDialogOpen(data.ps_id);
                                    }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                {data.schedule_description}
                            </CardContent>
                            <CardActions>
                                <IconButton onClick={()=>this.showMore(data.ps_id)}>
                                    {this.showDetail(data.ps_id) && <ExpandMoreIcon style={{transform: 'rotate(180deg)'}}/>}
                                    {!this.showDetail(data.ps_id) && <ExpandMoreIcon/>}
                                </IconButton>
                            </CardActions>
                            <Collapse in={this.showDetail(data.ps_id)} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Monday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d1_start} {data.d1_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Tuesday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d2_start} {data.d2_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Wednesday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d3_start} {data.d3_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Thursday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d4_start} {data.d4_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Friday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d5_start} {data.d5_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Saturday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d6_start} {data.d6_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>Sunday</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Typography variant='body2'>{data.d7_start} {data.d7_end}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Button onClick={()=>{this.editForm(data)}} variant='contained'>Edit</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                )
            })
        )
    }

    renderForm(){
        const { handleSubmit } = this.props;
        let mins15=timeSlots(15);
        return(
            <Dialog
                open={this.state.newDialog}
                onClose={()=>{this.setState({newDialog:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`${this.state.formTitle} Schedule`}</DialogTitle>
                <DialogContent>
                    <form
                        onSubmit={handleSubmit(this.addSchedule)}
                    >
                        <Grid container>
                            <Grid item xs={12} md={7}>
                                <Field
                                    name='schedule_name'
                                    value={this.schedule_name}
                                    component={renderTextField}
                                    label='Name of Schedule'
                                    margin='normal'
                                    fullWidth
                                    onChange={this.handleChange}
                                    validate={[this.required]}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Field
                                    name='schedule_description'
                                    component={renderTextField}
                                    label='Description of Schedule'
                                    margin='normal'
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {border: '1px solid #ced4da'}
                                    }}
                                    multiline
                                    rows='2'
                                    fullWidth
                                    onChange={this.handleChange}
                                    validate={[this.required]}
                                />
                            </Grid>
                            <Hidden mdDown>
                                <Grid item sm={5}>
                                </Grid>
                            </Hidden>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                    <Typography variant='body2'style={{
                                        position: 'absolute',
                                        bottom: 18,
                                        left: 8
                                    }}>Monday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d1_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleTimeChange('d1_end')}
                                    width={90}
                                    defaultValue={'09:00:00'}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d1_end'
                                    label='Finish'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                <Typography variant='body2'style={{
                                    position: 'absolute',
                                    bottom: 18,
                                    left: 8
                                }}>Tuesday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d2_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d2_end'
                                    component={renderNewSelect}
                                    label='Finish'
                                    margin='normal'
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                <Typography variant='body2'style={{
                                    position: 'absolute',
                                    bottom: 18,
                                    left: 8
                                }}>Wednesday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d3_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d3_end'
                                    label='Finish'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                <Typography variant='body2'style={{
                                    position: 'absolute',
                                    bottom: 18,
                                    left: 8
                                }}>Thursday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d4_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d4_end'
                                    label='Finish'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                <Typography variant='body2'style={{
                                    position: 'absolute',
                                    bottom: 18,
                                    left: 8
                                }}>Friday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d5_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d5_end'
                                    label='Finish'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                <Typography variant='body2'style={{
                                    position: 'absolute',
                                    bottom: 18,
                                    left: 8
                                }}>Saturday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d6_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d6_end'
                                    label='Finish'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={4}  style={{position:'relative'}}>

                                <Typography variant='body2'style={{
                                    position: 'absolute',
                                    bottom: 18,
                                    left: 8
                                }}>Sunday</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d7_start'
                                    label='Start'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name='d7_end'
                                    label='Finish'
                                    margin='normal'
                                    component={renderNewSelect}
                                    listOfValues={mins15}
                                    onChange={this.handleChange}
                                    validate={[this.laterThan]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant='contained'>
                                    {this.state.formTitle} Schedule
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        )
    }



    render(){


        return(
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    <Grid item xs={10}>
                        <Typography variant='h6'>Schedules</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={()=>{this.setState({newDialog:true, formTitle:'Add New'})}}><AddIcon/></Button>
                    </Grid>
                    <Grid item xs={12}>
                    {this.renderForm()}
                    </Grid>



                    {this.renderList()}
                    {this.renderDeleteDialog()}
                    {this.renderRefreshDialog()}
                    {this.renderErrorDialog()}
                </Grid>

        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.practitionerSchedules,
            isLoading: state.practitionerScheduleIsLoading,
            isError: state.practitionerScheduleIsError,
            needsRefresh: state.practitionerScheduleNeedsRefresh
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchData, deleteData, addData, updatePage, applySchedule}, dispatch);
}

export default reduxForm({form:'practitionerSchedule'})(connect(mapStateToProps, mapDispatchToProps)(PractitionerScheduleList));
