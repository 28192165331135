import {UPDATE_PAGE, TOGGLE_CHAT, UPDATE_UNREAD} from '../../constants_api';

export function updatePage(page){
    return(dispatch) => {
        dispatch({type:UPDATE_PAGE, page:page});
    }
}
export function toggleChatWindow(){
    return(dispatch) => {
        dispatch({type:TOGGLE_CHAT});
    }
}

export function updateUnreadChats(count){
    return(dispatch) => {
        dispatch({type:UPDATE_UNREAD, count:count})
    }
}