import React, {Component} from 'react';
import {
    Button,
    Tooltip
} from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

class TableZoomSelect extends Component{

    constructor(props){
        super(props);

        this.state= {};
        this.onClick=this.onClick.bind(this);
    }

    onClick(){
        if (typeof this.props.onClick !== 'undefined') {
            this.props.onClick(this.props.idRef);
        }
    }

    render(){
        return(
            <Tooltip id="tooltip-fab" title={this.props.tooltip}>
                <Button color="primary" variant="fab" mini onClick={this.onClick}>
                    <ZoomInIcon/>
                </Button>
            </Tooltip>

        )
    }
}

export default TableZoomSelect;