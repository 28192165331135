import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button,
    Dialog,
    DialogContent,
    CircularProgress,
    Typography,
    Grid,
    Hidden,
} from '@material-ui/core';

import {bindActionCreators} from 'redux';
import {fetchDosagesPatient,addDosage,deleteDosage} from '../actions/Dosage';
import {fetchDeliveryMethods} from '../actions/DeliveryMethods';
import {updatePage} from '../actions/Common';
import {renderTextField, renderSelect, renderCheckBox} from "../Components/Forms";
import {Field, reduxForm} from 'redux-form';
import {checkRole} from '../constants_api';
import moment from 'moment-timezone';
import _ from 'lodash';
import DosageDetail from './dosageDetail';

class DosageByPatient extends Component{

    constructor(props){
        super(props);

        this.state= {
            addNewDialogOpen: false,
            dosage_id_inuse: 0,
            data: {
                dosage_id: 0,
                created: new moment(),
                p_firstname: "",
                p_lastname: "",
                grams: 0,
                used: 0,
                months: 0,
                mandatoryNotes:'',
                deliveryMethod:'',
                cbdOnly:0,
            },
            keyField: 'dosage_id',
            editPath: 'dosage',
            deliveryMethodArray: [],
            monthArray:[{value:1, label:'1'},{value:2, label:'2'},{value:3, label:'3'},{value:4, label:'4'},{value:5, label:'5'},{value:6,label:'6'},
                {value:7, label:'7'},{value:8, label:'8'},{value:9,label:'9'},{value:10, label:'10'},{value:11, label:'11'},{value:12,label:'12'}],
            PrescriptionShow: [],
        };

        this.addDosage=this.addDosage.bind(this);
        this.showPrescriptions=this.showPrescriptions.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.cancel=this.cancel.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath)
        this.props.fetchDosagesPatient({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        if(!this.props.byPatient) {
            this.props.fetchDeliveryMethods({offset: 0, limit: 50, sortField: 'name', sortDirection: 'asc'});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.needsRefresh) {
            this.props.fetchDosagesPatient({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.deliveryMethods!==prevProps.deliveryMethods){
            //create the array needed for the status selector
            var arr = [];
            this.props.deliveryMethods.dataset.map((data)=>{
                arr.push({value:data.delivery_id, label:data.name});
                return true;
            });
            this.setState({deliveryMethodArray:arr});
        }
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
        this.props.reset();
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }


    isNumeric(value) {
        return isNaN(value) ? 'Must be a Number' : undefined;
    }

    isLessThan50(value) {
        return (isNaN(value) || value>50) ? 'Must be a number less than 50' : undefined;
    }

    //Dialog Functions


    showPrescriptions(id){
        const arr = this.state.PrescriptionShow;
        arr[`id_${id}`]=!arr[`id_${id}`];
        this.setState({PrescriptionShow:arr});
    }


    //Action Functions
    addDosage(values){
        const dm = this.props.deliveryMethods.dataset.find( function(obj) {return obj.delivery_id===values.delivery_id});
        const dosage = {
            patient_id: this.props.match.params.patient_id,
            grams: values.grams,
            months: values.months,
            notes: values.notes,
            mandatoryNotes: values.mandatoryNotes,
            delivery_id : values.delivery_id,
            deliveryMethod : dm.name,
            cbdOnly: values.cbdOnly
        }
        this.props.addDosage(dosage);
        this.setState({grams:''});
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    isPractitioner(){
        return checkRole('practitioner');
    }


    //Render Functions
    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }


    renderAddPrescriptionForm(id){
        return (
            <Fragment></Fragment>
        )
    }

    renderList(){
        if(_.isEmpty(this.props.dosages)){
            return(<Grid item xs={12}></Grid>);
        }
        const dos = _.values(this.props.dosages);
        const dosSorted = _.orderBy(dos,'created','desc');
        return (
            dosSorted.map((data)=>{
                return (
                    <DosageDetail data={data} key={data.dosage_id} dosage_id={data.dosage_id} byPatient={this.props.byPatient}/>
                );
            })

        )
    }
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };


    render(){
        const { handleSubmit, pristine, submitting } = this.props;
        return(
            <Fragment>
                <form onSubmit={handleSubmit(this.addDosage)}>
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    {this.props.byPatient &&
                    <Grid item xs={12}>
                        <Typography variant='h6'>Your Prescription details are below.
                        </Typography>
                        <Typography variant="subtitle1">
                            Questions about your prescription? Call us at 1-844- 410-2668 or email at patientservices@cannentaclinic.com to speak with an Educator in detail about your prescription.
                        </Typography>
                    </Grid>
                    }
                    {this.isPractitioner() &&
                        <Fragment>
                        <Grid item xs={12} sm={4}>
                            <Field autoFocus
                                   name='grams'
                                   component={renderTextField}
                                   label='Number of Grams per Day'
                                   margin='normal'
                                   fullWidth
                                   onChange={this.handleChange}
                                   validate={[this.required,this.isNumeric, this.isLessThan50]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
                                name='months'
                                component={renderSelect}
                                label='Number of Months'
                                margin='normal'
                                fullWidth
                                onChange={this.handleChange}
                                listOfValues={this.state.monthArray}
                                validate={[this.required,this.isNumeric]}
                            />
                        </Grid>
                            <Hidden xsDown>
                                <Grid item sm={4}>
                                </Grid>
                            </Hidden>
                        <Grid item xs={12} sm={3}>
                            <Field
                                name='delivery_id'
                                component={renderSelect}
                                label= 'Method'
                                margin='normal'
                                onChange={this.handleChange}
                                listOfValues={this.state.deliveryMethodArray}
                                defaultVal='Patient Choice'
                                validate={[this.required,this.isNumeric]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Grid container direction='row' alignItems='flex-end' style={{height:'100%'}}>
                                <Grid item>
                            <Typography variant="subtitle1" >
                                Please Select a delivery Method. If not Mandatory then select 'Patient Choice'
                            </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                            <Grid item xs={12} sm={3}>
                                <Field
                                    name='mandatoryNotes'
                                    component={renderCheckBox}
                                    margin='normal'
                                    onChange={this.handleChange}
                                    label="Mandatory Notes included"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Field
                                    name='cbdOnly'
                                    component={renderCheckBox}
                                    margin='normal'
                                    onChange={this.handleChange}
                                    label="CBD Only"
                                />
                            </Grid>
                        <Grid item xs={12} sm={9}>
                            <Field
                                   name='notes'
                                   component={renderTextField}
                                   label='Prescription Notes'
                                   margin='normal'
                                   multiline
                                   rows='4'
                                   fullWidth
                                   onChange={this.handleChange}
                                   validate={[this.required]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                Add
                            </Button>
                            <Button disabled={pristine || submitting} onClick={this.cancel} variant='contained'>Cancel</Button>
                        </Grid>
                        </Fragment>
                    }

                </Grid>
            {this.renderRefreshDialog()}
                </form>
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    {this.renderList()}
                </Grid>
            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            dosages: state.dosages,
            isLoading: state.dosageIsLoading,
            isError: state.dosageIsError,
            needsRefresh: state.dosageNeedsRefresh,
            credentials: state.credentials,
            deliveryMethods: state.deliveryMethods
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchDosagesPatient,deleteDosage,addDosage, updatePage, fetchDeliveryMethods}, dispatch);
}

export default reduxForm({
    form: 'addDosageForm'
})(connect(mapStateToProps, mapDispatchToProps)(DosageByPatient));
