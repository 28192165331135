import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    CircularProgress,
    Tooltip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import BackIcon from '@material-ui/icons/ArrowBack';
import {bindActionCreators} from 'redux';
import {getFreeAppointmentSlots,bookAppointmentSlot} from '../actions/Appointments';
import moment from 'moment-timezone';
import {Field, reduxForm} from 'redux-form';
import {updatePage} from '../actions/Common';
import {RenderTimezoneSelect, renderTextField, renderSelect} from "../Components/Forms";
import {
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
    Grid as TGrid,
    VirtualTable,
    TableHeaderRow,
    TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui';



const NameGroupCell = (props) => {
    const { row } = props;
    return (
        <TableGroupRow.Cell {...props}>

            {row.value}

        </TableGroupRow.Cell>
    );
};

const GroupCell = (props) => {
    const { column } = props;
    if (column.name === 'hour') {
        return <NameGroupCell {...props} />;
    }
    return <TableGroupRow.Cell {...props} />;
};

class CalendarWeekView extends Component{

    constructor(props){
        super(props);

        this.state= {
            deleteDialogOpen: false,
            data_id_inuse:0,
            isError:false,
            showMore:[],
            practitioner_id: null,
            newDialog: false,
            formTitle: 'Add New',
            isLoading: false,
            bgcolor: [],
            highlight:{},
            startTime:'09:00',
            endTime:'17:00',
            Calendar:false,
            selectedDate:'',
            selectedTime:'',
            selectArray:[],
            editPath:'patient',
            appt_id:0,
            bookShow:false,
            tz:'America/Toronto',
            rows:[],
            columns:[],
            appts:[],
            practitioners:[],
            dateData:null,
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.gotoPrevUrl=this.gotoPrevUrl.bind(this);
        this.gotoNextUrl=this.gotoNextUrl.bind(this);
        this.timezoneChange=this.timezoneChange.bind(this);
        this.Cell=this.Cell.bind(this);
        this.choose=this.choose.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        let monday = moment().tz(this.state.tz).startOf('isoweek').format('YYYY-MM-DD');
            let tuesday = moment(monday).add(1,'d').format('YYYY-MM-DD');
            let wednesday = moment(monday).add(2,'d').format('YYYY-MM-DD');
            let thursday = moment(monday).add(3,'d').format('YYYY-MM-DD');
            let friday = moment(monday).add(4,'d').format('YYYY-MM-DD');
            let saturday = moment(monday).add(5,'d').format('YYYY-MM-DD');
            let sunday = moment(monday).add(6,'d').format('YYYY-MM-DD');
            let nextMonday = moment(monday).add(7,'d').format('YYYY-MM-DD');
            let prevMonday = moment(monday).subtract(7,'d').format('YYYY-MM-DD');
            let dateData = {monday,tuesday,wednesday,thursday,friday,saturday,sunday,nextMonday, prevMonday};
        let start = `${monday} 00:00:00`;
        let end= `${sunday} 23:45:00`;
        let s = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
        let e = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
        this.setState({dateData});
        let cols = [
            {name: 'hour', title: 'Hour'},
            {name: 'time', title: 'Time'},
            {name: 'monday', title: moment(monday).format('ddd MMM DD')},
            {name: 'tuesday', title: moment(tuesday).format('ddd MMM DD')},
            {name: 'wednesday', title: moment(wednesday).format('ddd MMM DD')},
            {name: 'thursday', title: moment(thursday).format('ddd MMM DD')},
            {name: 'friday', title: moment(friday).format('ddd MMM DD')},
            {name: 'saturday', title: moment(saturday).format('ddd MMM DD')},
            {name: 'sunday', title: moment(sunday).format('ddd MMM DD')},
        ]
        this.setState({columns:cols})
        this.props.getFreeAppointmentSlots({start:s,end:e,byPatient:this.props.byPatient})
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.isError!==prevProps.isError){
            this.setState({isError:this.props.isError});
        }
        if(this.state.dateData!==prevState.dateData ){
            let start = `${this.state.dateData.monday} 00:00:00`;
            let end= `${this.state.dateData.sunday} 23:45:00`;
            let s = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
            let e = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
            this.props.getFreeAppointmentSlots({start:s,end:e,byPatient:this.props.byPatient})
            let cols = [
                {name: 'hour', title: 'Hour'},
                {name: 'time', title: 'Time'},
                {name: 'monday', title: moment(this.state.dateData.monday).format('ddd MMM DD')},
                {name: 'tuesday', title: moment(this.state.dateData.tuesday).format('ddd MMM DD')},
                {name: 'wednesday', title: moment(this.state.dateData.wednesday).format('ddd MMM DD')},
                {name: 'thursday', title: moment(this.state.dateData.thursday).format('ddd MMM DD')},
                {name: 'friday', title: moment(this.state.dateData.friday).format('ddd MMM DD')},
                {name: 'saturday', title: moment(this.state.dateData.saturday).format('ddd MMM DD')},
                {name: 'sunday', title: moment(this.state.dateData.sunday).format('ddd MMM DD')},
            ]
            this.setState({columns:cols})
        }
        if(this.props.data!==prevProps.data || this.state.tz!==prevState.tz){
            //lets create the appts array that we need.
            let appts={};
            let cal={};
            let rows=[];
            let dateData = this.state.dateData;
            let now = moment.utc();
            if(typeof this.props.data.dataset!=='undefined'){
                this.props.data.dataset.map((data)=>{
                    let utm=moment.utc(data.appt_dt,'YYYY-MM-DD HH:mm:ss');
                    if( now>utm ){
                        return true;
                    }
                    let localTime = utm.tz(this.state.tz).format('h:mm a');
                    let localDate= utm.tz(this.state.tz).format('ddd MMM DD');
                    let hr = utm.tz(this.state.tz).format('h a');
                    let nexthr = utm.add(1,'hours').format('h a');
                    let c = {};
                    if(typeof appts[localTime]==='undefined') {
                        appts[localTime]=[];
                    }
                    if(typeof appts[localTime][localDate]==='undefined') {
                        appts[localTime][localDate]=[];
                    }
                    if(typeof cal[localTime]!=='undefined'){
                        c = cal[localTime];
                    }
                    let dow='';
                    let hour= `${hr} to ${nexthr}`;
                    appts[localTime][localDate].push({practitioner:data.practitioner, appointment_id:data.appointment_id});
                    cal[localTime]={...c, localTime, hour};
                    switch(utm.tz(this.state.tz).format('YYYY-MM-DD')) {
                        case dateData.monday :
                            dow='monday';
                            break;
                        case dateData.tuesday :
                            dow='tuesday';
                            break;
                        case dateData.wednesday :
                            dow='wednesday';
                            break;
                        case dateData.thursday :
                            dow='thursday';
                            break;
                        case dateData.friday :
                            dow='friday';
                            break;
                        case dateData.saturday :
                            dow='saturday';
                            break;
                        case dateData.sunday :
                            dow='sunday';
                            break;
                        default :
                            dow='';
                    }
                    cal[localTime]={...cal[localTime], [dow]:'1'};
                    return true;
                })
            }

            Object.keys(cal).map((time)=>{
               rows.push({time,...cal[time]});
               return true;
            });
            this.setState({rows,appts});
        }
        if(this.props.appointmentBooked && !prevProps.appointmentBooked){
            this.props.onBooked();
            //this.props.history.push(`/patient/${this.props.patient_id}`);
        }
    }

    weekChange(day){
        let monday = new moment(day).tz(this.state.tz).startOf('isoweek').format('YYYY-MM-DD');
        let tuesday = moment(monday).add(1,'d').format('YYYY-MM-DD');
        let wednesday = moment(monday).add(2,'d').format('YYYY-MM-DD');
        let thursday = moment(monday).add(3,'d').format('YYYY-MM-DD');
        let friday = moment(monday).add(4,'d').format('YYYY-MM-DD');
        let saturday = moment(monday).add(5,'d').format('YYYY-MM-DD');
        let sunday = moment(monday).add(6,'d').format('YYYY-MM-DD');
        let nextMonday = moment(monday).add(7,'d').format('YYYY-MM-DD');
        let prevMonday = moment(monday).subtract(7,'d').format('YYYY-MM-DD');
        let dateData = {monday,tuesday,wednesday,thursday,friday,saturday,sunday,nextMonday, prevMonday};
        this.setState({dateData})
    }

    bookAppointment(values){
        this.setState({bookShow:false});
        let params = {
            appointment_id:values.appt_id,
            patient_id: this.props.patient_id,
            notes: values.notes,
            byPatient: this.props.byPatient
        }
        this.props.bookAppointmentSlot(params);
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            },
        });
    }

    //Dialog Functions

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }


    onTextFieldChange = item => ({target : {value}}) => {
        this.setState({
            data : {
                ...this.state.data,
                [item]:value
            }
        });

    }

    noValidate(value) {
        return  undefined ;
    }


    handleTextChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    renderBookDialog (){
        const { handleSubmit } = this.props;
        return (
            <Dialog
                open={this.state.bookShow}
                onClose={()=>{this.setState({bookShow:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Booking Appointment on ${this.state.selectedDate} at ${this.state.selectedTime}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                            Please Enter any notes you would like to tell your practitioner ahead of the appointment. e.g. new to cannabis, renewal, recreational user, etc
                    </DialogContentText>
                    <form onSubmit={handleSubmit(this.bookAppointment.bind(this))}>
                        <Field
                            name= 'appt_id'
                            component={renderSelect}
                            label= 'Practitioner'
                            margin='normal'
                            onChange={this.handleTextChange}
                            listOfValues={this.state.practitioners}
                            noBlank={true}
                            validate={[this.required]}
                        />
                    <Field
                        name= 'notes'
                        component={renderTextField}
                        label= 'Notes'
                        margin='normal'
                        onChange={this.handleTextChange}
                        InputProps={{
                            disableUnderline: true,
                            style: {border: '1px solid #ced4da'}
                        }}
                        multiline
                        rows='4'
                        fullWidth
                        validate={[this.noValidate]}
                    />
                    <Button onClick={()=>{this.setState({bookShow:false})}} color="secondary" variant='contained'>
                        Cancel
                    </Button>
                    <Button type="submit" color='primary' variant='contained'>
                        Confirm
                    </Button>
                    </form>
                </DialogContent>
            </Dialog>

        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    gotoPrevUrl(){
        this.props.history.push(this.props.dateData.prevUrl);
    }

    gotoNextUrl(){
        this.props.history.push(this.props.dateData.nextUrl);
    }


    renderCell(props){
        let appt = null;
        let time = null;
        let appointment_id=0;
        let inPast = appt < moment.utc() ? true :false;

        if(inPast){
            return false;
        }
        return(
            <Tooltip id="tooltip-fab" title={`${time}`}>
            <Button color="primary" variant="fab" mini onClick={()=>{this.setState({bookShow:true,appt_id:appointment_id})}}>
                <DoneIcon/>
            </Button>
            </Tooltip>
        )
    }

    choose(date,time){
        var arr = [];
        this.state.appts[time][date].map((data)=>{
            arr.push({value:data.appointment_id, label:data.practitioner});
            return true;
        });
        this.setState({practitioners:arr, selectedDate:date, selectedTime:time});
        this.setState({bookShow:true});
    }

    Cell(props){
        const { column, value, row } = props;
        if (column.name !== 'time' && column.name !== 'hour' && value==='1') {
            return (
                <VirtualTable.Cell {...props}>
                    <Tooltip id="tooltip-fab" title={`${column.title} ${row.localTime}`}>
                        <Button color="primary" variant="fab" mini onClick={()=>{this.choose(column.title,row.localTime)}}>
                            <DoneIcon/>
                        </Button>
                    </Tooltip>
                </VirtualTable.Cell>
            );
        }

        return <VirtualTable.Cell {...props} />;
    }


    timezoneChange(event) {
        this.setState({tz: event.target.value});
    };

    render(){
        let wh = window.innerHeight - 200;
        return(
                <Grid container>
                    <Grid item xs={2}><Button onClick={()=>this.weekChange(this.state.dateData.prevMonday)} color="primary" variant="fab"><BackIcon/></Button></Grid>
                    <Grid item xs={8} style={{textAlign:'center'}}>
                        <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/><Button variant='contained' onClick={this.props.onBooked}>Back</Button>
                    </Grid>
                    <Grid item xs={2} style={{textAlign:'right'}}><Button onClick={()=>this.weekChange(this.state.dateData.nextMonday)} color="primary" variant="fab"><ForwardIcon/></Button></Grid>
                    <Grid item xs={12}>
                    {this.state.columns.length>6 && 
                        <TGrid
                            rows={this.state.rows}
                            columns={this.state.columns}
                            style={{ height: `${wh}px` }}
                        >
                            <GroupingState
                                grouping={[{ columnName: 'hour' }]}
                            />
                            <IntegratedGrouping />
                            <VirtualTable
                                cellComponent={this.Cell}
                                height="auto"
                            />
                            <TableHeaderRow />
                            <TableGroupRow
                                cellComponent={GroupCell}
                            />
                        </TGrid>
                    }
                    </Grid>

                    {this.renderBookDialog()}
                    {this.renderRefreshDialog()}
                    {this.renderErrorDialog()}
                </Grid>

        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.calendar,
            isLoading: state.calendarIsLoading,
            isError: state.calendarIsError,
            appointmentBooked : state.appointmentBooked,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getFreeAppointmentSlots,bookAppointmentSlot, updatePage }, dispatch);
}

export default reduxForm({form:'addAppointment'})(connect(mapStateToProps, mapDispatchToProps)(CalendarWeekView));
