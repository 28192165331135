import React, {Component} from 'react';
//import ExpiringPrescriptionList from '../../containers/expiringPrescriptionList';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Paper} from '@material-ui/core';
import DayAppointments from '../../containers/dayAppointments'

const styles = theme => ({
    outer: {
        height: '80vh',
    },
    paper: {
        padding: theme.spacing.unit * 5,
        height: '100%',
        margin:'20'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    textField: {
        marginLeft: '50',
        marginRight: '50',
        paddingTop: '50',
        paddingBottom: '50',
        width: 200,
    },
});


class DailyAppointments extends Component {

    render() {
        const { classes } = this.props;
        let date = '';
        let practitioner = 'All'
        if(typeof this.props.match.params.date!=='undefined'){
            date = this.props.match.params.date;
        }
        if(typeof this.props.match.params.practitioner !== 'undefined'){
            practitioner = this.props.match.params.practitioner;
        }
        return (
            <Grid container className={classes.gridPageOuter}>
                <Grid item xs={12}>
                    <Grid item className={classes.gridContentOuter}>
                        <Paper className={classes.paper}>
                            <DayAppointments {...this.props} date={date} practitioner={practitioner}/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(DailyAppointments);