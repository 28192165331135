import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography
    } from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchUserDetail, editUser, addUser} from '../actions/Users';
import {Field, reduxForm,} from 'redux-form';
import {renderTextField} from '../Components/Forms';


class UserDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            user_id_inuse: 0,
            user_id:0,
            username: "",
            password: "",
            confirmpassword: "",
            firstname: "",
            lastname: "",
            deleteDialogOpen: false
        };
        this.handleChange=this.handleChange.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    }

    componentDidMount() {
        if(this.props.match.params.user_id>0){
            this.props.fetchUserDetail({user_id:this.props.match.params.user_id});
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.user!==prevProps.user){
                this.setState({
                    user_id: this.props.user.user_id,
                    username: this.props.user.username,
                    password: this.props.user.password,
                    firstname: this.props.user.firstname,
                    lastname: this.props.user.lastname
                });
                const formData = {
                    "username": this.props.user.username,
                    "firstname": this.props.user.firstname,
                    "lastname": this.props.user.lastname,
                    "password": '',
                    "confirmpassword": ''
                };
                this.props.initialize(formData);
            if(this.props.user.iAmNew){
                this.props.history.push(`/user/${this.props.user.user_id}`);
            }
        }
        if(this.props.location!==prevProps.location){
            this.props.fetchUserDetail({user_id:this.props.match.params.user_id});
        }

    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({user_id_inuse : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onSubmit (values){
        var user ={};
        if(this.props.match.params.user_id>0){
            user = {
                user_id: this.props.match.params.user_id,
                username : values.username,
                firstname: values.firstname,
                lastname: values.lastname,
                password: values.password
            }
            this.props.editUser(user);
        } else {
            user = {
                username : values.username,
                firstname: values.firstname,
                lastname: values.lastname,
                password: values.password
            }
            this.props.addUser(user);
        }

    }


    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        const delete_id = {user_id: id};
        this.props.deleteUser(delete_id);
    }


    //Render Functions

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete User ${this.state.user_id_inuse} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.user_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRefreshDialog(){
        return(<Dialog
            fullScreen
            open={this.props.isLoading}
            PaperProps={{style:{background:'transparent'}}}
        >
            <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                <DialogContent>
                    <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                </DialogContent>
            </div>
        </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/users')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/users')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

     handleChange = name => event => {
        this.setState({[name]: event.target.value});
     };




    render(){
        const { handleSubmit } = this.props;
        return(<div>
                <Typography variant="h5" color="inherit"><u>Profile</u></Typography>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div>
                        <Field
                            autoFocus
                            name= 'username'
                            component={renderTextField}
                            label= 'Username'
                            margin='normal'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <Field
                            name= 'firstname'
                            component={renderTextField}
                            label= 'First name'
                            margin='normal'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <Field
                            name= 'lastname'
                            component={renderTextField}
                            label= 'Last name'
                            margin='normal'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <Field
                            name= 'password'
                            component={renderTextField}
                            label='Password'
                            type='password'
                            margin='normal'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <Field
                            name= 'confirmpassword'
                            component={renderTextField}
                            label='Confirm Password'
                            type='password'
                            margin='normal'
                            onChange={this.handleChange}
                        />
                    </div>

                    <div>
                        <Button type="submit" color='secondary' variant='contained'>
                            Save
                        </Button>
                    </div>
                </form>
                {this.renderDeleteDialog()}
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
            </div>
        );
    }
}



const validate = values => {
    const errors = {}
    const requiredFields = [
        'username',
        'firstname',
        'lastname'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })
    if(values['password']!==values['confirmpassword']) {
        errors['confirmpassword'] = 'Please Ensure passwords Match';
    }
    return errors
}

function mapStateToProps(state){
    return(
        {
            user: state.user,
            isLoading: state.userIsLoading,
            isError: state.userIsError,
            credentials: state.credentials
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchUserDetail, editUser, addUser}, dispatch);
}


export default reduxForm({
    validate,
    form: 'editUserForm'
})(connect(mapStateToProps,mapDispatchToProps)(UserDetail));

