import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid
    } from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {getPatientMedicalInfo,setPatientMedicalInfo} from '../actions/PatientMedicalInfo';
import {Field, reduxForm, } from 'redux-form';
import {renderTextField, renderRadio, renderCheckBox} from '../Components/Forms';



class PatientMedicalInfo extends Component{

    constructor(props){
        super(props);

        this.state= {
            patient_id:0,
            how_negative:'',
            used_cannabis:0,
            used_cannabis_how:'',
            allergies:0, 
            allergy_list:'',
            abuse_program:0,
            abuse_program_details:'',
            history_side_affects:0,
            history_side_affects_details:'',
            prev_medical_treatments:'', 
            hope_to_gain:'',
            privacy:false,
            citizen:false,
            consent_to_share:false,
            agree_to_treat:false,
            yesNoList:[{value:'1', label:'Yes'},{value:'0',label:'No'}],
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleCheckChange=this.handleCheckChange.bind(this);
        this.cancel=this.cancel.bind(this);
    }

    componentDidMount() {
        if(this.props.patient_id>0){
            this.props.getPatientMedicalInfo({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
    }

    componentDidUpdate(prevProps, prevState){
       if(this.props.medical!==prevProps.medical){
                this.setState({
                    patient_id: this.props.patient_id,
                    how_negative:this.props.medical.how_negative,
                    used_cannabis:this.props.medical.used_cannabis,
                    used_cannabis_how:this.props.medical.used_cannabis_how,
                    allergies:this.props.medical.allergies,
                    allergy_list:this.props.medical.allergy_list,
                    abuse_program:this.props.medical.abuse_program,
                    abuse_program_details:this.props.medical.abuse_program_details,
                    history_side_affects:this.props.medical.history_side_affects,
                    history_side_affects_details:this.props.medical.history_side_affects_details,
                    prev_medical_treatments:this.props.medical.prev_medical_treatments,
                    hope_to_gain:this.props.medical.how_to_gain,
                    privacy:this.props.medical.privacy === "1" ? true : false,
                    citizen:this.props.medical.citizen === "1" ? true : false,
                    consent_to_share:this.props.medical.consent_to_share === "1" ? true : false,
                    agree_to_treat:this.props.medical.agree_to_treat === "1" ? true : false,
                });
                const formData = {
                    "how_negative":this.props.medical.how_negative,
                    "used_cannabis":this.props.medical.used_cannabis,
                    "used_cannabis_how":this.props.medical.used_cannabis_how,
                    "allergies":this.props.medical.allergies,
                    "allergy_list":this.props.medical.allergy_list,
                    "abuse_program":this.props.medical.abuse_program,
                    "abuse_program_details":this.props.medical.abuse_program_details,
                    "history_side_affects":this.props.medical.history_side_affects,
                    "history_side_affects_details":this.props.medical.history_side_affects_details,
                    "prev_medical_treatments":this.props.medical.prev_medical_treatments,
                    "hope_to_gain":this.props.medical.how_to_gain,
                    "privacy":this.props.medical.privacy === "1" ? true : false,
                    "citizen":this.props.medical.citizen === "1" ? true : false,
                    "consent_to_share":this.props.medical.consent_to_share === "1" ? true : false,
                    "agree_to_treat":this.props.medical.agree_to_treat === "1" ? true : false,
                };
                this.props.initialize(formData);
        }
        if(this.props.location!==prevProps.location){
            this.props.getPatientMedicalInfo({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
        this.props.reset();
    }


    //Action Functions

    onSubmit (values){
        if(this.props.patient_id>0){
            var medical = {
                patient_id: this.props.patient_id,
                how_negative:values.how_negative,
                used_cannabis:values.used_cannabis,
                used_cannabis_how:values.used_cannabis_how,
                allergies:values.allergies,
                allergy_list:values.allergy_list,
                abuse_program:values.abuse_program,
                abuse_program_details:values.abuse_program_details,
                history_side_affects:values.history_side_affects,
                history_side_affects_details:values.history_side_affects_details,
                prev_medical_treatments:values.prev_medical_treatments,
                hope_to_gain:values.how_to_gain,
                privacy:values.privacy ? '1' : '0',
                citizen:values.citizen ? '1' : '0',
                consent_to_share:values.consent_to_share ? '1' : '0',
                agree_to_treat:values.agree_to_treat ? '1' : '0',
                byPatient:this.props.byPatient,
            }
            this.props.setPatientMedicalInfo(medical);
            if(typeof this.props.dataSaved!=='undefined'){
                this.props.dataSaved();
            }
        }
    }



    //Render Functions


    renderRefreshDialog(){
        return(<Dialog
            fullScreen
            open={this.props.isLoading}
            PaperProps={{style:{background:'transparent'}}}
        >
            <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                <DialogContent>
                    <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                </DialogContent>
            </div>
        </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.setState({isError:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({isError:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    handleCheckChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    render(){
        const { handleSubmit, pristine, submitting } = this.props;
        if(this.props.patient_id<1){
            return(<div></div>);
        }
        return(
            <Fragment>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        {this.props.byPatient &&
                        <Grid item xs={12}>
                            <Typography variant='h6'>Medical History</Typography>
                            <Typography variant="subtitle1">Please answer the following questions and provide as many details as possible. </Typography>
                        </Grid>
                        }
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>How do your selected conditions impact your daily activities, mental health,and/or sleep?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'how_negative'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Have you used cannabis to help with this medical condition(s)? If yes, please give more details.</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'used_cannabis'
                                component={renderRadio}
                                listOfValues={this.state.yesNoList}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'used_cannabis_how'
                                component={renderTextField}
                                label= 'details'
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Are you suffering from any allergies? (if yes, please list them)</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'allergies'
                                component={renderRadio}
                                listOfValues={this.state.yesNoList}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'allergy_list'
                                component={renderTextField}
                                label= 'allergy list'
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Are you currently attending or have you recently (within 1 year) attended any substance abuse program?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'abuse_program'
                                component={renderRadio}
                                listOfValues={this.state.yesNoList}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'abuse_program_details'
                                component={renderTextField}
                                label= 'Details'
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Do you have a personal history of any of the following: Schizophrenia, psychosis, bipolar disorder, major depression, or serious liver/kidney/heart/lung disease. If yes, please give more details below.</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'history_side_affects'
                                component={renderRadio}
                                listOfValues={this.state.yesNoList}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'history_side_affects_details'
                                component={renderTextField}
                                label= 'Details'
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}style={{paddingBottom:'0px'}}>
                        <Typography variant='h6'>Please list all past and present medications and treatments you have undergone in order to manage the symptoms of your condition (e.g. prescription medication, therapy, etc.). You are required to upload supporting documentation prior to your appointment with our nurse practitioner.</Typography>
                    </Grid>

                    <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                        <Field
                            name= 'prev_medical_treatments'
                            component={renderTextField}
                            label= 'Details'
                            margin='normal'
                            onChange={this.handleChange}
                            InputProps={{
                                disableUnderline: true,
                                style: {border: '1px solid #ced4da'}
                            }}
                            multiline
                            rows='4'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                        <Field
                            name= 'privacy'
                            component={renderCheckBox}
                            margin='none'
                            onChange={this.handleChange}
                            hasLinks={true}
                            label={
                                <div>
                                    <span>I have read, understood and agree to the </span>
                                    <span><a href='http://cannentaclinic.com/privacy/' target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>
                                    <span> and the </span>
                                    <span><a href='http://cannentaclinic.com/terms/' target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>
                                </div>
                            }
                        />

                    </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'citizen'
                                component={renderCheckBox}
                                margin='none'
                                onChange={this.handleChange}
                                label="I confirm I am a Canadian citizen, permanent resident or an aboriginal currently residing in Canada."
                            />

                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'consent_to_share'
                                component={renderCheckBox}
                                margin='none'
                                onChange={this.handleChange}
                                label="I give consent to share my application with Ausabis's trusted healthcare professionals as is needed to process my application"
                            />

                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                name= 'agree_to_treat'
                                component={renderCheckBox}
                                margin='none'
                                onChange={this.handleChange}
                                label="I agree to be treated by a healthcare team located in Canada."
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                NEXT
                            </Button>
                            <Button disabled={pristine || submitting} onClick={this.cancel} variant='contained'>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
                {this.renderRefreshDialog()}
            </Fragment>
        );
    }
}



const validate = values => {
    const errors = {}
    const requiredFields = [
        'how_negative',
        'used_cannabis',
        'allergies',
        'abuse_program',
        'history_side_affects',
        'prev_medical_treatments',
        'hope_to_gain',
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })
    if(values.history_side_affects==='1'){
        if(!values.history_side_affects_details){
            errors.history_side_affects_details = 'Please enter side affect details';
        }
    }
    if(values.used_cannabis==='1'){
        if(!values.used_cannabis_how){
            errors.used_cannabis_how = 'Please enter how you used cannabis';
        }
    }
    if(values.allergies==='1'){
        if(!values.allergy_list){
            errors.allergy_list = 'Please enter your allergy details';
        }
    }
    if(values.abuse_program==='1'){
        if(!values.abuse_program_details){
            errors.abuse_program_details = 'Please enter details';
        }
    }

    if(!values.agree_to_treat){
        errors.agree_to_treat = 'Please accept the terms of treatment';
    }
    if(!values.citizen){
        errors.citizen = 'Please confirm your citizenship';
    }
    if(!values.consent_to_share){
        errors.consent_to_share = 'Please accept your consent to share';
    }
    if(!values.privacy){
        errors.privact = 'Please accept the privacy terms';
    }
    return errors
}

function mapStateToProps(state){
    return(
        {
            medical: state.medical,
            isLoading: state.patientIsLoading,
            isError: state.patientIsError,
            credentials: state.credentials,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getPatientMedicalInfo,setPatientMedicalInfo}, dispatch);
}


export default reduxForm({
    validate,
    form: 'editPatientMedicalForm'
})(connect(mapStateToProps,mapDispatchToProps)(PatientMedicalInfo));

