import React, { Component} from 'react';
import {Button, Grid, Typography, Dialog,DialogTitle,DialogActions,DialogContentText, DialogContent} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {renderTextField} from '../Components/Forms'
import {bindActionCreators} from "redux";
import {authPatient, forgotPassword} from "../actions/Patients";
import {updatePage} from "../actions/Common";
import {connect} from 'react-redux';



class PatientLoginDetail extends Component {

    constructor(props){
        super(props);
        this.state={
            email:'',
            loginFailed:false,
        }
        this.handleChange=this.handleChange.bind(this);
    }

    onSubmit(values){
        const cred = {email:values.email, password:values.password}
        this.props.authPatient(cred);
    }

    componentDidMount(){
        this.props.updatePage('/');
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.credentials!==prevProps.credentials){
            if(typeof (this.props.credentials.token) !=='undefined'){
                this.props.history.push('/client');
            }
        }
        if(this.props.loginFailed && !prevProps.loginFailed){
            this.setState({loginFailed:true});
        }
    }

    forgotPassword(sendBy){
        if(this.state.email>''){
            this.props.forgotPassword({email:this.state.email, sendBy:'Email'});
            alert('A Message has been sent to your email address');
        } else {
            alert('Please enter your email address in field before clicking "Forgot Password"');
        }

    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.loginFailed}
                onClose={()=>{this.setState({loginFailed:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Incorrect Login`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to log you in at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({loginFailed:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    render(){
        const {handleSubmit} = this.props;
        const { classes } = this.props;
        return(
            <Grid container  style={{flexGrow:1}} justify='center'>
                {this.renderErrorDialog()}
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Patient Login</Typography>
                    </Grid>
                    <Grid item xs={12}>
                          <Field
                              autoFocus
                            name= 'email'
                            component={renderTextField}
                            label= 'Email Address'
                            className={classes.textField}
                            margin='normal'
                              onChange={this.handleChange}
                          />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name= 'password'
                            component={renderTextField}
                            label='Password'
                            type='password'
                            className={classes.textField}
                            margin='normal'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" color='secondary' variant='contained'>
                            Login
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'10px'}}>
                        <Typography variant="body2">Forgot Password?
                            <Button onClick={()=>{this.forgotPassword()}} color='primary'>
                                Click Here
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:'10px'}}>
                        <Typography variant="body2">Don't have an Account?
                            <Button onClick={()=>{this.props.history.push('/signup')}} color='primary'>
                                Register Here
                            </Button>
                        </Typography>
                    </Grid>
                </form>
            </Grid>

        );
    }

}

const validate = values => {
    const errors = {}
    const requiredFields = [
        'username',
        'password'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    return errors
}

function mapStateToProps(state){
    return(
        {
            credentials: state.credentials,
            loginFailed: state.loginFailed,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({authPatient, updatePage, forgotPassword}, dispatch);
}


export default reduxForm({
    validate,
    form: 'userLoginForm'
})(connect(mapStateToProps,mapDispatchToProps)(PatientLoginDetail));