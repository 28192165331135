import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    CircularProgress,
    Select,
    MenuItem,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {getDateAppointments} from '../actions/Appointments';
import {
    Grid as TGrid,
    VirtualTable,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {updatePage} from '../actions/Common';
import moment from 'moment-timezone';
import {RenderTimezoneSelect, DateSelect} from "../Components/Forms";
import _ from 'lodash';


class DayAppointments extends Component{

    constructor(props){
        super(props);

        this.state= {
            editPath: 'viewSchedules',
            rows:[],
            columns: [],
            allColumns:[],
            dateSelected:'',
            tz:'America/Toronto',
            practitioners:[],
            practitionerSelected:"All",
        };
        this.Cell=this.Cell.bind(this);
        this.goTo=this.goTo.bind(this);
        this.DateChange = this.DateChange.bind(this);
        this.onPractitionerChange=this.onPractitionerChange.bind(this);
        this.timezoneChange=this.timezoneChange.bind(this);
        this.reloadDate=this.reloadDate.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        this.reloadDate(this.props.date);
        if(typeof this.props.practitioner !== 'undefined'){
            this.setState({practitionerSelected:this.props.practitioner});
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalCommand);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.data!==prevProps.data){
            if(this.props.data.total===0){
                this.setState({rows:[]});
            } else{
                this.setState({rows:this.props.data.dataset});
                this.buildDataGrid(this.props.data);
            }
        }
        if(this.props.date!==prevProps.date){
            this.reloadDate(this.props.date);
        }
    }

    buildDataGrid(data){
        //Loop through list of practitioners to create columns
        let practitioners=[];
        let cols=[{name:'appt_dt', title:'Time'}];
        const ps = _.values(data.practitioners);
        const psSorted = _.orderBy(ps,'practitioner','asc');
        psSorted.map((data)=>{
            practitioners.push({value:`p_${data.practitioner_id}` , label:data.practitioner});
            cols.push({name:`p_${data.practitioner_id}`,title:data.practitioner});
            return true;
        })
        //loop through the db data to create grid
        let grid = [];
        data.dataset.map((d)=>{
            let c = grid[d.appt_dt];
            grid[d.appt_dt]={...c, appt_dt:d.appt_dt, [`p_${d.practitioner_id}`]:{patient_id:d.patient_id,patient:d.patient}};
            return true;
        });
        let newGrid=_.values(grid);
        this.setState({rows:newGrid, allColumns:cols, practitioners});
        if(this.state.practitionerSelected==='All'){
            this.setState({columns:cols});
        } else {
            let t = cols.find(x => x.name===this.state.practitionerSelected);
            if(typeof t !== 'undefined'){
                let newCols = [{name:'appt_dt', title:'Time'},{name:this.state.practitionerSelected, title:t.title}];
                this.setState({columns:newCols});
            } else {
                this.setState({columns:[{name:'appt_dt', title:'Time'}]});
            }
        }
    }

    goTo(link){
        this.props.history.push(`/patient/${link}`);
    }

    Cell = (props) => {
        const { column } = props;
        if (column.name !== 'appt_dt') {
            if(typeof props.row[column.name] === 'undefined'){
                return(
                    <VirtualTable.Cell {...props} />
                )
            }
            let p= props.row[column.name].patient;
            let a=props.row[column.name].patient_id;
            if(p===null){
                return (
                    <VirtualTable.Cell {...props}>
                        Free
                    </VirtualTable.Cell>
                )
            } else {
                return (
                    <VirtualTable.Cell {...props}>
                        <Button onClick={()=>this.goTo(a)} color='primary' variant='contained'>{p}</Button>
                    </VirtualTable.Cell>
                );
            }
        }
        let tm = moment.utc(props.row.appt_dt).tz(this.state.tz).format('h mm a');
        return (
            <VirtualTable.Cell {...props}>
                {tm}
            </VirtualTable.Cell>
                );
    }

    DateChange(value) {
        let d = value;
        let e = d.format('YYYY-MM-DD');
        this.props.history.push(`/dailyappointments/${e}/${this.state.practitionerSelected}`);
    };

    reloadDate(value){
        let e = null;
        if(value==='' || typeof value === 'undefined'){
            e = moment.tz(moment(),this.state.tz).format('YYYY-MM-DD');
        } else {
            e = value + " 00:00:00";
        }
        let a =moment.tz(e,this.state.tz);
        let dateSelected=a.utc().format('YYYY-MM-DD');
        this.setState({dateSelected});
        this.props.getDateAppointments({startdate:a.format('YYYY-MM-DD hh:mm:ss')});
    }

    onPractitionerChange(event){
        this.setState({practitionerSelected:event.target.value});
        if(event.target.value==='All'){
            this.setState({columns:this.state.allColumns});
        } else {
            let title = this.state.allColumns.find(x => x.name===event.target.value).title;
            let newCols = [{name:'appt_dt', title:'Time'},{name:event.target.value, title:title}];
            this.setState({columns:newCols});
        }
        this.props.history.push(`/dailyappointments/${this.state.dateSelected}/${event.target.value}`);
    }

    timezoneChange(event) {
        this.setState({tz: event.target.value});
        this.reloadDate(this.state.dateSelected);
    };

    renderRefreshDialog(){
        let Style={
            background: 'transparent',
            textAlign: 'center',
            margin: 'auto'
        }
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={Style}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderSelectPractitioner(){
        let listOfValues=this.state.practitioners;
        return    (
                <Select
                    value={this.state.practitionerSelected}
                    onChange={this.onPractitionerChange}
                    name='practitioner'
                    autoWidth
                >
                    <MenuItem value="All">
                        <em>All</em>
                    </MenuItem>
                    {listOfValues.map((item)=>{
                            return(<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                        }
                    )}
                </Select>
        );
    }

    render(){
        const { rows, columns } = this.state;
        let tableHeight = Math.max(window.innerHeight -200,600);
        return(
            <Fragment>
                <Grid container>
                    <Grid item xs={4} style={{textAlign:'center'}}>
                        <DateSelect value={this.state.dateSelected} onChange={this.DateChange} myFormat='DD MMM YYYY' timezone={this.state.tz} showTodayButton/>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='h6' align='center'>Daily Appointments</Typography>
                    </Grid>
                    <Grid item xs={4} style={{textAlign:'center'}}>Practitioner : {this.renderSelectPractitioner()}</Grid>
                    <Grid item xs={2} style={{textAlign:'center'}}>
                        <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/>
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={12}>

                        <TGrid
                            rows={rows}
                            columns={columns}
                        >

                            <VirtualTable
                                cellComponent={this.Cell}
                                height={tableHeight}
                            />
                            <TableHeaderRow />
                        </TGrid>
                    </Grid>
                </Grid>
                {this.renderRefreshDialog()}
            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.dateAppointments,
            isLoading: state.patientAppointmentsIsLoading,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getDateAppointments, updatePage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DayAppointments);
