import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_AILMENTS = 'FETCH_AILMENTS';
export const FETCH_AILMENT_DETAIL = 'FETCH_AILMENT_DETAIL';
export const ADD_AILMENT = 'ADD_AILMENT';
export const EDIT_AILMENT = 'EDIT_AILMENT';
export const AILMENT_IS_LOADING = 'AILMENT_IS_LOADING';
export const AILMENT_IS_ERROR = 'AILMENT_IS_ERROR';
export const AILMENT_NEEDS_REFRESH = 'AILMENT_NEEDS_REFRESH';

export function fetchAilments(params){
    return (dispatch) => {
        dispatch({type: AILMENT_IS_LOADING, bool :true});
        dispatch({type: AILMENT_IS_ERROR, bool:false});
        dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}ailment/list?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_AILMENTS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: AILMENT_IS_ERROR, bool:true});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchAilmentDetail(ailment_id){
    return (dispatch) => {
        dispatch({type: AILMENT_IS_LOADING, bool :true});
        dispatch({type: AILMENT_IS_ERROR, bool:false});
        dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}ailment/fetch`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,ailment_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_AILMENT_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: AILMENT_IS_ERROR, bool:true});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addAilment(ailment){
    return (dispatch) => {
        dispatch({type: AILMENT_IS_LOADING, bool :true});
        dispatch({type: AILMENT_IS_ERROR, bool:false});
        dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}ailment/add`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,ailment, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_AILMENT, ailment_id: response.data.ailment_id})
                dispatch({type: AILMENT_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: AILMENT_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: AILMENT_IS_ERROR, bool:true});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
        });
    }
}

export function deleteAilment(id){
    return (dispatch) => {
        dispatch({type: AILMENT_IS_LOADING, bool :true});
        dispatch({type: AILMENT_IS_ERROR, bool:false});
        dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}ailment/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const ailment_id = {ailment_id:id};
        const request = axios.post(url,ailment_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: AILMENT_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: AILMENT_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: AILMENT_IS_ERROR, bool:true});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
        });
    }
}

export function editAilment(ailment){
    return (dispatch) => {
        dispatch({type: AILMENT_IS_LOADING, bool :true});
        dispatch({type: AILMENT_IS_ERROR, bool:false});
        dispatch({type: AILMENT_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}ailment/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,ailment, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: AILMENT_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: AILMENT_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: AILMENT_IS_ERROR, bool:true});
            dispatch({type: AILMENT_IS_LOADING, bool: false});
        });
    }
}