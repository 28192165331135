import React ,{Component, Fragment} from 'react';
import {Button, Typography, Paper, Grid, List, ListItem} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import {styles} from "../../style";
import SendIcon from '@material-ui/icons/Send';
import moment from "moment-timezone";
import {Interweave} from 'interweave';


class ChatMessageView extends Component {

    constructor(props){
        super(props);

        this.state= {
            message:'',

        };
        this.sendMessage=this.sendMessage.bind(this);
        this.handleMessageChange=this.handleMessageChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.messages !== prevProps.messages && this.props.lastMessage>''){
            // @ts-ignore
            this.refs[this.props.lastMessage].scrollIntoView();
        }
    }

    sendMessage(){
        this.props.addMessage(this.props.chat,this.state.message);
        this.setState({message:''});
    }

    handleMessageChange(event){
        this.setState({message:event.target.value})
    }

    render() {
        const { classes, chat, messages, lastMessage  } = this.props;
        const { message } = this.state;
        const options = {
            convertShortnames: true,
            convertUnicode: true,
            convertAscii: true,
            style: {
                height: 20,
                margin: 4,
            },
        };
        let title ='Select User to Chat';
        let online = false;
        if(chat) {
            if(typeof chat.data !== 'undefined'){
                title=chat.data.sortName;
                online=chat.data.loggedIn;
            }
        }
        return(
            <div className={classes.chatMessageList}>
                <div className={classes.chatMessageUser}>
                    {online &&
                    <Typography variant={"h6"} className={classes.chatMsgTitle}>
                        {title} <Button onClick={this.props.onCloseClick}>X</Button>
                    </Typography>
                    }
                    {!online &&
                    <Typography variant={"h6"} className={classes.chatMsgTitleOffline}>
                        {title}<Button onClick={this.props.onCloseClick}>X</Button>
                    </Typography>
                    }
                </div>
                <div className={classes.chatMessageBackground}>
                    {messages.length > 0 &&
                        <Fragment>
                        {messages.map((message) => {
                            let msgTxt = message.data.msgTxt.replace(/\r\n|\r|\n/g,"<br />");
                            let d = moment.utc(message.data.dt).local().format('HH:mm:ss');
                            //update message to read
                            if(this.props.myUserId !== message.data.msgFrom && !message.data.readState ){
                                this.props.readMessage(message.id);
                            }
                            return(
                                    <div key={message.id} ref={message.id}>
                                        { this.props.myUserId !== message.data.msgFrom &&
                                        <div className={classes.divRecdMessages}>
                                            <div className={classes.divmsgDt}>{`Rec'd ${d}`}</div>
                                            <div className={classes.messageText}><Interweave content={msgTxt} /></div>
                                        </div>
                                        }
                                        { this.props.myUserId === message.data.msgFrom &&
                                        <div className={classes.divSentMessages}>
                                            <div className={classes.divmsgDtS}>{`Sent ${d}`}</div>
                                            <div className={classes.messageText}><Interweave content={msgTxt}/></div>
                                        </div>
                                        }

                                    </div>
                                )
                            }
                        )}
                        </Fragment>
                    }
                </div>
                <div className={classes.divChatMessageForm}>
                    <div className={classes.divChatMessageInput}>
                            <TextField
                                name='message'
                                id='message'
                                onChange={this.handleMessageChange}
                                value={message}
                                fullWidth
                                rowsMax={3}
                                multiline
                                InputProps={{
                                    className: classes.chatMessageInput,
                                    disableUnderline: true,
                                    placeholder: 'Type your message here',
                                }}
                                onKeyPress={(ev) => {
                                    if (!ev.shiftKey && ev.key === 'Enter') {
                                        this.sendMessage();
                                        ev.preventDefault();
                                    }
                                }}
                            />
                    </div>
                    <div className={classes.divChatMessageSend}>
                        <Fab size='small' onClick={this.sendMessage}><SendIcon/></Fab>
                    </div>
                </div>

            </div>
        );
    }
}


// @ts-ignore
export default (withStyles(styles)(ChatMessageView));
