import React, {Component} from 'react';
import {Grid} from '@material-ui/core';


export default class VideoParticipant extends Component{

    constructor(props) {
        super(props);
        this.state={
            started:true,
        }
    }

    componentDidMount() {
        this.showVideo();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.tracks!==prevProps.tracks){
            this.showVideo();
        }
    }

    showVideo(){
        let container = this.refs[this.props.elem];
        let positionInfo = container.getBoundingClientRect();
        let width = positionInfo.width;
        let height = this.props.height;
        if(typeof height ==='undefined'){
            if(positionInfo.height>0){
                height = positionInfo.height;
            } else {
                height = 60;
            }
            
        }
        if (container.querySelector('video')) {
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
        }
        let tracks = this.props.tracks
        tracks.forEach(publication => {
            if (this.props.participant.isLocal){
                let track = publication.track;
                let vid =container.appendChild(track.attach());
                if(track.kind==='video'){
                    if(track.dimensions.height<height && typeof this.props.height !== 'undefined'){
                        vid.style.height=`${height}px`;
                    }
                }
                vid.style.maxWidth=`${width}px`;
                if(height>0){
                    vid.style.maxHeight=`${height}px`;
                }
            } else {
                if (publication.isSubscribed) {
                    let track = publication.track;
                    let vid =container.appendChild(track.attach());
                    if(track.kind==='video'){
                        if(track.dimensions.height<height && typeof this.props.height !== 'undefined'){
                            vid.style.height=`${height}px`;
                        }
                    }
                    vid.style.maxWidth=`${width}px`;
                    if(height>0){
                        vid.style.maxHeight=`${height}px`;
                    }
                }
            }
        });
    }


    render(){
      let participant = this.props.participant;
        return (
            <Grid item xs={12}>
                <div ref={this.props.elem} onClick={()=>this.props.onClick(participant.identity)}/>
            </Grid>
        );

    }
}