import * as TYPE from "../actions/IdentificationPhotos";


export function identification_photos (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_ID_PHOTOS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function identification_photo (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_ID_PHOTO_DETAIL:
            return action.payload;
        case TYPE.ADD_ID_PHOTO:
            return action.payload;
        default:
            return state;
    }
}

export function identificationPhotoIsLoading (state = false, action){
    switch(action.type){
        case TYPE.ID_PHOTO_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function identificationPhotoIsError (state = false, action){
    switch(action.type){
        case TYPE.ID_PHOTO_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function identificationPhotoNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.ID_PHOTO_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
