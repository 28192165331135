import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    CircularProgress,
    Typography,
    Table, TableHead, TableRow, TableCell, TableBody,
    Tooltip,
} from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue'
import InfoIcon from '@material-ui/icons/Done';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import BackIcon from '@material-ui/icons/ArrowBack';
import {bindActionCreators} from 'redux';
import {fetchPractitionerCalendar, clearAppointment, addAppointmentSlot} from '../actions/Appointments';
import {fetchPractitionerSchedules,applySchedule} from '../actions/PractitionerSchedules';
import {updatePage} from '../actions/Common';
import _ from 'lodash';
import "react-table-drag-select/style.css";
import moment from 'moment-timezone';
import {Field, reduxForm} from 'redux-form';
import {renderSelect} from '../Components/Forms'

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: lightBlue,
    },
});

class PractitionerWeekView extends Component{

    constructor(props){
        super(props);

        this.state= {
            deleteDialogOpen: false,
            data_id_inuse:0,
            isError:false,
            showMore:[],
            editPath:'practitioner',
            practitioner_id: null,
            newDialog: false,
            formTitle: 'Add New',
            cells: [
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false]
            ],
            isLoading: false,
            bgcolor: [],
            highlight:{},
            startTime:'09:00',
            endTime:'17:00',
            Calendar:false,
            selectArray:[],
            tz:'America/Toronto',
        };

        this.handleChange = this.handleChange.bind(this);
        this.showDetail=this.showDetail.bind(this);
        this.gotoPrevUrl=this.gotoPrevUrl.bind(this);
        this.gotoNextUrl=this.gotoNextUrl.bind(this);
        this.applySchedule=this.applySchedule.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        let start = `${this.props.dateData.monday} 00:00:00`;
        let end= `${this.props.dateData.sunday} 23:45:00`;
        let s = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
        let e = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
        this.props.fetchPractitionerCalendar({practitioner_id:this.props.practitioner_id,start:s,end:e})
        this.props.fetchPractitionerSchedules({practitioner_id:this.props.practitioner_id});
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.isError!==prevProps.isError){
            this.setState({isError:this.props.isError});
        }
        if(this.props.dateData!==prevProps.dateData || this.props.needsRefresh){
            let start = `${this.props.dateData.monday} 00:00:00`;
            let end= `${this.props.dateData.sunday} 23:45:00`;
            let s = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
            let e = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
            this.props.fetchPractitionerCalendar({practitioner_id:this.props.practitioner_id,start:s,end:e})
        }
        if(this.props.data!==prevProps.data){
            //lets create the appts array that we need.
            let appts={};
            if(typeof this.props.data.dataset!=='undefined'){
                this.props.data.dataset.map((data)=>{
                    let utm=moment.utc(data.appt_dt,'YYYY-MM-DD HH:mm:ss');
                    let localTime = utm.tz(this.state.tz).format('HH:mm');
                    let localDate= utm.tz(this.state.tz).format('YYYY-MM-DD');
                    if(typeof appts[localTime]==='undefined'){
                        appts[localTime]={};
                    }
                    appts[localTime][localDate]={appointment_id:data.appointment_id, patient_id: data.patient_id, status: data.status, utc:utm, patient: data.patient};
                    return true;
                })
            }
            let myTimes = this.buildArraySlots(this.props.dateData,appts);
            this.setState({Calendar:myTimes})
        }
        if(this.props.schedules!==prevProps.schedules && this.props.schedules!==null){
            //create the array needed for the status selector
            var arr = [];
            this.props.schedules.map((schedule)=>{
                arr.push({value:schedule.ps_id, label:schedule.schedule_name});
                return true;
            });
            this.setState({selectArray:arr});
        }
    }

    buildArraySlots(dateData,appts){
        let start = '00:00';
        let end = '23:45';
        let slots=[];
        let startTime = moment.utc(start, "HH:mm");
        let endTime = moment.utc(end, "HH:mm");
        while(startTime<=endTime){
            let t = startTime.format("HH:mm");
            let a={};
            if(typeof appts !=='undefined'){
                if(typeof appts[t]!=='undefined'){
                    a=appts[t];
                }
            }
            slots[t]={
                time:t,
                [dateData.monday]:a[dateData.monday],
                [dateData.tuesday]:a[dateData.tuesday],
                [dateData.wednesday]:a[dateData.wednesday],
                [dateData.thursday]:a[dateData.thursday],
                [dateData.friday]:a[dateData.friday],
                [dateData.saturday]:a[dateData.saturday],
                [dateData.sunday]:a[dateData.sunday]
            };
            startTime.add(15,'minutes');
        }
        return slots;
    }

    applySchedule(values) {
        let startdate = this.props.dateData.monday;
        this.props.applySchedule({schedule_id:values.ps_id, startdate});
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            },
            tableheader: {
                position: "sticky",
                top: 0,
                zIndex: 50,
                backgroundColor: '#fff'
    },
            tablecell:{
                padding: '12px',
            }

        });
    }

    //Dialog Functions

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }


    onTextFieldChange = item => ({target : {value}}) => {
        this.setState({
            data : {
                ...this.state.data,
                [item]:value
            }
        });

    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    showDetail(id){
        if(typeof this.state.showMore[id]==='undefined'){
            return false;
        } else {
            return this.state.showMore[id];
        }
    }

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete this schedule ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.data_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    gotoPrevUrl(){
        this.props.history.push(this.props.dateData.prevUrl);
    }

    gotoNextUrl(){
        this.props.history.push(this.props.dateData.nextUrl);
    }

    renderTitleBar(){
        return(
            <TableRow>
                <TableCell padding='dense' style={this.Style().tableheader}>Local Time</TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Mon</Typography><Typography variant="subtitle1">{moment(this.props.dateData.monday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Tue</Typography><Typography variant="subtitle1">{moment(this.props.dateData.tuesday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Wed</Typography><Typography variant="subtitle1">{moment(this.props.dateData.wednesday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Thu</Typography><Typography variant="subtitle1">{moment(this.props.dateData.thursday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Fri</Typography><Typography variant="subtitle1">{moment(this.props.dateData.friday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Sat</Typography><Typography variant="subtitle1">{moment(this.props.dateData.saturday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Sun</Typography><Typography variant="subtitle1">{moment(this.props.dateData.sunday).format('MMM DD')}</Typography></TableCell>
            </TableRow>
        )
    }

    renderEarlierBar(){
        let startTime = '00:00';
        let endTime = '09:00';
        if(this.state.startTime!=='00:00'){
            if(this.state.startTime>'09:00') {
                startTime ='09:00';
                endTime = '17:00';
            }
            return(
                <TableRow>
                    <TableCell colSpan={8} style={{textAlign:'center'}}><Button onClick={()=>{this.setState({startTime, endTime})}}>Earlier</Button></TableCell>
                </TableRow>
            )
        }
        return false;
    }

    renderLaterBar(){
        let endTime = '23:45'
        let startTime = '17:00'
        if(this.state.endTime!=='23:45'){
            if(this.state.endTime<'17:00') {
                endTime ='17:00';
                startTime = '09:00';
            }
            return(
                <TableRow>
                    <TableCell colSpan={8} style={{textAlign:'center'}}><Button onClick={()=>{this.setState({endTime,startTime})}}>Later</Button></TableCell>
                </TableRow>
            )
        }
        return false;
    }

    renderRows(){
        if(!this.state.Calendar){
            return(<TableRow/>)
        }
        let calendar = _.values(this.state.Calendar);
        return(
            calendar.map((data)=>{
                if(data.time>=this.state.startTime && data.time<=this.state.endTime){
                    return(
                        <TableRow key={data.time}>
                            <TableCell padding='dense'>{data.time}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.monday],this.props.dateData.monday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.tuesday],this.props.dateData.tuesday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.wednesday],this.props.dateData.wednesday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.thursday],this.props.dateData.thursday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.friday],this.props.dateData.friday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.saturday],this.props.dateData.saturday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.sunday],this.props.dateData.sunday,data.time)}</TableCell>
                        </TableRow>
                    )
                }
                return false;
            })
        )
    }

    renderCell(data,date,time){
        let a_dt = date + ' ' +time + ":00";
        let appt = moment.tz(a_dt,this.state.tz).utc();
        let inPast = appt < moment.utc() ? true :false;
        let appt_dt = appt.format('YYYY-MM-DD HH:mm:ss');
        if(typeof data === 'undefined'){
            if (inPast){
                return(<div/>)
            }
            return(
                <Button variant="fab" mini
                onClick={()=>{this.props.addAppointmentSlot({practitioner_id:this.props.practitioner_id,appt_dt})}}>
                    {'\u00A0'}
                </Button>
            )
        }
        if(data.status==='0'){
            if(inPast){
                return(
                    <Button color="secondary" variant="fab" mini disabled>
                        {'\u00A0'}
                    </Button>
                )
            }
            return(
                <Button color="primary" variant="fab" mini
                        onClick={()=>{this.props.clearAppointment({appointment_id:data.appointment_id})}}>
                    {'\u00A0'}
                </Button>
            )
        }
        return(
            <MuiThemeProvider theme={theme}>
                <Tooltip title={data.patient}>
                    <Button variant="fab" mini  color="primary"
                                     onClick={()=>{this.props.history.push(`/patient/${data.patient_id}`)}}>
                        <InfoIcon/>
                    </Button>
                </Tooltip>
            </MuiThemeProvider>
        )

    }

    render(){
        const { handleSubmit } = this.props;

        return(
                <Grid container>
                    <Grid item xs={2}><Button onClick={this.gotoPrevUrl} color="primary" variant="fab"><BackIcon/></Button></Grid>
                    <Grid item xs={8} style={{textAlign:'center'}}>
                        <form onSubmit={handleSubmit(this.applySchedule)}>
                            <Field
                                name= 'ps_id'
                                component={renderSelect}
                                label= 'Schedule'
                                onChange={this.handleChange}
                                listOfValues={this.state.selectArray}
                                margin="none"
                                validate={[this.required]}
                            />
                            <Button type="submit" color='secondary' variant='contained'>Apply Schedule to Week</Button>
                        </form>
                    </Grid>
                    <Grid item xs={2} style={{textAlign:'right'}}><Button onClick={this.gotoNextUrl} color="primary" variant="fab"><ForwardIcon/></Button></Grid>
                    <Grid item xs={12}>
                        <div>
                        <Table style={{overflow:'unset'}}>
                            <TableHead>
                                {this.renderTitleBar()}
                            </TableHead>
                            <TableBody>
                                {this.renderEarlierBar()}
                                {this.renderRows()}
                                {this.renderLaterBar()}
                            </TableBody>

                        </Table>
                        </div>
                    </Grid>

                    {this.renderDeleteDialog()}
                    {this.renderRefreshDialog()}
                    {this.renderErrorDialog()}
                </Grid>

        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.practitionerCalendar,
            isLoading: state.practitionerCalendarIsLoading,
            isError: state.practitionerCalendarIsError,
            needsRefresh: state.practitionerCalendarNeedsRefresh,
            schedules: state.practitionerSchedules,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPractitionerCalendar,clearAppointment,addAppointmentSlot, updatePage, fetchPractitionerSchedules, applySchedule}, dispatch);
}

export default reduxForm({form:'practitionerApplySchedule'})(connect(mapStateToProps, mapDispatchToProps)(PractitionerWeekView));
