import React ,{Component, Fragment} from 'react';
import { Drawer,  Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {styles} from "../../style";
import {getChatToken} from '../../actions/Users';
import _ from 'lodash';
import ChatUserList from './chatUserList';
import ChatMessageView from './chatMessageView';
import {CHATSERVER} from '../../constants_api';
import moment from 'moment-timezone';
import {updateUnreadChats} from '../../actions/Common';
import  {initializeApp} from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth, signInWithCustomToken} from "firebase/auth";


class Chat extends Component {

    constructor(props){
        super(props);
        this.state = {
            connected:false,
            db:null,
            my_user_id:null,
            chatUsers:[],
            tabSelect: 0,
            getToken: false,
            chatSelected: null,
            messagesSelected:[],
            inMessages:[],
            outMessages:[],
            myUnread:[],
            countUnread:0,
        };
        this.setDB=this.setDB.bind(this);
        this.resetDB=this.resetDB.bind(this);
        this.getChatToken=this.getChatToken.bind(this);
        this.onSelectedChat=this.onSelectedChat.bind(this);
        this.addMessage=this.addMessage.bind(this);
        this.readMessage=this.readMessage.bind(this);
    }

    componentDidMount() {
        this.getChatToken();
        this.config = {
            apiKey: "AIzaSyByvN3n6xWuuYAFhm84h8rj3wT6ZBIyWMk",
            authDomain: "ausabis-chat.firebaseapp.com",
            databaseURL: "https://ausabis-chat.firebaseio.com",
            projectId: "ausabis-chat",
            storageBucket: "ausabis-chat.appspot.com",
            messagingSenderId: "836959547603"
        };
        this.firebaseApp = initializeApp(this.config);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.chatToken!==prevProps.chatToken){
            const auth = getAuth();
            signInWithCustomToken(auth,this.props.chatToken).then(this.setDB).catch(this.resetDB);
        }
        //if the db is defined then set my login to true.
        if(this.state.db && this.state.db!==prevState.db){
            let db = this.state.db;
            db.collection(CHATSERVER.chatUsers).doc(this.props.credentials.details.user_id).set({
                firstname: this.props.credentials.details.firstname, //set my name in case it got changed
                lastname: this.props.credentials.details.lastname,
                loggedIn: true,
                },{merge : true});
        }
        //check to see if we have credentials but no chatToken
        if(this.props.credentials!==prevProps.credentials && typeof this.props.credentials.details !== 'undefined'){
            //we only want to fire a connect if the user changed - the token changes all the time.
            if(this.props.credentials.details.user_id !== prevProps.credentials.details.user_id){
                if(!this.props.chatToken && !this.state.connected){
                    //go grab a chat token
                    this.props.getChatToken();
                }
            }
        }
        if(this.state.inMessages!==prevState.inMessages || this.state.outMessages!==prevState.outMessages){
            let messages = _.concat(this.state.inMessages,this.state.outMessages);
            let sortedMessages = _.orderBy(messages,['dt'],['asc']);
            this.setState({messagesSelected:sortedMessages});
        }
        if(this.props.showChatWindow!==prevProps.showChatWindow && !this.props.showChatWindow){
            this.setState({tabSelect:0})
        }
        if(this.state.chatUsers!==prevState.chatUsers){
            //our chatusers have changed - time to reset and update our unread messages
            //now get unread messages and add to chatUsers.
            let unReadMsgs=this.state.db.collection(CHATSERVER.messages).where("msgTo", '==', this.props.credentials.details.user_id ).where("readState", "==", false)
            .onSnapshot((querySnapshot) => {
                let myUnread = [];
                querySnapshot.forEach(function(message) {
                    myUnread.push({id:message.id,from:message.data().msgFrom});
                });
                let countUnread = myUnread.length;
                this.setState({myUnread,countUnread});
                console.log("Unread Messages:",countUnread);
                this.props.updateUnreadChats(countUnread);
            })
        }
        if(this.state.myUnread!==prevState.myUnread){
            console.log("New Unread Messages",this.state.myUnread);
        }
    }

    componentWillUnmount(){
        let db = this.state.db;
        if(db){
            db.collection(CHATSERVER.chatUsers).doc(this.state.my_user_id).set({
                loggedIn: false,
            }, { merge: true });
            let unsubscribeUsers = db.collection(CHATSERVER.chatUsers)
                .onSnapshot(function () {});
            let unsubscribeMessages = db.collection(CHATSERVER.messages)
                .onSnapshot(function () {});
            unsubscribeUsers();
            unsubscribeMessages();
        }
    }

    getChatToken(){
        this.props.getChatToken();
        this.setState({connected:false, getToken:true});
    }

    setDB(){
        let db=getFirestore(this.firebaseApp);
        //now setup listeners for creating a list of users
        let ChatUsers = db.collection(CHATSERVER.chatUsers).orderBy("firstname");
        ChatUsers.onSnapshot((querySnapshot) => {
            let myUsers = [];
            querySnapshot.forEach((doc) => {
                let firstInitial='';
                let firstName='';
                let lastInitial='';
                let lastName='';
                let data=doc.data();
                if(data.firstname){
                    firstInitial=data.firstname.substring(0,1);
                    firstName=data.firstname;
                }
                if(data.lastname){
                    lastInitial=data.lastname.substring(0,1);
                    lastName=data.lastname;
                }
                let initials = firstInitial + lastInitial;
                let sortName=firstName + ' ' + lastName;
                data.sortName = sortName.toUpperCase();
                data.displayName=sortName.toLowerCase()
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
                data.initials=initials;
                let loggedIn = data.loggedIn ? true :false ;
                if(typeof  this.props.credentials.details!== 'undefined'){
                    if(doc.id!==this.props.credentials.details.user_id){
                        myUsers.push({"id":doc.id, data: data, sortName:data.sortName, loggedIn: loggedIn});
                    }
                }
            });
            let chatUsers = _.orderBy(myUsers,['loggedIn','sortName'],['desc','asc'])
            this.setState({chatUsers,db,connected:true, getToken:false});
            if(typeof this.props.credentials.details !=='undefined'){
                this.setState({my_user_id:this.props.credentials.details.user_id});
            }
        });
    }

    resetDB(){
        this.setState({db:null,connected:false});
    }



    onSelectedChat(chat){
        let db = this.state.db;
        this.setState({chatSelected:chat, tabSelect:1});
        //now get the messages for the chat selected
        //first get messages from chat person where I am the recipient
        if(typeof this.inMsgs!=='undefined'){
            console.log('We have inMsgs function',this.inMsgs);
            this.inMsgs();
        }
        if(typeof this.outMsgs!=="undefined"){
            this.outMsgs();
        }
        this.inMsgs=db.collection(CHATSERVER.messages).where("msgFrom", '==', chat.id ).where("msgTo", "==", this.props.credentials.details.user_id)
            .onSnapshot((querySnapshot) => {
                let inMessages = [];
                querySnapshot.forEach(function(message) {
                    inMessages.push({id:message.id,data:message.data(), dt:message.data().dt});
                });
                this.setState({inMessages});
            });
        this.outMsgs=db.collection(CHATSERVER.messages).where("msgFrom", '==', this.props.credentials.details.user_id ).where("msgTo", "==", chat.id)
            .onSnapshot((querySnapshot) => {
                let outMessages = [];
                querySnapshot.forEach(function(message) {
                    outMessages.push({id:message.id,data:message.data(), dt:message.data().dt});
                });
                this.setState({outMessages});
            });
    }

    addMessage(chat, message){
        //add message to db
        let db = this.state.db;
        let utc = moment.utc().format('YYYY-MM-DD hh:mm:ss');
        db.collection(CHATSERVER.messages).add({
            msgTxt: message,
            msgFrom: this.props.credentials.details.user_id,
            msgTo: chat.id,
            dt: utc,
            readState:false,
        }); //put sender in read recipients as well to make read queries easier.
    }

    readMessage(msgId){
        let db = this.state.db;
        let utc = moment.utc().format('YYYY-MM-DD hh:mm:ss');
        db.collection(CHATSERVER.messages).doc(msgId).set({
            readState: true,
            readTime: utc,
        },{merge : true});
    }

    handleChangeTab = (event, value) => {
        this.setState({ tabSelect: value });
    };

    render() {
        const { classes} = this.props;
        const { tabSelect,db } = this.state;
        let messagesSelected=[];
        let lastMessageId= '';
        if(this.state.messagesSelected.length>0){
            messagesSelected=this.state.messagesSelected;
            lastMessageId=_.last(messagesSelected).id;
        }
        return(
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={this.props.showChatWindow}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                    <div  className={classes.chatMain}>
                        <Typography variant='h6' className={classes.chatTitle}>CANNENTA CHAT</Typography>
                        {!this.state.connected && !this.state.getToken &&
                            <Button onClick={this.getChatToken} color='secondary'>Connect to Chat</Button>
                        }
                        {this.state.connected && tabSelect===0 &&
                        <div className={classes.divFlexGrowScroll}>
                                <ChatUserList 
                                    chatUsers={this.state.chatUsers} 
                                    onChatSelect={this.onSelectedChat} 
                                    unRead={this.state.myUnread} 
                                />
                        </div>
                            }
                        {this.state.connected && tabSelect===1 &&
                            <Fragment>
                                <div className={classes.chatCompressedList}>
                                    <ChatUserList chatUsers={this.state.chatUsers} onChatSelect={this.onSelectedChat} unRead={this.state.myUnread}/>
                                </div>
                                    <ChatMessageView
                                        chat={this.state.chatSelected}
                                        messages={messagesSelected}
                                        addMessage={this.addMessage}
                                        onCloseClick={()=>{this.setState({tabSelect:0})}}
                                        myUserId={this.props.credentials.details.user_id}
                                        lastMessage={lastMessageId}
                                        readMessage={this.readMessage}
                                        db={db}
                                    />

                            </Fragment>
                        }
                    </div>
            </Drawer>
        );
    }
}

function mapStateToProps(state){
    return(
        {
            showChatWindow: state.showChatWindow,
            chatToken: state.chatToken,
            credentials: state.credentials,
            fetchChatToken: state.fetchChatToken,
            unreadMsgs: state.unreadMsgs,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getChatToken,updateUnreadChats}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Chat));
