import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_STATUSES = 'FETCH_STATUSES';
export const FETCH_STATUSES_DETAIL = 'FETCH_STATUSES_DETAIL';
export const ADD_STATUSES = 'ADD_STATUSES';
export const EDIT_STATUSES = 'EDIT_STATUSES';
export const STATUSES_IS_LOADING = 'STATUSES_IS_LOADING';
export const STATUSES_IS_ERROR = 'STATUSES_IS_ERROR';
export const STATUSES_NEEDS_REFRESH = 'STATUSES_NEEDS_REFRESH';

export function fetchStatus(params){
    return (dispatch) => {
        dispatch({type: STATUSES_IS_LOADING, bool :true});
        dispatch({type: STATUSES_IS_ERROR, bool:false});
        dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}status/list?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_STATUSES, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: STATUSES_IS_ERROR, bool:true});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchStatusDetail(status_id){
    return (dispatch) => {
        dispatch({type: STATUSES_IS_LOADING, bool :true});
        dispatch({type: STATUSES_IS_ERROR, bool:false});
        dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}status/fetch`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_STATUSES_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: STATUSES_IS_ERROR, bool:true});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addStatus(status){
    return (dispatch) => {
        dispatch({type: STATUSES_IS_LOADING, bool :true});
        dispatch({type: STATUSES_IS_ERROR, bool:false});
        dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}status/add`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_STATUSES, status_id: response.data.status_id})
                dispatch({type: STATUSES_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: STATUSES_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: STATUSES_IS_ERROR, bool:true});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
        });
    }
}

export function deleteStatus(id){
    return (dispatch) => {
        dispatch({type: STATUSES_IS_LOADING, bool :true});
        dispatch({type: STATUSES_IS_ERROR, bool:false});
        dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}status/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const status_id = {status_id:id};
        const request = axios.post(url,status_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: STATUSES_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: STATUSES_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: STATUSES_IS_ERROR, bool:true});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
        });
    }
}

export function editStatus(status){
    return (dispatch) => {
        dispatch({type: STATUSES_IS_LOADING, bool :true});
        dispatch({type: STATUSES_IS_ERROR, bool:false});
        dispatch({type: STATUSES_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}status/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: STATUSES_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: STATUSES_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: STATUSES_IS_ERROR, bool:true});
            dispatch({type: STATUSES_IS_LOADING, bool: false});
        });
    }
}