import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_HC_PHOTOS = 'FETCH_HC_PHOTOS';
export const FETCH_HC_PHOTO_DETAIL = 'FETCH_HC_PHOTO_DETAIL';
export const ADD_HC_PHOTO = 'ADD_HC_PHOTO';
export const EDIT_HC_PHOTO = 'EDIT_HC_PHOTO';
export const HC_PHOTO_IS_LOADING = 'HC_PHOTO_IS_LOADING';
export const HC_PHOTO_IS_ERROR = 'HC_PHOTO_IS_ERROR';
export const HC_PHOTO_NEEDS_REFRESH = 'HC_PHOTO_NEEDS_REFRESH';


export function fetchPatientHealthcardPhoto(params){
    return (dispatch) => {
        dispatch({type: HC_PHOTO_IS_LOADING, bool :true});
        dispatch({type: HC_PHOTO_IS_ERROR, bool:false});
        dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/getHealthcardDocument`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: HC_PHOTO_IS_LOADING, bool: false});
            dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_HC_PHOTO_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: HC_PHOTO_IS_ERROR, bool:true});
            dispatch({type: HC_PHOTO_IS_LOADING, bool: false});
            dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addHealthcardPhoto(params){
    return (dispatch) => {
        dispatch({type: HC_PHOTO_IS_LOADING, bool :true});
        dispatch({type: HC_PHOTO_IS_ERROR, bool:false});
        dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/addHealthcardDocument?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: HC_PHOTO_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_HC_PHOTO, payload: response.data.answer})
                dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: HC_PHOTO_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: HC_PHOTO_IS_ERROR, bool:true});
            dispatch({type: HC_PHOTO_IS_LOADING, bool: false});
        });
    }
}

export function deleteHealthcardPhoto(id){
    return (dispatch) => {
        dispatch({type: HC_PHOTO_IS_LOADING, bool :true});
        dispatch({type: HC_PHOTO_IS_ERROR, bool:false});
        dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/deleteHealthcardDocument`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const photo_id = {healthcard_photo_id:id};
        const request = axios.post(url,photo_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: HC_PHOTO_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: HC_PHOTO_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: HC_PHOTO_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: HC_PHOTO_IS_ERROR, bool:true});
            dispatch({type: HC_PHOTO_IS_LOADING, bool: false});
        });
    }
}

