import React, {Component} from 'react';
import SetPassword from '../../containers/patientSetPassword';
import {Grid, Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {PublicHeader} from '../Layouts';


const styles = theme => ({
    outer: {
        height: '80vh',
    },
    paper: {
        padding: theme.spacing.unit * 5,
        margin:'20'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: '50',
        marginRight: '50',
        paddingTop: '50',
        paddingBottom: '50',
        width: 200,
    },
    menu: {
        width: 200,
    },
});

class ConfirmationPage extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container  justify='center'>
                <Grid item xs={12}>
                    <PublicHeader {...this.props}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <Paper className={classes.paper}>
                                <SetPassword {...this.props}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ConfirmationPage);



