import React ,{Component} from 'react';
import LpsList from '../../containers/lp-list';
import {Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    outer: {
        height: '80vh',
    },
    paper: {
        padding: theme.spacing.unit * 5,
        height: '100%',
        margin:'20'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
});



class Lps extends Component {
    render() {
        return(
            <Grid container style={{height: '94vh'}}>
            <Grid item style={{height: '80vh'}} xs={12}>

            <LpsList {...this.props}/>
            </Grid>

            </Grid>
        );
    }
}

export default withStyles(styles)(Lps);
