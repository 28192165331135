import React, {Component} from 'react';
import StatusDetail from '../../containers/status-detail';
import {Grid, Paper} from '@material-ui/core';
import {updatePage} from '../../actions/Common';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../style';


class StatusDetails extends Component {
    componentDidMount() {
        this.props.updatePage('status');
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container className={classes.gridPageOuter}>
                <Grid item xs={12}>

                    <Grid item className={classes.gridContentOuter}>
                        <Grid container alignItems='center' justify='center' direction='row'>
                            <Grid item xs={12} >
                                <Paper className={classes.paper}>
                                    <Grid container justify='space-around' direction='row' style={{width:'100%'}}>

                                            <StatusDetail {...this.props}/>

                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Grid>


                </Grid>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({updatePage}, dispatch);
}
export default connect(null,mapDispatchToProps)(withStyles(styles)(StatusDetails));



