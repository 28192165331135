import * as TYPE from "../actions/Ailments";


export function ailments (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_AILMENTS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function ailment (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_AILMENT_DETAIL:
            return action.payload;
        case TYPE.ADD_AILMENT:
            return {ailment_id: action.ailment_id, iAmNew: true};
        default:
            return state;
    }
}

export function ailmentIsLoading (state = false, action){
    switch(action.type){
        case TYPE.AILMENT_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function ailmentIsError (state = false, action){
    switch(action.type){
        case TYPE.AILMENT_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function ailmentNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.AILMENT_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
