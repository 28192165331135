import { combineReducers } from 'redux';
import {patients,patientIsLoading, patientIsError, patientNeedsRefresh, patient,
    patientAilments,patientAilmentsIsError,patientAilmentsIsLoading,patientAilmentsNeedsRefresh,
    patientNotes,patientNotesIsError,patientNotesIsLoading,patientNotesNeedsRefresh, patientReminderSent,
    patientSignupIsError,patientSignupWait,patientPasswordUpdated} from './reducer_patients';
import {ailments,ailmentIsLoading, ailmentIsError, ailmentNeedsRefresh, ailment} from './reducer_ailments';
import {users, userIsLoading, userIsError, userNeedsRefresh, user, userRoles, userRolesIsError, userRolesIsLoading, userRolesNeedsRefresh, changingPassword, passwordMessage, chatToken, fetchChatToken} from './reducer_users';
import {credentials, loginFailed, unreadMsgs} from './reducer_auth';
import {reducer as formReducer}from 'redux-form';
import {page,showChatWindow} from './reducer_currentPage';
import {status,statuses,statusIsError,statusIsLoading,statusNeedsRefresh} from './reducer_statuses';
import {apptStatus,apptStatuses,apptStatusIsError,apptStatusNeedsRefresh,appttatusIsLoading} from './reducer_apptStatus';
import {medical,medicalIsError,medicalIsLoading} from './reducer_patientMedicalInfo';
import {mailing,mailingIsError,mailingIsLoading} from './reducer_patientMailing';
import {patientLpInfo,patientLPInfoErrorMessage,patientLPInfoIsError,patientLPInfoIsLoading,patientLPInfoNeedsRefresh} from './reducer_patientLPInfo';
import {address,addresses, mailingAddress,mailingAddresses} from './reducer_address';
import {dosage,dosageIsError,dosageIsLoading,dosageNeedsRefresh,dosages,dosageDetails} from './reducer_dosages';
import {lp,lpIsError,lpIsLoading,lpNeedsRefresh,lps, lpMethodIsError,lpMethodIsLoading,lpMethodNeedsRefresh,lpMethods} from './reducer_lps';
import {deliveryMethod,deliveryMethods,deliveryMethodIsError,deliveryMethodIsLoading,deliveryMethodNeedsRefresh} from './reducer_deliveryMethods';
import {prescription,prescriptionIsError,prescriptionIsLoading,prescriptionNeedsRefresh,prescriptions,expiringIsError,expiringIsLoading,expiringPrescriptions} from './reducer_prescriptions';
import {pdNeedsRefresh,pdIsError,pdIsLoading,practitionerDetail} from './reducer_practitioners';
import {identification_photo,identification_photos,identificationPhotoIsError,identificationPhotoIsLoading,identificationPhotoNeedsRefresh} from './reducer_identification_photos';
import {healthcard_photo,healthcard_photos,healthcardPhotoIsError,healthcardPhotoIsLoading,healthcardPhotoNeedsRefresh} from './reducer_healthcard_photos';
import {communication,communicationIsError,communicationIsLoading,communicationNeedsRefresh,communications} from './reducer_communications';
import {videoToken,videoIdentity,loadingParticipants,participants,videoCommandLoading,videoCommands,videoCommandSetting,rooms,loadingRooms} from './reducer_video';
import {practitionerScheduleIsError,practitionerScheduleIsLoading,practitionerScheduleNeedsRefresh,practitionerSchedules} from './reducer_practitionerSchedules';
import {followup,followupIsError,followupIsLoading} from './reducer_patientFollowup';
import {practitionerCalendar,practitionerCalendarIsError,practitionerCalendarIsLoading,practitionerCalendarNeedsRefresh,calendar,
    calendarIsError,calendarIsLoading,calendarNeedsRefresh,appointmentBooked,patientAppointments,patientAppointmentsIsIsError,patientAppointmentsIsLoading,patientAppointmentsIsNeedsRefresh,
    appointmentCancelled, dateAppointments} from './reducer_appts';
import {cancelReason,cancelReasons,cancelReasonIsError,cancelReasonIsLoading,cancelReasonNeedsRefresh} from './reducer_cancelReason';
import {patient_photo,patient_photos,patientPhotoIsError,patientPhotoIsLoading,patientPhotoNeedsRefresh} from './reducer_patient_photos';

const rootReducer = combineReducers({
    credentials, loginFailed, unreadMsgs,
    patients,patientIsLoading,patientIsError,patientNeedsRefresh,patient,
    patientNotes,patientNotesIsError,patientNotesIsLoading,patientNotesNeedsRefresh,
    patientAilments,patientAilmentsIsLoading,patientAilmentsIsError,patientAilmentsNeedsRefresh,patientReminderSent,
    patientSignupIsError,patientSignupWait,patientPasswordUpdated,
    ailments,ailmentIsLoading,ailmentIsError,ailmentNeedsRefresh,ailment,
    users,userIsLoading,userIsError,userNeedsRefresh,user,changingPassword,passwordMessage,chatToken,fetchChatToken,
    userRoles,userRolesIsLoading,userRolesIsError,userRolesNeedsRefresh,
    status,statuses,statusIsLoading,statusIsError,statusNeedsRefresh,
    apptStatus,apptStatuses,apptStatusIsError,apptStatusNeedsRefresh,appttatusIsLoading,
    medical,medicalIsError,medicalIsLoading,
    mailing,mailingIsError,mailingIsLoading,
    patientLpInfo,patientLPInfoErrorMessage,patientLPInfoIsError,patientLPInfoIsLoading,patientLPInfoNeedsRefresh,
    address,addresses,mailingAddress,mailingAddresses,
    dosage,dosageIsError,dosageIsLoading,dosageNeedsRefresh,dosages,dosageDetails,
    lp,lpIsError,lpIsLoading,lpNeedsRefresh,lps, lpMethodIsError,lpMethodIsLoading,lpMethodNeedsRefresh,lpMethods,
    deliveryMethod,deliveryMethods,deliveryMethodIsError,deliveryMethodIsLoading,deliveryMethodNeedsRefresh,
    prescription,prescriptionIsError,prescriptionIsLoading,prescriptionNeedsRefresh,prescriptions,expiringIsError,expiringIsLoading,expiringPrescriptions,
    pdNeedsRefresh,pdIsError,pdIsLoading,practitionerDetail,
    identification_photo,identification_photos,identificationPhotoIsError,identificationPhotoIsLoading,identificationPhotoNeedsRefresh,
    healthcard_photo,healthcard_photos,healthcardPhotoIsError,healthcardPhotoIsLoading,healthcardPhotoNeedsRefresh,
    communication,communicationIsError,communicationIsLoading,communicationNeedsRefresh,communications,
    videoToken,videoIdentity,loadingParticipants,participants,videoCommandLoading,videoCommands,videoCommandSetting,rooms,loadingRooms,
    practitionerScheduleIsError,practitionerScheduleIsLoading,practitionerScheduleNeedsRefresh,practitionerSchedules,
    calendar,calendarIsError,calendarIsLoading,calendarNeedsRefresh,appointmentBooked,patientAppointmentsIsIsError,patientAppointmentsIsLoading,patientAppointmentsIsNeedsRefresh,patientAppointments,
    followup,followupIsError,followupIsLoading,
    practitionerCalendar,practitionerCalendarIsError,practitionerCalendarIsLoading,practitionerCalendarNeedsRefresh,appointmentCancelled,dateAppointments,
    cancelReason,cancelReasons,cancelReasonIsError,cancelReasonIsLoading,cancelReasonNeedsRefresh,
    patient_photo,patient_photos,patientPhotoIsError,patientPhotoIsLoading,patientPhotoNeedsRefresh,
    form: formReducer,
    page,showChatWindow
});

export default rootReducer;

// patient_tab,patient_tabIsError,patient_tabIsLoading,patient_tabNeedsRefresh,patient_tabs,
//     question,questionIsError,questionIsLoading,questionNeedsRefresh,questions,
//     tabquestionIsError,tabquestionIsLoading,tabquestionNeedsRefresh,tabquestions,
//     answer,answerIsError,answerIsLoading,answerNeedsRefresh,