import * as TYPE from "../actions/ApptStatus";


export function apptStatuses (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_APPTSTATUS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function apptStatus (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_APPTSTATUS_DETAIL:
            return action.payload;
        case TYPE.ADD_APPTSTATUS:
            return {status_id: action.status_id, iAmNew: true};
        default:
            return state;
    }
}

export function appttatusIsLoading (state = false, action){
    switch(action.type){
        case TYPE.APPTSTATUS_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function apptStatusIsError (state = false, action){
    switch(action.type){
        case TYPE.APPTSTATUS_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function apptStatusNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.APPTSTATUS_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
