import * as TYPE from "../actions/PatientFollowup";


export function followup (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_PFU:
             return action.payload;
        default:
            return state;
    }
}

export function followupIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PFU_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function followupIsError (state = false, action){
    switch(action.type){
        case TYPE.PFU_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}
