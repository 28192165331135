import * as TYPE from "../actions/Lps";


export function lps (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_LPS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function lp (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_LP_DETAIL:
            return action.payload;
        case TYPE.ADD_LP:
            return {lp_id: action.lp_id, iAmNew: true};
        default:
            return state;
    }
}

export function lpIsLoading (state = false, action){
    switch(action.type){
        case TYPE.LP_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function lpIsError (state = false, action){
    switch(action.type){
        case TYPE.LP_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function lpNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.LP_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function lpMethods (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_LP_METHODS:
            return action.payload;
        default:
            return state;
    }
}

export function lpMethodIsLoading (state = false, action){
    switch(action.type){
        case TYPE.LP_METHOD_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function lpMethodIsError (state = false, action){
    switch(action.type){
        case TYPE.LP_METHOD_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function lpMethodNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.LP_METHOD_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
