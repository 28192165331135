import React, {Component } from 'react';
import {connect} from 'react-redux';
import {Card,  CardHeader,  CardContent, CardActions,
    Grid,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton,
    CircularProgress,
    Typography,
    Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import {bindActionCreators} from 'redux';
import {getPatientAppointments as fetchData, cancelAppointmentSlot, editAppointmentNotes} from '../actions/Appointments';
import {updatePage} from '../actions/Common';
import {fetchCancelReason} from '../actions/CancelReason';
import moment from 'moment-timezone';
import CalendarWeekView from './calendarWeekViewNew';
import {RenderTimezoneSelect, renderSelect} from "../Components/Forms";
import {Field, reduxForm} from 'redux-form';
import {addSMS, addAppointmentEmail} from '../actions/Communication';
import {checkRole} from '../constants_api';
import EditAppointmentNotes from './editAppointmentNotes';


class PatientAppointmentList extends Component{

    constructor(props){
        super(props);

        this.state= {
            appt_id:null,
            isError:false,
            editPath:'patient',
            patient_id: null,
            showCalendar: false,
            showPatientDeleteDialog: false,
            showDeleteDialog: false,
            showEditNotes:false,
            monday:null,
            dateData:null,
            cancelReasons:[],
            tz:'America/Toronto',
            notes:'',
        };
        this.deleteAppt=this.deleteAppt.bind(this);
        this.timezoneChange=this.timezoneChange.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.nextButton=this.nextButton.bind(this);
        this.showEditAppt=this.showEditAppt.bind(this);
        this.updateAppointmentNotes=this.updateAppointmentNotes.bind(this);
    }

    componentDidMount() {
        //this.props.updatePage(this.state.editPath);
        if(this.props.patient_id>0){
            this.props.fetchData({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
            this.props.fetchCancelReason({byPatient:this.props.byPatient});
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.isError!==prevProps.isError){
            this.setState({isError:this.props.isError});
        }
        if (this.props.needsRefresh) {
            this.props.fetchData({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.cancelReasons!==prevProps.cancelReasons){
            //build array for select box
            var arr = [];
            this.props.cancelReasons.dataset.map((reason)=>{
                arr.push({value:reason.reason_id, label:reason.name});
                return true;
            });
            this.setState({cancelReasons:arr});
        }
        if(this.props.appointmentCancelled!== prevProps.appointmentCancelled && this.props.appointmentCancelled){
            this.setState({showDeleteDialog:false});
            this.props.fetchData({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
    }


    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancelAppointment(values){
        let params = { appointment_id : this.state.appt_id, cancelReason:values.cancelReason};
        this.props.cancelAppointmentSlot(params);

    }

    updateAppointmentNotes(values){
        let params = { appointment_id: this.state.appt_id, notes: values.notes, }
        this.props.editAppointmentNotes(params);
        this.setState({showEditNote:false});
    }

    showEditAppt(appt_id,notes){
        this.setState({appt_id:appt_id, showEditNotes:true, notes:notes});
    }

    deleteAppt(id){
        if(this.props.byPatient){
            this.setState({showPatientDeleteDialog:true});
        } else {
            this.setState({showDeleteDialog:true,appt_id:id});
        }
    }

    renderPatientDeleteDialog (){
        return (
            <Dialog
                open={this.state.showPatientDeleteDialog}
                onClose={()=>{this.setState({showPatientDeleteDialog:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to cancel your appointment online. Please call Us to rebook.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({showPatientDeleteDialog:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };


    renderEditNotes (){
       return(
          <EditAppointmentNotes notes={this.state.notes} handleClose={()=>{this.setState({showEditNotes:false})}} handleFormSubmit={this.updateAppointmentNotes} openState={this.state.showEditNotes}/>
        );
    }

    renderDeleteDialog (){
        const { handleSubmit } = this.props;
        return (
            <Dialog
                open={this.state.showDeleteDialog}
                onClose={()=>{this.setState({showDeleteDialog:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Cancel Appointment`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please Provide Reason for cancelling appointment
                    </DialogContentText>
                    <form onSubmit={handleSubmit(this.cancelAppointment.bind(this))}>
                        <Field
                            name= 'cancelReason'
                            component={renderSelect}
                            label= 'Reason'
                            margin='normal'
                            onChange={this.handleChange}
                            listOfValues={this.state.cancelReasons}
                        />
                        <Button onClick={()=>{this.setState({showDeleteDialog:false})}} variant='contained'>
                            Exit
                        </Button>
                        <Button type="submit" color='secondary' variant='contained'>
                            Cancel Appointment
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>

        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList(){
        if(typeof this.props.data.dataset === 'undefined' || this.props.data.dataset===null){
            return(<div></div>);
        }

        return (
            this.props.data.dataset.map((data)=>{
                let appt_dt = moment.utc(data.appt_dt).tz(this.state.tz).format('YY-MM-DD HH:mm');
                let status='';
                switch(data.status){
                    case '1':
                        status='Booked';
                        break;
                    case '2':
                        status='Cancelled';
                        break;
                    case '3':
                        status='Completed';
                        break;
                    default:
                        status='Booked';
                }
                let now = new moment.utc().tz(this.state.tz).format('YY-MM-DD HH:mm');
                let inPast = appt_dt>now ? false :true;
                let statusText = `${status} by ${data.creator}`;
                let appt_displaydt = moment.utc(data.appt_dt).tz(this.state.tz).format('YYYY MMM Do h:mm a');
                let titleText = `${appt_displaydt} - (${data.practitioner})`;
                return(
                    <Grid item xs={12} key={data.appointment_id}>
                        <Card>
                            <CardHeader
                                title={titleText}
                                subheader={statusText}
                                action={!inPast && status!=='Cancelled' &&
                                    <IconButton>
                                        <DeleteIcon onClick={()=>this.deleteAppt(data.appointment_id)}/>
                                    </IconButton>
                                }
                            />
                            <CardContent>
                                {data.notes}
                            </CardContent>
                            {!inPast && status!=='Cancelled' && checkRole('sendReminder') &&
                                <CardActions>
                                    <Tooltip title='SMS Reminder'>
                                        <IconButton>
                                            <SendIcon onClick={()=>this.sendReminder(appt_displaydt)}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Email Reminder'>
                                        <IconButton>
                                            <EmailIcon onClick={()=>this.sendEmailReminder(data.appointment_id)}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Edit Notes'>
                                        <IconButton>
                                            <EditIcon onClick={()=>this.showEditAppt(data.appointment_id,data.notes)}/>
                                        </IconButton>
                                    </Tooltip>
                                </CardActions>
                            }
                        </Card>
                    </Grid>
                )
            })
        )
    }

    hideCalendar(){
        this.setState({showCalendar:false});
        //and refresh for good measure!
        this.props.fetchData({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
    }

    weekChange(day){
        let monday = new moment(day).tz(this.state.tz).startOf('isoweek').format('YYYY-MM-DD');
        let tuesday = moment(monday).add(1,'d').format('YYYY-MM-DD');
        let wednesday = moment(monday).add(2,'d').format('YYYY-MM-DD');
        let thursday = moment(monday).add(3,'d').format('YYYY-MM-DD');
        let friday = moment(monday).add(4,'d').format('YYYY-MM-DD');
        let saturday = moment(monday).add(5,'d').format('YYYY-MM-DD');
        let sunday = moment(monday).add(6,'d').format('YYYY-MM-DD');
        let nextMonday = moment(monday).add(7,'d').format('YYYY-MM-DD');
        let prevMonday = moment(monday).subtract(7,'d').format('YYYY-MM-DD');
        let dateData = {monday,tuesday,wednesday,thursday,friday,saturday,sunday,nextMonday, prevMonday};
        this.setState({dateData})
    }

    timezoneChange(event) {
        this.setState({tz: event.target.value});
    };

    sendReminder(appt_dt) {
        //check the mobile has the right format
        const mob = this.props.patient.secondary_phone.substr(0,1)==='+' ? this.props.patient.secondary_phone : '+1' + this.props.patient.secondary_phone;
        const message = {
            content:`Reminder from Cannenta Clinic: Your appointment is at ${appt_dt}. Please be logged in and ready at least 5 minutes before your appointment time and enter the “Video Consult”.  Call us at 1-844-410-2668 if you require further assistance.`,
            mobile: mob,
            patient_id: this.props.patient_id,
            subject: "Appointment Reminder"
        }
        this.props.addSMS(message);
    }

    sendEmailReminder(appt_id) {
        this.props.addAppointmentEmail({appointment:appt_id,tz:this.state.tz});
    }

    nextButton(){
        if(typeof this.props.dataSaved!=='undefined') {
            this.props.dataSaved();
        }
    }

    render(){
        //check to see if any appointments are already in advance and then decide what to do.
        let dateData = null;
        let patient_id=this.props.patient_id;
        if(this.state.dateData === null || typeof this.state.dateData ==='undefined'){
            let monday = moment(this.props.match.params.date).tz(this.state.tz).startOf('isoweek').format('YYYY-MM-DD');
            let tuesday = moment(monday).add(1,'d').format('YYYY-MM-DD');
            let wednesday = moment(monday).add(2,'d').format('YYYY-MM-DD');
            let thursday = moment(monday).add(3,'d').format('YYYY-MM-DD');
            let friday = moment(monday).add(4,'d').format('YYYY-MM-DD');
            let saturday = moment(monday).add(5,'d').format('YYYY-MM-DD');
            let sunday = moment(monday).add(6,'d').format('YYYY-MM-DD');
            let nextMonday = moment(monday).add(7,'d').format('YYYY-MM-DD');
            let prevMonday = moment(monday).subtract(7,'d').format('YYYY-MM-DD');
            dateData = {monday,tuesday,wednesday,thursday,friday,saturday,sunday,nextMonday, prevMonday};
        } else {
            dateData = this.state.dateData;
        }

        if(this.state.showCalendar){
            return(<CalendarWeekView history={this.props.history} patient_id={patient_id} dateData={dateData}
                                     onBooked={this.hideCalendar.bind(this)} weekChange={this.weekChange.bind(this)} byPatient={this.props.byPatient}/>)
        } else {
            return(
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    {this.props.byPatient &&
                    <Grid item xs={12}>
                        <Typography variant='h6'>Appointments</Typography>
                        <Typography variant="subtitle1">Your appointment date and time is listed below.</Typography>
                        <Typography variant="subtitle1">Tap the “+” icon to select a date and time when you would like to have a video consultation.</Typography>
                    </Grid>
                    }
                    <Grid item xs={10}>
                        {!this.props.byPatient &&
                        <Typography variant='h6'>Appointments</Typography>
                        }
                        <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/>
                    </Grid>

                    <Grid item xs={2}>
                        <Button onClick={()=>{this.setState({showCalendar:true})}} variant="fab" mini color='secondary'><AddIcon/></Button>
                    </Grid>

                    {this.renderList()}
                    {this.renderRefreshDialog()}
                    {this.renderErrorDialog()}
                    {this.renderPatientDeleteDialog()}
                    {this.renderDeleteDialog()}
                    {this.renderEditNotes()}
                    <Grid item xs={12}>
                        <Button color='secondary' variant='contained' onClick={this.nextButton}>
                            NEXT
                        </Button>

                    </Grid>
                </Grid>

            );
        }

    }
}


function mapStateToProps(state){
    return(
        {
            patient: state.patient,
            data: state.patientAppointments,
            isLoading: state.patientAppointmentsIsLoading,
            isError: state.patientAppointmentsIsIsError,
            needsRefresh: state.patientAppointmentsIsNeedsRefresh,
            cancelReasons: state.cancelReasons,
            appointmentCancelled: state.appointmentCancelled,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchData, updatePage, fetchCancelReason,cancelAppointmentSlot, addSMS, addAppointmentEmail, editAppointmentNotes}, dispatch);
}

export default reduxForm({form:'cancelAppointment'})(connect(mapStateToProps, mapDispatchToProps)(PatientAppointmentList));
