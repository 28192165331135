import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid as TGrid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Paper,
} from '@material-ui/core';
import {fetchExpiringPrescription as fetchData} from '../actions/DosagePrescription';
import {updatePage} from '../actions/Common';
import TableZoomSelect from '../Components/Table/cell';





class ExpiringPrescriptionList extends Component{

    constructor(props){
        super(props);

        this.state= {
            editPath: 'patient',
            columns: [
                {name: 'firstname', title: 'First Name'},
                {name: 'lastname', title: 'Last Name'},
                {name: 'expiryDate', title: 'Expiry Date'},
                {name: 'months', title: 'Months'},
                {name: 'select', title: 'Select'}
            ],
            rows:[],
            pageSizes: [5, 10, 15, 0],
            selection: [],
        };
        this.changeSelection=this.changeSelection.bind(this);
        this.onRefreshClick=this.onRefreshClick.bind(this);
        this.Cell=this.Cell.bind(this);
        this.goTo=this.goTo.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        this.props.fetchData();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.data!==prevProps.data){
            if(typeof this.props.data.dataset!=='undefined'){
                this.setState({rows:this.props.data.dataset});
            }
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onRefreshClick(event){
        this.props.fetchData();
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    changeSelection(selection){
        this.setState({ selection });
        if(typeof this.state.rows[selection[0]].patient_id !=='undefined'){
            let pid = this.state.rows[selection[0]].patient_id
            this.props.history.push(`/patient/${pid}`);
        }
    }

    goTo(link){
        this.props.history.push(`/patient/${link}`);
    }

    Cell = (props) => {
        const { column } = props;
        if (column.name === 'select') {
            return (
                <Table.Cell {...props}>
                    <TableZoomSelect tooltip={'Select'} onClick={this.goTo} idRef={props.row.patient_id}/>
                </Table.Cell>
            );
        }

        return <Table.Cell {...props} />;
    }

    render(){
        const { rows, columns } = this.state;
        return(
            <Paper>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h6' align='center'>Expiring Prescriptions</Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <TGrid
                            rows={rows}
                            columns={columns}
                        >
                            <PagingState
                                defaultCurrentPage={0}
                                pageSize={6}
                            />
                            <IntegratedPaging />

                            <Table
                                cellComponent={this.Cell}
                            />
                            <TableHeaderRow />
                            <PagingPanel />
                        </TGrid>
                        <Button variant='contained' color="primary" onClick={this.onRefreshClick}>Refresh</Button>
                    </Grid>
                </Grid>
                {this.renderRefreshDialog()}
            </Paper>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.expiringPrescriptions,
            isLoading: state.expiringIsLoading,
            isError: state.expiringIsError
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchData, updatePage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiringPrescriptionList);
