import React, {Component, useRef } from 'react';
import {
    Grid,
    Button,
    } from '@material-ui/core';
import Cropper from 'react-cropper';
import '../../node_modules/cropperjs/dist/cropper.css';
import UndoIcon from '@material-ui/icons/Undo';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import DoneIcon from '@material-ui/icons/Done';


export default class ImageEditor extends Component {
    constructor(props){
        super(props);

        this.state= {
            newImage:null,
            cropper:null,
        };
    }

    componentDidMount() {
        let newImage = this.checkOriginalSize(this.props.originalImage);
        this.setState({newImage:newImage});
    }
    _onCrop(){

    }

    checkOriginalSize(base64image){
        let wh = window.innerHeight;
        let ww = window.innerWidth;
        var image = new Image();
        image.src=base64image;
        let maxHeight = wh-300;
        let maxWidth = ww-200;
        let hr = image.height/maxHeight;
        let wr = image.width/maxWidth;
        let h = image.height;
        let w = image.width;
        if(hr>1 && hr>wr){
            h=maxHeight;
            w=parseInt(w/hr,10);
        }
        if(wr>1 && wr>hr){
            w=maxWidth;
            h=parseInt(h/wr,10);
        }
        var canvas = document.createElement('canvas');
        canvas.width=w;
        canvas.height=h;
        canvas.getContext('2d').drawImage(image, 0,0,w,h);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataUrl;
    }

    revertImage(){
        let newImage = this.checkOriginalSize(this.props.originalImage);
        this.setState({newImage:newImage});
    }

    saveImage(){
        this.props.updateCallback(this.state.cropper.getCroppedCanvas().toDataURL());
    }

    rightRotateImage(){
        this.state.cropper.rotate(90);
    }

    leftRotateImage(){
        this.state.cropper.rotate(-90);
    }

    revertThisImage(){
        this.state.cropper.reset();
    }

    render(){
        return(
            <Grid container>
                <Grid item xs={12}>
                    <Cropper
                        src={this.state.newImage}
                        crop={this._onCrop.bind(this)}
                        guides={false}
                        autoCropArea={1}
                        onInitialized={(instance) => {
                            this.setState({cropper:instance});
                          }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={this.saveImage.bind(this)} color="secondary" variant="fab"><DoneIcon/></Button>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={this.leftRotateImage.bind(this)} color="primary" variant="fab"><RotateLeftIcon/></Button>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={this.rightRotateImage.bind(this)} color="primary" variant="fab"><RotateRightIcon/></Button>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={this.revertThisImage.bind(this)} color="secondary" variant="fab"><UndoIcon/></Button>
                </Grid>

            </Grid>

        )
    }

}

