import React, { Component} from 'react';
import {Button, Grid, Typography, Dialog, DialogContent, CircularProgress} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {renderTextField} from '../Components/Forms'
import {bindActionCreators} from "redux";
import {getPatientTokenByUrl,setPatientPassword} from '../actions/Patients';
import {connect} from 'react-redux';



class SetPassword extends Component {

    constructor(props){
        super(props);

        this.state= {
            patient_id: null,
            temp:false,
            password:'',
            confirmPassword:'',
            goodToken:false,
            passwordUpdated:false,
            timeout:false,
        };
        this.onChangePassword=this.onChangePassword.bind(this);
        this.passwordMatch=this.passwordMatch.bind(this);
    }

    onSubmit(values){
        let params = {token:this.props.credentials.token, newPassword: values.password, patient_id: this.props.patient.patient_id}
        console.log('Using params', params);
        this.props.setPatientPassword(params);
    }

    componentDidMount(){
        this.props.getPatientTokenByUrl({code:this.props.match.params.code});
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.credentials!==prevProps.credentials){
            this.setState({goodToken:true});
            localStorage.setItem('token', this.props.credentials.token);
            console.log(this.props.patient);
        }
        if(this.props.patient!==prevProps.patient){
        }
        if(this.props.patientPasswordUpdated!==prevProps.patientPasswordUpdated && this.props.patientPasswordUpdated){
            //navigate to the patient pages.
            this.props.history.push('/client');
        }
    }

    required(value) {
        return value ? undefined : 'Required';
    }

    passwordMatch(value) {
        return value===this.state.password ? undefined : 'Passwords must match';
    }

    onChangePassword(event){
        this.setState({password:event.target.value});
    }



    renderLoadingDialog(){
        return(<Dialog
                fullScreen
                open={this.props.wait}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={{
                            background: 'transparent',
                            textAlign: 'center',
                            margin: 'auto'
                        }}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }


    render(){
        const {handleSubmit} = this.props;
        const { classes } = this.props;
        return(
            <Grid container>
                {this.renderLoadingDialog()}
                {this.state.goodToken &&
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Please Enter New Password</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            autoFocus
                            fullWidth
                            name='password'
                            component={renderTextField}
                            label='New Password'
                            type='password'
                            className={classes.textField}
                            margin='normal'
                            validate={[this.required]}
                            onChange={this.onChangePassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name='confirm_password'
                            component={renderTextField}
                            label='Confirm New Password'
                            type='password'
                            className={classes.textField}
                            margin='normal'
                            validate={[this.required, this.passwordMatch]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" color='secondary' variant='contained'>
                            Set Password
                        </Button>
                    </Grid>
                </form>
                }
                {!this.state.goodToken && !this.props.wait &&
                    <Grid item xs={12}>
                        <Typography variant='h6'>Token invalid or expired. Please request a new one by calling 1-844-410-2668</Typography>
                    </Grid>
                }
            </Grid>
        );
    }

}

function mapStateToProps(state){
    return(
        {
            credentials: state.credentials,
            patient:state.patient,
            wait:state.patientSignupWait,
            patientPasswordUpdated:state.patientPasswordUpdated,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getPatientTokenByUrl, setPatientPassword}, dispatch);
}


export default reduxForm({
    form: 'setPasswordForm'
})(connect(mapStateToProps,mapDispatchToProps)(SetPassword));