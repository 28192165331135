import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_CANCELREASON = 'FETCH_CANCELREASON';
export const FETCH_CANCELREASON_DETAIL = 'FETCH_CANCELREASON_DETAIL';
export const ADD_CANCELREASON = 'ADD_CANCELREASON';
export const EDIT_CANCELREASON = 'EDIT_CANCELREASON';
export const CANCELREASON_IS_LOADING = 'CANCELREASON_IS_LOADING';
export const CANCELREASON_IS_ERROR = 'CANCELREASON_IS_ERROR';
export const CANCELREASON_NEEDS_REFRESH = 'CANCELREASON_NEEDS_REFRESH';

export function fetchCancelReason(params){
    return (dispatch) => {
        dispatch({type: CANCELREASON_IS_LOADING, bool :true});
        dispatch({type: CANCELREASON_IS_ERROR, bool:false});
        dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}cancel_reason/list?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_CANCELREASON, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchCancelReasonDetail(status_id){
    return (dispatch) => {
        dispatch({type: CANCELREASON_IS_LOADING, bool :true});
        dispatch({type: CANCELREASON_IS_ERROR, bool:false});
        dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}cancel_reason/fetch?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_CANCELREASON_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addCancelReason(status){
    return (dispatch) => {
        dispatch({type: CANCELREASON_IS_LOADING, bool :true});
        dispatch({type: CANCELREASON_IS_ERROR, bool:false});
        dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}cancel_reason/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_CANCELREASON, status_id: response.data.status_id})
                dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
        });
    }
}

export function deleteCancelReason(id){
    return (dispatch) => {
        dispatch({type: CANCELREASON_IS_LOADING, bool :true});
        dispatch({type: CANCELREASON_IS_ERROR, bool:false});
        dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}cancel_reason/delete?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const status_id = {status_id:id};
        const request = axios.post(url,status_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
        });
    }
}

export function editCancelReason(status){
    return (dispatch) => {
        dispatch({type: CANCELREASON_IS_LOADING, bool :true});
        dispatch({type: CANCELREASON_IS_ERROR, bool:false});
        dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}cancel_reason/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,status, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: CANCELREASON_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: CANCELREASON_IS_ERROR, bool:true});
            dispatch({type: CANCELREASON_IS_LOADING, bool: false});
        });
    }
}