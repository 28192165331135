import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Grid,Hidden,
    Typography,
    Paper,
} from '@material-ui/core';
import moment from 'moment-timezone';
import {fetchCommunicationDetail} from '../actions/Communication';
import {bindActionCreators} from "redux";
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';

class MessageShowDetail extends Component {

    constructor(props){
        super(props);
        this.state={
            editorState : EditorState.createEmpty()
        };

    }

    componentDidMount() {
        this.props.fetchCommunicationDetail({communication_id:this.props.communication_id});
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.data!==prevProps.data){
            const contentBlock = htmlToDraft(this.props.data.content);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                this.setState({editorState : EditorState.createWithContent(contentState)});
            }
        }

    }

    render(){
        const data = this.props.data;
        var created = new moment.tz(data.created,'UTC');
        var Dcreated = created.tz('America/Toronto').format('YYYY-MM-DD, HH:mm');
        const editorStyle = {
            border: '1px solid black',
            padding: '5px',
            borderRadius: '2px',
            height: '300px',
            width: '100%',
        };

        return (
            <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                {data.type === 'SMS' &&
                    <Fragment>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1">
                                Subject: {data.subject}
                            </Typography>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item sm={4}>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" align='right'>
                                {Dcreated}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1">
                                To: {data.address}
                            </Typography>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item sm={4}>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" align='right'>
                                Sender: {data.senderName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Typography variant="subtitle1">
                                    {data.content}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                Status: {data.msgStatus}
                            </Typography>
                        </Grid>
                    </Fragment>
                }
                {data.type === 'Email' &&
                <Fragment>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1">
                            Subject: {data.subject}
                        </Typography>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={4}>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" align='right'>
                            {Dcreated}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1">
                            To: {data.address}
                        </Typography>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={4}>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle1" align='right'>
                            Sender: {data.senderName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Editor
                            editorState={this.state.editorState}
                            readOnly={true}
                            toolbarHidden={true}
                            editorStyle={editorStyle}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            Status: {data.msgStatus}
                        </Typography>
                    </Grid>
                </Fragment>
                }
            </Grid>
        );
    }

}


function mapStateToProps(state){
    return(
        {
            data: state.communication,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchCommunicationDetail}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageShowDetail);


