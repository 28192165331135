import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_PFU = 'FETCH_PFU';
export const ADD_PFU = 'ADD_PFU';
export const EDIT_PFU = 'EDIT_PFU';
export const PFU_IS_LOADING = 'PFU_IS_LOADING';
export const PFU_IS_ERROR = 'PFU_IS_ERROR';


export function getPatientFollowup(patient_id){
    return (dispatch) => {
        dispatch({type: PFU_IS_LOADING, bool :true});
        dispatch({type: PFU_IS_ERROR, bool:false});
        const url = `${API_URL}patient/getFollowup`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PFU_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PFU, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: PFU_IS_ERROR, bool:true});
            dispatch({type: PFU_IS_LOADING, bool: false});
        });
    }
}

export function setPatientFollowup(params){
    return (dispatch) => {
        dispatch({type: PFU_IS_LOADING, bool :true});
        dispatch({type: PFU_IS_ERROR, bool:false});
        const url=`${API_URL}patient/setFollowup?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PFU_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_PFU, followup_id: response.data.followup_id})
            } else {
                dispatch({type: PFU_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PFU_IS_ERROR, bool:true});
            dispatch({type: PFU_IS_LOADING, bool: false});
        });
    }
}

