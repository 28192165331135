import * as TYPE from "../actions/CancelReason";


export function cancelReasons (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_CANCELREASON:
            return  action.payload.result;
        default:
            return state;
    }
}

export function cancelReason (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_CANCELREASON_DETAIL:
            return action.payload;
        case TYPE.ADD_CANCELREASON:
            return {status_id: action.status_id, iAmNew: true};
        default:
            return state;
    }
}

export function cancelReasonIsLoading (state = false, action){
    switch(action.type){
        case TYPE.CANCELREASON_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function cancelReasonIsError (state = false, action){
    switch(action.type){
        case TYPE.CANCELREASON_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function cancelReasonNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.CANCELREASON_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
