import * as TYPE from "../actions/PractitionerDetail";

export function practitionerDetail (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_P_DETAIL:
            return action.payload;
        default:
            return state;
    }
}

export function pdIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PD_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function pdIsError (state = false, action){
    switch(action.type){
        case TYPE.PD_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function pdNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.PD_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

