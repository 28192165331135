import axios from 'axios';
import {API_URL, UPDATE_TOKEN, DELETE_TOKEN} from '../../constants_api'

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_IS_ERROR = 'USER_IS_ERROR';
export const USER_NEEDS_REFRESH = 'USER_NEEDS_REFRESH';
export const USER_ROLES_IS_LOADING = 'USER_ROLES_IS_LOADING';
export const USER_ROLES_IS_ERROR = 'USER_ROLES_IS_ERROR';
export const USER_ROLES_NEEDS_REFRESH = 'USER_ROLES_NEEDS_REFRESH';
export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const ADD_USER_ROLES = 'ADD_USER_ROLES';
export const DELETE_USER_ROLES = 'DELETE_USER_ROLES';
export const PASSWORD_CHANGING = 'PASSWORD_CHANGING';
export const PASSWORD_MESSAGE = 'PASSWORD_MESSAGE';
export const UPDATE_CHAT_TOKEN = 'UPDATE_CHAT_TOKEN';
export const FETCH_CHAT_TOKEN = 'FETCH_CHAT_TOKEN';


export function fetchUsers(params){
    return (dispatch) => {
        dispatch({type: USER_IS_LOADING, bool :true});
        dispatch({type: USER_IS_ERROR, bool:false});
        dispatch({type: USER_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}user/list?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_IS_LOADING, bool: false});
            dispatch({type: USER_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_USERS, payload: response.data});
            }
       }).catch((err)=>{
            dispatch({type: USER_IS_ERROR, bool:true});
            dispatch({type: USER_IS_LOADING, bool: false});
            dispatch({type: USER_NEEDS_REFRESH, bool: false});
        });
    }
}

export function fetchUserDetail(user_id){
    return (dispatch) => {
        dispatch({type: USER_IS_LOADING, bool :true});
        dispatch({type: USER_IS_ERROR, bool:false});
        dispatch({type: USER_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}user/details`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,user_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_IS_LOADING, bool: false});
            dispatch({type: USER_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_USER_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: USER_IS_ERROR, bool:true});
            dispatch({type: USER_IS_LOADING, bool: false});
            dispatch({type: USER_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addUser(user){
    return (dispatch) => {
        dispatch({type: USER_IS_LOADING, bool :true});
        dispatch({type: USER_IS_ERROR, bool:false});
        dispatch({type: USER_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}user/add`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,user,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_USER, user_id: response.data.user_id});
                dispatch({type: USER_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: USER_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: USER_IS_ERROR, bool:true});
            dispatch({type: USER_IS_LOADING, bool: false});
        });
    }
}

export function deleteUser(id){
    return (dispatch) => {
        dispatch({type: USER_IS_LOADING, bool :true});
        dispatch({type: USER_IS_ERROR, bool:false});
        dispatch({type: USER_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}user/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const user_id = {user_id:id};
        const request = axios.post(url,user_id, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: USER_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: USER_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: USER_IS_ERROR, bool:true});
            dispatch({type: USER_IS_LOADING, bool: false});
        });
    }
}

export function editUser(user){

    return (dispatch) => {
        dispatch({type: USER_IS_LOADING, bool :true});
        dispatch({type: USER_IS_ERROR, bool:false});
        dispatch({type: USER_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}user/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,user,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: USER_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: USER_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: USER_IS_ERROR, bool:true});
            dispatch({type: USER_IS_LOADING, bool: false});
        });
    }
}

export function authUser(cred){
    return (dispatch) => {
        const url = `${API_URL}user/auth?XDEBUG_SESSION_START=PHPSTORM`;
        const request = axios.post(url,cred);
        dispatch({type: FETCH_CHAT_TOKEN, bool:true});
        request.then((response) => {
            dispatch({type: FETCH_CHAT_TOKEN, bool:false});
            if(response.data.Success){
                dispatch({type: UPDATE_TOKEN, token: response.data.Token});
                dispatch({type: UPDATE_CHAT_TOKEN, token: response.data.chatToken});
            }
        }).catch((err)=> {
            dispatch({type: FETCH_CHAT_TOKEN, bool:false});
        });
    }
}

export function getChatToken(){
    return (dispatch) => {
        const url = `${API_URL}user/getChatToken?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.get(url,header);
        dispatch({type: FETCH_CHAT_TOKEN, bool:true});
        request.then((response) => {
            dispatch({type: FETCH_CHAT_TOKEN, bool:false});
            if(response.data.Success){
                dispatch({type: UPDATE_TOKEN, token: response.data.Token});
                dispatch({type: UPDATE_CHAT_TOKEN, token: response.data.chatToken});
            }
        }).catch((err)=> {
            dispatch({type: FETCH_CHAT_TOKEN, bool:false});
        });
    }
}

export function changePassword(passwords){
    return (dispatch) => {
        const url = `${API_URL}user/changePassword?XDEBUG_SESSION_START=PHPSTORM`;
        dispatch({type: PASSWORD_CHANGING, bool: true});
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,passwords,header);
        request.then((response) => {
            if(response.data.Success){
                dispatch({type:PASSWORD_MESSAGE, msg: "Password Succesfully Changed"});
            } else {
                dispatch({type:PASSWORD_MESSAGE, msg: response.data.answer});
            }
            dispatch({type: PASSWORD_CHANGING, bool: false});
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
        }).catch((err)=> {

        });
    }
}


export function logoutUser(){
    return (dispatch) => {
        dispatch({type: DELETE_TOKEN});
        let url = `${API_URL}user/logout`;
        let token = localStorage.getItem("token");
        let header = {headers: {"Authorization" : "Bearer " + token}};
        let request = axios.get(url,header);
        console.log("Logged out",request);
        localStorage.removeItem('token');
    }
}

export function fetchUserRoles(user_id){
    return (dispatch) => {
        dispatch({type: USER_ROLES_IS_LOADING, bool :true});
        dispatch({type: USER_ROLES_IS_ERROR, bool:false});
        dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}user/getAppRoles`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,user_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_ROLES_IS_LOADING, bool: false});
            dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_USER_ROLES, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: USER_ROLES_IS_ERROR, bool:true});
            dispatch({type: USER_ROLES_IS_LOADING, bool: false});
            dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addUserRole(user_role){
    return (dispatch) => {
        dispatch({type: USER_ROLES_IS_LOADING, bool :true});
        dispatch({type: USER_ROLES_IS_ERROR, bool:false});
        dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}user/addRole`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,user_role,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_ROLES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: USER_ROLES_IS_ERROR, bool:true});
            dispatch({type: USER_ROLES_IS_LOADING, bool: false});
            dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
        });
    }
}

export function deleteUserRole(user_role){
    return (dispatch) => {
        dispatch({type: USER_ROLES_IS_LOADING, bool :true});
        dispatch({type: USER_ROLES_IS_ERROR, bool:false});
        dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}user/deleteRole`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,user_role,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: USER_ROLES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: USER_ROLES_IS_ERROR, bool:true});
            dispatch({type: USER_ROLES_IS_LOADING, bool: false});
            dispatch({type: USER_ROLES_NEEDS_REFRESH, bool: false});
        });
    }
}
