import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {fetchPatientIdentificationPhoto,addIdentificationPhoto, deleteIdentificationPhoto} from '../actions/IdentificationPhotos';
import {Field, reduxForm, } from 'redux-form';
import { renderImageUpload, renderDatePicker, renderSelect} from '../Components/Forms';
import moment from "moment-timezone";
import ImageEditor from './imageEditor';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import Webcam from 'react-webcam';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import {styles} from '../style';

class PatientIdentificationDocumentDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            identification_photo_id: 0,
            idType: '',
            patient_id: this.props.patient_id,
            id_expiry: '',
            image: null,
            imageSrc: '',
            idTypes: [{label: 'Driving License', value: 'Driving License'}, {label: 'Passport', value: 'Passport'},  {label: 'Health Card', value: 'Health Card'},
                {label: 'Photo Card', value: 'Photo Card'}, {label: 'Other', value: 'Other'}],
            zoomImageDialog:false,
            originalImage:null,
            webCamDialog: false,
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleImageChange=this.handleImageChange.bind(this);
        this.handleExpiryChange=this.handleExpiryChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.updateZoomImage=this.updateZoomImage.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if(this.props.patient_id>0 && !this.props.passPhoto){
            this.props.fetchPatientIdentificationPhoto({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.passPhoto){
            this.setState({image:this.props.image, originalImage:this.props.image});
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.identification_photo!==prevProps.identification_photo){
            this.setState({
                identification_photo_id:this.props.identification_photo.identification_photo_id,
                idType:this.props.identification_photo.idType,
                id_expiry:this.props.identification_photo.id_expiry,
                image:this.props.identification_photo.image,
                originalImage: this.props.identification_photo.image,
            });
            const formData = {
                "identification_photo_id":this.props.identification_photo.identification_photo_id,
                "idType":this.props.identification_photo.idType,
                "id_expiry":this.props.identification_photo.id_expiry,
                "image":this.props.identification_photo.image,
            };
            this.props.initialize(formData);
        }
        if(this.props.location!==prevProps.location){
            this.props.fetchPatientIdentificationPhoto(this.props.patient_id);
        }

    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({pd_in_use : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onSubmit (values){
        var id_doc = {
            patient_id: this.props.patient_id,
            idType:values.idType,
            id_expiry:this.state.id_expiry,
            image:this.state.image,
            byPatient:this.props.byPatient,
        }
        this.props.addIdentificationPhoto(id_doc);
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    revertImage(){
        this.setState({image:this.state.originalImage});
    }

    //Render Functions
    renderZoomImage (){
        return (
            <Dialog
                open={this.state.zoomImageDialog}
                onClose={()=>{this.setState({zoomImageDialog:false})}}
                maxWidth={false}
            >
                <DialogTitle id="alert-dialog-title">{`Image Capture Viewer`}</DialogTitle>
                <DialogContent>
                    <ImageEditor originalImage={this.state.image} updateCallback={this.updateZoomImage}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({zoomImageDialog:false})}} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push(`/practitioner/${this.props.practitioner_id}`)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push(`/practitioner/${this.props.practitioner_id}`)}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderWebcam(){

        let dWidth = window.innerWidth - 100;
        let dHeight = window.innerHeight -50;
        let imgWidth = Math.min(dWidth -100,640);
        let imgHeight = Math.min(dHeight -300,640);
        const videoConstraints = {
            facingMode: 'user',
        };

        return (
            <Dialog
                open={this.state.webCamDialog}
                onClose={()=>{this.setState({webCamDialog:false})}}
                maxWidth={false}
                style={{maxHeight:`${dHeight}px`}}
                PaperProps={{style:{width:`${dWidth}px`}}}
                scroll="paper"
            >
                <DialogTitle>Capture Image</DialogTitle>
                <DialogContent>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Typography variant="subtitle1">Hold your identification up to the camera. The image must clearly show your photo, Date of Birth, and country of region. Select “Capture image” to save the image. </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Webcam
                                audio={false}
                                ref={this.setRef}
                                screenshotFormat="image/jpeg"
                                width={imgWidth}
                                height={imgHeight}
                                videoConstraints={videoConstraints}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>

                        <Button onClick={()=>{this.setState({webCamDialog:false})}} variant='contained' color='secondary'>Cancel</Button>
                        <Button onClick={this.capture} variant='contained' color='primary'>Capture Image</Button>

                </DialogActions>
            </Dialog>
        )
    }

    setRef = (webcam) => {
        this.webcam = webcam;
    }

    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        this.setState({image:imageSrc, webCamDialog:false})
    };


    updateZoomImage(image){
        this.setState({image:image, zoomImageDialog:false});
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    handleImageChange = (event) => {
        this.setState({imageSrc: event.target.files[0]})
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            var image = new Image();
            image.onload = function(imageEvent) {
                let maxHeight = 2000;
                let maxWidth = 2000;
                let hr = image.height/maxHeight;
                let wr = image.width/maxWidth;
                let h = image.height;
                let w = image.width;
                if(hr>1 && hr>wr){
                    h=maxHeight;
                    w=parseInt(w/hr,10);
                }
                if(wr>1 && wr>hr){
                    w=maxWidth;
                    h=parseInt(h/wr,10);
                }
                var canvas = document.createElement('canvas');
                canvas.width=w;
                canvas.height=h;
                canvas.getContext('2d').drawImage(image, 0,0,w,h);
                var dataUrl = canvas.toDataURL('image/jpeg');
                this.setState({ image:dataUrl});
            }.bind(this);
            image.src=reader.result;
        }.bind(this);
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    }

    handleExpiryChange= (value) => {
        let d = moment(value).format("YYYY-MM-DD");
        this.setState({id_expiry: d});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }


    render(){
        const { handleSubmit, pristine, submitting } = this.props;
        if(this.props.patient_id<1){
            return(<div></div>);
        }
        let imgWidth = window.innerWidth -100;
        return(<Fragment>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="inherit"><u>Identification Document</u></Typography>
                        </Grid>
                        {this.props.byPatient &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Please upload or use the camera icon to capture a clear image of your government issued identification.</Typography>
                        </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <Field
                                autoFocus
                                name= 'idType'
                                component={renderSelect}
                                label= 'Type of Identification'
                                margin='normal'
                                onChange={this.handleChange}
                                validate={[this.required]}
                                listOfValues={this.state.idTypes}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name= 'id_expiry'
                                component={renderDatePicker}
                                label= 'Expiry Date'
                                margin='normal'
                                onChange={this.handleExpiryChange}
                                validate={[this.required]}
                                myFormat='MMM DD YYYY'
                            />
                        </Grid>


                            { !this.props.passPhoto &&
                            <Fragment>
                                <Grid item xs={12} sm={6}>
                                    <Button onClick={()=>{this.setState({webCamDialog:true})}} variant="fab" color="secondary"><CameraIcon/></Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name='imageSrc'
                                        fname='imageSrc'
                                        component={renderImageUpload}
                                        onChange={this.handleImageChange}
                                        label='Upload Identification Image'
                                        margin='normal'
                                    />
                                </Grid>
                            </Fragment>
                            }
                        <Grid item xs={12}>
                            <img src={this.state.image} alt="" style={{maxWidth:`${imgWidth}px`}}/>
                            {this.state.image!==this.state.originalImage &&
                            <Button onClick={this.revertImage} color="secondary" variant="fab"><UndoIcon/></Button>
                            }
                            {this.state.image !== null &&
                            <Button onClick={() => {
                                this.setState({zoomImageDialog: true})
                            }} color="secondary" variant="fab"><EditIcon/></Button>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                NEXT
                            </Button>
                            <Button disabled={pristine || submitting} onClick={()=>{this.props.reset();this.cancel()}} variant='contained'>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
                {this.renderZoomImage()}
                {this.renderWebcam()}

            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            identification_photo: state.identification_photo,
            isLoading: state.identificationPhotoIsLoading,
            isError: state.identificationPhotoIsError
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPatientIdentificationPhoto, addIdentificationPhoto, deleteIdentificationPhoto}, dispatch);
}


export default reduxForm({})(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)((PatientIdentificationDocumentDetail))));

