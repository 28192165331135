import * as TYPE from "../actions/HealthcardPhotos";


export function healthcard_photos (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_HC_PHOTOS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function healthcard_photo (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_HC_PHOTO_DETAIL:
            return action.payload;
        case TYPE.ADD_HC_PHOTO:
            return action.payload;
        default:
            return state;
    }
}

export function healthcardPhotoIsLoading (state = false, action){
    switch(action.type){
        case TYPE.HC_PHOTO_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function healthcardPhotoIsError (state = false, action){
    switch(action.type){
        case TYPE.HC_PHOTO_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function healthcardPhotoNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.HC_PHOTO_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
