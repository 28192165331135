import React, {Component, } from 'react';
import PatientDetail from '../../containers/patient-detail';
import PatientAilment from '../../containers/patient-ailment';
import PatientMedicalInfo from '../../containers/patient-medicalInfo';
import DosageByPatient from '../../containers/container-dosageByPatientList';
import PatientIdentificationDocumentDetail from '../../containers/patient_identificationPhoto-detail';
import PatientHealthcardDocumentDetail from '../../containers/patient_healthcardPhoto-detail';
import PatientVideo from '../../containers/patientVideo';
import PatientAppointmentList from '../../containers/patientAppointmentList';
import CommunicationList from '../../containers/communication-list';
import PatientFollowup from '../../containers/patient-followup';
import PatientNotes from '../../containers/patient-notes';
import PatientLPInfo from '../../containers/patientLPLoginList'
import {Grid, Paper, AppBar, Tab, Tabs} from '@material-ui/core';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../style';
import ProfileIcon from '@material-ui/icons/PersonOutline';
import HeartPulse from 'mdi-react/HeartPulseIcon';
import MedicalBag from 'mdi-react/MedicalBagIcon';
import AccountCardDetails from 'mdi-react/FolderSharedIcon';
import CreditCard from 'mdi-react/CreditCardIcon';
import CalendarClock from 'mdi-react/CalendarClockIcon';
import VideoAccount from 'mdi-react/VideoAccountIcon';
import Pill from 'mdi-react/PillIcon';
import Email from 'mdi-react/EmailIcon';
import PhonePlus from 'mdi-react/PhonePlusIcon';
import Notebook from 'mdi-react/NotebookIcon';
import ImageMultiple from 'mdi-react/ImageMultipleIcon';
import LanPending from 'mdi-react/LanPendingIcon';
import {checkRole} from '../../constants_api';
import {updatePage} from '../../actions/Common';
import PatientPhotos from '../../containers/patientPhoto';



class PatientDetails extends Component {

    constructor(props){
        super(props);
        this.state={
            tabIndex:'profile',
            QtabSelected:0,
            patient_id:null,
            id_fetched:false,
            value:'profile',
            tabDisable:{},
            tabArray:[],
        };
        //this.renderTabs=this.renderTabs.bind(this);
        //this.renderTabContent=this.renderTabContent.bind(this);
    }

    componentDidMount() {
        this.props.updatePage('patient');
        //if(this.props.match.params.patient_id>0){
            this.setState({patient_id:this.props.match.params.patient_id, id_fetched:true});
        //}
        let tabArray = ['profile','ailments','medical','identification','healthcard','extraImages'];
        if(checkRole('dosage')){
            tabArray.push('dosage');
        }
        if(checkRole('viewMessages')){
            tabArray.push('messages');
        }
        tabArray.push('appointments');
        if(checkRole('practitioner')){
            tabArray.push('notes');
        }
        tabArray.push('video');
        if(checkRole('followup')){
            tabArray.push('followup');
        }
        tabArray.push('lpids')
        this.setState({tabArray});
    }

    patientChange(newPatientId){
        this.setState({patient_id:newPatientId});
        this.enableAllTabs(true);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    enableAllTabs(moveNext) {
        let tabDisable={profile:false, ailments:false, medical:false, photo:false, healthcard:false, extraImages:false, appointments:false, video:false, dosage:false, messages:false, followup:false, notes:false, lpids:false};
        this.setState({tabDisable});
        if(moveNext){
            let index = this.state.tabArray.indexOf(this.state.value);
            let nextItem = '';
            if(index >= 0 && index < this.state.tabArray.length - 1){
                nextItem = this.state.tabArray[index + 1];
            }
            this.setState({value:nextItem})
        }
    }

    disableOtherTabs(tabname){
        let tabDisable={profile:true, ailments:true, medical:true, photo:true, healthcard:true, extraImages:true, appointments:true, video:true, dosage: true, messages:true, followup:true, notes:true, lpids:true};
        tabDisable[tabname]=false;
        this.setState({tabDisable});
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        //let room = 'patient_'+this.state.patient_id;

        return (
            <Grid container className={classes.gridPageOuter}>
                <Grid item xs={12}>

                    {this.state.id_fetched &&
                    <Grid item className={classes.gridContentOuter}>
                        <AppBar position="static" color="secondary">
                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant='scrollable'
                                scrollButtons="on"
                            >
                                <Tab label="Profile" icon={<ProfileIcon/>} disabled={this.state.tabDisable.profile} value='profile'/>
                                <Tab label="Ailments" icon={<HeartPulse/>}  disabled={this.state.tabDisable.ailments} value='ailments'/>
                                <Tab label="Medical" icon={<MedicalBag/>} disabled={this.state.tabDisable.medical} value='medical'/>
                                <Tab label="Photo ID" icon={<AccountCardDetails/>} disabled={this.state.tabDisable.photo} value='identification'/>
                                <Tab label="Healthcard" icon={<CreditCard/>} disabled={this.state.tabDisable.healthcard} value='healthcard'/>
                                <Tab label="Extra Images" icon={<ImageMultiple/>} disabled={this.state.tabDisable.extraImages} value='extraImages'/>
                                {checkRole('dosage') &&
                                    <Tab label="Dosage" icon={<Pill/>} disabled={this.state.tabDisable.dosage} value='dosage'/>
                                }
                                {checkRole('viewMessages') &&
                                    <Tab label="Messages" icon={<Email/>} disabled={this.state.tabDisable.messages} value='messages'/>
                                }
                                <Tab label="Appointment" icon={<CalendarClock/>} disabled={this.state.tabDisable.appointments} value='appointments'/>
                                {checkRole('practitioner') &&
                                <Tab label="Notes" icon={<Notebook/>} disabled={this.state.tabDisable.notes} value='notes'/>
                                }
                                <Tab label="Video" icon={<VideoAccount/>} disabled={this.state.tabDisable.video} value='video'/>
                                {checkRole('followup') &&
                                <Tab label="Follow Up" icon={<PhonePlus/>} disabled={this.state.tabDisable.followup} value='followup'/>
                                }
                                <Tab label="LP Ids" icon={<LanPending/>} disabled={this.state.tabDisable.lpids} value='lpids'/>
                            </Tabs>
                        </AppBar>
                            <Grid item xs={12}>
                                {value === 'profile' &&
                                <Paper className={classes.paper}>
                                    <PatientDetail {...this.props}
                                                   patient_id={this.state.patient_id}
                                                   byPatient={false}
                                                   dataChange={()=>this.disableOtherTabs('profile')}
                                                   dataSaved={()=>this.enableAllTabs(true)}
                                                   newPatient={this.patientChange.bind(this)}
                                    />
                                </Paper>
                                }
                                {value === 'ailments' &&
                                <Paper className={classes.paper}>
                                    <PatientAilment {...this.props}
                                                    patient_id={this.state.patient_id}
                                                    byPatient={false}
                                                    dataChange={()=>this.disableOtherTabs('ailment')}
                                                    dataSaved={()=>this.enableAllTabs(true)}
                                    />
                                </Paper>
                                }
                                {value === 'medical' &&
                                <Paper className={classes.paper}>
                                    <PatientMedicalInfo {...this.props}
                                                        patient_id={this.state.patient_id}
                                                        byPatient={false}
                                                        dataChange={()=>this.disableOtherTabs('medical')}
                                                        dataSaved={()=>this.enableAllTabs(true)}/>
                                </Paper>
                                }
                                {value === 'identification' &&
                                <Paper className={classes.paper}>
                                    <PatientIdentificationDocumentDetail {...this.props} byPatient={false}
                                                                         patient_id={this.state.patient_id}
                                                                         passPhoto={false}
                                                                         form={'mainIdDocForm'}
                                                                         dataChange={()=>this.disableOtherTabs('photo')}
                                                                         dataSaved={()=>this.enableAllTabs(true)}/>
                                </Paper>
                                }
                                {value === 'healthcard' &&
                                <Paper className={classes.paper}>
                                    <PatientHealthcardDocumentDetail {...this.props} byPatient={false}
                                                                     patient_id={this.state.patient_id}
                                                                     passPhoto={false}
                                                                     form={'mainHealthcardForm'}
                                                                     dataChange={()=>this.disableOtherTabs('healthcard')}
                                                                     dataSaved={()=>this.enableAllTabs(true)}/>
                                </Paper>
                                }
                                {value === 'extraImages' &&
                                <Paper className={classes.paper}>
                                    <PatientPhotos {...this.props} byPatient={false}
                                                                     patient_id={this.state.patient_id}
                                                                     passPhoto={false}
                                                                     form={'mainPhotoForm'}/>
                                </Paper>
                                }
                                {value === 'dosage' &&
                                <Paper className={classes.paper}>
                                    <DosageByPatient {...this.props}
                                                     patient_id={this.state.patient_id}
                                                     byPatient={false}
                                                     dataChange={()=>this.disableOtherTabs('dosage')}
                                                     dataSaved={()=>this.enableAllTabs(true)}
                                    />
                                </Paper>
                                }
                                {value === 'messages' &&
                                <Paper className={classes.paper}>
                                    <CommunicationList {...this.props}
                                                     patient_id={this.state.patient_id}
                                                     byPatient={false}
                                                     dataChange={()=>this.disableOtherTabs('messages')}
                                                     dataSaved={()=>this.enableAllTabs(true)}
                                    />
                                </Paper>
                                }
                                {value === 'appointments' &&
                                <Paper className={classes.paper}>
                                    <PatientAppointmentList {...this.props}
                                                            patient_id={this.state.patient_id}
                                                            byPatient={false}
                                                            dataChange={()=>this.disableOtherTabs('appointments')}
                                                            dataSaved={()=>this.enableAllTabs(true)}/>
                                </Paper>
                                }
                                {value === 'notes' &&
                                <Paper className={classes.paper}>
                                    <PatientNotes {...this.props}
                                                            patient_id={this.state.patient_id}
                                                            byPatient={false}
                                                            dataChange={()=>this.disableOtherTabs('notes')}
                                                            dataSaved={()=>this.enableAllTabs(false)}/>
                                </Paper>
                                }
                                <Paper className={(value==='video' ? classes.paper :classes.paperHidden)}>
                                    <PatientVideo {...this.props} patient_id={this.state.patient_id}
                                                     isPatient={false} autoJoin={false} />
                                </Paper>

                                {value === 'followup' &&
                                <Paper className={classes.paper}>
                                    <PatientFollowup {...this.props} patient_id={this.props.match.params.patient_id}
                                                     byPatient={false}
                                                     dataChange={()=>this.disableOtherTabs('followup')}
                                                     dataSaved={()=>this.enableAllTabs(false)}/>
                                </Paper>
                                }
                                {value === 'lpids' &&
                                <Paper className={classes.paper}>
                                    <PatientLPInfo {...this.props} patient_id={this.props.match.params.patient_id}
                                                     byPatient={false}
                                                     dataChange={()=>this.disableOtherTabs('followup')}
                                                     dataSaved={()=>this.enableAllTabs(false)}/>
                                </Paper>
                                }
                            </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({updatePage}, dispatch);
}

function mapStateToProps(state){
    return(
        {
            credentials: state.credentials,
        });
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PatientDetails));



