import * as TYPE from "../actions/Dosage";
import _ from 'lodash';


export function dosages (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_DOSAGES:
            const obj=_.mapKeys(action.payload.result.dataset,'dosage_id');
            return  obj;
        default:
            return state;
    }
}

export function dosageDetails (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_DOSAGE_DETAIL:
            const obj={...state, [action.payload.dosage_id]:action.payload};
            return obj;
        default:
            return state;
    }
}

export function dosage (state = {}, action){
    switch(action.type) {
        case TYPE.ADD_DOSAGE:
            return {dosage_id: action.dosage_id, iAmNew: true};
        default:
            return state;
    }
}

export function dosageIsLoading (state = false, action){
    switch(action.type){
        case TYPE.DOSAGE_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function dosageIsError (state = false, action){
    switch(action.type){
        case TYPE.DOSAGE_IS_ERROR :
             return action.bool;
        default:
            return state;
    }
}

export function dosageNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.DOSAGE_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

