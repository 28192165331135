import React ,{Component, Fragment} from 'react';
import {List, ListItem,  ListItemText, ListItemSecondaryAction, Avatar,  IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import {styles} from "../../style";
import {Field, reduxForm} from "redux-form";
import _ from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

class ChatUserList extends Component {

    constructor(props){
        super(props);

        this.state= {
            filter:'',

        };
        this.handleFilterChange=this.handleFilterChange.bind(this);
    }

    handleFilterChange (event) {
        this.setState({filter: event.target.value});
    };

    renderItem(data){
        const { classes, chatUsers, unRead, handleSubmit } = this.props;
        let unReadMessages = _.filter(unRead, {from: data.id}).length;
        return(
            <ListItem button key={data.id} onClick={() => {this.props.onChatSelect(data)}}>
                            {!data.data.loggedIn &&
                                <Fragment>
                                    <Avatar className={classes.chatAvatar}>{data.data.initials.toUpperCase()}</Avatar>
                                    <ListItemText primary={data.data.sortName}
                                                  classes={{primary: classes.userDisconnectedList, secondary: classes.userSecondaryText}}
                                                  secondary={data.data.firstname}/>
                                    {unReadMessages >0 && 
                                        <ListItemSecondaryAction className={classes.divmsgDt}>{unReadMessages} Unread</ListItemSecondaryAction>
                                    }
                                    
                                </Fragment>
                            }
                            {data.data.loggedIn &&
                                <Fragment>
                                    <Avatar className={classes.onlineChatAvatar}>{data.data.initials.toUpperCase()}</Avatar>
                                    <ListItemText primary={data.data.sortName}
                                                  classes={{primary: classes.userConnectedList, secondary: classes.userSecondaryText}}
                                                  secondary={data.data.firstname}/>
                                    {unReadMessages >0  && 
                                        <ListItemSecondaryAction className={classes.divmsgDt}>{unReadMessages} Unread</ListItemSecondaryAction>
                                    }
                                </Fragment>
                            }
                        </ListItem>
        );
    }

    render() {
        const { classes, chatUsers, unRead, handleSubmit } = this.props;
        const {filter} = this.state;
        let Users = _.filter(chatUsers, function(item){
            return filter>'' ? item.sortName.indexOf(filter.toUpperCase())>-1: true;
        });

        return(
            <Fragment>
                <div className={classes.chatSearchDiv}>
                    <TextField
                        onChange={this.handleFilterChange}
                        name="filter"
                        id="filter"
                        value={filter}
                        placeholder="Search for User"
                        InputProps={{
                            className: classes.searchChatUser
                        }}
                    />
                    {filter <= '' &&
                    <IconButton><SearchIcon/></IconButton>
                    }
                    {filter>'' &&
                    <IconButton onClick={()=>{this.setState({filter:''})}}><ClearIcon/></IconButton>
                    }
                </div>
                <div style={{
                    height: '80%',
                    overflow:'auto'
                }}>
                <List dense={true}>
                    {Users.map((data) => ( this.renderItem(data) ))}
                </List>
                </div>
            </Fragment>
        );
    }
}

const validate = values => {
    const errors = {}
    if (values.filter) {
        if(values.filter.length<3) {
            errors.filter = 'Please enter a minimum of 3 characters to search';
        }
    }
    return errors
}

export default reduxForm({
    validate,
    form: 'chatUserSearch'
})(withStyles(styles)(ChatUserList));
