import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_MAILING = 'FETCH_MAILING';
export const MAILING_IS_LOADING = 'MAILING_IS_LOADING';
export const MAILING_IS_ERROR = 'MAILING_IS_ERROR';


export function getPatientMailing(patient){
    return (dispatch) => {
        dispatch({type: MAILING_IS_LOADING, bool :true});
        dispatch({type: MAILING_IS_ERROR, bool:false});
        const url = `${API_URL}mailing/fetchByPatient?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: MAILING_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_MAILING, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: MAILING_IS_ERROR, bool:true});
            dispatch({type: MAILING_IS_LOADING, bool: false});
        });
    }
}

export function setPatientMailing(mailing){
    return (dispatch) => {
        dispatch({type: MAILING_IS_LOADING, bool :true});
        dispatch({type: MAILING_IS_ERROR, bool:false});
        const url=`${API_URL}mailing/add?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,mailing, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: MAILING_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_MAILING, payload: response.data.answer});
            } else {
                dispatch({type: MAILING_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: MAILING_IS_ERROR, bool:true});
            dispatch({type: MAILING_IS_LOADING, bool: false});
        });
    }
}

