import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Grid, Paper,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {getPatientMailing, setPatientMailing} from '../actions/PatientMailing';
import {fetchMailingAddressDetail,fetchMailingAddresses} from '../actions/AddressCheck';
import {Field, reduxForm, } from 'redux-form';
import {renderTextField} from '../Components/Forms';
import {POSTKEY} from "../constants_api";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import _ from 'lodash';


class PatientMailing extends Component{

    constructor(props){
        super(props);

        this.state= {
            mailing_id:0,
            patient_id:0,
            street:'',
            suburb:'',
            state:'',
            postcode:'',
            inUse: false,
            showSuggestions:false,
            mAddressSuggest:'',
            addressSuggestions:[],
            useSuggestion: false,
            suggestedAddress: '',
        };
        this.handleChange=this.handleChange.bind(this);
        this.cancel=this.cancel.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.handleChangeAddress=this.handleChangeAddress.bind(this);
        this.copyAddress=this.copyAddress.bind(this);
    }

    componentDidMount() {
        if(this.props.patient_id>0){
            this.props.getPatientMailing({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
            const formData = {
                "patient_id":this.props.patient_id,
                "street":'',
                "suburb":'',
                "state":'',
                "postcode":'',
            };
            this.props.initialize(formData);
        }
    }

    componentDidUpdate(prevProps, prevState){
       if(this.props.mailing!==prevProps.mailing && this.props.mailing){
                this.setState({
                    patient_id:this.props.patient_id,
                    street:this.props.mailing.street,
                    suburb:this.props.mailing.suburb,
                    state:this.props.mailing.state,
                    postcode:this.props.mailing.postcode,
                });
           const formData = {
               "patient_id":this.props.patient_id,
               "street":this.props.mailing.street,
               "suburb":this.props.mailing.suburb,
               "state":this.props.mailing.state,
               "postcode":this.props.mailing.postcode,
           };
           this.props.initialize(formData);
        }
        if(this.props.mailingAddresses !== prevProps.mailingAddresses){
            let showSuggestions = false;
            let addressSuggestions = [];
            if(typeof this.props.mailingAddresses.Items !== 'undefined'){
                if(this.props.mailingAddresses.Items.length>0){
                    showSuggestions=true;
                    addressSuggestions=_.filter(this.props.mailingAddresses.Items,{Next:'Retrieve'});
                }
            }
            this.setState({showSuggestions, addressSuggestions});
        }
        if(this.props.mailingAddress !== prevProps.mailingAddress){
            //first reset search lockout
            this.props.change('mAddressSuggest',this.state.suggestedAddress );
            this.props.change('street',this.props.mailingAddress.Items[0].Line1);
            this.props.change('suburb',this.props.mailingAddress.Items[0].City);
            this.props.change('state',this.props.mailingAddress.Items[0].Province);
            this.props.change('postcode',this.props.mailingAddress.Items[0].PostalCode);
            this.setState({useSuggestion : false});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
        this.props.reset();
    }


    //Action Functions

    onSubmit (values){
        if(this.props.patient_id>0){
            let mailing = {
                patient_id: this.props.patient_id,
                street:values.street,
                suburb:values.suburb,
                state:values.state,
                postcode:values.postcode,
                byPatient:this.props.byPatient,
            }
            this.props.setPatientMailing(mailing);
            // if(typeof this.props.dataSaved!=='undefined'){
            //     this.props.dataSaved();
            // }
        }
    }

    //Render Functions

    renderRefreshDialog(){
        return(<Dialog
            fullScreen
            open={this.props.isLoading}
            PaperProps={{style:{background:'transparent'}}}
        >
            <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                <DialogContent>
                    <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                </DialogContent>
            </div>
        </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.setState({isError:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({isError:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    required(value) {
        return value ? undefined : 'Required'
    }

    handleChangeAddress(event) {
        if(event.target.value.length>4){
            let search={
                Key:POSTKEY,
                SearchTerm: event.target.value,
                Country: 'CAN',
            }
            if(!this.state.useSuggestion){
                this.props.fetchMailingAddresses(search);
            }
        }
        this.setState({[event.target.name]: event.target.value});
    };


    getSuggestionValue(suggestion) {
        this.setState({showSuggestions : false, useSuggestion:true, suggestedAddress: `${suggestion.Text} ${suggestion.Description}`})
        let search={Key:POSTKEY,Id:suggestion.Id};
        this.props.fetchMailingAddressDetail(search);
    }

    copyAddress(){
        this.props.change('street', this.props.homeAddress.street);
        this.props.change('suburb', this.props.homeAddress.suburb);
        this.props.change('state', this.props.homeAddress.state);
        this.props.change('postcode', this.props.homeAddress.postcode);
    }

    render(){
        const { handleSubmit, pristine, submitting, classes } = this.props;
        if(this.props.patient_id<1){
            return(<div></div>);
        }

        return(
            <Fragment>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        <Grid item xs={12} sm={12} md={12} className={classes.gridForm}>
                            <Button variant='contained' onClick={this.copyAddress}>Copy Home Address</Button>
                            <Field
                                name= 'mAddressSuggest'
                                component={renderTextField}
                                label= 'Find Address'
                                margin='normal'
                                onChange={this.handleChangeAddress}
                                fullWidth
                            />
                            {this.state.showSuggestions &&
                            <Paper>
                                <List dense>
                                    {this.state.addressSuggestions.map((address) => {
                                        return(<ListItem key={address.Id} button onClick={()=>{this.getSuggestionValue(address)}}><ListItemText primary={`${address.Text} ${address.Description}`} /></ListItem>)
                                    })}
                                </List>
                            </Paper>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
                            <Field
                                autoFocus
                                name= 'street'
                                component={renderTextField}
                                label= 'Street'
                                margin='normal'
                                onChange={this.handleChange}
                                validate={[this.required]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
                            <Field
                                autoFocus
                                name= 'suburb'
                                component={renderTextField}
                                label= 'City'
                                margin='normal'
                                onChange={this.handleChange}
                                validate={[this.required]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.gridForm}>
                            <Field
                                autoFocus
                                name= 'state'
                                component={renderTextField}
                                label= 'Province'
                                margin='normal'
                                onChange={this.handleChange}
                                validate={[this.required]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className={classes.gridForm}>
                            <Field
                                autoFocus
                                name= 'postcode'
                                component={renderTextField}
                                label= 'Postcode'
                                margin='normal'
                                onChange={this.handleChange}
                                validate={[this.required]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                Save
                            </Button>
                            <Button disabled={pristine || submitting} onClick={this.cancel} variant='contained'>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>

                {this.renderRefreshDialog()}
            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            mailing: state.mailing,
            isLoading: state.mailingIsLoading,
            isError: state.mailingIsError,
            credentials: state.credentials,
            mailingAddress: state.mailingAddress,
            mailingAddresses: state.mailingAddresses,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getPatientMailing, setPatientMailing, fetchMailingAddressDetail, fetchMailingAddresses}, dispatch);
}


export default reduxForm({})(connect(mapStateToProps,mapDispatchToProps)(PatientMailing));

