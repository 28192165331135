import * as TYPE from "../actions/AddressCheck";


export function addresses (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_ADDRESSES:
            return  action.payload;
        default:
            return state;
    }
}

export function address (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_ADDRESS_DETAIL:
            return  action.payload;
        default:
            return state;
    }
}

export function mailingAddresses (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_MAILING_ADDRESSES:
            return  action.payload;
        default:
            return state;
    }
}

export function mailingAddress (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_MAILING_ADDRESS_DETAIL:
            return  action.payload;
        default:
            return state;
    }
}


export function addressesLoading (state = false, action){
    switch(action.type) {
        case TYPE.ADDRESSES_ARE_LOADING:
            return  action.bool;
        default:
            return state;
    }
}

