import * as TYPE from "../actions/Appointments";


export function practitionerCalendar (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_APPOINTMENTS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function practitionerCalendarIsLoading (state = false, action){
    switch(action.type){
        case TYPE.APPTS_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function practitionerCalendarIsError (state = false, action){
    switch(action.type){
        case TYPE.APPTS_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function practitionerCalendarNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.APPTS_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function calendar (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_SLOTS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function calendarIsLoading (state = false, action){
    switch(action.type){
        case TYPE.SLOTS_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function calendarIsError (state = false, action){
    switch(action.type){
        case TYPE.SLOTS_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function calendarNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.SLOTS_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function appointmentBooked (state = false, action){
    switch(action.type){
        case TYPE.APPT_BOOKED :
            return action.bool;
        default:
            return state;
    }
}

export function appointmentCancelled (state = false, action){
    switch(action.type){
        case TYPE.APPT_CANCELLED :
            return action.bool;
        default:
            return state;
    }
}

export function patientAppointments (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_PATIENT_APPOINTMENTS:
            return  action.payload.result;
        default:
            return state;
    }
}
export function patientAppointmentsIsLoading (state = false, action){
    switch(action.type){
        case TYPE.APPTS_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function patientAppointmentsIsIsError (state = false, action){
    switch(action.type){
        case TYPE.APPTS_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientAppointmentsIsNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.APPTS_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function dateAppointments (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_DATE_APPOINTMENTS:
            return  action.payload.result;
        default:
            return state;
    }
}