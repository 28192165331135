import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,Paper,
    Dialog,DialogContent,DialogTitle,DialogActions,
    CircularProgress,
    Typography,
    Grid,
    Tooltip,
    Fab,
} from '@material-ui/core';

import VolumeOff from '@material-ui/icons/VolumeOff';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VideoCam from '@material-ui/icons/Videocam';
import VideoCamOff from '@material-ui/icons/VideocamOff';
import CallIcon from '@material-ui/icons/CallEnd';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import DeleteIcon from '@material-ui/icons/Delete';
import IDCardIcon from '@material-ui/icons/CreditCard';
import HealthcardIcon from '@material-ui/icons/LocalHospital';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import ContentSave from '@material-ui/icons/Save';
import {bindActionCreators} from 'redux';
import {fetchVideoToken, startVideoLog,endVideoLog, sendVideoUrl, sendPatientFullUrl, getRoomParticipants} from '../actions/Video';
import {addPatientPhoto} from '../actions/PatientPhotos';
import {connect as VideoConnect, createLocalTracks} from 'twilio-video';
import jwtDecode from "jwt-decode";
import moment from 'moment-timezone';
import MainVideo from '../Components/Video/mainVideo';
import PatientHealthcardDocumentDetail from './patient_healthcardPhoto-detail';
import {fetchPatientHealthcardPhoto} from '../actions/HealthcardPhotos';
import PatientIdentificationDocumentDetail from './patient_identificationPhoto-detail';
import {fetchPatientIdentificationPhoto} from '../actions/IdentificationPhotos';
import {getPatientMedicalInfo} from "../actions/PatientMedicalInfo";
import {getPatientAppointments} from '../actions/Appointments';
import ImageEditor from './imageEditor';
import _ from 'lodash';
import {checkRole} from '../constants_api';
import {createMuiTheme,MuiThemeProvider} from "@material-ui/core/styles/index";
import teal from "@material-ui/core/colors/teal";
import {RenderTimezoneSelect} from "../Components/Forms";
import {isIOS,isChrome} from 'react-device-detect';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: teal,
    },
});

class VideoConference extends Component{

    constructor(props){
        super(props);

        this.state= {
            videoToken:'',
            identity: 'Me',
            roomName: '',
            localMediaAvailable: false,
            hasJoinedRoom: false,
            activeRoom: null,
            participants:{},
            iAmMute: false,
            iAmVideoMute: false,
            iAmConnecting: false,
            iAmJoining: false,
            extraCommands: false,
            type: 'patient',
            allowVideo: false,
            viewVideo: false,
            selectedParticipant: null,
            imageCaptures: {},
            imageCounter: 0,
            zoomImageDialog :false,
            renderIdDialog :false,
            renderHealthDialog: false,
            termsAgreed: false,
            getParticipantsTimer: 10000,
            tz:'America/Toronto',
            nextAppointment:{},
            upcoming: false,
            iosDevice: '',
        };
        this.thumbnail = React.createRef();
        this.Captures = React.createRef();
        this.mainVideoRef = React.createRef();
        this.joinRoom=this.joinRoom.bind(this);
        this.roomJoined=this.roomJoined.bind(this);
        this.muteMe=this.muteMe.bind(this);
        this.unMuteMe=this.unMuteMe.bind(this);
        this.videoMuteMe=this.videoMuteMe.bind(this);
        this.videoUnMuteMe=this.videoUnMuteMe.bind(this);
        this.leaveRoom=this.leaveRoom.bind(this);
        this.runCommands=this.runCommands.bind(this);
        this.attachTrack=this.attachTrack.bind(this);
        this.renderCaptures=this.renderCaptures.bind(this);
        this.captureVideo=this.captureVideo.bind(this);
        this.saveImage=this.saveImage.bind(this);
        this.updateZoomImage=this.updateZoomImage.bind(this);
        this.timezoneChange=this.timezoneChange.bind(this);
    }

    componentDidMount() {
        let type = this.props.isPatient ? 'patient' : this.props.isPractitioner ? 'practitioner' : 'user' ;
        if(type!=='user'){
            this.setState({allowVideo:true, viewVideo:true});
        } else {
            if(checkRole('viewVideo')){
                this.setState({viewVideo : true});
            }
        }
        //get all the precursor data required before doing the video conference
        if(typeof this.props.healthcard_photo.active ==='undefined' && !this.props.isPatient && this.props.patient_id>0){
            this.props.fetchPatientHealthcardPhoto({patient_id:this.props.patient_id, byPatient:false});
        }
        if(typeof this.props.identification_photo.active ==='undefined' && !this.props.isPatient && this.props.patient_id>0){
            this.props.fetchPatientIdentificationPhoto({patient_id:this.props.patient_id, byPatient:false});
        }
        if(this.props.patient_id>0 && typeof this.props.medical.privacy === 'undefined'){
            this.props.getPatientMedicalInfo({patient_id:this.props.patient_id, byPatient:this.props.isPatient});
        }
        if(this.props.isPatient){  
            //fetch appointments
            this.props.getPatientAppointments({patient_id:this.props.patient_id, byPatient:true});
        } else {
            this.props.getRoomParticipants({room:this.props.room});
            this.interval= setInterval(()=>this.props.getRoomParticipants({room:this.props.room}),this.state.getParticipantsTimer);
        }
        if(typeof this.props.medical.privacy !== 'undefined'){
            let termsAgreed = this.props.medical.privacy==="1" && this.props.medical.citizen==="1" && this.props.medical.consent_to_share==="1" && this.props.medical.agree_to_treat==="1";
            this.setState({termsAgreed});
        }

        //grab a token to allow video conferencing

        this.setState({type:type});
        this.props.fetchVideoToken({id:this.props.id, room:this.props.room, isPatient:this.props.isPatient, extraId:this.props.extraId, isConferenceRoom:this.props.isConferenceRoom});
    }

    componentWillUnmount() {
        if(typeof this.state.activeRoom!=='undefined' && this.state.activeRoom!==null){
            this.props.endVideoLog({participant_id:this.props.id, room:this.props.room, type:this.state.type, conference_id:this.state.activeRoom.sid });

        }
        if(!this.props.isPatient){
            clearInterval(this.interval);
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.videoToken!==prevProps.videoToken){
            if(this.props.autoJoin) {
                this.joinRoom(this.state.allowVideo,this.state.allowVideo);
            }
        }
        if(this.props.videoIdentity!==prevProps.videoIdentity){
            this.setState({identity: this.props.videoIdentity});
        }
        if(this.props.healthcard_photo!==prevProps.healthcard_photo){
            this.setState({renderHealthDialog:false})
        }
        if(this.props.identification_photo !==prevProps.identification_photo){
            this.setState({renderIdDialog:false})
        }
        if(this.props.medical!==prevProps.medical){
            let termsAgreed = this.props.medical.privacy==="1" && this.props.medical.citizen==="1" && this.props.medical.consent_to_share==="1" && this.props.medical.agree_to_treat==="1";
            this.setState({termsAgreed});
        }
        if(this.props.appointments!==prevProps.appointments){
            //find latest appointment
            let booked = _.orderBy(_.filter(this.props.appointments.dataset,{status:"1"}),'appt_dt');
            let arr=[];
            let upcoming=false;
            let now = moment.utc().subtract(30 ,'minutes').format('YYYY-MM-DD HH:mm:00');
            booked.map((data)=>{
                if(data.appt_dt>now){
                    upcoming = true;
                    arr.push(data);

                }
                return 1;
            })
            if(upcoming){
                this.setState({nextAppointment:arr[0], upcoming})
            }
        }
        if(this.props.roomParticipants!==prevProps.roomParticipants){
            this.setState({roomParticipants:this.props.roomParticipants})
        }
    }

    timezoneChange(event) {
        this.setState({tz: event.target.value});
    };

    runCommands(command){
        switch(command){
            case 'Reload' :
                this.leaveRoom();
                setTimeout(()=> {
                    this.joinRoom(this.state.allowVideo,this.state.allowVideo);
                }, (3 * 1000));
                break;
            case 'ForceRefresh' :
                window.location.reload(true);
                break;
            default:
        }
    }

    joinRoom(video,audio) {
        //just check the token we have is still valid..
        if(typeof this.props.videoToken === 'undefined'){
            alert('A problem occured please try to join the room again');
            return false;
        }
        let jwt = jwtDecode(this.props.videoToken);
        let m = moment.utc().unix();
        if(m>jwt.exp){
            //we need to get a new videoToken
            this.props.fetchVideoToken({id:this.props.id, room:this.props.room, isPatient:this.props.isPatient, extraId:this.props.extraId, isConferenceRoom:this.props.isConferenceRoom});
            alert('A problem has occurred please try to join the room again');
            return false;
        }
        let connectOptions = {
            video:video,
            audio:audio
        };
        if(isIOS){
            alert("We are ios");
            
            if(video){
                connectOptions = {
                    video: { width: 640 },
                    audio: audio
                }
                console.log("We are ios and need video", connectOptions);
            } else {
                connectOptions = {
                    video: false,
                    audio: audio
                }
            }
        }
        
        this.setState({iAmJoining:true});
        // navigator.mediaDevices.enumerateDevices().then(devices => {
        //     console.log("devices:",devices);
        //     const videoInput = devices.find(device => device.kind === 'videoinput');
        //     this.setState({iosDevice:videoInput.deviceId});
        //     console.log("Chose video with id:", videoInput);
        //     const lT = createLocalTracks({ audio: true, video: { deviceId: videoInput.deviceId , width: 640, frameRate: 15, facingMode: "user" } });
        //     console.log("Localtracks",lT);
        //     return lT;
        // }).then( localTracks => {
        //     VideoConnect(this.props.videoToken, {tracks: localTracks }).then(this.roomJoined, error => {
        //         alert('Could not connect to Video Server: ' + error.message);
        //         // let devices = navigator.mediaDevices.enumerateDevices();
        //         this.setState({iAmJoining:false});
        //     });
        // });

        // createLocalTracks({ audio: true, video: { width: 640, frameRate: 15, facingMode: "user" } }).then(localTracks => {
        //     VideoConnect(this.props.videoToken, {name: this.props.room ,tracks: localTracks }).then(this.roomJoined, error => {
        //         alert('Could not connect to Video Server: ' + error.message);
        //         let newdevices = navigator.mediaDevices.enumerateDevices();
        //         console.log("Tried to get new devices", newdevices);
        //         this.setState({iAmJoining:false});
        //     });
        // });
        VideoConnect(this.props.videoToken, {audio:true, video: { width:640, frameRate:15}}).then(this.roomJoined, error => {
            alert('Could not connect to Video Server: ' + error.message);
            let newdevices = navigator.mediaDevices.enumerateDevices();
            console.log("Tried to get new devices", newdevices);
            this.setState({iAmJoining:false});
        });
    }

    
    roomJoined(room) {
        // Called when a participant joins a room
        //first log to db
        this.props.startVideoLog({participant_id:this.props.id, room:this.props.room, type:this.state.type, conference_id:room.sid });

        this.setState({
            activeRoom: room,
            localMediaAvailable: true,
            hasJoinedRoom: true,
            iAmJoining: false,
        });
        
        // this.selectParticipant(room.localParticipant);
        room.localParticipant.tracks.forEach(publication => {
            this.attachTrack(publication, room.localParticipant);
          });
        

        // Attach the room's participants.
        room.participants.forEach(participant => {
            this.participantConnected(participant);
        });
        
        // Participant joining room
        room.on('participantConnected', participant => {
            this.participantConnected(participant);
        });


        // Detach all participant’s track when they leave a room.
        room.on('participantDisconnected', participant => {
            if(this.state.selectedParticipant===participant){
                this.setState({selectedParticipant:null});
            }
        });

        // Once the local participant leaves the room
        room.on('disconnected', () => {
            this.props.endVideoLog({participant_id:this.props.id, room:this.props.room, type:this.state.type, conference_id:room.sid });
            this.setState({hasJoinedRoom: false, localMediaAvailable: false, mainVideo:'', activeRoom:null, participants: {}});
        });
    }

    participantConnected(participant){
        participant.tracks.forEach(publication => {
            this.trackPublished(publication, participant);
          });
          participant.on('trackPublished', publication => {
            this.trackPublished(publication, participant);
          });
        participant.on('trackUnpublished', this.trackUnpublished);
    }

    trackPublished(publication, participant){
        if(publication.isSubscribed){
            this.attachTrack(publication, participant);
        }
        publication.on('subscribed', track => {
            this.attachTrack(publication, participant);
          });
        publication.on('unsubscribed', this.detachTrack);
        
    }

    trackUnpublished(publication){
        console.log('Publication unpublished', publication);
    }


    attachTrack(publication, participant) {
        let contain = this.thumbnail.current;
        let positionInfo = contain.getBoundingClientRect();
        let width = positionInfo.width;
        let track = publication.track;
        
        if(track!==null){
            
            if(track.kind==='video'){
                let video = track.attach();
                video.id = participant.sid;
                let vid =contain.appendChild(video);
                vid.style.maxWidth=`${width}px`;
                if(participant !== this.state.activeRoom.localParticipant){
                    video.onclick = () => {
                        this.selectParticipant(participant);
                    };
                    if(this.state.selectedParticipant===null){
                        this.selectParticipant(participant);
                    }
                }
            } else {
                contain.appendChild(track.attach());
            }   
        }; 
    }
      
      // Detach given track from the DOM
    detachTrack(track) {
        track.detach().forEach(element => {
            element.remove();
        });
    }
    

    selectParticipant(participant){
        this.setState({selectedParticipant:participant});
        //loop through thumbnails and hide the selected but make all others inline.
        let contain = this.thumbnail.current;
        let elements = contain.childNodes;
        elements.forEach(element => {
            if(element.tagName==='VIDEO'){
                if(element.id===participant.sid){
                    element.style.display='none';
                } else {
                    element.style.display='inline';
                }
            }
        });
    }

    leaveRoom(){
        let room = this.state.activeRoom;
        this.setState({iAmMute:false, iAmVideoMute:false, activeRoom:null, participants: {}, selectParticipant:null}); //set icons back to default
        let container = this.thumbnail.current;
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
        room.disconnect();
        this.props.endVideoLog({participant_id:this.props.id, room:this.props.room, type:this.state.type, conference_id:this.state.activeRoom.sid });
    }

    muteMe(){
        var localParticipant = this.state.activeRoom.localParticipant;
        localParticipant.audioTracks.forEach(function (audioTrack) {
            audioTrack.track.disable();
        });
        this.setState({iAmMute:true});
    }

    unMuteMe(){
        var localParticipant = this.state.activeRoom.localParticipant;
        localParticipant.audioTracks.forEach(function (audioTrack) {
            audioTrack.track.enable();
        });
        this.setState({iAmMute:false});
    }

    videoMuteMe(){
        var localParticipant = this.state.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach(function (videoTrack) {
            videoTrack.track.disable();
        });
        this.setState({iAmVideoMute:true});
    }

    videoUnMuteMe(){
        var localParticipant = this.state.activeRoom.localParticipant;
        localParticipant.videoTracks.forEach(function (videoTrack) {
            videoTrack.track.enable();
        });
        this.setState({iAmVideoMute:false});
    }

    //Other communication functions
    sendFullMessage(sendBy){
        let params = {patient_id: this.props.patient_id, sendBy:sendBy};
        this.props.sendPatientFullUrl(params);
    }


    captureVideo(){
        //use mainVideo
        let vidDiv = this.mainVideoRef.current;
        let video = vidDiv.querySelector('video');
        let videoWidth = video.videoWidth;
        let videoHeight = video.videoHeight;
        let canvas = document.createElement("canvas");
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        canvas.getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        let images = this.state.imageCaptures;
        images[this.state.imageCounter]={id:this.state.imageCounter, image:dataUrl, width:videoWidth, height:videoHeight};
        this.setState({ imageCaptures:images, imageCounter:this.state.imageCounter+1});
    }

    deleteCapture(image_id){
        let imgs = this.state.imageCaptures;
        delete imgs[image_id];
        this.setState({imageCaptures: imgs});
        this.renderCaptures();
    }

    setIdCard(image_id){
        this.setState({idImage:this.state.imageCaptures[image_id].image, renderIdDialog:true});
    }

    setHealthcard(image_id){
        this.setState({healthImage:this.state.imageCaptures[image_id].image, renderHealthDialog:true});
    }

    zoomImage(image_id){
        //open fullscreen dialog with this image as the zoomedImage
        this.setState({zoomImage: this.state.imageCaptures[image_id], zoomImgSrc:this.state.imageCaptures[image_id].image ,zoomImageDialog:true});

    }

    saveImage(image){
        var id_doc = {
            patient_id: this.props.patient_id,
            image:image.image,
            byPatient:this.props.byPatient,
        }
        this.props.addPatientPhoto(id_doc);
    }

    updateZoomImage(image){
        let image_id = this.state.zoomImage.id;
        let images = this.state.imageCaptures;
        images[image_id].image=image;
        this.setState({imageCaptures:images,zoomImageDialog:false});
    }

    showCapture(){
        if(!this.props.isPatient) {
            if (checkRole('practitioner') || checkRole('viewVideo')) {
                return true;
            }
        }
        return false;
    }



    //Render Functions



    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            },
            paper: {
                padding: '10px',
                margin:'10px'
            },
        });
    }



    //Render Functions

    renderHealthCard (){
        return (
            <Dialog
                open={this.state.renderHealthDialog}
                onClose={()=>{this.setState({renderHealthDialog:false})}}
                maxWidth={false}
            >
                <DialogContent>
                    <PatientHealthcardDocumentDetail {...this.props} patient_id={this.props.match.params.patient_id} passPhoto={true} image={this.state.healthImage}  form={'videoHealthcardForm'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({renderHealthDialog:false})}} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderIdCard (){
        return (
            <Dialog
                open={this.state.renderIdDialog}
                onClose={()=>{this.setState({renderIdDialog:false})}}
                maxWidth={false}
            >
                <DialogContent>
                    <PatientIdentificationDocumentDetail {...this.props} patient_id={this.props.match.params.patient_id} passPhoto={true} image={this.state.idImage}  form={'videoIdDocForm'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({renderIdDialog:false})}} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderZoomImage (){
        return (
            <Dialog
                open={this.state.zoomImageDialog}
                onClose={()=>{this.setState({zoomImageDialog:false})}}
                maxWidth={false}
            >
                <DialogTitle id="alert-dialog-title">{`Image Capture Viewer`}</DialogTitle>
                <DialogContent>
                    <ImageEditor originalImage={this.state.zoomImgSrc} updateCallback={this.updateZoomImage}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({zoomImageDialog:false})}} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderCommunicationDialog(){
        return(<Dialog
                fullScreen
                open={this.props.communicationIsLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }
    
    renderCaptures(){
        //check to see if any captures have been done
        if(this.state.imageCounter===0){
            return false;
        }
        let container = this.Captures.current;
        let positionInfo = container.getBoundingClientRect();
        let maxHeight = this.state.windowHeight-200;
        let maxWidth =   positionInfo.width; //parseInt(this.state.windowWidth/4,10);
        //let cellHeight = parseInt(maxHeight/5,10);
        let imageWidth = maxWidth - 40;
        return(
            <div style={{width:`${maxWidth}px`, height:`${maxHeight}px`, overflow:"auto "}}>
                {
                    _.orderBy(_.values(this.state.imageCaptures),'id','desc').map( image => (
                            <Paper style={this.Style().paper} key={image.id}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={image.image} width={imageWidth} alt=""/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title='Delete'>
                                            <Fab onClick={()=>{this.deleteCapture(`${image.id}`)}} color="secondary"  size='small'><DeleteIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Set as ID'>
                                            <Fab onClick={()=>{this.setIdCard(`${image.id}`)}} color="primary" size='small'><IDCardIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Set as Healthcard'>
                                            <Fab onClick={()=>{this.setHealthcard(`${image.id}`)}} color="secondary" size='small'><HealthcardIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Zoom'>
                                            <Fab onClick={()=>{this.zoomImage(`${image.id}`)}} color="primary" size='small'><ZoomIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Save'>
                                            <Fab onClick={()=>{this.saveImage(image)}} color="primary" size='small'><ContentSave/></Fab>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    )
                }
                
            </div>

        )
    }
    
    renderRoomParticipants(){
        const ps = _.values(this.state.roomParticipants);
        return(
            ps.map((participant)=> {
                return(
                    <Typography key={participant.identity} variant="subtitle1">{participant.identity} - {participant.duration}</Typography>
                )
            })
        );
    }


    render() {
        let appt_dt=null;
        if(this.state.upcoming){
            appt_dt = moment.utc(this.state.nextAppointment.appt_dt).tz(this.state.tz).format('YY-MM-DD HH:mm');
        }

        return (
        <MuiThemeProvider theme={theme}>
            <Grid container>
                <Grid item sm={8} xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Dialog open={this.state.iAmJoining}>
                                <DialogContent><Typography variant="h6">Please Wait ...</Typography></DialogContent>
                            </Dialog>
                            {isIOS && 
                            <Typography variant="subtitle1">WE are IOS {`${this.state.iosDevice}`}</Typography>
                            }
                            {this.state.hasJoinedRoom && this.state.viewVideo &&
                                <MainVideo participant={this.state.selectedParticipant} vidRef={this.mainVideoRef}/>
                            }
                            {!this.state.termsAgreed && this.state.type==='patient' &&
                                            <Typography variant="subtitle1">Please Accept Terms and Conditions in the Medical Section before Continuing </Typography>
                            }
                            {!this.state.termsAgreed && this.state.type!=='patient' &&
                                    <Button onClick={()=>{this.props.getPatientMedicalInfo({patient_id:this.props.patient_id, byPatient:this.props.isPatient});}}>click to ReCheck Patient has Accepted Terms and Conditions in the Medical Section before Continuing</Button>        
                            }
                            {!this.state.hasJoinedRoom && this.state.type!=='patient' &&
                                <Fragment>
                                    {this.renderRoomParticipants()}
                                </Fragment>
                            }
                            {this.state.upcoming && !this.state.hasJoinedRoom &&
                                        <Fragment>
                                            <Typography variant="subtitle1">Your appointment is set for {`${appt_dt}`}</Typography>
                                            <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/>
                                        </Fragment>
                            }
                            {!this.state.hasJoinedRoom && this.state.type==='patient' && this.state.termsAgreed &&
                                <Typography variant="subtitle1">Ready to attend your video consultation with a Health Care Practitioner? Select the telephone icon to connect.</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {this.state.type!=='patient' &&
                                <Fragment>
                                    <Grid item xs={2}>
                                        <Tooltip title='Send Video link by Email'><Fab onClick={()=>{this.sendFullMessage('Email')}} color="secondary"><EmailIcon/></Fab></Tooltip>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Tooltip title='Send Video link by SMS'><Fab onClick={()=>{this.sendFullMessage('SMS')}} color="secondary"><SmsIcon/></Fab></Tooltip>
                                    </Grid>
                                </Fragment>
                                }
                                
                                {this.state.hasJoinedRoom && this.state.allowVideo &&
                                <Fragment>
                                    <Grid item xs={4} sm={2}>
                                        {this.state.iAmMute && <Tooltip title='UnMute'><Fab onClick={this.unMuteMe} color="secondary"><VolumeOff/></Fab></Tooltip>}
                                        {!this.state.iAmMute && <Tooltip title='Audio Mute'><Fab onClick={this.muteMe} color="primary"><VolumeUp/></Fab></Tooltip>}
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        {this.state.iAmVideoMute && <Tooltip title='Restart Video'><Fab onClick={this.videoUnMuteMe} color="secondary"><VideoCamOff/></Fab></Tooltip>}
                                        {!this.state.iAmVideoMute && <Tooltip title='Blank Video'><Fab onClick={this.videoMuteMe} color="primary" ><VideoCam/></Fab></Tooltip>}
                                    </Grid>
                                </Fragment>                                    
                                }
                                <Grid item sm={2} xs={4}>
                                    {this.state.hasJoinedRoom && <Tooltip title='Leave Consult'><Fab onClick={this.leaveRoom} color="secondary" ><CallIcon/></Fab></Tooltip>}
                                    {!this.state.hasJoinedRoom && this.state.allowVideo && this.state.termsAgreed && this.state.type==='patient' && <Tooltip title='Join Consult'><Fab onClick={()=>this.joinRoom(true,true)}  color="primary" ><CallIcon/></Fab></Tooltip>}
                                    {!this.state.hasJoinedRoom && !this.state.allowVideo && this.state.termsAgreed && this.state.type!=='patient' && <Tooltip title='Join Consult'><Fab onClick={()=>this.joinRoom(false,false)}  ><CallIcon/></Fab></Tooltip>}
                                    {!this.state.hasJoinedRoom && this.state.allowVideo && this.state.type!=='patient' && <Tooltip title='Join Consult'><Fab onClick={()=>this.joinRoom(true,true)}  color="primary" ><CallIcon/></Fab></Tooltip>}
                                    {!this.state.hasJoinedRoom && !this.state.allowVideo && this.state.type!=='patient' && <Tooltip title='Join Consult'><Fab onClick={()=>this.joinRoom(false,false)}  ><CallIcon/></Fab></Tooltip>}
                                </Grid>
                                {this.showCapture() && this.state.selectedParticipant!==null && this.state.hasJoinedRoom &&
                                        <Fragment>
                                            <Grid item xs={2}>
                                                <Tooltip title='Capture Image'><Fab onClick={this.captureVideo} color="secondary"><CameraIcon/></Fab></Tooltip>
                                            </Grid>
                                        </Fragment>
                                        }
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.type !== 'patient' &&
                        <Grid container>
                            <Grid item xs={6}>
                            <img src={this.props.healthcard_photo.image} style={{maxHeight: '150px', maxWidth: '300px'}}
                                 alt=""/>
                            </Grid>
                            <Grid item xs={6}>
                            <img src={this.props.identification_photo.image} style={{maxHeight:'150px', maxWidth:'300px'}} alt=""/>
                            </Grid>
                        </Grid>
                    }   
                </Grid>
                {this.state.viewVideo &&
                    <Grid item sm={2} xs={6}>
                        <div ref={this.thumbnail}/>
                    </Grid>
                }
                
                {this.state.type !== 'patient' &&
                    <Grid item sm={2}>
                        <div ref={this.Captures}/>
                        {this.renderCaptures()}
                        {this.renderZoomImage()}
                        {this.renderHealthCard()}
                        {this.renderIdCard()}
                    </Grid>
                }
                {this.renderCommunicationDialog()}
            </Grid>
        </MuiThemeProvider>
        );
    }
}

function mapStateToProps(state){
    return(
        {
            videoToken: state.videoToken,
            videoIdentity: state.videoIdentity,
            videoCommandSetting : state.videoCommandSetting,
            communicationIsLoading: state.communicationIsLoading,
            healthcard_photo: state.healthcard_photo,
            identification_photo: state.identification_photo,
            medical: state.medical,
            appointments: state.patientAppointments,
            loadingParticipants: state.loadingParticipants,
            roomParticipants : state.participants,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchVideoToken, startVideoLog,endVideoLog, sendVideoUrl, getPatientMedicalInfo,getRoomParticipants,
        sendPatientFullUrl, addPatientPhoto, fetchPatientHealthcardPhoto,fetchPatientIdentificationPhoto,getPatientAppointments}, dispatch);
}


export default connect(mapStateToProps,mapDispatchToProps)(VideoConference);

