import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_LPS = 'FETCH_LPS';
export const FETCH_LP_DETAIL = 'FETCH_LP_DETAIL';
export const ADD_LP = 'ADD_LP';
export const EDIT_LP = 'EDIT_LP';
export const LP_IS_LOADING = 'LP_IS_LOADING';
export const LP_IS_ERROR = 'LP_IS_ERROR';
export const LP_NEEDS_REFRESH = 'LP_NEEDS_REFRESH';
export const FETCH_LP_METHODS = 'FETCH_LP_METHODS';
export const LP_METHOD_IS_LOADING = 'LP_METHOD_IS_LOADING';
export const LP_METHOD_IS_ERROR = 'LP_METHOD_IS_ERROR';
export const LP_METHOD_NEEDS_REFRESH = 'LP_METHOD_NEEDS_REFRESH';



export function fetchLps(params){
    return (dispatch) => {
        dispatch({type: LP_IS_LOADING, bool :true});
        dispatch({type: LP_IS_ERROR, bool:false});
        dispatch({type: LP_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}lp/list`;
        let token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_IS_LOADING, bool: false});
            dispatch({type: LP_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_LPS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: LP_IS_ERROR, bool:true});
            dispatch({type: LP_IS_LOADING, bool: false});
            dispatch({type: LP_NEEDS_REFRESH, bool: false});
        });
    }
}

export function fetchLpsByMethod(params){
    return (dispatch) => {
        const url = `${API_URL}lp/listByMethod?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            if(response.data.Success){
                dispatch({type: FETCH_LPS, payload: response.data});
            }
        }).catch((err)=>{

        });
    }
}

export function fetchLpDetail(lp_id){
    return (dispatch) => {
        dispatch({type: LP_IS_LOADING, bool :true});
        dispatch({type: LP_IS_ERROR, bool:false});
        dispatch({type: LP_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}lp/fetch`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,lp_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_IS_LOADING, bool: false});
            dispatch({type: LP_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_LP_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: LP_IS_ERROR, bool:true});
            dispatch({type: LP_IS_LOADING, bool: false});
            dispatch({type: LP_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addLp(lp){
    return (dispatch) => {
        dispatch({type: LP_IS_LOADING, bool :true});
        dispatch({type: LP_IS_ERROR, bool:false});
        dispatch({type: LP_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}lp/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,lp, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_LP, lp_id: response.data.lp_id})
                dispatch({type: LP_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: LP_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: LP_IS_ERROR, bool:true});
            dispatch({type: LP_IS_LOADING, bool: false});
        });
    }
}

export function deleteLp(id){
    return (dispatch) => {
        dispatch({type: LP_IS_LOADING, bool :true});
        dispatch({type: LP_IS_ERROR, bool:false});
        dispatch({type: LP_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}lp/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const lp_id = {lp_id:id};
        const request = axios.post(url,lp_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: LP_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: LP_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: LP_IS_ERROR, bool:true});
            dispatch({type: LP_IS_LOADING, bool: false});
        });
    }
}

export function editLp(lp){
    return (dispatch) => {
        dispatch({type: LP_IS_LOADING, bool :true});
        dispatch({type: LP_IS_ERROR, bool:false});
        dispatch({type: LP_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}lp/edit?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,lp, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: LP_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: LP_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: LP_IS_ERROR, bool:true});
            dispatch({type: LP_IS_LOADING, bool: false});
        });
    }
}

export function fetchLpMethods(lp_id){
    return (dispatch) => {
        dispatch({type: LP_METHOD_IS_LOADING, bool :true});
        dispatch({type: LP_METHOD_IS_ERROR, bool:false});
        dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}lp/getMethods?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,lp_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_METHOD_IS_LOADING, bool: false});
            dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_LP_METHODS, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: LP_METHOD_IS_ERROR, bool:true});
            dispatch({type: LP_METHOD_IS_LOADING, bool: false});
            dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addLpMethod(lp_method){
    return (dispatch) => {
        dispatch({type: LP_METHOD_IS_LOADING, bool :true});
        dispatch({type: LP_METHOD_IS_ERROR, bool:false});
        dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}lp/addMethod?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,lp_method,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_METHOD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: LP_METHOD_IS_ERROR, bool:true});
            dispatch({type: LP_METHOD_IS_LOADING, bool: false});
            dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
        });
    }
}

export function deleteLpMethod(lp_method){
    return (dispatch) => {
        dispatch({type: LP_METHOD_IS_LOADING, bool :true});
        dispatch({type: LP_METHOD_IS_ERROR, bool:false});
        dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}lp/deleteMethod`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,lp_method,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: LP_METHOD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: LP_METHOD_IS_ERROR, bool:true});
            dispatch({type: LP_METHOD_IS_LOADING, bool: false});
            dispatch({type: LP_METHOD_NEEDS_REFRESH, bool: false});
        });
    }
}
