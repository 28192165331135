import React, { Component, } from 'react';
import { Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logoutPatient,updateTokenFromStorage} from "../../actions/Patients";
import jwtDecode from 'jwt-decode';
import {createMuiTheme,MuiThemeProvider} from "@material-ui/core/styles/index";
import {withRouter} from 'react-router-dom';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#1565c0',
        },
        secondary: {
            main: '#81d4fa',
        },
    },
});

class PatientHeader extends Component {

    constructor(props){
        super(props);
        this.state = {
            auth:false,
        };
        this.Logout=this.Logout.bind(this);
    }

    componentDidMount(){
        this.checkAutoLogout();
        this.interval= setInterval(()=>this.checkAutoLogout(),30000);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.credentials!==prevProps.credentials){
            this.checkAutoLogout();
            localStorage.setItem('token', this.props.credentials.token);
        }
    }

    checkAutoLogout(){
        if(this.props.location.pathname!=='/patientLogin' && this.props.location.pathname!=='/' && this.props.location.pathname.substr(0,7)!=='/signup'){
            if(!this.checkAuthenticated()){
                clearInterval(this.interval);
                this.Logout();
                return false;
            }
        }
        return true;
    }

    checkAuthenticated(){
        let token=this.props.credentials.token;
        let details = this.props.credentials.details;
        if(this.props.credentials.token<='' || typeof this.props.credentials.token === 'undefined'){
            //check to see if we have a token stored in localStorage
            if(localStorage.getItem('token')>''){
                token=localStorage.getItem('token');
                details=jwtDecode(token);
                //fire an update token
                this.props.updateTokenFromStorage(token);
            } else {
                this.setState({auth:false});
                return false;
            }
        }
        var ct = new Date().getTime();
        var curTime = Math.floor(ct/1000);
        //check to see if the token hasn't expired
        if(curTime<details.exp){
            this.setState({auth:true});
            return true;
        } else {
            this.setState({auth:false});
            return false;
        }
    }


    Logout(){
        localStorage.removeItem('token');
        this.props.logoutPatient();
        this.props.history.push('/patientLogin');
    }

    render() {

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container alignItems='center'>
                    <Grid item xs={3} style={{marginBottom:'10px'}}>
                        <img src="/LogoWords.png" height="60px" alt="logo"/>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container justify='flex-end'>
                            <Grid item>
                                {this.state.auth &&
                                    <Button onClick={()=>{this.Logout()}}>LOGOUT</Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        )
    }

}


function mapStateToProps(state){
    return(
        {
            credentials: state.credentials,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({logoutPatient,updateTokenFromStorage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PatientHeader));
