import React ,{Component} from 'react';
import {Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VideoConference from '../../containers/video-controls-2'


const styles = theme => ({
    outer: {
        height: '80vh',
    },
    paper: {
        padding: theme.spacing.unit * 5,
        height: '100%',
        margin:'20'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
});



class Test extends Component {

    render() {
        console.log("What props", this.props);
        return(
            <Grid container style={{height: '94vh'}}>
            <Grid item xs={12}>
                
            </Grid>

            </Grid>
        );
    }
}

export default withStyles(styles)(Test);
