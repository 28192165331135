import React, { Component } from 'react';
import {Grid} from '@material-ui/core';
import {createMuiTheme,MuiThemeProvider} from "@material-ui/core/styles/index";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#1565c0',
        },
        secondary: {
            main: '#81d4fa',
        },
    },
});


export default class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            menuSelected: false,
            anchorEl: null,
            profileMenuAnchor: null,
        };
    }


    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Grid container justify="center" alignItems="center">
                    <Grid item style={{marginBottom:'10px'}}>
                        <img src="/LogoWords.png" height="90px" alt="logo"/>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        )
    }

}


