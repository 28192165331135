import * as TYPE from "../actions/DeliveryMethods";


export function deliveryMethods (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_DELIVERY_METHODS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function deliveryMethod (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_DELIVERY_METHOD_DETAIL:
            return action.payload;
        case TYPE.ADD_DELIVERY_METHOD:
            return {delivery_id: action.delivery_id, iAmNew: true};
        default:
            return state;
    }
}

export function deliveryMethodIsLoading (state = false, action){
    switch(action.type){
        case TYPE.DELIVERY_METHOD_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function deliveryMethodIsError (state = false, action){
    switch(action.type){
        case TYPE.DELIVERY_METHOD_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function deliveryMethodNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.DELIVERY_METHOD_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
