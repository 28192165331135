import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button,
    Typography,
    Grid,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {addEmail} from '../actions/Communication';
import {renderTextField} from "../Components/Forms";
import {Field, reduxForm, } from 'redux-form';
import { EditorState, convertToRaw,  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import  draftToHtml from 'draftjs-to-html';


class EmailMsg extends Component{

    constructor(props){
        super(props);

        this.state= {
            email:'',
            email_subject:'',
            htmlContent:'',
            uploadedImages: [],
            editorState: EditorState.createEmpty(),
        };
        this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
    }

    componentDidMount() {
        const formData = {
            "email": this.props.patient.email,
            "email_subject": "",
        };
        this.props.initialize(formData);
    }


    uploadImageCallBack(file){
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        let uploadedImages = this.state.uploadedImages;

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }

        uploadedImages.push(imageObject);

        this.setState({uploadedImages: uploadedImages});

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: imageObject.localSrc } });
            }
        );
    }



    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }


    onEmailSubmit (values){
        let emailcontent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        let email = {
            patient_id : this.props.patient.patient_id,
            email : values.email,
            subject : values.email_subject,
            content: emailcontent
        }
        this.props.addEmail(email);
        this.props.closeHandler();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    //Render Functions
    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    render(){
        const getFileBase64 = (file, callback) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            // Since FileReader is asynchronous,
            // we need to pass data back.
            reader.onload = () => callback(reader.result);
            // TODO: catch an error
            reader.onerror = error => {};
        };

        const imageUploadCallback = file => new Promise(
            (resolve, reject) => getFileBase64(
                file,
                data => resolve({ data: { link: data } })
            )
        );
        const { handleSubmit } = this.props;
        const editorStyle = {
            border: '1px solid black',
            padding: '5px',
            borderRadius: '2px',
            height: '300px',
            width: '100%',
        };

        return(
            <Grid item xs={12}>
                <Typography variant="h5" color="inherit"><u>New Email</u></Typography>
                <form onSubmit={handleSubmit(this.onEmailSubmit.bind(this))}>
                    <div>
                        <Field
                            autoFocus
                            name= 'email'
                            value={this.state.email}
                            component={renderTextField}
                            label= 'To'
                            margin='normal'
                            onChange={this.handleChange}
                            validate={[this.required]}
                            fullWidth
                        />
                    </div>
                    <div>
                        <Field
                            name= 'email_subject'
                            component={renderTextField}
                            label= 'Subject'
                            margin='normal'
                            onChange={this.handleChange}
                            fullWidth
                            validate={[this.required]}
                        />
                    </div>
                    <div>
                        <Editor
                            initialEditorState={this.state.editorState}
                            editorStyle={editorStyle}
                            toolbar={{ image: { uploadEnabled: true, uploadCallback: imageUploadCallback, previewImage:true }}}
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    </div>
                    <div>
                        <Button type="submit" color='secondary' variant='contained'>
                            Send
                        </Button>
                    </div>
                </form>
            </Grid>

        );
    }
}


function mapStateToProps(state){
    return(
        {
            patient: state.patient,
            communication : state.communication
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({addEmail}, dispatch);
}

export default reduxForm({
    form: 'newEmailForm'
})(connect(mapStateToProps,mapDispatchToProps)(EmailMsg));




