import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    Typography,
    Grid,
    List,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchCommunicationsByPatient,addSMS} from '../actions/Communication';
import {MessageListItem} from './messageListItem';
import {renderTextField} from "../Components/Forms";
import {Field, reduxForm} from 'redux-form';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EmailMsg  from './email-message';
import {checkRole} from '../constants_api';

class CommunicationList extends Component{

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            newSMSOpen: false,
            newEmailOpen: false,
            mobile: '',
        };
        this.closeEmail=this.closeEmail.bind(this);
    }


    componentDidMount() {
        this.props.fetchCommunicationsByPatient({patient_id:this.props.patient_id});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.needsRefresh) {
            this.props.fetchCommunicationsByPatient({patient_id:this.props.patient_id});
        }
        if(this.props.data!==prevProps.data){
        }
        if(this.props.patient!==prevProps.patient){
            this.setState({mobile: this.props.patient.secondary_phone});
            const formData = {
                "mobile": this.props.patient.secondary_phone,
                "subject": "",
                "content": ""
            };
            this.props.initialize(formData);
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    canSendMessages(){
        return checkRole('sendMessages');
    }

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }

    onSubmit (values){
        let sms = {
            patient_id : this.props.patient_id,
            mobile : values.mobile,
            subject : values.subject,
            content : values.content
        }
        this.props.addSMS(sms);
        this.setState({newSMSOpen:false});
    }

    closeEmail(){
        this.setState({newEmailOpen:false});
    }


    //Render Functions
    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.state.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderAddSMS(){
        const { handleSubmit } = this.props;
        return(
            <Dialog
                fullWidth={true}
                maxWidth = {'md'}
                open={this.state.newSMSOpen}
                onClose={()=>{this.setState({newSMSOpen:false})}}
            >
                    <DialogContent>
                        <div style={{width:'100%'}}>
                            <Typography variant="h5" color="inherit"><u>New SMS</u></Typography>
                            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <div>
                                    <Field
                                        autoFocus
                                        name= 'mobile'
                                        value={this.state.mobile}
                                        component={renderTextField}
                                        label= 'Mobile'
                                        margin='normal'
                                        onChange={this.handleChange}
                                        validate={[this.required]}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name= 'subject'
                                        component={renderTextField}
                                        label= 'Subject (Not Sent to Patient)'
                                        margin='normal'
                                        onChange={this.handleChange}
                                        fullWidth
                                        validate={[this.required]}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name= 'content'
                                        component={renderTextField}
                                        label= 'Message'
                                        margin='normal'
                                        onChange={this.handleChange}
                                        fullWidth
                                        multiline
                                        validate={[this.required]}
                                    />
                                </div>
                                <div>
                                    <Button type="submit" color='secondary' variant='contained'>
                                        Send
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({newSMSOpen:false})}} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderAddEmail(){
        return(
            <Dialog
                fullWidth={true}
                maxWidth = {'md'}
                open={this.state.newEmailOpen}
                onClose={()=>{this.setState({newEmailOpen:false})}}
            >
                <DialogContent>
                    <EmailMsg patient={this.props.patient} closeHandler={this.closeEmail}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({newEmailOpen:false})}} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList(){
        if(typeof this.props.data.dataset === 'undefined'){
            return(<div></div>);
        }
        return (
            <Grid item xs={12}>
                <List>
                    {this.props.data.dataset.map((data)=>{
                    return (
                        <MessageListItem data={data} key={data.communication_id} communication_id={data.communication_id}/>
                    );
                })}
                </List>
            </Grid>
        )
    }


    render(){
        return(
            <Fragment>
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    { this.canSendMessages() &&
                    <Grid item xs={12}>
                        <Button onClick={()=>{this.setState({newSMSOpen:true})}}>
                            New SMS
                        </Button>
                        <Button onClick={()=>{this.setState({newEmailOpen:true})}}>
                            New Email
                        </Button>
                    </Grid> }
                    {this.renderList()}
                </Grid>
                {this.renderRefreshDialog()}
                {this.renderAddSMS()}
                {this.renderAddEmail()}
            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.communications,
            isLoading: state.communicationIsLoading,
            isError: state.communicationIsError,
            needsRefresh: state.communicationNeedsRefresh,
            patient: state.patient,
            communication : state.communication
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchCommunicationsByPatient, addSMS}, dispatch);
}

export default reduxForm({
    form: 'newSmsForm'
})(connect(mapStateToProps,mapDispatchToProps)(CommunicationList));
