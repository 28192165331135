import React ,{Component} from 'react';
import {PublicHeader} from '../Layouts';
import VideoConference from '../../containers/video-conference';
import {Grid} from '@material-ui/core';


export default class PublicVideo extends Component {
    render() {
        let params= this.props.match.params.id.split("T");
        let room = "patient_" + params[0];
        return(
            <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                <Grid item xs={12}>
                    <PublicHeader {...this.props}/>
                </Grid>
            <Grid item xs={12}>

            <VideoConference {...this.props} id={params[0]} room={room} isPatient={true} autoJoin={true} acceptCommands={true}/>
            </Grid>

            </Grid>
        );
    }
}

