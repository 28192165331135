import React, { Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Grid, Typography, Dialog, DialogContent, DialogContentText, DialogActions,DialogTitle, CircularProgress} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {renderTextField, renderDatePicker, renderRadio} from '../Components/Forms'
import {bindActionCreators} from "redux";
import {signupPatient} from '../actions/Patients';
import {connect} from 'react-redux';
import moment from "moment-timezone";



class SignupDetail extends Component {

    constructor(props){
        super(props);

        this.state= {
            firstname: "",
            lastname:"",
            email:'',
            dob: '',
            secondary_phone:'',
            isError: false,
            messageList:[{value:'Email', label:'Email'},{value:'SMS',label:'SMS'}],
            referCode: '',
            registerSuccess: false,
        };
        this.handleChangeDob=this.handleChangeDob.bind(this);
        this.handleCodeChange=this.handleCodeChange.bind(this);
    }

    onSubmit(values){
        let patient = {
            firstname: values.firstname,
            lastname:values.lastname,
            dob:this.state.dob,
            email:values.email,
            secondary_phone:values.secondary_phone,
            sendBy:values.sendBy,
            referCode: values.referCode,
        }
        this.props.signupPatient(patient);
    }

    componentDidMount(){
        let formData={ 'referCode' : this.props.match.params.code};
        this.props.initialize(formData);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.patientSignupIsError!==prevProps.patientSignupIsError){
            this.setState({isError:this.props.patientSignupIsError})
        }
        if(this.props.patient!==prevProps.patient){
            this.setState({registerSuccess:true});
        }
    }

    required(value) {
        return value ? undefined : 'Required'
    }

    handleChangeDob(value) {
        let d = moment(value).format("YYYY-MM-DD");
        this.setState({dob: d});
    };


    handleCodeChange(event){
        //this.setState({[event.target.name]: event.target.value});
    };

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.setState({isError:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time. Please Call Cannenta on 1-844-410-2668
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({isError:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderLoadingDialog(){
        return(<Dialog
                fullScreen
                open={this.props.patientSignupWait}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={{
                            background: 'transparent',
                            textAlign: 'center',
                            margin: 'auto'
                        }}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderSuccessDialog(){
        return(
            <Dialog
                open={this.state.registerSuccess}
            >
            <DialogTitle id="alert-dialog-title">{`Thank you for registering`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Thank you for registering, a confirmation message has been sent to you.
                    If you have any questions please Call Cannenta on 1-844-410-2668.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{this.setState({registerSuccess:false}); this.props.history.push('/');}} color="primary">
                    OK
                </Button>
            </DialogActions>
            </Dialog>
        );
    }

    render(){
        const {handleSubmit} = this.props;
        const { classes } = this.props;
        return(
            <Grid container  style={{flexGrow:1}}>
                {this.renderErrorDialog()}
                {this.renderLoadingDialog()}
                {this.renderSuccessDialog()}
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Please Enter Details to Register</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                          autoFocus
                          fullWidth
                        name= 'firstname'
                        component={renderTextField}
                        label= 'First Name'
                        className={classes.textField}
                        margin='normal'
                          validate={[this.required]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name= 'lastname'
                            component={renderTextField}
                            label= 'Last Name'
                            className={classes.textField}
                            margin='normal'
                            validate={[this.required]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name= 'email'
                            component={renderTextField}
                            label='Email'
                            className={classes.textField}
                            margin='normal'
                            validate={[this.required]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name= 'secondary_phone'
                            component={renderTextField}
                            label='Mobile Phone'
                            className={classes.textField}
                            margin='normal'
                            validate={[this.required]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name= 'dob'
                            component={renderDatePicker}
                            label= 'Date of Birth'
                            margin='normal'
                            onChange={this.handleChangeDob}
                            myFormat='MMM-DD-YYYY'
                            validate={[this.required]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name= 'referCode'
                            component={renderTextField}
                            label='Enter Code (Optional)'
                            className={classes.textField}
                            margin='normal'
                            onChange={this.handleCodeChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Please send confirmation using:</Typography>
                        <Field
                            name= 'sendBy'
                            component={renderRadio}
                            label= 'Send Confirmation'
                            margin='normal'
                            validate={[this.required]}
                            listOfValues={this.state.messageList}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" color='secondary' variant='contained'>
                            Signup
                        </Button>
                    </Grid>
                    <Grid item style={{paddingTop:'10px'}}>
                        <Typography variant="body2">Already registered?
                                <Link to='/patientlogin'>
                                    Login Here
                                </Link>
                        </Typography>
                    </Grid>
                </form>
            </Grid>
        );
    }

}

function mapStateToProps(state){
    return(
        {
            patientSignupWait: state.patientSignupWait,
            patientSignupIsError: state.patientSignupIsError,
            patient:state.patient,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({signupPatient}, dispatch);
}


export default reduxForm({
    form: 'signupForm'
})(connect(mapStateToProps,mapDispatchToProps)(SignupDetail));