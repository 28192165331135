import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,DialogContent,DialogTitle,DialogActions,
    CircularProgress,
    Typography,
    Grid,
    Paper,
    Tooltip,
    Fab,
} from '@material-ui/core';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import IDCardIcon from '@material-ui/icons/CreditCard';
import HealthcardIcon from '@material-ui/icons/LocalHospital';
import ZoomIcon from '@material-ui/icons/ZoomIn';
//import {ContentSave} from 'mdi-material-ui';
import ContentSave from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import {bindActionCreators} from 'redux';
import {setCommand} from '../actions/Video';
import _ from 'lodash';
import {checkRole} from '../constants_api';
import {sendVideoUrl, getRoomParticipants, sendPatientFullUrl} from '../actions/Video';
import PatientHealthcardDocumentDetail from './patient_healthcardPhoto-detail';
import PatientIdentificationDocumentDetail from './patient_identificationPhoto-detail';
import ImageEditor from './imageEditor';
import VideoParticipant from '../Components/Video/participant';
import VideoControls from './video-controls-2';
import {createMuiTheme,MuiThemeProvider} from "@material-ui/core/styles/index";
import teal from "@material-ui/core/colors/teal";
import {getPatientMedicalInfo} from "../actions/PatientMedicalInfo";
import {addPatientPhoto} from '../actions/PatientPhotos';
import {getPatientAppointments} from '../actions/Appointments';
import moment from "moment-timezone";
import {RenderTimezoneSelect} from "../Components/Forms";

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: teal,
    },
});

class VideoConference extends Component{

    constructor(props){
        super(props);

        this.state= {
            identity: 'Me',
            roomName: '',
            localMediaAvailable: false,
            hasJoinedRoom: false,
            activeRoom: null,
            participants:{},
            mainVideo:'',
            iAmConnecting: false,
            imageCapture:'',
            iAmJoining: false,
            windowWidth: 0,
            windowHeight: 0,
            imageCaptures: {},
            imageCounter:0,
            zoomImageDialog:false,
            zoomImage:null,
            zoomImgSrc:null,
            renderHealthDialog:false,
            healthImage:null,
            renderIdDialog:false,
            idImage:null,
            roomParticipants:null,
            extraCommands: false,
            getParticipantsTimer: 100000,
            termsAgreed: 0,
            tz:'America/Toronto',
            nextAppointment:{},
            upcoming: false,
        };
        this.roomSid='';
        this.renderParticipants = this.renderParticipants.bind(this);
        this.renderCaptures=this.renderCaptures.bind(this);
        this.renderZoomImage=this.renderZoomImage.bind(this);
        this.renderHealthCard=this.renderHealthCard.bind(this);
        this.renderIdCard=this.renderIdCard.bind(this);
        this.renderMainVideo=this.renderMainVideo.bind(this);
        this.zoomImage=this.zoomImage.bind(this);
        this.chooseFirstVideo=this.chooseFirstVideo.bind(this);
        this.captureVideo=this.captureVideo.bind(this);
        this.sendMessage=this.sendMessage.bind(this);
        this.updateZoomImage=this.updateZoomImage.bind(this);
        this.refreshParticipants=this.refreshParticipants.bind(this);
        this.selectMainVideo=this.selectMainVideo.bind(this);
        this.setRemoteCommand=this.setRemoteCommand.bind(this);
        this.updatedConference=this.updatedConference.bind(this);
    }

    componentDidMount() {
        if(!this.props.isPatient){
            this.props.getRoomParticipants({room:this.props.room});
            this.interval= setInterval(()=>this.props.getRoomParticipants({room:this.props.room}),this.state.getParticipantsTimer);
        } else {
            //fetch appointments
            this.props.getPatientAppointments({patient_id:this.props.patient_id, byPatient:true});
        }
        if(this.props.patient_id>0 && typeof this.props.medical.privacy === 'undefined'){
            this.props.getPatientMedicalInfo({patient_id:this.props.patient_id, byPatient:this.props.isPatient});
        }

        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        if(!this.props.isPatient){
            clearInterval(this.interval);
        }

        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.healthcard_photo!==prevProps.healthcard_photo){
            this.setState({renderHealthDialog:false})
        }
        if(this.props.identification_photo !==prevProps.identification_photo){
            this.setState({renderIdDialog:false})
        }
        if(this.props.roomParticipants!==prevProps.roomParticipants){
            this.setState({roomParticipants:this.props.roomParticipants})
        }
        if(this.props.medical!==prevProps.medical){
            let termsAgreed = this.props.medical.privacy && this.props.medical.citizen && this.props.medical.consent_to_share && this.props.medical.agree_to_treat;
            this.setState({termsAgreed});
        }
        if(this.props.appointments!==prevProps.appointments){
            //find latest appointment
            let booked = _.orderBy(_.filter(this.props.appointments.dataset,{status:"1"}),'appt_dt');
            let arr=[];
            let upcoming=false;
            let now = new moment.utc().tz(this.state.tz).subtract(30 ,'minutes').format('YYYY-MM-DD HH:mm:00');
            booked.map((data)=>{
                if(data.appt_dt>now){
                    upcoming = true;
                    arr.push(data);

                }
                return 1;
            })
            if(upcoming){
                this.setState({nextAppointment:arr[0], upcoming})
            }
        }
    }

    timezoneChange(event) {
        this.setState({tz: event.target.value});
    };

    updatedConference(conference){
        this.setState({identity:conference.identity, participants:conference.participants, activeRoom:conference.activeRoom});
        this.chooseFirstVideo();
        if(conference.activeRoom!==null){
            if(!this.props.isPatient) {
                clearInterval(this.interval);
                this.interval=0;
            }
            this.setState({hasJoinedRoom:true});
        }else {
            if(!this.props.isPatient) {
                if (this.interval === 0) {
                    this.interval = setInterval(() => this.props.getRoomParticipants({room: this.props.room}), this.state.getParticipantsTimer);
                }
            }
            this.setState({hasJoinedRoom:false, mainVideo:''});
        }
    }

    updateIdentity(identity){
        this.setState({identity:identity});
    }

    setRemoteCommand(identity, command){
        this.props.setCommand({room:this.props.room, identity:identity, command:command})
    }

    updateDimensions(){
        this.setState({windowWidth:window.innerWidth});
        this.setState({windowHeight:window.innerHeight});
    }

    refreshParticipants(){
        let room = this.state.activeRoom;
        room.participants.forEach(participant => {
            let p = this.state.participants;
            p[participant.identity]=participant;
            this.setState({participants: p});
        });
    }

    captureVideo(){
        //use mainVideo
        let vidDiv = this.refs.mainVideoContainer.refs.mainVideo;
        let video = vidDiv.querySelector('video');
        let videoWidth = video.videoWidth;
        let videoHeight = video.videoHeight;
        let canvas = document.createElement("canvas");
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        canvas.getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        let images = this.state.imageCaptures;
        images[this.state.imageCounter]={id:this.state.imageCounter, image:dataUrl, width:videoWidth, height:videoHeight};
        this.setState({ imageCaptures:images, imageCounter:this.state.imageCounter+1});
    }

    deleteCapture(image_id){
        let imgs = this.state.imageCaptures;
        delete imgs[image_id];
        this.setState({imageCaptures: imgs});
        this.renderCaptures();
    }

    setIdCard(image_id){
        this.setState({idImage:this.state.imageCaptures[image_id].image, renderIdDialog:true});
    }

    setHealthcard(image_id){
        this.setState({healthImage:this.state.imageCaptures[image_id].image, renderHealthDialog:true});
    }

    zoomImage(image_id){
        //open fullscreen dialog with this image as the zoomedImage
        this.setState({zoomImage: this.state.imageCaptures[image_id], zoomImgSrc:this.state.imageCaptures[image_id].image ,zoomImageDialog:true});

    }

    saveImage(image){
        var id_doc = {
            patient_id: this.props.patient_id,
            image:image.image,
            byPatient:this.props.byPatient,
        }
        this.props.addPatientPhoto(id_doc);
    }

    updateZoomImage(image){
        let image_id = this.state.zoomImage.id;
        let images = this.state.imageCaptures;
        images[image_id].image=image;
        this.setState({imageCaptures:images,zoomImageDialog:false});
    }

    chooseFirstVideo(){
        if(this.state.mainVideo<='' && this.state.activeRoom!==null){ //if we don't have a main video selected then choose one of the non Local videos to start with.
            const ps = _.values(this.state.participants);
            this.roomSid=this.state.activeRoom.localParticipant.sid;
            ps.map((participant)=> {
                if (participant.sid !== this.roomSid) {
                    //see if we have a video
                    var tracks = Array.from(participant.videoTracks.values());
                    if(tracks.length>0){
                        this.selectMainVideo(participant.identity);
                    }
                }
                return true;
            });
        }
    }

    selectMainVideo(identity){
        this.setState({mainVideo:identity});
    }

    showCapture(){
        if(!this.props.isPatient) {
            if (checkRole('practitioner') || checkRole('viewVideo')) {
                return true;
            }
        }
        return false;
    }

    showSendLinks(){
        if(!this.props.isPatient){
            if(checkRole('practitioner') || checkRole('sendMessages') ){
                return true;
            }
        }
        return false;
    }

    allowVideo(){
        let vid = true;
        if(!this.props.isPatient){
            if(!checkRole('practitioner')){
                vid= false;
            }
        }
        return vid;
    }

    sendMessage(sendBy){
        let params = {patient_id: this.props.patient_id, sendBy:sendBy};
        this.props.sendVideoUrl(params);
    }

    sendFullMessage(sendBy){
        let params = {patient_id: this.props.patient_id, sendBy:sendBy};
        this.props.sendPatientFullUrl(params);
    }
    //Render Functions

    renderHealthCard (){
        return (
            <Dialog
                open={this.state.renderHealthDialog}
                onClose={()=>{this.setState({renderHealthDialog:false})}}
                maxWidth={false}
            >
                <DialogContent>
                    <PatientHealthcardDocumentDetail {...this.props} patient_id={this.props.match.params.patient_id} passPhoto={true} image={this.state.healthImage}  form={'videoHealthcardForm'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({renderHealthDialog:false})}} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderIdCard (){
        return (
            <Dialog
                open={this.state.renderIdDialog}
                onClose={()=>{this.setState({renderIdDialog:false})}}
                maxWidth={false}
            >
                <DialogContent>
                    <PatientIdentificationDocumentDetail {...this.props} patient_id={this.props.match.params.patient_id} passPhoto={true} image={this.state.idImage}  form={'videoIdDocForm'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({renderIdDialog:false})}} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderZoomImage (){
        return (
            <Dialog
                open={this.state.zoomImageDialog}
                onClose={()=>{this.setState({zoomImageDialog:false})}}
                maxWidth={false}
            >
                <DialogTitle id="alert-dialog-title">{`Image Capture Viewer`}</DialogTitle>
                <DialogContent>
                    <ImageEditor originalImage={this.state.zoomImgSrc} updateCallback={this.updateZoomImage}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({zoomImageDialog:false})}} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderCaptures(){
        //check to see if any captures have been done
        if(this.state.imageCounter===0){
            return false;
        }
        let container = this.refs.Captures;
        let positionInfo = container.getBoundingClientRect();
        let maxHeight = this.state.windowHeight-200;
        let maxWidth =   positionInfo.width; //parseInt(this.state.windowWidth/4,10);
        //let cellHeight = parseInt(maxHeight/5,10);
        let imageWidth = maxWidth - 40;
        return(
            <div style={{width:`${maxWidth}px`, height:`${maxHeight}px`, overflow:"auto "}}>
                {
                    _.orderBy(_.values(this.state.imageCaptures),'id','desc').map( image => (
                            <Paper style={this.Style().paper} key={image.id}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={image.image} width={imageWidth} alt=""/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title='Delete'>
                                            <Fab onClick={()=>{this.deleteCapture(`${image.id}`)}} color="secondary"  size='small'><DeleteIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Set as ID'>
                                            <Fab onClick={()=>{this.setIdCard(`${image.id}`)}} color="primary" size='small'><IDCardIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Set as Healthcard'>
                                            <Fab onClick={()=>{this.setHealthcard(`${image.id}`)}} color="secondary" size='small'><HealthcardIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Zoom'>
                                            <Fab onClick={()=>{this.zoomImage(`${image.id}`)}} color="primary" size='small'><ZoomIcon/></Fab>
                                        </Tooltip>
                                        <Tooltip title='Save'>
                                            <Fab onClick={()=>{this.saveImage(image)}} color="primary" size='small'><ContentSave/></Fab>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    )
                }
                {this.renderZoomImage()}
                {this.renderHealthCard()}
                {this.renderIdCard()}
            </div>

        )
    }

    renderMainVideo(){
        if(this.state.mainVideo>'' && this.state.participants!==null){
            let participant=this.state.participants[this.state.mainVideo];
            if(typeof participant === 'undefined'){
                return (<div></div>);
            }
            let tracks = Array.from(participant.tracks.values());
            let windowHeight=window.innerHeight-400;
            let extraButtons = (checkRole('practitioner') || checkRole('viewVideo')) && !this.props.isPatient;
            return(
                <div style={{position:'relative'}}>
                    <VideoParticipant height={windowHeight} participant={participant} tracks={tracks} elem='mainVideo' onClick={()=>{return false;}} ref='mainVideoContainer'/>
                    { extraButtons &&
                    <div style={{position:'absolute', display:'block', zIndex:2, left:10, bottom:10}}>
                        <Tooltip title='Force Reset Remote'><Fab onClick={()=>{this.setRemoteCommand(this.state.mainVideo,'Reload')}} color="secondary" size='small'><ErrorIcon/></Fab></Tooltip>
                    </div>
                    }
                </div>
            );
        }
        return (<div></div>);
    }

    renderParticipants(){
        const ps = _.values(this.state.participants);
        return(
            ps.map((participant)=> {
                if(participant.identity!==this.state.mainVideo){
                    let tracks = Array.from(participant.tracks.values());
                    return (
                        <VideoParticipant key={participant.sid} participant={participant} onClick={this.selectMainVideo} tracks={tracks} elem={participant.sid}/>
                    );
                }
                return (<div key={participant.sid}></div>);
            })
        )
    }

    renderRoomParticipants(){
        const ps = _.values(this.state.roomParticipants);
        return(
            ps.map((participant)=> {
                return(
                    <Typography key={participant.identity} variant="subtitle1">{participant.identity} - {participant.duration}</Typography>
                )
            })
        );
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            },
            paper: {
                padding: '10px',
                margin:'10px'
            },
        });
    }

    renderCommunicationDialog(){
        return(<Dialog
                fullScreen
                open={this.props.communicationIsLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }


    //Render Functions
    render() {
        let allowVideo = this.allowVideo();
        return (
            <MuiThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <Grid container>
                        {this.props.isPatient && !this.state.hasJoinedRoom &&
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Video Consultation</Typography>
                                </Grid>
                                    {this.state.upcoming &&
                                        <Fragment>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="subtitle1">Your appointment is set for {`${this.state.nextAppointment.appt_dt}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1">Ready to attend your video consultation with a Health Care Practitioner? Select the telephone icon to connect.</Typography>
                                            </Grid>
                                        </Fragment>
                                    }
                            </Fragment>
                        }
                        {this.props.isPatient && this.state.upcoming && this.state.hasJoinedRoom && !this.state.mainVideo &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">(Name of NP or HCP)  will be with you shortly.</Typography>
                            <Typography variant="subtitle1">Having difficulties connecting? Please call us at 1-844-410-2668</Typography>
                        </Grid>
                            }
                        <Grid item xs={12} sm={10}>
                            <Grid container>

                                {this.state.hasJoinedRoom &&
                                <Grid item xs={12}>
                                    {this.renderMainVideo()}
                                </Grid>
                                }
                                {!this.state.hasJoinedRoom &&
                                <Grid item xs={12}>
                                    {this.renderRoomParticipants()}
                                </Grid>
                                }
                                <Dialog open={this.state.iAmJoining}>
                                    <DialogContent><Typography variant="h6">Please Wait ...</Typography></DialogContent>
                                </Dialog>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {this.showSendLinks() &&
                                        <Fragment>
                                            <Grid item xs={2}>
                                                <Tooltip title='Send Video link by Email'><Fab onClick={()=>{this.sendFullMessage('Email')}} color="secondary"><EmailIcon/></Fab></Tooltip>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip title='Send Video link by SMS'><Fab onClick={()=>{this.sendFullMessage('SMS')}} color="secondary"><SmsIcon/></Fab></Tooltip>
                                            </Grid>
                                            {this.renderCommunicationDialog()}
                                        </Fragment>
                                        }
                                        {this.state.termsAgreed==1 &&
                                            <VideoControls {...this.props} isPractitioner={checkRole('practitioner')}
                                                       updatedConference={this.updatedConference}
                                                       allowVideo={allowVideo} patient_id={this.props.patient_id}/>
                                        }

                                        {this.state.termsAgreed==0 &&
                                            <Typography variant="subtitle1">Please Accept Terms and Conditions in the Medical Section before Continuing </Typography>
                                        }
                                        {this.showCapture() && this.state.mainVideo>'' &&
                                        <Fragment>
                                            <Grid item xs={2}>
                                                <Tooltip title='Capture Image'><Fab onClick={this.captureVideo} color="secondary"><CameraIcon/></Fab></Tooltip>
                                            </Grid>
                                        </Fragment>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {this.renderParticipants()}
                        </Grid>
                        {!this.props.isPatient &&
                            <Fragment>
                        <Grid item xs={12} sm={6}>
                            <img src={this.props.healthcard_photo.image} style={{maxHeight: '150px', maxWidth: '300px'}}
                                 alt=""/>
                        </Grid>
                        < Grid item xs={12} sm={6} ref="healthCardImage">
                            <img src={this.props.identification_photo.image} style={{maxHeight:'150px', maxWidth:'300px'}} alt=""/>
                            </Grid>
                            </Fragment>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}><div ref="Captures"/>
                    {this.renderCaptures()}
                </Grid>

            </Grid>
            </MuiThemeProvider>
        );
    }
}

function mapStateToProps(state){
    return(
        {
            communicationIsLoading: state.communicationIsLoading,
            healthcard_photo: state.healthcard_photo,
            identification_photo: state.identification_photo,
            roomParticipants : state.participants,
            loadingParticipants: state.loadingParticipants,
            videoCommandSetting : state.videoCommandSetting,
            patientUrlWait: state.patientUrlWait,
            medical: state.medical,
            appointments: state.patientAppointments,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({sendVideoUrl, getRoomParticipants, setCommand, sendPatientFullUrl,getPatientMedicalInfo, addPatientPhoto,getPatientAppointments}, dispatch);
}


export default connect(mapStateToProps,mapDispatchToProps)(VideoConference);

