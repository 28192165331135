import * as TYPE from "../actions/Patients";


export function patients (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_PATIENTS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function patient (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_PATIENT_DETAIL:
            return action.payload;
        case TYPE.ADD_PATIENT:
            return {patient_id: action.patient_id, iAmNew: true};
        default:
            return state;
    }
}

export function patientAilments (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_PATIENT_AILMENT:
            return action.payload;
        default:
            return state;
    }
}

export function patientNotes (state = {}, action){
    switch(action.type){
        case TYPE.FETCH_PATIENT_NOTES:
            return action.payload;
        default:
            return state;
    }
}


export function patientIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function patientIsError (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function patientFilter (state='', action){
    switch(action.type){
        case TYPE.UPDATE_PATIENT_FILTER :
            return action.filter;
        default:
            return state;
    }
}

export function patientAilmentsIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_AILMENTS_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function patientAilmentsIsError (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_AILMENT_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientAilmentsNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_AILMENT_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function patientNotesIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_NOTES_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function patientNotesIsError (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_NOTES_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientNotesNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_NOTES_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function patientReminderSent (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_SMS_SENT :
            return action.bool;
        default:
            return state;
    }
}

export function patientSignupIsError (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_SIGNUP_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientSignupWait (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_SIGNUP_WAIT :
            return action.bool;
        default:
            return state;
    }
}

export function patientPasswordUpdated (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_PASSWORD_UPDATED :
            return action.bool;
        default:
            return state;
    }
}

