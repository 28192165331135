import React, {Component} from 'react';
import ChangePassword from '../../containers/change-password';
import {Grid, Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Footer} from '../Layouts';


const styles = theme => ({
    outer: {
        height: '80vh',
    },
    paper: {
        padding: theme.spacing.unit * 5,
        height: '100%',
        margin:'20'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: '50',
        marginRight: '50',
        paddingTop: '50',
        paddingBottom: '50',
        width: 200,
    },
    menu: {
        width: 200,
    },
});

class ChangePasswordPage extends Component {
    render() {
    const { classes } = this.props;

    return (
        <Grid container style={{height:'94vh'}}>
            <Grid item style={{height:'80vh'}} xs={12}>

            <Grid container alignItems='center' justify='center' direction='row' className={classes.outer}>
                <Grid item>
                    <Paper className={classes.paper}>
                        <ChangePassword {...this.props}/>
                    </Paper>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12}>
                <Footer/>
            </Grid>
        </Grid>
        );
    }
}

export default withStyles(styles)(ChangePasswordPage);



