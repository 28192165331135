import React, {Component} from 'react';
import PatientLoginDetail from '../../containers/patientLoginDetail';
import {Grid, Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {PatientHeader} from '../Layouts';
import Image from '../../img/leaves.jpg'; // Import using relative path

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 4,
        margin:'15'
    },
    paperContainer: {
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        padding: theme.spacing.unit * 4,
        margin:'15'
    }
});
class PatientLoginPage extends Component {
    render() {
    const { classes } = this.props;

    return (

        <Grid container style={{height:'94vh'}} >
            <Grid item xs={12}>
                <PatientHeader {...this.props}/>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paperContainer}>
                    <Grid container justify='center'  style={{height:'94vh'}} alignItems='center' >
                        <Grid item>
                            <Paper className={classes.paper}>
                                <PatientLoginDetail {...this.props} reqLogin={false}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        );
    }
}

export default withStyles(styles)(PatientLoginPage);



