import React, {Component} from 'react';
import ExpiringPrescriptionList from '../../containers/expiringPrescriptionList';
import { withStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

const styles = theme => ({
    outer: {
        height: '80vh',
    },
    paper: {
        padding: theme.spacing.unit * 5,
        height: '100%',
        margin:'20'
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    textField: {
        marginLeft: '50',
        marginRight: '50',
        paddingTop: '50',
        paddingBottom: '50',
        width: 200,
    },
});


class ExpiringPrescriptionReport extends Component {
    render() {

        return (
            <Grid container style={{height: '94vh'}}>
                <Grid item style={{height: '80vh'}} xs={12}>
                    <ExpiringPrescriptionList reportType='Standard' {...this.props}/>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ExpiringPrescriptionReport);