import * as TYPE from "../actions/DosagePrescription";


export function prescriptions (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_DOSAGE_PRESCRIPTIONS:
            const dosage_id = action.payload.result.dosage_id;
            const obj = {...state, [dosage_id]:{id:dosage_id, result: action.payload.result}};
            return  obj;
        default:
            return state;
    }
}

export function prescription (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_DOSAGE_PRESCRIPTIONS_DETAIL:
            return action.payload;
        case TYPE.ADD_DOSAGE_PRESCRIPTIONS:
            return {prescription_id: action.payload.prescription_id, iAmNew: true};
        default:
            return state;
    }
}

export function prescriptionIsLoading (state = {}, action){
    switch(action.type){
        case TYPE.DOSAGE_PRESCRIPTIONS_IS_LOADING :
            const dosage_id = action.payload.dosage_id;
            const obj = {...state,[dosage_id]:{id:dosage_id, bool: action.payload.bool}};
            return obj;
        default:
            return state;
    }
}

export function prescriptionIsError (state = {}, action){
    switch(action.type){
        case TYPE.DOSAGE_PRESCRIPTIONS_IS_ERROR :
            const dosage_id = action.payload.dosage_id;
            const obj = {...state,[dosage_id]:{id:dosage_id, bool: action.payload.bool} };
            return obj;
        default:
            return state;
    }
}

export function prescriptionNeedsRefresh (state = {}, action){
    switch(action.type){
        case TYPE.DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH :
            const dosage_id = action.payload.dosage_id;
            const bool = action.payload.bool;
            const obj = {...state, [dosage_id]:{id:dosage_id, bool: bool}};
            return obj;
        default:
            return state;
    }
}

export function expiringPrescriptions (state = {}, action){
    switch(action.type) {
        case TYPE.EXPIRING_PRESCRIPTIONS:
            return  action.payload.answer;
        default:
            return state;
    }
}

export function expiringIsLoading (state = false, action){
    switch(action.type){
        case TYPE.EXPIRING_PRESCRIPTIONS_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function expiringIsError (state = false, action){
    switch(action.type){
        case TYPE.EXPIRING_PRESCRIPTIONS_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}
