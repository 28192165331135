import React, {Component} from 'react';
import SignupDetail from '../../containers/signup-details';
import {Grid, Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {PatientHeader} from '../Layouts';
import Image from '../../img/leaves.jpg'; // Import using relative path




const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 4,
        margin:'15'
    },
    paperContainer: {
        backgroundImage: `url(${Image})`,
        backgroundSize:'cover',
        padding: theme.spacing.unit * 4,
        margin:'15'
    }
});

class SignupPage extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <PatientHeader {...this.props}/>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperContainer}>
                    <Grid container justify='center'>
                        <Grid item>
                            <Paper className={classes.paper}>
                                <SignupDetail {...this.props}/>
                            </Paper>
                        </Grid>
                    </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(SignupPage);



