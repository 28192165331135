import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchPatientNotes, addPatientNote, deletePatientNote} from '../actions/Patients';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../style';
import {Field, reduxForm} from 'redux-form';
import {renderTextField,RenderTimezoneSelect} from "../Components/Forms";
import moment from "moment-timezone";
import Delete from '@material-ui/icons/Delete'
//import {Delete} from 'mdi-material-ui';



class PatientNote extends Component{
    constructor(props){
        super(props);

        this.state= {
            patient_notes:[],
            tz:'America/Toronto',
            deleteDialogOpen:false,
            deleteNoteId:0,
        };
        this.handleChange=this.handleChange.bind(this);
        this.cancel=this.cancel.bind(this);
        this.timezoneChange=this.timezoneChange.bind(this);
        this.deleteNote=this.deleteNote.bind(this);
    }

    componentDidMount() {
        this.props.fetchPatientNotes({patient_id:this.props.match.params.patient_id});
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.patient_notes!==prevProps.patient_notes){
            this.setState({patient_notes:this.props.patient_notes});
        }
        if(this.props.needsRefresh){
            this.props.fetchPatientNotes({patient_id:this.props.match.params.patient_id});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    timezoneChange(event) {
        this.setState({tz: event.target.value});
    };

    //Dialog Functions


    //Action Functions



    //Render Functions

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.setState({deleteDialogOpen:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete Note ${this.state.deleteNoteId} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({deleteDialogOpen:false})}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.deleteNote(this.state.deleteNoteId)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        );
    }


    renderList(){
        if(typeof this.state.patient_notes.dataset !== 'undefined'){
            return (
                    this.state.patient_notes.dataset.map((value) => {
                        let displaydt = moment.utc(value.created).tz(this.state.tz).format('YYYY MMM Do h:mm a');
                        return(
                            <Grid item  xs={12} key={value.patient_notes_id}>
                                <Grid container style={{flexGrow:1, padding:'10px'}}>
                                    <Grid item xs={2}>
                                        <Typography style={{paddingTop:'6px'}}>{displaydt}</Typography>
                                        <Button onClick={()=>{this.setState({deleteNoteId:value.patient_notes_id, deleteDialogOpen:true })}} variant='fab' mini><Delete/></Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                            <Typography style={{paddingTop:'6px', paddingLeft:'12px', paddingRight:'12px'}}>{value.notes}</Typography>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography style={{paddingTop:'6px'}}>{value.practitioner}</Typography>
                                    </Grid>

                                </Grid>
                                <hr/>
                            </Grid>
                        )
                    }))
        }
    }

    onSubmit (values){
        var patientNotes = {
                patient_id: this.props.match.params.patient_id,
                note: values.note
            }
            this.props.addPatientNote(patientNotes);
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    deleteNote(note_id){
        let params = {patient_notes_id:note_id};
        this.props.deletePatientNote(params);
        this.setState({deleteDialogOpen:false});
    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    }


    render(){
        const { handleSubmit, pristine, submitting} = this.props;

        if(this.props.match.params.patient_id<1){
            return(<div></div>);
        }
        return(
            <Grid container direction='row' style={{flexGrow:1}}>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))} style={{'width':'100%'}}>

                <Grid item  xs={12}>
                            <Grid container style={{flexGrow:1, padding:'5px'}}>
                                <Grid item xs={9}>
                                        <Field
                                            autoFocus
                                            name= 'note'
                                            component={renderTextField}
                                            label= 'Notes'
                                            margin='normal'
                                            onChange={this.handleChange}
                                            value=''
                                            fullWidth
                                            multiline
                                            rows='4'
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                        SAVE
                                    </Button>
                                    <Button disabled={pristine || submitting} onClick={()=>{this.props.reset();this.cancel()}} variant='contained'>Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                </form>
                <Grid item xs={12}>
                    <Typography variant='h6'>Notes</Typography>
                    <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/>
                </Grid>
                {this.renderList()}
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
                {this.renderDeleteDialog()}
                </Grid>

        );
    }
}


function mapStateToProps(state){
    return(
        {
            patient_notes: state.patientNotes,
            isLoading: state.patientNotesIsLoading,
            isError: state.patientNotesIsError,
            needsRefresh: state.patientNotesNeedsRefresh
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPatientNotes, addPatientNote, deletePatientNote}, dispatch);
}

const validate = values => {
    const errors = {}
    const requiredFields = [
        'notes'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })
    return errors
}

export default reduxForm({
    validate,
    form: 'addPatientNoteForm'
})(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PatientNote)));


