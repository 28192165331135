import React, {Component, Fragment } from 'react';
import {connect} from 'react-redux';
import {Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
    Grid,
    Card,  CardHeader,  CardContent, CardActions,
     Collapse,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import {bindActionCreators} from 'redux';
import {deleteDosage, fetchDosageDetail} from '../actions/Dosage';
import {checkRole} from '../constants_api';
import moment from 'moment-timezone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PrescriptionByDosage from "./dosagePrescriptionList";

class DosageDetail extends Component {

    constructor(props){
        super(props);

        this.state= {
            deleteDialogOpen: false,
            PrescriptionShow: false,
            data: {},
            refresh: false,
        };

        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.showPrescriptions=this.showPrescriptions.bind(this);
        this.upDateInUse=this.upDateInUse.bind(this);
    }

    componentDidMount() {
        this.setState({data: this.props.data});
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.state.refresh){
            this.setState({refresh:false});
            this.props.fetchDosageDetail({dosage_id:this.props.data.dosage_id, byPatient:this.props.byPatient})
        }
        if(this.props.dosage!==prevProps.dosage){
            this.setState({data: this.props.dosage});
        }
    }

    //Dialog Functions


    onDeleteDialogOpen(id) {
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete  ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm()}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    onDeleteConfirm (){
        this.setState({ deleteDialogOpen: false });
        this.props.deleteDosage(this.props.data.dosage_id);
    }


    isPractitioner(){
        return checkRole('practitioner');
    }

    showPrescriptions(){
        this.setState({PrescriptionShow:!this.state.PrescriptionShow});
    }

    upDateInUse(){
        this.setState({refresh:true});
    }

    render(){
        const data = this.state.data;
        let created = new moment.tz(data.created,'UTC');
        let Dcreated = this.props.byPatient ? created.tz('America/Toronto').format('YYYY-MM-DD') : created.tz('America/Toronto').format('YYYY-MM-DD, HH:mm');
        return (
            <Grid item xs={12} key={data.dosage_id}>
                <Card style={{background:'#81d4fa'}}>
                    <CardHeader
                        title={Dcreated}
                        subheader={data.p_firstname}
                        action={this.isPractitioner() && data.inUse!=="1" &&
                        <IconButton onClick={() => {
                            if(this.isPractitioner()){
                                this.setState({deleteDialogOpen:true});
                            }
                        }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        }
                    />
                    <CardContent>
                        <Typography variant="subtitle1">
                            {data.grams} grams per day for {data.months} months.
                        </Typography>
                        <Typography variant='body2'>
                            Delivery Method: {data.deliveryMethod}
                        </Typography>
                        {!this.props.byPatient &&
                        <Typography component="p" variant='body2'>
                            {data.mandatoryNotes === '1' && <b>*** MANDATORY *** </b>}
                            {data.cbdOnly === '1' && <b>--CBD ONLY-- </b>}
                            {data.notes}
                        </Typography>
                        }
                    </CardContent>
                    {this.props.byPatient &&
                    <CardContent>
                        <PrescriptionByDosage dosage={data} updateInUse={this.upDateInUse} form={'addPrescriptionForm-'+data.dosage_id} byPatient={this.props.byPatient}/>
                    </CardContent>
                    }
                    {!this.props.byPatient &&
                        <Fragment>
                            <CardActions>
                                <IconButton onClick={() => this.showPrescriptions()}>
                                    {this.state.PrescriptionShow && <ExpandMoreIcon style={{transform: 'rotate(180deg)'}}/>}
                                    {!this.state.PrescriptionShow && <ExpandMoreIcon/>}
                                </IconButton>
                            </CardActions>
                            <Collapse in={this.state.PrescriptionShow} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <PrescriptionByDosage dosage={data} updateInUse={this.upDateInUse} form={'addPrescriptionForm-'+data.dosage_id} byPatient={this.props.byPatient}/>
                                </CardContent>
                            </Collapse>
                        </Fragment>
                    }


                </Card>
                {this.renderDeleteDialog()}
            </Grid>
        );
    }

}

function mapStateToProps(state,ownProps){
    return(
        {
            dosage: state.dosageDetails[ownProps.dosage_id],
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({deleteDosage, fetchDosageDetail}, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(DosageDetail);
