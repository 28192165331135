import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Paper,
    MenuItem,
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {bindActionCreators} from 'redux';
import {fetchPatientDetail, editPatient, addPatient} from '../actions/Patients';
import {fetchStatus} from '../actions/Statuses';
import {fetchAddressDetail,fetchAddresses} from '../actions/AddressCheck';
import {Field, reduxForm, } from 'redux-form';
import {renderTextField, renderSelect, renderDateTimePicker, renderDatePicker} from '../Components/Forms';
import moment from 'moment-timezone';
import PatientMailing from './patient-mailing';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {POSTKEY} from '../constants_api';
import _ from 'lodash';

class PatientDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            patient_id_inuse: 0,
            patient_id:0,
            firstname: "",
            lastname:"",
            email:'',
            dob: '',
            current_status:"",
            primary_phone:'',
            secondary_phone:'',
            street:'',
            suburb:'',
            state:'',
            postcode:'',
            height_ft:0,
            height_in:0,
            weight:0,
            deleteDialogOpen: false,
            selectArray:[],
            showSuggestions:false,
            addressSuggest:'',
            addressSuggestions:[],
            useSuggestion: false,
            suggestedAddress: '',
            fullAddress:'',
            appt_dt: '',
            isError: false,
            gender: '',
            referCode: '',
            genderList: [{value:'Female',label:'Female'},{value:'Male',label:'Male'},{value:'Other',label:'Other'},{value:'Undisclosed',label:'Undisclosed'}],
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleChangeDate=this.handleChangeDate.bind(this);
        this.handleChangeDob=this.handleChangeDob.bind(this);
        this.handleChangeAddress=this.handleChangeAddress.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
    }

    componentDidMount() {
        moment.tz.setDefault("America/Toronto");
        if(this.props.patient_id>0){
            this.props.fetchPatientDetail({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(!this.props.byPatient){
            this.props.fetchStatus({offset:0, limit: 50,  sortField:'name', sortDirection:'asc'});
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.patientNeedsRefresh!==prevProps.patientNeedsRefresh && this.props.patientNeedsRefresh){
            this.props.fetchPatientDetail({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.patient!==prevProps.patient && this.props.patient!==null){
                this.setState({
                    patient_id: this.props.patient.patient_id,
                    firstname: this.props.patient.firstname,
                    lastname:this.props.patient.lastname,
                    dob:this.props.patient.dob,
                    current_status:this.props.patient.current_status,
                    email:this.props.patient.email,
                    primary_phone:this.props.patient.primary_phone,
                    secondary_phone:this.props.patient.secondary_phone,
                    street:this.props.patient.street,
                    suburb:this.props.patient.suburb,
                    state:this.props.patient.state,
                    postcode:this.props.patient.postcode,
                    height_ft:this.props.patient.height_ft,
                    height_in:this.props.patient.height_in,
                    weight:this.props.patient.weight,
                    appt_dt: this.props.patient.appt_dt,
                    referCode: this.props.patient.referCode,
                    gender: this.props.patient.gender,
                });
                const formData = {
                    "firstname": this.props.patient.firstname,
                    "lastname":this.props.patient.lastname,
                    "dob":this.props.patient.dob,
                    "current_status":this.props.patient.current_status,
                    "email":this.props.patient.email,
                    "primary_phone":this.props.patient.primary_phone,
                    "secondary_phone":this.props.patient.secondary_phone,
                    "street":this.props.patient.street,
                    "suburb":this.props.patient.suburb,
                    "state":this.props.patient.state,
                    "postcode":this.props.patient.postcode,
                    "height_ft":this.props.patient.height_ft,
                    "height_in":this.props.patient.height_in,
                    "weight":this.props.patient.weight,
                    "appt_dt": this.props.patient.appt_dt,
                    "referCode": this.props.patient.referCode,
                    "gender": this.props.patient.gender,
                };
                this.props.initialize(formData);
            if(this.props.patient.iAmNew){
                this.props.history.push(`/patient/${this.props.patient.patient_id}`);
                this.props.newPatient(this.props.patient.patient_id);
            }
        }
        if(this.props.location!==prevProps.location){
            this.props.fetchPatientDetail({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.statuses!==prevProps.statuses){
            //create the array needed for the status selector
            var arr = [];
            this.props.statuses.dataset.map((status)=>{
                arr.push({value:status.status_id, label:status.name});
                return true;
            });
           this.setState({selectArray:arr});
        }
        if(this.props.isError !== prevProps.isError){
            this.setState({isError:this.props.isError});
        }
        if(this.props.addresses !== prevProps.addresses){
            let showSuggestions = false;
            let addressSuggestions = [];
            if(typeof this.props.addresses.Items !== 'undefined'){
                if(this.props.addresses.Items.length>0){
                    showSuggestions=true;
                    addressSuggestions=_.filter(this.props.addresses.Items,{Next:'Retrieve'});
                }
            }
            this.setState({showSuggestions, addressSuggestions});
        }
        if(this.props.address !== prevProps.address){
            //first reset search lockout
            this.props.change('addressSuggest',this.state.suggestedAddress );
            this.props.change('street',this.props.address.Items[0].Line1);
            this.props.change('suburb',this.props.address.Items[0].City);
            this.props.change('state',this.props.address.Items[0].Province);
            this.props.change('postcode',this.props.address.Items[0].PostalCode);
            this.setState({
                useSuggestion : false,
                street: this.props.address.Items[0].Line1,
                suburb: this.props.address.Items[0].City,
                state: this.props.address.Items[0].Province,
                postcode: this.props.address.Items[0].PostalCode,
            });
        }
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({patient_id_inuse : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onSubmit (values){
       if(this.props.patient_id>0){
            let patient = {
                patient_id: this.props.patient_id,
                firstname: values.firstname,
                lastname:values.lastname,
                email:values.email,
                dob:this.state.dob,
                current_status:values.current_status,
                primary_phone:values.primary_phone,
                secondary_phone:values.secondary_phone,
                street:values.street,
                suburb:values.suburb,
                state:values.state,
                postcode:values.postcode,
                height_ft:values.height_ft,
                height_in:values.height_in,
                weight:values.weight,
                appt_dt: this.state.appt_dt,
                referCode: this.state.referCode,
                gender: values.gender,
                byPatient: this.props.byPatient,
            }
            this.props.editPatient(patient);
           if(typeof this.props.dataSaved!=='undefined'){
               this.props.dataSaved();
           }
        } else {
            let patient = {
                firstname: values.firstname,
                lastname:values.lastname,
                dob:this.state.dob,
                email:values.email,
                current_status:values.current_status,
                primary_phone:values.primary_phone,
                secondary_phone:values.secondary_phone,
                street:values.street,
                suburb:values.suburb,
                state:values.state,
                postcode:values.postcode,
                height_ft:values.height_ft,
                height_in:values.height_in,
                weight:values.weight,
                appt_dt: this.state.appt_dt,
                gender: values.gender,
                referCode: this.state.referCode,
            }
            this.props.addPatient(patient);
        }

    }


    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        const delete_id = {patient_id: id};
        this.props.deleteUser(delete_id);
    }


    //Render Functions

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete Patient ${this.state.patient_id_inuse} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.patient_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRefreshDialog(){
        return(<Dialog
            fullScreen
            open={this.props.isLoading}
            PaperProps={{style:{background:'transparent'}}}
        >
            <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                <DialogContent>
                    <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                </DialogContent>
            </div>
        </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.setState({isError:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time. Possible Duplicate Record.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({isError:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };


    handleChangeDate(value) {
        let d = moment(value).utc().format("YYYY-MM-DD HH:mm:ss");
        this.setState({appt_dt: d});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    handleChangeDob(value) {
        let d = moment(value).utc().format("YYYY-MM-DD");
        this.setState({dob: d});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    handleChangeAddress(event) {
        if(event.target.value.length>4){
            let search={
                Key:POSTKEY,
                SearchTerm: event.target.value,
                Country: 'CAN',
            }
            if(!this.state.useSuggestion){
                this.props.fetchAddresses(search);
            }
        }
        this.setState({[event.target.name]: event.target.value});
    };


    getSuggestionValue(suggestion) {
        this.setState({showSuggestions : false, useSuggestion:true, suggestedAddress: `${suggestion.Text} ${suggestion.Description}`})
        let search={Key:POSTKEY,Id:suggestion.Id};
        this.props.fetchAddressDetail(search);
    }


    render(){
        const { handleSubmit, pristine, submitting  } = this.props;
        const {classes} = this.props;
        let Address = {
            street: this.state.street,
            suburb: this.state.suburb,
            state: this.state.state,
            postcode: this.state.postcode,
        };
        return(<Fragment>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        {this.props.byPatient &&
                            <Grid item xs={12}>
                                <Typography variant='h6'>Please fill in/verify your general information below:</Typography>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                autoFocus
                                name= 'firstname'
                                component={renderTextField}
                                label= 'First name (Legal Name)'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Field
                                name= 'lastname'
                                component={renderTextField}
                                label= 'Last name'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className={classes.gridForm}>
                            <Field
                                name= 'email'
                                component={renderTextField}
                                label= 'Email address'
                                margin='normal'
                                onChange={this.handleChange}
                                type='email'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name= 'primary_phone'
                                component={renderTextField}
                                label= 'Primary Phone'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                name= 'secondary_phone'
                                component={renderTextField}
                                label= 'Mobile'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Field
                                name= 'gender'
                                component={renderSelect}
                                label= 'Gender'
                                margin='normal'
                                onChange={this.handleChange}
                                listOfValues={this.state.genderList}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className={classes.gridForm}>
                            <Field
                                name= 'addressSuggest'
                                component={renderTextField}
                                label= 'Find Address'
                                margin='normal'
                                onChange={this.handleChangeAddress}
                                fullWidth
                            />
                            {this.state.showSuggestions &&
                            <Paper>
                                <List dense>
                                {this.state.addressSuggestions.map((address) => {
                                    return(<ListItem key={address.Id} button onClick={()=>{this.getSuggestionValue(address)}}><ListItemText primary={`${address.Text} ${address.Description}`} /></ListItem>)
                                })}
                                </List>
                            </Paper>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
                            <Field
                                name= 'street'
                                component={renderTextField}
                                label= 'Address'
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}  sm={6} md={3} className={classes.gridForm}>
                            <Field
                                name= 'suburb'
                                component={renderTextField}
                                label= 'City'
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}  sm={6} md={3} className={classes.gridForm}>
                            <Field
                                name= 'state'
                                component={renderTextField}
                                label= 'Province'
                                margin='normal'
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}  sm={6} md={3} className={classes.gridForm}>
                            <Field
                                name= 'postcode'
                                component={renderTextField}
                                label= 'Postal Code'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}  sm={6} md={3}>
                            <Field
                                name= 'height_ft'
                                component={renderTextField}
                                label= 'Height ft'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}  sm={6} md={3}>
                            <Field
                                name= 'height_in'
                                component={renderTextField}
                                label= 'Height in'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}  sm={6} md={3}>
                            <Field
                                name= 'weight'
                                component={renderTextField}
                                label= 'Weight'
                                margin='normal'
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}  sm={6} md={3}>
                            <Field
                                name= 'dob'
                                component={renderDatePicker}
                                label= 'Date of Birth'
                                margin='normal'
                                onChange={this.handleChangeDob}
                                myFormat='MMM-DD-YYYY'
                            />
                        </Grid>

                        {!this.props.byPatient &&
                            <Fragment>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name= 'current_status'
                                        component={renderSelect}
                                        label= 'Status'
                                        margin='normal'
                                        onChange={this.handleChange}
                                        listOfValues={this.state.selectArray}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name= 'referCode'
                                        label='Referral Code'
                                        component={renderTextField}
                                        onChange={this.handleChange}
                                        margin='normal'
                                    />
                                </Grid>
                            </Fragment>
                        }
                        {this.props.byPatient &&
                        <Fragment>
                            <Grid item xs={12} sm={3} style={{display: 'none'}}>
                                <Field
                                    name= 'current_status'
                                    component={renderSelect}
                                    label= 'Status'
                                    margin='normal'
                                    onChange={this.handleChange}
                                    listOfValues={this.state.selectArray}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{display: 'none'}}>
                                <Field
                                    name= 'appt_dt'
                                    label='Appointment Date'
                                    component={renderDateTimePicker}
                                    onChange={this.handleChangeDate}
                                    myFormat='ddd MMM DD YYYY, h:mm a'
                                    showTodayButton
                                    fullWidth
                                    clearable
                                />
                            </Grid>
                        </Fragment>
                        }
                        <Grid item xs={12}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px', marginBottom:'12px'}}>
                                NEXT
                            </Button>
                            <Button disabled={pristine || submitting} onClick={()=>{this.props.reset();this.cancel()}} variant='contained' style={{marginRight:'6px', marginBottom:'12px'}}>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid item xs={12}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle1">Mailing Address</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <PatientMailing patient_id={this.props.patient_id}
                                            byPatient={this.props.byPatient}
                                            form={'patientMailing'}
                                            homeAddress={Address}
                                            classes={this.props.classes}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                {this.renderDeleteDialog()}
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
            </Fragment>
        );
    }
}



const validate = values => {
    const errors = {}
    const requiredFields = [
        'email',
        'firstname',
        'lastname',
        'primary_phone'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })
    return errors
}

function mapStateToProps(state){
    return(
        {
            patient: state.patient,
            isLoading: state.patientIsLoading,
            isError: state.patientIsError,
            patientNeedsRefresh: state.patientNeedsRefresh,
            credentials: state.credentials,
            statuses: state.statuses,
            address: state.address,
            addresses: state.addresses,
            patientReminderSent: state.patientReminderSent,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPatientDetail, editPatient, addPatient, fetchStatus, fetchAddresses, fetchAddressDetail, }, dispatch);
}


export default reduxForm({
    validate,
    form: 'editPatientForm'
})(connect(mapStateToProps,mapDispatchToProps)(PatientDetail));

