import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_DELIVERY_METHODS = 'FETCH_DELIVERY_METHODS';
export const FETCH_DELIVERY_METHOD_DETAIL = 'FETCH_DELIVERY_METHOD_DETAIL';
export const ADD_DELIVERY_METHOD = 'ADD_DELIVERY_METHOD';
export const EDIT_DELIVERY_METHOD = 'EDIT_DELIVERY_METHOD';
export const DELIVERY_METHOD_IS_LOADING = 'DELIVERY_METHOD_IS_LOADING';
export const DELIVERY_METHOD_IS_ERROR = 'DELIVERY_METHOD_IS_ERROR';
export const DELIVERY_METHOD_NEEDS_REFRESH = 'DELIVERY_METHOD_NEEDS_REFRESH';

export function fetchDeliveryMethods(params){
    return (dispatch) => {
        dispatch({type: DELIVERY_METHOD_IS_LOADING, bool :true});
        dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:false});
        dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}delivery-method/list`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_DELIVERY_METHODS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchDeliveryMethodDetail(deliveryMethod_id){
    return (dispatch) => {
        dispatch({type: DELIVERY_METHOD_IS_LOADING, bool :true});
        dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:false});
        dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}delivery-method/fetch`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,deliveryMethod_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_DELIVERY_METHOD_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addDeliveryMethod(deliveryMethod){
    return (dispatch) => {
        dispatch({type: DELIVERY_METHOD_IS_LOADING, bool :true});
        dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:false});
        dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}delivery-method/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,deliveryMethod, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_DELIVERY_METHOD, delivery_id: response.data.delivery_id})
                dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
        });
    }
}

export function deleteDeliveryMethod(id){
    return (dispatch) => {
        dispatch({type: DELIVERY_METHOD_IS_LOADING, bool :true});
        dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:false});
        dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}delivery-method/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const deliveryMethod_id = {delivery_id:id};
        const request = axios.post(url,deliveryMethod_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
        });
    }
}

export function editDeliveryMethod(deliveryMethod){
    return (dispatch) => {
        dispatch({type: DELIVERY_METHOD_IS_LOADING, bool :true});
        dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:false});
        dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}delivery-method/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,deliveryMethod, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: DELIVERY_METHOD_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: DELIVERY_METHOD_IS_ERROR, bool:true});
            dispatch({type: DELIVERY_METHOD_IS_LOADING, bool: false});
        });
    }
}