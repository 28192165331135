import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
// import VideoConference from './video-conference';
import VideoConference from './video-controls-2';
import jwtDecode from "jwt-decode";


export default class PatientVideo extends Component{

    constructor(props){
        super(props);
        this.state={
            identity: null,
            ready: false,
            showVideo: false,
        }
        this.shadowStyle = {
            border: '1px solid #dcd9d9',
            borderRadius: '4px',
            marginBottom: '15px',
            boxShadow: '5px 5px 5px #e0e3e4',
            fontWeight: 'lighter'
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState){

    }



    //Render Functions
    render() {
            /*
             Controls showing of the local track
             Only show video track after user has joined a room else show nothing
            */
            let room = "patient_" + this.props.patient_id;
            let jwt = jwtDecode(localStorage.getItem('token'));
            let isPractitioner = false;
            if(jwt.roles.indexOf('practitioner')>-1) {
                isPractitioner=true;
            }
            let id = jwt.user_id;
            if(this.props.patient_id>0){
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <VideoConference {...this.props} id={id} room={room} isPatient={false} autoJoin={false} acceptCommands={false} isPractitioner={isPractitioner}/>
                        </Grid>
                    </Grid>
                );
            } else {
                return(<div/>);
            }

        }
}






