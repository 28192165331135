import React, {Component,} from 'react';
import {connect} from 'react-redux';
import {List, ListItem, ListItemText,ListItemSecondaryAction,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Switch
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchUserRoles, addUserRole, deleteUserRole} from '../actions/Users';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../style';



class UserRole extends Component{
    constructor(props){
        super(props);

        this.state= {
            user_roles:[]
        };
    }

    componentDidMount() {
        this.props.fetchUserRoles({user_id:this.props.match.params.user_id});
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.user_roles!==prevProps.user_roles){
            this.setState({user_roles:this.props.user_roles});
        }
        if(this.props.needsRefresh){
            this.props.fetchUserRoles({user_id:this.props.match.params.user_id});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    //Dialog Functions


    //Action Functions

    switchRole = role_id => event =>{
        if(event.target.checked){
            this.props.addUserRole({user_id: this.props.match.params.user_id, role_id:role_id});
        } else {
            this.props.deleteUserRole({user_id: this.props.match.params.user_id, role_id:role_id})
        }

    }

    //Render Functions

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/users')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/users')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList(){
        if(this.state.user_roles.length){
            return (
            this.state.user_roles.map((value) => {
                return(
                    <ListItem key={value.role_id} button>
                        <ListItemText primary={value.name}/>
                        <ListItemSecondaryAction>
                            <Switch
                                onChange={this.switchRole(value.role_id)}
                                checked={value.enabled==="1"}
                                color='primary'
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                )
                }))
        }
    }


    render(){
        if(this.props.match.params.user_id<1){
            return(<div></div>);
        }

        return(<div style={{width:'100%', maxWidth:'300px'}}>
                <Typography align="center" variant="h5" color="inherit"><u>Roles</u></Typography>
                <List>
                {this.renderList()}
                </List>
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
            </div>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            user_roles: state.userRoles,
            isLoading: state.userRolesIsLoading,
            isError: state.userRolesIsError,
            needsRefresh: state.userRolesNeedsRefresh
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchUserRoles, addUserRole, deleteUserRole}, dispatch);
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(UserRole));


