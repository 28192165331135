import {UPDATE_PAGE, TOGGLE_CHAT} from '../constants_api';

export function page( state = '', action){
    switch (action.type){
        case UPDATE_PAGE:
            return action.page;
        default:
            return state;
    }
}
 export function showChatWindow( state = false, action){
    switch (action.type){
        case TOGGLE_CHAT:
            return !state;
        default:
            return state;
    }
 }