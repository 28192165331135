import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'
import {A_DOSAGE_NEEDS_REFRESH} from '../Dosage'

export const FETCH_DOSAGE_PRESCRIPTIONS = 'FETCH_DOSAGE_PRESCRIPTIONS';
export const FETCH_DOSAGE_PRESCRIPTIONS_DETAIL = 'FETCH_DOSAGE_PRESCRIPTIONS_DETAIL';
export const ADD_DOSAGE_PRESCRIPTIONS = 'ADD_DOSAGE_PRESCRIPTIONS';
export const DOSAGE_PRESCRIPTIONS_IS_LOADING = 'DOSAGE_PRESCRIPTIONS_IS_LOADING';
export const DOSAGE_PRESCRIPTIONS_IS_ERROR = 'DOSAGE_PRESCRIPTIONS_IS_ERROR';
export const DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH = 'DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH';
export const EXPIRING_PRESCRIPTIONS_IS_LOADING = 'EXPIRING_PRESCRIPTIONS_IS_LOADING';
export const EXPIRING_PRESCRIPTIONS_IS_ERROR = 'EXPIRING_PRESCRIPTIONS_IS_ERROR';
export const EXPIRING_PRESCRIPTIONS = 'EXPIRING_PRESCRIPTIONS';

export function fetchDosagePrescription(params){
    return (dispatch) => {
        //dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:true}});
        dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:false}});
        dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:false}});
        const url = `${API_URL}prescription/listByDosage?XDEBUG_SESSION_START=PHPSTORM`;
        let token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:false}});
            dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:false}});
            if(response.data.Success){
                dispatch({type: FETCH_DOSAGE_PRESCRIPTIONS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:true}});
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:false}});
            dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:false}});
        });
    }
}
export function fetchPrescriptionDetail(prescription_id){
    return (dispatch) => {
        const url = `${API_URL}prescription/fetch`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,prescription_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            if(response.data.Success){
                dispatch({type: FETCH_DOSAGE_PRESCRIPTIONS_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
        });
    }
}

export function addPrescription(params){
    return (dispatch) => {
        dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:true}});
        dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:false}});
        dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:false}});
        const url=`${API_URL}prescription/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:false}});
            if(response.data.Success){
                const p = {prescription_id: response.data.prescription_id, dosage_id:params.dosage_id, bool:true};
                dispatch({type: ADD_DOSAGE_PRESCRIPTIONS, payload:p });
                dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:true}});
                dispatch({type: A_DOSAGE_NEEDS_REFRESH, payload:p});
            } else {
                dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:false}});
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:true}});
        });
    }
}

export function deletePrescription(params){
    return (dispatch) => {
        dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:true}});
        dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:false}});
        dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:false}});
        const url=`${API_URL}prescription/delete?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:false}});
            if(response.data.Success){
                dispatch({type: DOSAGE_PRESCRIPTIONS_NEEDS_REFRESH, payload :{ dosage_id:params.dosage_id, bool:true}});
            } else {
                dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:true}});
            }
        }).catch((err)=>{
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_ERROR, payload :{ dosage_id:params.dosage_id, bool:true}});
            dispatch({type: DOSAGE_PRESCRIPTIONS_IS_LOADING, payload :{ dosage_id:params.dosage_id, bool:false}});
        });
    }
}


export function createPDF(id){
    const token = localStorage.getItem("token");
    axios({
        url: `${API_URL}prescription/pdf?XDEBUG_SESSION_START=PHPSTORM&prescription_id=${id}`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {"Authorization": "Bearer " + token}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `prescription_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
    });
}

export function createTestPDF(id){
    const token = localStorage.getItem("token");
    axios({
        url: `${API_URL}prescription/testpdf?XDEBUG_SESSION_START=PHPSTORM&practitioner_id=${id}`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {"Authorization": "Bearer " + token}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `prescription_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
    });
}

export function fetchExpiringPrescription(){
    return (dispatch) => {
        dispatch({type: EXPIRING_PRESCRIPTIONS_IS_LOADING, bool:true});
        dispatch({type: EXPIRING_PRESCRIPTIONS_IS_ERROR, bool:false});
        const url = `${API_URL}patient/getExpiringPrescriptions?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        let params = {};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: EXPIRING_PRESCRIPTIONS_IS_LOADING,bool:false});
            if(response.data.Success){
                dispatch({type: EXPIRING_PRESCRIPTIONS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: EXPIRING_PRESCRIPTIONS_IS_LOADING, bool:false});
            dispatch({type: EXPIRING_PRESCRIPTIONS_IS_ERROR, bool:true});
        });
    }
}