import * as TYPE from "../actions/PractitionerSchedules";


export function practitionerSchedules (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_SCHEDULES:
            return  action.payload.result;
        default:
            return state;
    }
}

export function practitionerScheduleIsLoading (state = false, action){
    switch(action.type){
        case TYPE.SCHEDULES_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function practitionerScheduleIsError (state = false, action){
    switch(action.type){
        case TYPE.SCHEDULES_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function practitionerScheduleNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.SCHEDULES_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
