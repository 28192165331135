import * as TYPE from "../actions/Statuses";


export function statuses (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_STATUSES:
            return  action.payload.result;
        default:
            return state;
    }
}

export function status (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_STATUSES_DETAIL:
            return action.payload;
        case TYPE.ADD_STATUSES:
            return {status_id: action.status_id, iAmNew: true};
        default:
            return state;
    }
}

export function statusIsLoading (state = false, action){
    switch(action.type){
        case TYPE.STATUSES_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function statusIsError (state = false, action){
    switch(action.type){
        case TYPE.STATUSES_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function statusNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.STATUSES_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
