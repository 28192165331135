import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid,
} from '@material-ui/core';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import {bindActionCreators} from 'redux';
import {fetchPatientHealthcardPhoto,addHealthcardPhoto, deleteHealthcardPhoto} from '../actions/HealthcardPhotos';
import {Field, reduxForm, } from 'redux-form';
import {renderTextField, renderImageUpload, renderDatePicker, } from '../Components/Forms';
import moment from "moment-timezone";
import ImageEditor from './imageEditor';
import Webcam from 'react-webcam';
import CameraIcon from '@material-ui/icons/PhotoCamera';


class PatientHealthcardDocumentDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            healthcard_photo_id: 0,
            healthcard_number: '',
            patient_id: this.props.patient_id,
            id_expiry: '',
            image: null,
            imageSrc: '',
            zoomImageDialog:false,
            originalImage: null,
            webCamDialog:false,
        };
        this.handleChange=this.handleChange.bind(this);
        this.handleImageChange=this.handleImageChange.bind(this);
        this.handleExpiryChange=this.handleExpiryChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.revertImage=this.revertImage.bind(this);
        this.updateZoomImage=this.updateZoomImage.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if(this.props.patient_id>0 && !this.props.passPhoto){
            this.props.fetchPatientHealthcardPhoto({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.passPhoto){
            this.setState({image:this.props.image, originalImage:this.props.image});
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.healthcard_photo!==prevProps.healthcard_photo){
            this.setState({
                healthcard_photo_id:this.props.healthcard_photo.healthcard_photo_id,
                healthcard_number:this.props.healthcard_photo.healthcard_number,
                id_expiry:this.props.healthcard_photo.id_expiry,
                image:this.props.healthcard_photo.image,
                originalImage: this.props.healthcard_photo.image,
            });
            const formData = {
                "healthcard_photo_id":this.props.healthcard_photo.healthcard_photo_id,
                "healthcard_number":this.props.healthcard_photo.healthcard_number,
                "id_expiry":this.props.healthcard_photo.id_expiry,
                "image":this.props.healthcard_photo.image,
            };
            this.props.initialize(formData);
        }
        if(this.props.location!==prevProps.location){
            this.props.fetchPatientHealthcardPhoto({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }

    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({pd_in_use : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onSubmit (values){
        var id_doc = {
            patient_id: this.props.patient_id,
            healthcard_number:values.healthcard_number,
            id_expiry:this.state.id_expiry,
            image:this.state.image,
            byPatient:this.props.byPatient,
        }
        this.props.addHealthcardPhoto(id_doc);
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    revertImage(){
        this.setState({image:this.state.originalImage});
    }
    //Render Functions



    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push(`/`)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push(`/`)}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderZoomImage (){
        return (
            <Dialog
                open={this.state.zoomImageDialog}
                onClose={()=>{this.setState({zoomImageDialog:false})}}
                maxWidth={false}
            >
                <DialogTitle id="alert-dialog-title">{`Image Capture Viewer`}</DialogTitle>
                <DialogContent>
                    <ImageEditor originalImage={this.state.image} updateCallback={this.updateZoomImage}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({zoomImageDialog:false})}} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderWebcam(){

        let dWidth = window.innerWidth - 100;
        let dHeight = window.innerHeight -50;
        let imgWidth = Math.min(dWidth -100,640);
        let imgHeight = Math.min(dHeight -300,640);
        const videoConstraints = {
            facingMode: 'user',
        };

        return (
            <Dialog
                open={this.state.webCamDialog}
                onClose={()=>{this.setState({webCamDialog:false})}}
                maxWidth={false}
                style={{maxHeight:`${dHeight}px`}}
                PaperProps={{style:{width:`${dWidth}px`}}}
                scroll="paper"
            >
                <DialogTitle>Capture Image</DialogTitle>
                <DialogContent>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Typography variant="subtitle1">Hold your Health Card up to the camera. The image must clearly show your photo, Date of Birth, and country of region. Select “Capture image” to save the image. </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Webcam
                                audio={false}
                                ref={this.setRef}
                                screenshotFormat="image/jpeg"
                                width={imgWidth}
                                height={imgHeight}
                                videoConstraints={videoConstraints}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button onClick={()=>{this.setState({webCamDialog:false})}} variant='contained' color='secondary'>Cancel</Button>
                    <Button onClick={this.capture} variant='contained' color='primary'>Capture Image</Button>

                </DialogActions>
            </Dialog>
        )
    }

    setRef = (webcam) => {
        this.webcam = webcam;
    }

    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        this.setState({image:imageSrc, webCamDialog:false})
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
        return true;
    };

    updateZoomImage(image){
        this.setState({image:image, zoomImageDialog:false});
    }

    handleImageChange = (event) => {
        this.setState({imageSrc: event.target.files[0]})
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            var image = new Image();
            image.onload = function(imageEvent) {
                let maxHeight = 2000;
                let maxWidth = 2000;
                let hr = image.height/maxHeight;
                let wr = image.width/maxWidth;
                let h = image.height;
                let w = image.width;
                if(hr>1 && hr>wr){
                    h=maxHeight;
                    w=parseInt(w/hr,10);
                }
                if(wr>1 && wr>hr){
                    w=maxWidth;
                    h=parseInt(h/wr,10);
                }
                var canvas = document.createElement('canvas');
                canvas.width=w;
                canvas.height=h;
                canvas.getContext('2d').drawImage(image, 0,0,w,h);
                var dataUrl = canvas.toDataURL('image/jpeg');
                this.setState({ image:dataUrl});
            }.bind(this);
            image.src=reader.result;
        }.bind(this);
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    }

    handleExpiryChange= (value) => {
        let d = moment(value).format("YYYY-MM-DD");
        this.setState({id_expiry: d});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }




    render(){
        const { handleSubmit, pristine, submitting } = this.props;
        const {classes} = this.props;
        if(this.props.patient_id<1){
            return(<div></div>);
        }
        let imgWidth = window.innerWidth -100;

        return(<Fragment>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="inherit"><u>Healthcard Document</u></Typography>
                        </Grid>
                        {this.props.byPatient &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Please upload or use the camera icon to capture a clear image of your Health Card.</Typography>
                        </Grid>
                        }
                        <Grid item xs={12} sm={6} className={classes.gridForm}>
                            <Field
                                autoFocus
                                name= 'healthcard_number'
                                component={renderTextField}
                                label= 'Healthcard Number'
                                margin='normal'
                                onChange={this.handleChange}
                                validate={[this.required]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.gridForm}>
                            <Field
                                name= 'id_expiry'
                                component={renderDatePicker}
                                label= 'Expiry Date'
                                margin='normal'
                                onChange={this.handleExpiryChange}
                                validate={[this.required]}
                                myFormat='MMM DD YYYY'
                            />
                        </Grid>


                        {!this.props.passPhoto &&
                            <Fragment>
                            <Grid item xs={12} sm={6} className={classes.gridForm}>
                                <Button onClick={()=>{this.setState({webCamDialog:true})}} variant="fab" color="secondary"><CameraIcon/></Button>
                            </Grid>
                                <Grid item xs={12} sm={6} className={classes.gridForm}>
                                <Field
                                    name='imageSrc'
                                    fname='imageSrc'
                                    component={renderImageUpload}
                                    onChange={this.handleImageChange}
                                    label='Upload Healthcard Image'
                                    margin='normal'
                                />
                            </Grid>
                            </Fragment>
                        }
                        <Grid item xs={12} className={classes.gridForm}>
                            <img src={this.state.image} alt="" style={{maxWidth:`${imgWidth}px`}}/>
                            {this.state.image!==this.state.originalImage &&
                                    <Button onClick={this.revertImage} color="secondary" variant="fab"><UndoIcon/></Button>
                            }
                            {this.state.image !== null &&
                            <Button onClick={() => {
                                this.setState({zoomImageDialog: true})
                            }} color="secondary" variant="fab"><EditIcon/></Button>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.gridForm}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                NEXT
                            </Button>
                            <Button disabled={pristine || submitting} onClick={()=>{this.props.reset();this.cancel()}} variant='contained'>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
                {this.renderZoomImage()}
                {this.renderWebcam()}

            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            healthcard_photo: state.healthcard_photo,
            isLoading: state.healthcardPhotoIsLoading,
            isError: state.healthcardPhotoIsError
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPatientHealthcardPhoto,addHealthcardPhoto, deleteHealthcardPhoto}, dispatch);
}


export default reduxForm({})(connect(mapStateToProps,mapDispatchToProps)(PatientHealthcardDocumentDetail));

