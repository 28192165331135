import React, {Component} from 'react';
import PractitionerDetail from '../../containers/practitioner-detail';
import {Grid, Paper} from '@material-ui/core';
import {updatePage} from '../../actions/Common';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../style';


class PractitionerDetailPage extends Component {
    componentDidMount() {
        this.props.updatePage('practitioner');
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                                <PractitionerDetail {...this.props} practitioner_id={this.props.match.params.practitioner_id}/>
                    </Paper>
                </Grid>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({updatePage}, dispatch);
}
export default connect(null,mapDispatchToProps)(withStyles(styles)(PractitionerDetailPage));



