import React ,{Component} from 'react';
import CalendarWeekView from '../../containers/calendarWeekView';
import {Grid, Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../style';
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";




class SlotCalendar extends Component {

    render() {
        let patient_id=0;
        if(typeof this.props.match.params.id === 'undefined' || !this.props.match.params.id){
            let jwt = jwtDecode(localStorage.getItem('token'));
            if(jwt){
                patient_id=jwt.patient_id;
            } else {
                return false;
            }

        } else {
            patient_id=this.props.match.params.id;
        }
        let monday = new moment(this.props.match.params.date).local().startOf('isoweek').format('YYYY-MM-DD');
        let tuesday = moment(monday).add(1,'d').format('YYYY-MM-DD');
        let wednesday = moment(monday).add(2,'d').format('YYYY-MM-DD');
        let thursday = moment(monday).add(3,'d').format('YYYY-MM-DD');
        let friday = moment(monday).add(4,'d').format('YYYY-MM-DD');
        let saturday = moment(monday).add(5,'d').format('YYYY-MM-DD');
        let sunday = moment(monday).add(6,'d').format('YYYY-MM-DD');
        let nextMonday = moment(monday).add(7,'d').format('YYYY-MM-DD');
        let prevMonday = moment(monday).subtract(7,'d').format('YYYY-MM-DD');
        let nextUrl = `/calendar/${patient_id}/${nextMonday}`;
        let prevUrl = `/calendar/${patient_id}/${prevMonday}`;
        let dateData = {monday,tuesday,wednesday,thursday,friday,saturday,sunday,nextMonday, prevMonday,nextUrl,prevUrl};

        const { classes } = this.props;

        return (
            <Grid container className={classes.gridPageOuter}>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <CalendarWeekView {...this.props} patient_id={patient_id} dateData={dateData}/>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(SlotCalendar);
