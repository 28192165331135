import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_COMMUNICATIONS = 'FETCH_COMMUNICATIONS';
export const FETCH_COMMUNICATION_DETAIL = 'FETCH_COMMUNICATION_DETAIL';
export const ADD_SMS_COMMUNICATION = 'ADD_SMS_COMMUNICATION';
export const ADD_EMAIL_COMMUNICATION = 'ADD_EMAIL_COMMUNICATION';
export const COMMUNICATION_IS_LOADING = 'COMMUNICATION_IS_LOADING';
export const COMMUNICATION_IS_ERROR = 'COMMUNICATION_IS_ERROR';
export const COMMUNICATION_NEEDS_REFRESH = 'COMMUNICATION_NEEDS_REFRESH';

export function fetchCommunicationsByPatient(params){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}communication/listByPatient?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_COMMUNICATIONS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        });
    }
}

export function fetchCommunicationDetail(params){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}communication/fetch?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_COMMUNICATION_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addSMS(communication){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}communication/sendSMS?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,communication, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_SMS_COMMUNICATION, communication_id: response.data.communication_id})
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}

export function addEmail(communication){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}communication/sendEmail?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,communication, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_EMAIL_COMMUNICATION, communication_id: response.data.communication_id})
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}

export function addAppointmentEmail(communication){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}communication/sendAppointmentEmail?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,communication, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_EMAIL_COMMUNICATION, communication_id: response.data.communication_id})
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}


export function deleteCommunication(id){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}communication/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const communication_id = {communication_id:id};
        const request = axios.post(url,communication_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}

export function editCommunication(communication){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        dispatch({type: COMMUNICATION_IS_ERROR, bool:false});
        dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}communication/edit`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,communication, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_ERROR, bool:true});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}