import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_ID_PHOTOS = 'FETCH_ID_PHOTOS';
export const FETCH_ID_PHOTO_DETAIL = 'FETCH_ID_PHOTO_DETAIL';
export const ADD_ID_PHOTO = 'ADD_ID_PHOTO';
export const EDIT_ID_PHOTO = 'EDIT_ID_PHOTO';
export const ID_PHOTO_IS_LOADING = 'ID_PHOTO_IS_LOADING';
export const ID_PHOTO_IS_ERROR = 'ID_PHOTO_IS_ERROR';
export const ID_PHOTO_NEEDS_REFRESH = 'ID_PHOTO_NEEDS_REFRESH';


export function fetchPatientIdentificationPhoto(params){
    return (dispatch) => {
        dispatch({type: ID_PHOTO_IS_LOADING, bool :true});
        dispatch({type: ID_PHOTO_IS_ERROR, bool:false});
        dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/getPatientIdentificationDocument?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: ID_PHOTO_IS_LOADING, bool: false});
            dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_ID_PHOTO_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: ID_PHOTO_IS_ERROR, bool:true});
            dispatch({type: ID_PHOTO_IS_LOADING, bool: false});
            dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addIdentificationPhoto(params){
    return (dispatch) => {
        dispatch({type: ID_PHOTO_IS_LOADING, bool :true});
        dispatch({type: ID_PHOTO_IS_ERROR, bool:false});
        dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/addIdentificationDocument?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: ID_PHOTO_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_ID_PHOTO, payload: response.data.answer})
                dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: ID_PHOTO_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: ID_PHOTO_IS_ERROR, bool:true});
            dispatch({type: ID_PHOTO_IS_LOADING, bool: false});
        });
    }
}

export function deleteIdentificationPhoto(id){
    return (dispatch) => {
        dispatch({type: ID_PHOTO_IS_LOADING, bool :true});
        dispatch({type: ID_PHOTO_IS_ERROR, bool:false});
        dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/deleteIdentificationPhoto`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const photo_id = {identification_photo_id:id};
        const request = axios.post(url,photo_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: ID_PHOTO_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ID_PHOTO_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: ID_PHOTO_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: ID_PHOTO_IS_ERROR, bool:true});
            dispatch({type: ID_PHOTO_IS_LOADING, bool: false});
        });
    }
}

