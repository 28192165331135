import jwtDecode from "jwt-decode";
import _ from 'lodash';

function getAPI(){
    var API_URL_1 = "https://crm.ausabis.com/api/";
    if(window.location.hostname==='localhost'){
        API_URL_1 = "http://localhost:8080/api/";
    } else if(window.location.hostname==='127.0.0.1') {
        API_URL_1 = "https://new-dot-ausabis-crm.appspot.com/api/";
    } else if(window.location.hostname==='new-dot-ausabis-patient.appspot.com') {
        API_URL_1 = "https://new-dot-ausabis-crm.ue.r.appspot.com/api/";
    } else if(window.location.hostname==='partner-dot-ausabis-patient.appspot.com') {
        API_URL_1 = "https://partner-dot-ausabis-crm.ue.r.appspot.com/api/";
    } else if(window.location.hostname==='new-dot-ausabis-patient.ue.r.appspot.com') {
        API_URL_1 = "https://new-dot-ausabis-crm.ue.r.appspot.com/api/";
    } else if(window.location.hostname==='pm-dot-ausabis-patient.appspot.com') {
        API_URL_1 = "https://testing-dot-ausabis-crm.appspot.com/api/";
    } else if (window.location.hostname==='patient-dot-ausabis-patient.appspot.com') {
        API_URL_1 = "https://testing-dot-ausabis-crm.appspot.com/api/";
    } else if (window.location.hostname==='testing-dot-ausabis-patient.appspot.com') {
        API_URL_1 = "https://testing-dot-ausabis-crm.appspot.com/api/";
    } else if (window.location.hostname==='sb1-dot-ausabis-patient.appspot.com') {
        API_URL_1 = "https://sb1-dot-ausabis-crm.ue.r.appspot.com/api/";
    }
    return (API_URL_1);
}

export function getHomePage(){
    var HomePage = "patient";
    if(window.location.hostname==='127.0.0.1'){
        HomePage = "user";
    } else if(window.location.hostname==='localhost') {
        HomePage = "user";
    } else if(window.location.hostname==='pm-dot-ausabis-patient.appspot.com') {
        HomePage = "user";
    } else if(window.location.hostname==='secretpm-dot-ausabis-patient.appspot.com') {
        HomePage = "user";
    } else if(window.location.hostname==='pm.ausabis.com') {
        HomePage = "user";
    } else if(window.location.hostname==='ausabis-patient.appspot.com') {
        HomePage = "user";
    } else if(window.location.hostname==='pm.cannentaclinic.com') {
        HomePage = "user";
    } else if(window.location.hostname==='sb1-dot-ausabis-patient.appspot.com') {
        HomePage = "user";
    }
    return (HomePage);
}

export function isTesting(url){
    var testing = true;
    if(url==='https://crm.ausabis.com/api/'){
        testing=false;
    }
    return testing;
}

export function whichChat(){
    let chat = {chatUsers:'ChatUsers',messages:'Messages'};
    if(window.location.hostname==='pm.cannentaclinic.com'){
        chat={chatUsers:'LIVEChatUsers',messages:'LIVEMessages'};
    }
    return chat;
}

export const API_URL = getAPI();
export const TESTING = isTesting(API_URL);
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const TOGGLE_CHAT = 'TOGGLE_CHAT';
export const CP_FIND_API_URL = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?callback=?';
export const CP_RETRIEVE_API_URL = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?callback=?';
export const PASSWORD_FAILED = 'PASSWORD_FAILED';
export const CHATSERVER = whichChat();
export const UPDATE_UNREAD = 'UPDATE_UNREAD';
export const POSTKEY = 'HG75-BF74-XM39-JZ28';
export const POSTURL = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws';
export const POSTDETAILURL = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws';

export function checkRole(role){
    if(typeof localStorage.getItem('token') ==='undefined' || localStorage.getItem('token')<=''){
        return false;
    }
   var jwt = jwtDecode(localStorage.getItem('token'));
    if(typeof jwt.roles !== 'undefined'){
        if(jwt.roles.indexOf(role)>-1) {
            return true;
        }
    }
   return false;
}

export function timeSlots(interval){
    var hour, minute;
    let times=[];
    for(hour=0; hour<24; hour++){
        for(minute=0; minute<60; minute=minute+interval){
            let min = String(minute);
            let hr = String(hour);
            min=min.length<2 ? '0' + min : min;
            hr=hr.length<2 ? '0' + hr : hr;
            let tm=`${hr}:${min}`;
            let val=`${hr}:${min}:00`;
            times.push({value:val, label:tm});
        }
    }
    return times;
}

export const default_timezone = 'America/Toronto';
export function timezoneList(){
    //let timezones = moment.tz.names();
    let tzs =[
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Montreal',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Resolute',
        'America/Atikokan',
        'America/Rankin_Inlet',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',];
    let timezones = _.sortBy(tzs);
    var arr = [];
    timezones.map((timezone)=>{
        let labl= timezone.split("/");
        let label = labl[1];
        arr.push({value:timezone, label:label});
        return true;
    });
    return arr;
}
