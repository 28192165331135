import {UPDATE_TOKEN, DELETE_TOKEN, PASSWORD_FAILED, UPDATE_UNREAD} from '../constants_api';
import jwtDecode from 'jwt-decode';

export function credentials( state = {}, action){
    switch (action.type){
        case UPDATE_TOKEN:
            let details = action.token>'' ? jwtDecode(action.token) : {};
            const jwt={token: action.token, details: details};
            return jwt;
        case DELETE_TOKEN:
            return {};
        default:
            return state;
    }
}

export function loginFailed( state=false, action){
    switch (action.type){
        case PASSWORD_FAILED:
            return action.bool;
        default:
            return state;
    }
}

export function unreadMsgs( state=0, action){
    switch (action.type){
        case UPDATE_UNREAD:
            return action.count;
        default:
            return state;
    }
}