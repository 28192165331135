import React, {Component} from 'react';
import {
    Dialog, DialogTitle, DialogActions, DialogContent,
    Button,
    IconButton,
    Avatar,
    ListItem, ListItemText, ListItemAvatar,ListItemSecondaryAction,
} from '@material-ui/core';
import moment from 'moment-timezone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import VideoIcon from '@material-ui/icons/Videocam';
import MessageShowDetail from './messageShowDetail';

export class MessageListItem extends Component {

    constructor(props){
        super(props);
        this.state={
          detailDialogOpen: false,
        };

    }


    renderDetailDialog (){
        return (
            <Dialog
                fullWidth={true}
                maxWidth = {'md'}
                open={this.state.detailDialogOpen}
                onClose={()=>{this.setState({detailDialogOpen:false})}}
            >
                <DialogTitle id="alert-dialog-title">{this.props.data.type}</DialogTitle>
                <DialogContent>
                    <MessageShowDetail {...this.props}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({detailDialogOpen:false})}} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    render(){
        const data = this.props.data;
        var created = new moment.tz(data.created,'UTC');
        var Dcreated = created.tz('America/Toronto').format('YYYY-MM-DD, HH:mm');
        return (
                <ListItem key={data.communication_id}>
                    {this.renderDetailDialog()}
                    <ListItemAvatar>
                        <Avatar>
                            {data.type==='SMS' && <SmsIcon/>}
                            {data.type==='Email' && <EmailIcon/>}
                            {data.type==='Video' && <VideoIcon/>}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${data.subject} (${data.senderName})`}
                        secondary={Dcreated}
                    />
                    <ListItemSecondaryAction>
                        <IconButton onClick={()=>{this.setState({detailDialogOpen:true})}}>
                            <ZoomInIcon/>
                        </IconButton>
                    </ListItemSecondaryAction>

                </ListItem>

        );
    }

}




