import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api';
import {COMMUNICATION_NEEDS_REFRESH, COMMUNICATION_IS_LOADING} from '../Communication';

export const FETCH_VIDEO_TOKEN = 'FETCH_VIDEO_TOKEN';
export const PARTICIPANTS_ARE_LOADING = 'PARTICIPANTS_ARE_LOADING';
export const FETCH_PARTICIPANTS = 'FETCH_PARTICIPANTS';
export const VIDEO_COMMAND_IS_LOADING = 'VIDEO_COMMAND_IS_LOADING';
export const FETCH_VIDEO_COMMAND = 'FETCH_VIDEO_COMMAND';
export const VIDEO_COMMAND_IS_SETTING = 'VIDEO_COMMAND_IS_SETTING';
export const ROOMS_ARE_LOADING = 'ROOMS_ARE_LOADING';
export const FETCH_ROOMS = 'FETCH_ROOMS';

export function fetchVideoToken(params){
    return (dispatch) => {
        let url='';
        if(params.isConferenceRoom){
            url = `${API_URL}video/roomToken?XDEBUG_SESSION_START=PHPSTORM`;
        } else {
            url = `${API_URL}video/link?XDEBUG_SESSION_START=PHPSTORM`;
        }
        let request = axios.post(url, params);
        request.then((response) => {
            //dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            if(response.data.Success){
                dispatch({type: FETCH_VIDEO_TOKEN, payload: response.data});
            }
        }).catch((err)=>{

        });
    }
}

export function sendVideoUrl(params){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        const url = `${API_URL}communication/sendUrlMessage?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
                dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            }
        }).catch((err)=>{

        });
    }
}

export function sendPatientFullUrl(patient){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        const url=`${API_URL}patient/sendFullUrl?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
                dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}

export function getRoomParticipants(params){
    return (dispatch) => {
        dispatch({type: PARTICIPANTS_ARE_LOADING, bool: true});
        const url = `${API_URL}video/getRoomParticipants?XDEBUG_SESSION_START=PHPSTORM`;
        const request = axios.post(url, params);
        request.then((response) => {
            dispatch({type: PARTICIPANTS_ARE_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PARTICIPANTS, payload: response.data});
            }
        }).catch((err)=>{

        });
    }
}

export function getRooms(){
    return (dispatch) => {
        dispatch({type: ROOMS_ARE_LOADING, bool: true});
        const url = `${API_URL}video/getRooms?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const params = {test:'test'};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: ROOMS_ARE_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_ROOMS, payload: response.data});
            }
        }).catch((err)=>{

        });
    }
}

export function getCommand(params){
    return (dispatch) => {
        dispatch({type: VIDEO_COMMAND_IS_LOADING, bool: true});
        const url = `${API_URL}video/getCommand?XDEBUG_SESSION_START=PHPSTORM`;
        const request = axios.post(url, params);
        request.then((response) => {
            dispatch({type: VIDEO_COMMAND_IS_LOADING, bool: false});
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            if(response.data.Success){
                dispatch({type: FETCH_VIDEO_COMMAND, payload: response.data.command});
            }
        }).catch((err)=>{

        });
    }
}

export function setCommand(params){
    return (dispatch) => {
        dispatch({type: VIDEO_COMMAND_IS_SETTING, bool: true});
        const url = `${API_URL}video/setCommand?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: VIDEO_COMMAND_IS_SETTING, bool: false});
        }).catch((err)=>{

        });
    }
}

export function startVideoLog(params){
    return (dispatch) => {
        const url = `${API_URL}video/startLog?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
        }).catch((err)=>{

        });
    }
}

export function endVideoLog(params){
    return (dispatch) => {
        const url = `${API_URL}video/endLog?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
        }).catch((err)=>{

        });
    }
}
