import * as TYPE from "../actions/Video";

export function videoToken (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_VIDEO_TOKEN:
            return  action.payload.videoToken;
        default:
            return state;
    }
}

export function videoIdentity (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_VIDEO_TOKEN:
            return  action.payload.identity;
        default:
            return state;
    }
}

export function participants ( state=[], action){
    switch(action.type) {
        case TYPE.FETCH_PARTICIPANTS:
            return  action.payload.participants;
        default:
            return state;
    }
}

export function loadingParticipants ( state = false, action){
    switch(action.type) {
        case TYPE.PARTICIPANTS_ARE_LOADING:
            return  action.bool;
        default:
            return state;
    }
}

export function videoCommands (state=[], action){
    switch(action.type) {
        case TYPE.FETCH_VIDEO_COMMAND:
            return  action.payload.command;
        default:
            return state;
    }
}

export function videoCommandLoading (state = false, action){
    switch(action.type) {
        case TYPE.VIDEO_COMMAND_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function videoCommandSetting (state=false , action){
    switch (action.type){
        case TYPE.VIDEO_COMMAND_IS_SETTING:
            return action.bool;
        default:
            return state;
    }
}

export function rooms ( state=[], action){
    switch(action.type) {
        case TYPE.FETCH_ROOMS:
            return  action.payload.rooms;
        default:
            return state;
    }
}

export function loadingRooms ( state = false, action){
    switch(action.type) {
        case TYPE.ROOMS_ARE_LOADING:
            return  action.bool;
        default:
            return state;
    }
}