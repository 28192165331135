import * as TYPE from "../actions/Users";


export function users (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_USERS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function user (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_USER_DETAIL:
            return action.payload;
        case TYPE.ADD_USER:
            return {user_id: action.user_id, iAmNew: true};
        default:
            return state;
    }
}

export function userRoles (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_USER_ROLES:
            return action.payload;
        default:
            return state;
    }
}

export function userIsLoading (state = false, action){
    switch(action.type){
        case TYPE.USER_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function userIsError (state = false, action){
    switch(action.type){
        case TYPE.USER_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function userNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.USER_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function userRolesIsLoading (state = false, action){
    switch(action.type){
        case TYPE.USER_ROLES_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function userRolesIsError (state = false, action){
    switch(action.type){
        case TYPE.USER_ROLES_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function userRolesNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.USER_ROLES_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}

export function changingPassword (state = false, action){
    switch(action.type){
        case TYPE.PASSWORD_CHANGING :
            return action.bool;
        default:
            return state;
    }
}

export function passwordMessage (state = '', action){
    switch (action.type){
        case TYPE.PASSWORD_MESSAGE:
            return action.msg;
        default:
            return state;
    }
}

export function chatToken (state = '', action){
    switch(action.type){
        case TYPE.UPDATE_CHAT_TOKEN:
            return action.token;
        default:
            return state;
    }
}

export function fetchChatToken (state = false, action){
    switch(action.type){
        case TYPE.FETCH_CHAT_TOKEN:
            return action.bool;
        default:
            return state;
    }
}