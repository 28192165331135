import React, {Component} from 'react';
import {TextField,  RadioGroup, Select, MenuItem, Input, InputLabel, FormControl,
    FormHelperText, FormControlLabel, Radio, Checkbox, Typography} from '@material-ui/core';
import { DateTimePicker} from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';
import { TimePicker } from '@material-ui/pickers';
import Dropzone from 'react-dropzone';
import SimpleTimePicker from 'react-times';
import 'react-times/css/classic/default.css';
import 'react-times/css/material/default.css';
import {default_timezone,timezoneList} from '../../constants_api'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core';

const materialTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                alignItems: 'center',
            },
        },
        MuiPickersToolbarButton: {
            toolbarBtn: {
                fontSize: '2rem',
            }
        },
    }
})

export class renderTextField extends Component {
    render(){
        const {input, meta :{touched, error}, label, ...custom } = this.props;
        const hasError = Boolean(touched && error);
        return    (
            <TextField
                placeholder={label}
                label={label}
                helperText={error}
                error={hasError}
                {...input}
                {...custom}
            />

        );
    }
}

export class renderNewTextField extends Component {
    render(){
        const {input: {value, onChange}, meta :{touched, error}, label, ...custom } = this.props;
        const hasError = Boolean(touched && error);
        return    (
            <TextField
                placeholder={label}
                label={label}
                helperText={error}
                error={hasError}
                value={value}
                onChange={onChange}
                {...custom}
            />

        );
    }
}


export class renderRadio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfValues: []
        }
    }
    componentDidMount(){
        this.setState({listOfValues:this.props.listOfValues})
    }
    render(){
        const {input: {value, onChange}, meta :{touched, error}, listOfValues,  } = this.props;
        const hasError = Boolean(touched && error);
        return (
            <FormControl  component="fieldset" error={hasError}>
                    <RadioGroup
                        value={value}
                        onChange={onChange}
                        row
                    >
                        {listOfValues.map((item)=>{
                            return(<FormControlLabel key={item.label} value={item.value} control={<Radio color="primary" />} label={item.label} />)
                        }
                        )}

                    </RadioGroup>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        );
    }
}

const styles = {
    selectableLabel: {
        userSelect: 'all',
    },
};

class renderChckBox extends Component {

    render(){
        const {input: {value, onChange}, meta :{touched, error}, label, margin, classes, hasLinks } = this.props;
        const hasError = Boolean(touched && error);
        let cls = hasLinks ? {label:classes.selectableLabel} : {};
        return    (
            <FormControl error={hasError}  margin={margin}>
            <FormControlLabel
                classes={cls}
                control={
                    <Checkbox
                        onChange={onChange}
                        checked={Boolean(value)}
                        color="primary"
                    />}
                label={label} />
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        );
    }
}
const renderCheckBox = withStyles(styles)(renderChckBox);
export {renderCheckBox};

export class renderSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listOfValues: []
        }
    }

    componentDidMount(){
        this.setState({listOfValues:this.props.listOfValues})
    }

    render(){
        const {input: {value, onChange}, meta :{touched, error}, label, listOfValues, margin, noBlank } = this.props;
        const hasError = Boolean(touched && error);
        const noShowBlank = Boolean(noBlank);
        return    (
            <FormControl error={hasError} style={{minWidth:'200px'}} margin={margin}>
                <InputLabel htmlFor={label}>{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                name={label}
                input={<Input id={label} fullWidth={true}/>}
                autoWidth
            >
                {!noShowBlank &&
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                }
                {listOfValues.map((item)=>{
                    return(<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    }
                )}
            </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>

        );
    }
}

export class RenderTimezoneSelect extends Component {
    render(){
        const {value, onChange, label, margin } = this.props;
        let val =value;
        if(value<=''){
            val=default_timezone;
        }
        return    (
            <FormControl style={{minWidth:'150px'}} margin={margin}>
                <InputLabel htmlFor={label}>{label}</InputLabel>
                <Select
                    value={val}
                    onChange={onChange}
                    name={label}
                    input={<Input id={label} fullWidth={true}/>}
                    autoWidth
                >
                    {timezoneList().map((item)=>{
                            return(<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                        }
                    )}
                </Select>
            </FormControl>

        );
    }
}

export class renderNewSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listOfValues: []
        }
    }

    componentDidMount(){
        this.setState({listOfValues:this.props.listOfValues})
    }

    render(){
        const {input: {value, onChange, name}, meta :{touched, error}, label, listOfValues, margin, width, ...custom } = this.props;
        const hasError = Boolean(touched && error);
        let minWidth= width;
        return    (
            <FormControl error={hasError} style={{minWidth:`${minWidth}px`}} margin={margin}>
                <InputLabel htmlFor={label}>{label}</InputLabel>
                <Select
                    value={value}
                    onChange={onChange}
                    input={<Input id={label} fullWidth={true}/>}
                    autoWidth
                    name={name}
                    {...custom}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {listOfValues.map((item)=>{
                            return(<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                        }
                    )}
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>

        );
    }
}

export class renderNativeSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listOfValues: []
        }
    }

    componentDidMount(){
        this.setState({listOfValues:this.props.listOfValues})
    }

    render(){
        const {input: {value, onChange, name}, meta :{touched, error}, label, listOfValues, margin, width, ...custom } = this.props;
        const hasError = Boolean(touched && error);
        let minWidth= width;
        return    (
            <FormControl error={hasError} style={{minWidth:`${minWidth}px`}} margin={margin}>
                <InputLabel htmlFor={label}>{label}</InputLabel>
                <Select
                    native
                    value={value}
                    onChange={onChange}
                    input={<Input id={label} fullWidth={true}/>}
                    autoWidth
                    name={name}
                    {...custom}
                >
                    <option value=""></option>
                    {listOfValues.map((item)=>{
                            return(<option key={item.value} value={item.value}>{item.label}</option>)
                        }
                    )}
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>

        );
    }
}


export class renderDateTimePicker extends Component {
    render(){
        const {input: {value, onChange}, meta :{ error}, label, myFormat, ...custom } = this.props;
        const display_value= value>'' ? value : null;
        return    (
            <MuiThemeProvider theme={materialTheme}>
            <DateTimePicker
                label={label}
                onChange={onChange}
                value={display_value}
                helperText={error}
                emptyLabel='Please Select Date/Time'
                format={myFormat}
                {...custom}
            />
            </MuiThemeProvider>
        );
    }
}

export class renderDatePicker extends Component {
    render(){
        const {input: {value, onChange}, meta :{ error}, label, myFormat, ...custom } = this.props;
        const display_value= value>'' ? value : null;
        return    (
            <MuiThemeProvider theme={materialTheme}>
            <DatePicker
                label={label}
                onChange={onChange}
                value={display_value}
                helperText={error}
                emptyLabel='Please Select Date'
                format={myFormat}
                {...custom}
            />
            </MuiThemeProvider>
        );
    }
}
export class DateSelect extends Component {
    render(){
        const {value, onChange, label, margin, myFormat, timezone, ...custom } = this.props;
        const display_value= value>'' ? value : null;
        return    (
            <MuiThemeProvider theme={materialTheme}>
                <DatePicker
                    label={label}
                    onChange={onChange}
                    value={display_value}
                    emptyLabel='Please Select Date'
                    format={myFormat}
                    {...custom}
                />
            </MuiThemeProvider>
        );
    }
}


export class renderTimePicker extends Component {
    render(){
        const {input: {value, onChange}, meta :{ error}, label, myFormat, ...custom } = this.props;
        const display_value= value>'' ? value : null;
        return    (
            <TimePicker
                label={label}
                onChange={onChange}
                value={display_value}
                helperText={error}
                emptyLabel={label}
                format={myFormat}
                {...custom}
            />
        );
    }
}

export class renderSimpleTimePicker extends Component {
    render(){
        const {input: {value, onChange}, meta :{ error, touched}, label, margin, ...custom } = this.props;
        const display_value= value>'' ? `${value.hour}:${value.minute}` : null;
        const hasError = Boolean(touched && error);
        return    (
            <FormControl error={hasError} style={{minWidth:'150px'}} margin={margin}>
                <Typography variant='body2'>{label}</Typography>
                <SimpleTimePicker
                    onTimeChange={onChange}
                    theme='classic'
                    time={display_value}
                    minuteStep={15}
                    {...custom}
                />
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        );
    }
}

export class renderFileUpload extends Component {
    render(){
        const {input: { onChange}, meta :{touched, error}, label, margin ,  } = this.props;
        const hasError = Boolean(touched && error);
        return(
            <FormControl error={hasError} style={{minWidth:'150px'}} margin={margin}>
                <Typography variant='body2'>{label}</Typography>
                <input type="file" onChange={onChange}/>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        );
    }
}

export class renderImageUpload extends Component {
    render(){
        const {input: {onChange}, meta :{touched, error}, label, margin ,preview,} = this.props;
        const hasError = Boolean(touched && error);
        return(
            <div>
                <FormControl error={hasError} style={{minWidth:'150px'}} margin={margin}>
                    <InputLabel htmlFor={label}>{label}</InputLabel>
                    <input type="file" onChange={onChange} accept="image/png, image/jpeg"/>
                    <FormHelperText>{error}</FormHelperText>
                </FormControl>
                <img src={preview} alt=""/>
            </div>
        );
    }
}

export class renderFileUpload_2 extends Component {
    render(){
        const {input: {value, onChange}, meta :{touched, error}, label, fname , } = this.props;
        const files = value;
        const hasError = Boolean(touched && error);
        return(
            <div>
                <Dropzone
                    name={fname}
                    onDrop={( filesToUpload, e ) => onChange(filesToUpload)}
                >
                    <div>{label}</div>
                </Dropzone>
                {hasError &&
                <span>{error}</span>}
                {files && Array.isArray(files) && (
                    <ul>
                        { files.map((file, i) => <li key={i}>{file.name}</li>) }
                    </ul>
                )}
            </div>
        );
    }
}