import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    CircularProgress,
    Typography,
    Grid,
    Card,  CardHeader, CardContent,
    Hidden,
} from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import {bindActionCreators} from 'redux';
import {fetchDosagePrescription,addPrescription,deletePrescription,createPDF} from '../actions/DosagePrescription';
import {renderTextField, renderSelect} from "../Components/Forms";
import {Field, reduxForm} from 'redux-form';
import {checkRole} from '../constants_api';
import moment from 'moment-timezone';
import {fetchLpsByMethod} from '../actions/Lps';

class PrescriptionByDosage extends Component{

    constructor(props){
        super(props);

        this.state= {
            addNewDialogOpen: false,
            deleteDialogOpen: false,
            editDialogOpen: false,
            isLoading: false,
            prescription_id_inuse: 0,
            data: {
                prescription_id: 0,
                patient_id: 0,
                practitioner_id: 0,
                created: new moment(),
                parked: new moment(),
                lp_id: 0,
                grams: 0,
                months: 0,
                notes:'',
                mandatoryNotes:false,
                cbdOnly:false,
                deliveryMethod:'',
                dosage_id:0,
                expired: 0,
            },
            bounce: 0,
            providers:[],
            prescriptions:{},
            hideAdd: false,
        };

        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.addPrescription=this.addPrescription.bind(this);
        this.handleChangeGrams=this.handleChangeGrams.bind(this);
        this.gramsLeft=this.gramsLeft.bind(this);
    }

    componentDidMount() {
        this.props.fetchDosagePrescription({dosage_id:this.props.dosage.dosage_id, byPatient:this.props.byPatient});
        if(!this.props.byPatient){
            this.props.fetchLpsByMethod({delivery_id:this.props.dosage.delivery_id,mandatoryCapable:this.props.dosage.mandatoryNotes,cbdOnlyCapable:this.props.dosage.cbdOnly});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.needsRefresh.bool) {
            this.props.fetchDosagePrescription({dosage_id:this.props.dosage.dosage_id, byPatient:this.props.byPatient});
        }
        if(this.props.version!==prevProps.version){
            //this.props.fetchDosagePrescription({dosage_id:this.props.dosage.dosage_id});
        }

        if(this.props.providers!==prevProps.providers){
            //create the array needed for the status selector
            var arr = [];
            this.props.providers.dataset.map((data)=>{
                arr.push({value:data.lp_id, label:data.name});
                return true;
            });
            this.setState({providers:arr});
        }

        if(this.props.prescriptions!==prevProps.prescriptions){
            // check to see if we have any prescriptions and if we have then update the inUse for the dosage
                this.props.updateInUse();
            //check to see if Dosage limit is reached and remove add button
            //const dosage_id = this.props.dosage.dosage_id;
            const p = this.props.prescriptions;
            this.setState({prescriptions:p.result});
            if(parseFloat(p.result.total_dose)>=parseFloat(this.props.dosage.grams)){
                this.setState({hideAdd:true});
            } else {
                this.setState({hideAdd:false});
            }
        }
    }


    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    //Form Validation Functions:
    required(value) {
       return value ? undefined : 'Required'
    }

    gramsLeft(value) {
        if(parseFloat(value)>parseFloat(this.props.dosage.grams)-parseFloat(this.props.dosage.used)){
            return 'You do not have Sufficient grams left';
        } else {
            return undefined;
        }
    }

    isNumeric(value) {
        return isNaN(value) ? 'Must be a Number' : undefined;
    }

    //Dialog Functions


    onDeleteDialogOpen(id) {
        this.setState({prescription_id_inuse : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions
    addPrescription(values){
        const prescription = {
            dosage_id : this.props.dosage.dosage_id,
            grams: values.grams,
            LP_id : values.lp_id
        }
        this.props.addPrescription(prescription);
    }

    canPrescribe(){
        return checkRole('prescription');
    }

    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        const params = {dosage_id:this.props.data.dosage_id,prescription_id:id};
        this.props.deletePrescription(params);
    }

    handleChangeGrams(event) {
        //do something if needed.
    };

    //Render Functions
    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.state.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderDeleteDialog (id){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete this Prescription ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.dosage_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList(){
        if(typeof this.state.prescriptions.dataset === 'undefined'){
            return(<Grid item xs={12}></Grid>);
        }
        return (
            this.state.prescriptions.dataset.map((data)=>{
                let created = new moment.tz(data.created,'UTC');
                let Dcreated = this.props.byPatient ? created.tz('America/Toronto').format('YYYY-MM-DD') : created.tz('America/Toronto').format('YYYY-MM-DD, HH:mm');

                return (
                    <Grid item xs={12} key={data.prescription_id}>
                       <Card>
                           <CardHeader
                               title={Dcreated}
                               subheader={data.firstname + ' ' + data.lastname}
                               action={ this.canPrescribe() &&
                               <IconButton onClick={() => {createPDF(data.prescription_id)}}>
                                   <BookIcon />
                               </IconButton>
                               }
                           />
                           <CardContent>
                               <Typography variant="subtitle1">
                                   {data.grams} grams per day for {data.months} months from Provider: {data.provider}
                               </Typography>
                           </CardContent>
                       </Card>
                    </Grid>
                );
            })
        )
    }

    render(){
        const { handleSubmit } = this.props;

        return(
            <Fragment>
                <form
                    onSubmit={handleSubmit(this.addPrescription)}
                >
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    {this.canPrescribe() && !this.state.hideAdd &&
                        <Fragment>
                        <Grid item xs={12} sm={4}>
                            <Field autoFocus
                                   name='grams'
                                   component={renderTextField}
                                   label='Number of Grams per Day'
                                   margin='normal'
                                   fullWidth
                                   onChange={this.handleChange}
                                   validate={[this.required,this.gramsLeft, this.isNumeric]}
                            />
                        </Grid>
                            <Hidden xsDown>
                                <Grid item sm={4}>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} sm={4}>
                                <Field
                                    name='lp_id'
                                    component={renderSelect}
                                    label='Provider'
                                    margin='normal'
                                    fullWidth
                                    onChange={this.handleChange}
                                    listOfValues={this.state.providers}
                                    validate={[this.required]}
                                />
                            </Grid>


                            <Grid item xs={12} sm={3}>
                            <Button type="submit" variant='contained'>
                                Add
                            </Button>
                        </Grid>
                        </Fragment>
                    }
                    {this.renderList()}
                </Grid>
            {this.renderDeleteDialog()}
            {this.renderRefreshDialog()}
                </form>
            </Fragment>
        );
    }
}


function mapStateToProps(state, ownProps){
    return(
        {
            test: state.prescriptionIsLoading,
            prescriptions: state.prescriptions[ownProps.dosage.dosage_id],
            isLoading: state.prescriptionIsLoading[ownProps.dosage.dosage_id],
            isError: state.prescriptionIsError[ownProps.dosage.dosage_id],
            needsRefresh: state.prescriptionNeedsRefresh[ownProps.dosage.dosage_id],
            credentials: state.credentials,
            providers: state.lps
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchDosagePrescription,deletePrescription,addPrescription,fetchLpsByMethod}, dispatch);
}

export default reduxForm({
})(connect(mapStateToProps, mapDispatchToProps)(PrescriptionByDosage));
