const drawerWidth = 400;
export const styles = theme => ({
    gridPageOuter: {

        flexGrow:1,
    },
    gridHeader:{
        height: '80px',
    },
    gridFooter:{
        height: '60px',
    },
    gridContentOuter:{
        // minHeight: '100%',
        marginTop: '-100px',
        paddingTop:'100px'},
        flexGrow:1,
    gridForm: {
        paddingRight: '20px',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        // height: '95%',
        margin:'20'
    },
    paperHidden: {
        padding: theme.spacing.unit * 2,
        // height: '95%',
        margin:'20',
        display:'none',
    },

    control: {
        padding: theme.spacing.unit * 2,
    },
    textField: {
        marginLeft: '50',
        marginRight: '50',
        paddingTop: '50',
        paddingBottom: '50',
        width: 200,
    },
    tabLink: {
        float: 'left',
        marginLeft: '10px',
        marginRight: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    spacedCard: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    rightSpacedButton: {
        marginRight: '16px',
    },
    photoPaper:{
        marginTop: '10px',
        marginBottom: '10px',
    },

    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: theme.zIndex.grid,
    },
    drawerPaper: {
        width: drawerWidth,
        padding: '0px',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit ,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight:0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    userConnectedList: {
        color: '#0EC00E'
    },
    userDisconnectedList: {
        color: '#fff'
    },
    userSecondaryText: {
        color: '#DCDCDC',
        fontStyle: 'italic',
    },
    chatMain: {
        height:'100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor:'#4B4A61',
    },
    chatAvatar:{
        width: 36,
        height: 36,
    },
    onlineChatAvatar:{
        width: 36,
        height: 36,
        backgroundColor: '#0EC00E',
    },
    chatTitle:{
        color: '#FFF',
        textAlign: 'center',
    },
    searchChatUser:{
        color: 'white',
    },
    chatSearchDiv:{
        textAlign: 'center',
    },
    chatCompressedList:{
        height:'20vh',
        flex: '0 0',
        marginBottom: '20px',
    },
    divFlexGrowScroll:{
        flex: '1 1 auto',
        overflow: 'auto'
    },
    chatMessageList:{
        height: 'calc(80vh - 32px)',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    chatMessageUser:{
        textAlign: 'center',
    },
    chatMsgTitle:{
        color: '#FFF',
        backgroundColor: '#0EC00E',
        textAlign: 'center',
    },
    chatMsgTitleOffline:{
        color: '#000',
        backgroundColor: 'lightgrey',
        textAlign: 'center',
    },
    chatMessageBackground:{
        backgroundColor: '#5C5766',
        margin: '10px 10px 10px 10px',
        flex: '1 1 auto',
        overflow:'auto',
    },
    divChatMessageForm:{
        Height: '110px',
        minHeight: '110px',
        backgroundColor:'#4B4A61',
        display: 'flex',
    },
    divChatMessageInput:{
        backgroundColor:'#4B4A61',
        flex: '1 1 auto',
        padding: '10px 10px 10px 10px',
        alignSelf: 'center'
    },
    divChatMessageSend:{
        display: 'flex',
        alignItems: 'center',
        paddingRight: '10px'
    },
    divSentMessages:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '0px 10px 2px 20%',
        margin: '20px 0px 0px 0px',
    },
    divRecdMessages:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0px 20% 2px 10px',
        margin: '20px 0px 0px 0px',
            },
    divmsgDt:{
        color: 'white',
    },
    divmsgDtS:{
        color: 'white',
        textAlign:'right',
    },
    messageText:{
        padding: '6px 6px 6px 6px',
        background: 'lightGrey',
        borderRadius: '6px',
    },
    chatMessageInput:{
        color: 'black',
        background: 'white',
    }
});

