import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_PMI = 'FETCH_PMI';
export const ADD_PMI = 'ADD_PMI';
export const EDIT_PMI = 'EDIT_PMI';
export const PMI_IS_LOADING = 'PMI_IS_LOADING';
export const PMI_IS_ERROR = 'PMI_IS_ERROR';


export function getPatientMedicalInfo(patient_id){
    return (dispatch) => {
        dispatch({type: PMI_IS_LOADING, bool :true});
        dispatch({type: PMI_IS_ERROR, bool:false});
        const url = `${API_URL}patient/getMedicalInfo`;
        let token= localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PMI_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PMI, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: PMI_IS_ERROR, bool:true});
            dispatch({type: PMI_IS_LOADING, bool: false});
        });
    }
}

export function setPatientMedicalInfo(medical){
    return (dispatch) => {
        dispatch({type: PMI_IS_LOADING, bool :true});
        dispatch({type: PMI_IS_ERROR, bool:false});
        const url=`${API_URL}patient/setMedicalInfo`;
        let token= localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,medical, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PMI_IS_LOADING, bool: false});
            if(response.data.Success){
                // dispatch({type: ADD_PMI, medical_id: response.data.medical_id});
                dispatch({type: FETCH_PMI, payload: response.data.answer});
            } else {
                dispatch({type: PMI_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PMI_IS_ERROR, bool:true});
            dispatch({type: PMI_IS_LOADING, bool: false});
        });
    }
}

