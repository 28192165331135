import React ,{Component} from 'react';
import {Grid} from '@material-ui/core';


export default class mainVideo extends Component {

    componentDidUpdate(prevProps, prevState){
        if(this.props.participant!==prevProps.participant){
            let participant=this.props.participant;
            if(typeof participant === 'undefined' || participant===null){
                return (<div></div>);
            }
            let tracks = Array.from(participant.tracks.values());
            let windowHeight=window.innerHeight-200;
            let container = this.props.vidRef.current;
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
            let positionInfo = container.getBoundingClientRect();
            let width = positionInfo.width;
            let height = this.props.height;
            if(typeof height ==='undefined'){
                if(positionInfo.height>0){
                    height = positionInfo.height;
                } else {
                    height = windowHeight*2/3;
                }
                
            }
            tracks.forEach(publication => {
                let track = publication.track;
                let vid =container.appendChild(track.attach());
                if(track.kind==='video'){
                    if(track.dimensions.height<height){
                        vid.style.height=`${height}px`;
                    }
                }
                vid.style.maxWidth=`${width}px`;
                if(height>0){
                    vid.style.maxHeight=`${height}px`;
                }
            });
        }
        
    }

    render() {
        
        return(
            <div ref={this.props.vidRef} />     
        );
    }
}
