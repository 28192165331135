import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchLpDetail, editLp, addLp} from '../actions/Lps';
import {Field, reduxForm, } from 'redux-form';
import {renderTextField, renderCheckBox} from '../Components/Forms';


class LpDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            lp_id_inuse: 0,
            lp_id:0,
            name: "",
            ranking: 0,
            mandatoryCapable: 0,
            cbdOnlyCapable: 0,
            deleteDialogOpen: false,
        };
        this.handleChange=this.handleChange.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
    }

    componentDidMount() {
        if(this.props.match.params.lp_id>0){
            this.props.fetchLpDetail({lp_id:this.props.match.params.lp_id});
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.lp!==prevProps.lp){
            this.setState({
                lp_id: this.props.lp.lp_id,
                name: this.props.lp.name,
                ranking: this.props.lp.ranking,
                mandatoryCapable: parseInt(this.props.lp.mandatoryCapable,10),
                cbdOnlyCapable: parseInt(this.props.lp.cbdOnlyCapable,10),
            });
            const formData = {
                "lp_id": this.props.lp.lp_id,
                "name": this.props.lp.name,
               "ranking": this.props.lp.ranking,
                "mandatoryCapable" : parseInt(this.props.lp.mandatoryCapable,10),
                "cbdOnlyCapable" : parseInt(this.props.lp.cbdOnlyCapable,10),
            };
            this.props.initialize(formData);
            if(this.props.lp.iAmNew){
                this.props.history.push(`/lp/${this.props.lp.lp_id}`);
            }
        }
        if(this.props.location!==prevProps.location){
            this.props.fetchLpDetail({lp_id:this.props.match.params.lp_id});
        }

    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({lp_id_inuse : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onSubmit (values){
        var lp={};
        if(this.props.match.params.lp_id>0){
            lp = {
                lp_id: this.props.match.params.lp_id,
                name : values.name,
                ranking: values.ranking,
                mandatoryCapable: values.mandatoryCapable,
                cbdOnlyCapable: values.cbdOnlyCapable,
            }
            this.props.editLp(lp);
        } else {
            lp = {
                name : values.name,
                ranking: values.ranking,
                mandatoryCapable: values.mandatoryCapable,
                cbdOnlyCapable: values.cbdOnlyCapable,
            }
            this.props.addLp(lp);
        }
    }


    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        const delete_id = {lp_id: id};
        this.props.deleteLp(delete_id);
    }


    //Render Functions

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete Lp ${this.state.lp_id_inuse} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.lp_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/lps')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/lps')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };




    render(){
        const { handleSubmit } = this.props;
        return(<div style={{width:'100%'}}>
                <Typography variant="h5" color="inherit"><u>LP</u></Typography>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div>
                        <Field
                            autoFocus
                            name= 'name'
                            component={renderTextField}
                            label= 'Name'
                            margin='normal'
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <Field
                            name= 'ranking'
                            component={renderTextField}
                            label= 'Ranking'
                            margin='normal'
                            onChange={this.handleChange}
                            multiline
                            fullWidth
                        />
                    </div>
                    <div>
                        <Field
                            name= 'mandatoryCapable'
                            component={renderCheckBox}
                            label= 'Can take Mandatory Prescriptions'
                            margin='normal'
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </div>
                    <div>
                        <Field
                            name= 'cbdOnlyCapable'
                            component={renderCheckBox}
                            label= 'Offers CBD Only Products'
                            margin='normal'
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </div>
                    <div>
                        <Button type="submit" color='secondary' variant='contained'>
                            Save
                        </Button>
                    </div>
                </form>
                {this.renderDeleteDialog()}
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
            </div>
        );
    }
}



const validate = values => {
    const errors = {}
    const requiredFields = [
        'name',
        'ranking',
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })

    return errors
}

function mapStateToProps(state){
    return(
        {
            lp: state.lp,
            isLoading: state.lpIsLoading,
            isError: state.lpIsError
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchLpDetail, editLp, addLp}, dispatch);
}


export default reduxForm({
    validate,
    form: 'editLpForm'
})(connect(mapStateToProps,mapDispatchToProps)(LpDetail));

