import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'

export const FETCH_P_DETAIL = 'FETCH_P_DETAIL';
export const ADD_PD = 'ADD_PD';
export const EDIT_PD = 'EDIT_PD';
export const PD_IS_LOADING = 'PD_IS_LOADING';
export const PD_IS_ERROR = 'PD_IS_ERROR';
export const PD_NEEDS_REFRESH = 'PD_NEEDS_REFRESH';


export function fetchPractitionerDetail(user_id){
    return (dispatch) => {
        dispatch({type: PD_IS_LOADING, bool :true});
        dispatch({type: PD_IS_ERROR, bool:false});
        dispatch({type: PD_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}practitioner_detail/fetch?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const user = {user_id:user_id};
        const request = axios.post(url,user,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PD_IS_LOADING, bool: false});
            dispatch({type: PD_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_P_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: PD_IS_ERROR, bool:true});
            dispatch({type: PD_IS_LOADING, bool: false});
            dispatch({type: PD_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addPractitionerDetail(pd){
    return (dispatch) => {
        dispatch({type: PD_IS_LOADING, bool :true});
        dispatch({type: PD_IS_ERROR, bool:false});
        dispatch({type: PD_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}practitioner_detail/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,pd, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_PD, dp_id: response.data.user_id})
                dispatch({type: PD_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: PD_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PD_IS_ERROR, bool:true});
            dispatch({type: PD_IS_LOADING, bool: false});
        });
    }
}

export function deletePractitionerDetail(user_id){
    return (dispatch) => {
        dispatch({type: PD_IS_LOADING, bool :true});
        dispatch({type: PD_IS_ERROR, bool:false});
        dispatch({type: PD_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}practitioner_detail/delete?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const user = {user_id:user_id};
        const request = axios.post(url,user,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PD_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: PD_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PD_IS_ERROR, bool:true});
            dispatch({type: PD_IS_LOADING, bool: false});
        });
    }
}

export function editPractitionerDetail(pd){
    return (dispatch) => {
        dispatch({type: PD_IS_LOADING, bool :true});
        dispatch({type: PD_IS_ERROR, bool:false});
        dispatch({type: PD_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}practitioner_detail/edit?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,pd, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PD_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PD_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: PD_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PD_IS_ERROR, bool:true});
            dispatch({type: PD_IS_LOADING, bool: false});
        });
    }
}
