import {renderTextField} from "../Components/Forms";
import React, {Component } from 'react';
import {Field, reduxForm} from 'redux-form';
import {
    Button,
    Dialog,  DialogContent, DialogTitle,
} from '@material-ui/core';

class EditAppointmentNotes extends Component {

    constructor(props) {
        super(props);
        this.state={
            notes:'',
        };
        this.handleClose=this.handleClose.bind(this);
        this.handleChange=this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({notes:this.props.notes});
    }

    componentDidUpdate(prevProps,prevState){
        if(this.props.notes!==prevProps.notes){
            this.setState({notes:this.props.notes});
            let formData = {notes:this.props.notes};
            this.props.initialize(formData);
        }
    }

    noValidate(value) {
        return  undefined ;
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleClose(){
        this.props.handleClose();
    }

    Submit(values){
        this.props.handleFormSubmit(values);
    }

    render() {
        const {handleSubmit,openState} = this.props;
        return (
            <Dialog
                open={openState}
                onClose={this.handleClose}
            >
                <DialogTitle>Edit Notes</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(this.Submit.bind(this))}>
                        <Field
                            name='notes'
                            component={renderTextField}
                            label='Notes'
                            margin='normal'
                            onChange={this.handleChange}
                            InputProps={{
                                disableUnderline: true,
                                style: {border: '1px solid #ced4da'}
                            }}
                            multiline
                            rows='4'
                            fullWidth
                            validate={[this.noValidate]}
                        />
                        <Button onClick={this.handleClose} variant='contained'>
                            Exit
                        </Button>
                        <Button type="submit" color='secondary' variant='contained'>
                            Update Notes
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}
export default reduxForm({form:'editAppointmentNotes'})(EditAppointmentNotes);
