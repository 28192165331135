import React ,{Component} from 'react';
import PractitionerScheduleList from '../../containers/practitionerScheduleList';
import {Grid, Paper} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../style';




class PractitionerSchedules extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.gridPageOuter}>
                <Grid item xs={12}>
                    <Grid item className={classes.gridContentOuter}>
                        <Paper className={classes.paper}>
                            <PractitionerScheduleList {...this.props}/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(PractitionerSchedules);
