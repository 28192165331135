import React, {Component} from 'react';
import UserDetail from '../../containers/user-detail';
import UserRole from '../../containers/user-role';
import {Grid, Paper} from '@material-ui/core';
import {Footer} from '../Layouts';
import {updatePage} from '../../actions/Common';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../style';


class UserDetails extends Component {
    componentDidMount() {
        this.props.updatePage('user');
    }
    render() {
        const { classes } = this.props;

        return (
            <Grid container className={classes.gridPageOuter}>
                <Grid item xs={12}>
                    <Grid item className={classes.gridContentOuter}>
                        <Grid container alignItems='center' justify='center' direction='row'>
                            <Grid item xs={12} >
                                <Paper className={classes.paper}>
                                    <Grid container justify='space-around' direction='row'>
                                        <Grid item>
                                            <UserDetail {...this.props}/>
                                        </Grid>
                                        <Grid item style={{minWidth:'200px', maxWidth:'300px', width:'100%'}}>
                                            <UserRole {...this.props} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} className={classes.gridFooter}>
                        <Footer/>
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({updatePage}, dispatch);
}
export default connect(null,mapDispatchToProps)(withStyles(styles)(UserDetails));



