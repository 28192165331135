import React, { Component, Fragment } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem} from '@material-ui/core';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {logoutUser} from "../../actions/Users";
import jwtDecode from 'jwt-decode';
import {withRouter} from 'react-router-dom';
import {toggleChatWindow} from '../../actions/Common';
import ChatIcon from '@material-ui/icons/Chat';
import CloseChatIcon from '@material-ui/icons/ChatBubbleOutline';

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {
            menuSelected: false,
            anchorEl: null,
            profileMenuAnchor: null,
            reportMenuAnchor: null,
            idleTimer: null,
        };
        this.credentialCheck=this.credentialCheck.bind(this);
        this.Logout=this.Logout.bind(this);
    }

    componentDidMount(){
        //this.checkAuthenticated();
        this.interval = setInterval(this.credentialCheck,600000);
    }

    componentDidUpdate(prevProps, prevState) {

        //check to see if page has changed and is not the login page
        if(this.props.currentPage!==prevProps.currentPage && this.props.currentPage!=='/'){
            //check to see if we have credentials
            if(localStorage.getItem('token')>''){
                //check to see if the page matches one of the roles in the token
                if(!this.checkAuthenticated()){
                    this.props.history.push('/');
                }
            } else {
                //if no credentials push back to login page
                this.props.history.push('/');
            }
        }

        if(this.props.credentials.token){
            if(!prevProps.credentials.token){
                //check to see if the page matches one of the roles in the token
                if(!this.checkAuthenticated()){
                    this.props.history.push('/');
                }
            } else {
                if(prevProps.credentials.token!==this.props.credentials.token){
                    //New token received need to recheck authentication if roles have changed
                    if(this.props.credentials.details.roles===prevProps.credentials.details.roles){
                        // check to see if the page matches one of the roles in the token
                        if(!this.checkAuthenticated()){
                            this.props.history.push('/');
                        }
                    }
                }
            }
        }
    }

    checkAuthenticated(){
        const justAuth=[];
        justAuth.push('/changePassword');
        //check to see if we are at the homepage
        if(this.props.location.pathname!=='/'){
            //check to see if we have a token
            if(localStorage.getItem('token')>''){
                var jwt = jwtDecode(localStorage.getItem('token'));
                var ct = new Date().getTime();
                var curTime = Math.floor(ct/1000);
                //check to see if the token hasn't expired
                if(curTime<jwt.exp){
                    //check to see if current page is in allowed pages
                    if(jwt.roles.indexOf(this.props.currentPage)>-1 || justAuth.indexOf(this.props.currentPage)>-1){
                        //we are on an authorised page
                        return true;
                    } else {
                        console.log("Role not found=" + this.props.currentPage);
                        return false;
                    }
                } else {
                    console.log("Token Expired");
                    return false;
                }
            } else {
                console.log("No Token");
                return false;
            }
        } else {
            return true;
        }
    }

    credentialCheck(){
        if(!this.checkAuthenticated()){
            this.Logout();
        }
    }

    Logout(){
        if(this.props.showChatWindow){
            this.props.toggleChatWindow();
        }
        if(!this.isExpired(jwtDecode(localStorage.getItem('token')))){
            this.props.logoutUser();
        }
        this.props.history.push('/');
    }

    isExpired(jwt){
        var ct = new Date().getTime();
        var curTime = Math.floor(ct/1000);
        //check to see if the token hasn't expired
        if(curTime<jwt.exp){
            return false;
        } else {
            return true;
        }
    }

    handleMenuSelect = (event, value) => {
        this.setState({ anchorEl: null, profileMenuAnchor: null, reportMenuAnchor: null });
        if(value==='/logout'){
            this.Logout();
        } else {
            this.props.history.push(value);
        }
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };



    handleProfileMenuClick = event => {
        this.setState({ profileMenuAnchor: event.currentTarget });
    };

    handleProfileMenuClose = () => {
        this.setState({ profileMenuAnchor: null });
    };

    handleReportMenuClick = event => {
        this.setState({ reportMenuAnchor: event.currentTarget });
    };

    handleReportMenuClose = () => {
        this.setState({ reportMenuAnchor: null });
    };

    renderMenu() {
        //if we have a token in credentials then store it.
        if(this.props.credentials.token){
           localStorage.setItem('token', this.props.credentials.token);
        }
        if(!this.props.credentials.details){
            return(<Button onClick={()=>{this.props.history.push('/login')}}>LOGIN</Button>);
        } else {
            var roles= [];
            if(localStorage.getItem('token')>''){
                var jwt = jwtDecode(localStorage.getItem('token'));
                if(!this.isExpired(jwt)) {
                    roles = jwt.roles;
                }
            }
            if(!roles){
                roles=[];
            }
            var menuArray = [];
            var menuProfile = [];
            var menuReport = [];

            if(roles.indexOf('ailment')>-1){
                menuArray.push({title:'AILMENTS', link:'/ailments'});
            }
            if(roles.indexOf('user')>-1){
                menuArray.push({title:'USERS', link:'/users'});
            }
            if(roles.indexOf('status')>-1){
                menuArray.push({title:'STATUS', link:'/statuses'});
                menuArray.push({title:'CANCEL REASONS', link:'/cancel_reasons'});
            }
            if(roles.indexOf('lp')>-1){
                menuArray.push({title:'LP', link:'/lps'});
            }
            if(roles.indexOf('delivery_type')>-1){
                menuArray.push({title:'DELIVERY METHODS', link:'/delivery_types'});
            }

            if(roles.indexOf('editPractitioner')>=1){
                menuArray.push({title:'PRACTITIONERS', link:'/practitioners'});
            }

            if(roles.indexOf('practitioner'>=-1)){
                menuProfile.push({title:'MY DETAILS', link:'/practitioner/'+jwt.user_id});
                menuProfile.push({title:'MY SCHEDULES', link:'/schedule/'});
                menuProfile.push({title:'MY CALENDAR', link:'/practitionercalendar/'});
            }
            if(roles.indexOf('viewSchedules'>=1)){
                menuProfile.push({title: 'ALL SCHEDULES', link:'/dailyappointments'});
            }
            menuProfile.push({title:'CHANGE PASSWORD', link:'/changePassword'});
            menuProfile.push({title:'LOGOUT', link:'/logout'});

            menuReport.push({title:'EXPIRING PRESCRIPTIONS', link:'/expiring'});
            menuReport.push({title:'PATIENT WAITING FOR VIDEO', link:'/waiting'});

            const { anchorEl, profileMenuAnchor, reportMenuAnchor } = this.state;

            return(
                <Fragment>
                    <Button color='secondary' onClick={event => this.handleMenuSelect(event,'/patients')}> PATIENTS </Button>
                    <Button onClick={this.handleClick} color='secondary'>ADMIN</Button>
                            <Menu
                                id="admin-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                        {this.renderMenuItems(menuArray)}
                            </Menu>
                    <Button onClick={this.handleReportMenuClick} color='secondary'>REPORTS</Button>
                    <Menu
                        id="report-menu"
                        anchorEl={reportMenuAnchor}
                        open={Boolean(reportMenuAnchor)}
                        onClose={this.handleReportMenuClose}
                    >
                        {this.renderMenuItems(menuReport)}
                    </Menu>
                    <Button onClick={this.handleProfileMenuClick} color='secondary'>{this.props.credentials.details.firstname}.</Button>
                    <Menu
                        id="profile-menu"
                        anchorEl={profileMenuAnchor}
                        open={Boolean(profileMenuAnchor)}
                        onClose={this.handleProfileMenuClose}
                    >
                        {this.renderMenuItems(menuProfile)}
                    </Menu>
                </Fragment>
            );
        }
    }


    renderMenuItems(menuitems){
        return(
            menuitems.map((mi) => {
                // <Tab key={mi.title} label={mi.title} component={Link} to={mi.link}/>
                return (
                    <MenuItem key={mi.title} onClick={event => this.handleMenuSelect(event,mi.link)}>{mi.title}</MenuItem>
                );
            })
        )
    }

    render() {
        console.log("Header got unread Messages", this.props.unreadMsgs)
        return (
            <AppBar position="static">
                <Toolbar>

                    <Typography variant="h5" color="inherit">
                        Cannenta Clinic
                    </Typography>
                    {this.renderMenu()}

                    <div style={{flexGrow:1}}/>
                    <div style={{display:'flex'}}>
                        {this.props.credentials.details &&
                        <Button onClick={this.props.toggleChatWindow} color="secondary">
                            {!this.props.showChatWindow &&
                                <Fragment><ChatIcon/>{this.props.unreadMsgs} Unread</Fragment>
                            }
                            {this.props.showChatWindow &&
                            <CloseChatIcon/>
                            }
                        </Button>
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

}


function mapStateToProps(state){
    return(
        {
            currentPage: state.page,
            credentials: state.credentials,
            showChatWindow: state.showChatWindow,
            chatToken: state.chatToken,
            unreadMsgs: state.unreadMsgs,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({logoutUser,toggleChatWindow}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
