import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TablePagination,
    TableFooter,
    TableSortLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {bindActionCreators} from 'redux';
import {fetchLps as fetchData, deleteLp as deleteData} from '../actions/Lps';
import {updatePage} from '../actions/Common';


class LpList extends Component{

    constructor(props){
        super(props);

        this.state= {
            addNewDialogOpen: false,
            deleteDialogOpen: false,
            editDialogOpen: false,
            data_id_inuse: 0,
            listLimit: 5,
            listOffset: 0,
            sortField: 'name',
            sortDirection: 'asc',
            qryParamsChange: false,
            pageNo: 0,
            total: 1,
            data: {
                lp_id:0,
                name: "",
                ranking: 0,
            },
            keyField: 'lp_id',
            editPath: 'lp',
            columns: [
                {field:'lp_id', label:'id', align: 'right'},
                {field:'name', label:'Name', align: 'left'},
                {field:'ranking', label:'ranking', align: 'left'}
            ],
        };

        this.onRefreshClick = this.onRefreshClick.bind(this);
        this.onEditDialogOpen = this.onEditDialogOpen.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.onLimitChange = this.onLimitChange.bind(this);
        this.onOffsetChange = this.onOffsetChange.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.onSortDirectionChange = this.onSortDirectionChange.bind(this);
        this.handleFirstPageButtonClick = this.handleFirstPageButtonClick.bind(this);
        this.handleNextButtonClick=this.handleNextButtonClick.bind(this);
        this.handleBackButtonClick=this.handleBackButtonClick.bind(this);
        this.handleLastPageButtonClick=this.handleLastPageButtonClick.bind(this);
        this.renderTableFooter=this.renderTableFooter.bind(this);
        this.renderTitleCells=this.renderTitleCells.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath)
        this.props.fetchData({offset:0,limit:5,sortField:this.state.sortField, sortDirection:this.state.sortDirection});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.needsRefresh) {
            this.props.fetchData({offset: this.props.data.offset, limit: this.props.data.limit,
            sortField:this.props.data.sortField, sortDirection:this.props.data.sortDirection});
        }
        if(this.state.qryParamsChange){
            this.setState({qryParamsChange: false});
            this.props.fetchData({offset: this.state.listOffset, limit:this.state.listLimit,
            sortField: this.state.sortField, sortDirection: this.state.sortDirection});
        }
        if(this.props.data !== prevProps.data){
            if(typeof this.props.data.total!=='undefined'){
                this.setState({total: parseInt(this.props.data.total,10)});
            }
        }
    }


    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }



    //Dialog Functions

    onRefreshClick(event){
        this.props.fetchData({offset: this.state.listOffset, limit:this.state.listLimit,sortField:this.state.sortField, sortDirection:this.state.sortDirection});
    }

    onEditDialogOpen(key) {
        this.props.history.push(`/${this.state.editPath}/${key}`);
    }


    onDeleteDialogOpen(id) {
        this.setState({data_id_inuse : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };


    //Action Functions

    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        this.props.deleteData(id);
    }

    onLimitChange(no){
        this.setState({ listLimit: no, listOffset: 0 }); //if we change page size go back to start of list
        this.setState({qryParamsChange: true});
    }

    onOffsetChange(no){
        this.setState({ listOffset: no });
        this.setState({qryParamsChange: true});
    }

    onSortDirectionChange = property => event => {
        if(property===this.state.sortField){
            const newDirection = this.state.sortDirection==='asc' ? 'desc' : 'asc';
            this.setState({sortDirection : newDirection});
        } else {
            this.setState({sortField : property});
            this.setState({sortDirection:'asc'});
        }
        this.setState({qryParamsChange: true});
    }

    handleFirstPageButtonClick = event =>{
        this.setState({pageNo:0});
        this.onOffsetChange(0);
    }

    handleBackButtonClick = event => {
        const newPage = this.state.pageNo -1;
        this.setState({pageNo:newPage});
        const newOffset=this.state.listLimit*newPage ;
        this.onOffsetChange(newOffset);
    };

    handleNextButtonClick = event => {
        const newPage = this.state.pageNo +1;
        this.setState({pageNo:newPage});
        const newOffset=this.state.listLimit*newPage ;
        this.onOffsetChange(newOffset);
    };

    handleLastPageButtonClick = event => {
        const page=Math.max(0, Math.ceil(this.state.total / this.state.listLimit) - 1)
        this.setState({pageNo: page });
        const newOffset=this.state.listLimit*page ;
        this.onOffsetChange(newOffset);
    };


    handleChangePage(event, page){
        const newOffset=this.state.listLimit*page ;
        this.setState({pageNo:page})
        this.onOffsetChange(newOffset);
    }

    handleChangeRowsPerPage(event){
        this.onLimitChange(event.target.value);
    }


    //Render Functions

    onTextFieldChange = item => ({target : {value}}) => {
        this.setState({
            data : {
                ...this.state.data,
                [item]:value
            }
        });

    }

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete ${this.state.data_id_inuse} ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.data_id_inuse)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList( key){
        if(typeof this.props.data.dataset === 'undefined'){
            return(<TableRow></TableRow>);
        }
        return (
            this.props.data.dataset.map((data)=>{
                return (
                    <TableRow key={data[key]}>
                        {this.renderRowDetail(data)}
                        <TableCell>
                            <IconButton aria-label="Delete" onClick={() => {this.onDeleteDialogOpen(data[key])}}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton aria-label="Edit" onClick={() => {this.onEditDialogOpen(data[key])}}>
                                <CreateIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            })
        )
    }

    renderRowDetail(data){
        return(
            this.state.columns.map((col)=>{
                const value= data[col.field] ? data[col.field].substr(0,50): data[col.field];
                return(
                    <TableCell key={col.field}  align={col.align}>{value}</TableCell>
                )
            })
        )

    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderTitleCells(){
        return (
            this.state.columns.map((column) => {
                return (
                    <TableCell key={column.field}><TableSortLabel
                        active={this.state.sortField===column.field}
                        direction={this.state.sortDirection}
                        onClick={this.onSortDirectionChange(column.field)}
                    >{column.label}</TableSortLabel></TableCell>
                );
            })
        )
    }

    renderTableFooter(){
        return (
            <div style={{width:'100%'}}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={this.state.pageNo === 0}
                    aria-label="First Page"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={this.state.pageNo === 0}
                    aria-label="Previous Page"
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={this.state.pageNo >= Math.ceil(this.state.total / this.state.listLimit) - 1}
                    aria-label="Next Page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={this.state.pageNo >= Math.ceil(this.state.total / this.state.listLimit) - 1}
                    aria-label="Last Page"
                >
                    <LastPageIcon />
                </IconButton>
            </div>
        )
    }

    render(){


        return(<div>
                <Table>
                    <TableHead>
                        <TableRow>
                            {this.renderTitleCells()}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderList( this.state.keyField)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={this.state.columns.count}
                                count={this.state.total}
                                rowsPerPage={this.state.listLimit}
                                rowsPerPageOptions={[5,10,20,50]}
                                page={this.state.pageNo}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                Actions={this.renderTableFooter}
                            />
                        </TableRow>
                    </TableFooter>
                </Table><br/>
                <Button variant='contained' color="primary" onClick={this.onRefreshClick}>Refresh</Button>
                <Button variant='contained' onClick={() => {this.props.history.push(`/${this.state.editPath}/0`);}}>Add New</Button>
                {this.renderDeleteDialog()}
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
            </div>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.lps,
            isLoading: state.lpIsLoading,
            isError: state.lpIsError,
            needsRefresh: state.lpNeedsRefresh
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchData, deleteData, updatePage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LpList);
