import axios from 'axios';
import {API_URL, UPDATE_TOKEN} from '../../constants_api'
import {APPTS_NEEDS_REFRESH} from '../Appointments'

export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';
export const SCHEDULES_IS_LOADING = 'SCHEDULES_IS_LOADING';
export const SCHEDULES_IS_ERROR = 'SCHEDULES_IS_ERROR';
export const SCHEDULES_NEEDS_REFRESH = 'SCHEDULES_NEEDS_REFRESH';

export function fetchPractitionerSchedules(params){
    return (dispatch) => {
        dispatch({type: SCHEDULES_IS_LOADING, bool :true});
        dispatch({type: SCHEDULES_IS_ERROR, bool:false});
        dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}appointment/getPractitionerSchedules?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: SCHEDULES_IS_LOADING, bool: false});
            dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_SCHEDULES, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: SCHEDULES_IS_ERROR, bool:true});
            dispatch({type: SCHEDULES_IS_LOADING, bool: false});
            dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: false});
        });
    }
}


export function addPractitionerSchedule(schedule){
    return (dispatch) => {
        dispatch({type: SCHEDULES_IS_LOADING, bool :true});
        dispatch({type: SCHEDULES_IS_ERROR, bool:false});
        dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}appointment/addPractitionerSchedule?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,schedule, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: SCHEDULES_IS_LOADING, bool: false});
            if(response.data.Success){
               dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: SCHEDULES_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: SCHEDULES_IS_ERROR, bool:true});
            dispatch({type: SCHEDULES_IS_LOADING, bool: false});
        });
    }
}

export function deletePractitionerSchedule(params){
    return (dispatch) => {
        dispatch({type: SCHEDULES_IS_LOADING, bool :true});
        dispatch({type: SCHEDULES_IS_ERROR, bool:false});
        dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}appointment/deletePractitionerSchedule?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: SCHEDULES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: SCHEDULES_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: SCHEDULES_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: SCHEDULES_IS_ERROR, bool:true});
            dispatch({type: SCHEDULES_IS_LOADING, bool: false});
        });
    }
}

export function applySchedule(params){
    return (dispatch) => {
        const url=`${API_URL}appointment/setWeekSchedule?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,params,header);
        request.then((response) => {
            if(response.data.Success){
                dispatch({type: APPTS_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
        });
    }
}