import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Typography,
    Paper,
    Button,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {getRooms} from '../actions/Video';
import {
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid as TGrid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {updatePage} from '../actions/Common';
import TableZoomSelect from '../Components/Table/cell';
import jwtDecode from "jwt-decode";


class RoomList extends Component{

    constructor(props){
        super(props);

        this.state= {
            editPath: 'patient',
            rows:[],
            columns: [
                {name: 'patientName', title: 'Patient'},
                {name: 'duration', title: 'Duration'},
                {name: 'appt_dt', title: 'Appointment Date'},
                {name: 'practitionerName', title: 'Practitioner'},
                {name: 'participant_id', title: 'Select'}
            ],
        };
        this.Cell=this.Cell.bind(this);
        this.goTo=this.goTo.bind(this);

    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        this.props.getRooms();
        this.intervalCommand = setInterval(()=>{this.props.getRooms()},5000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalCommand);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.data!==prevProps.data){
            if(this.props.data===null){
                this.setState({rows:[]});
            } else{
                this.setState({rows:this.props.data});
            }
        }
    }

    goTo(link){
        this.props.history.push(`/patient/${link}`);
    }

    Cell = (props) => {
        const { column } = props;
        if (column.name === 'participant_id') {
            return (
                <Table.Cell {...props}>
                    <TableZoomSelect tooltip={'Select'} onClick={this.goTo} idRef={props.row.participant_id}/>
                </Table.Cell>
            );
        }

        return <Table.Cell {...props} />;
    }

    render(){
        const { rows, columns } = this.state;
        return(
            <Paper>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h6' align='center'>Patients waiting for Video</Typography>
                    </Grid>

                    <Grid item xs={12}>

                        <TGrid
                            rows={rows}
                            columns={columns}
                        >
                            <PagingState
                                defaultCurrentPage={0}
                                pageSize={6}
                            />
                            <IntegratedPaging />

                            <Table
                                cellComponent={this.Cell}
                            />
                            <TableHeaderRow />
                            <PagingPanel />
                        </TGrid>
                        <Button variant='contained' color="primary" onClick={this.props.getRooms}>Refresh</Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.rooms,
            isLoading: state.loadingRooms,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getRooms, updatePage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomList);
