import React, {Component, } from 'react';
import {connect} from 'react-redux';
import {List, ListItem, ListItemText,ListItemSecondaryAction,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Switch
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchLpMethods, addLpMethod, deleteLpMethod} from '../actions/Lps';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../style';



class LpMethod extends Component{
    constructor(props){
        super(props);

        this.state= {
            lp_methods:[]
        };
    }

    componentDidMount() {
        this.props.fetchLpMethods({lp_id:this.props.match.params.lp_id});
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.lp_methods!==prevProps.lp_methods){
            this.setState({lp_methods:this.props.lp_methods});
        }
        if(this.props.needsRefresh){
            this.props.fetchLpMethods({lp_id:this.props.match.params.lp_id});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    //Dialog Functions


    //Action Functions

    switchMethod = delivery_id => event =>{
        if(event.target.checked){
            this.props.addLpMethod({lp_id: this.props.match.params.lp_id, delivery_id:delivery_id});
        } else {
            this.props.deleteLpMethod({lp_id: this.props.match.params.lp_id, delivery_id:delivery_id})
        }

    }

    //Render Functions

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/lps')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/lps')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderList(){
        if(this.state.lp_methods.length){
            return (
            this.state.lp_methods.map((value) => {
                return(
                    <ListItem key={value.delivery_id} button>
                        <ListItemText primary={value.name}/>
                        <ListItemSecondaryAction>
                            <Switch
                                onChange={this.switchMethod(value.delivery_id)}
                                checked={value.enabled==="1"}
                                color='primary'
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                )
                }))
        }
    }


    render(){
        if(this.props.match.params.lp_id<1){
            return(<div></div>);
        }

        return(<div style={{width:'100%', maxWidth:'300px'}}>
                <Typography align="center" variant="h5" color="inherit"><u>Delivery Methods</u></Typography>
                <List>
                {this.renderList()}
                </List>
                {this.renderRefreshDialog()}
                {this.renderErrorDialog()}
            </div>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            lp_methods: state.lpMethods,
            isLoading: state.lpMethodIsLoading,
            isError: state.lpMethodIsError,
            needsRefresh: state.lpMethodNeedsRefresh
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchLpMethods, addLpMethod, deleteLpMethod}, dispatch);
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LpMethod));


