import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid,
    } from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {getPatientFollowup,setPatientFollowup} from '../actions/PatientFollowup';
import {Field, reduxForm, } from 'redux-form';
import {renderTextField, } from '../Components/Forms';



class PatientFollowup extends Component{

    constructor(props){
        super(props);

        this.state= {
            patient_id:0,
            q1:'',
            q2:'',
            q3:'',
            q4:'',
            q5:'',
            q6:'',
            q7:'',
            q8:'',
            q9:'',
            q10:'',

        };
        this.handleChange=this.handleChange.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if(this.props.patient_id>0){
            this.props.getPatientFollowup({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
    }

    componentDidUpdate(prevProps, prevState){
       if(this.props.followup!==prevProps.followup){
                this.setState({
                    patient_id: this.props.patient_id,
                    q1:this.props.followup.q1,
                    q2:this.props.followup.q2,
                    q3:this.props.followup.q3,
                    q4:this.props.followup.q4,
                    q5:this.props.followup.q5,
                    q6:this.props.followup.q6,
                    q7:this.props.followup.q7,
                    q8:this.props.followup.q8,
                    q9:this.props.followup.q9,
                    q10:this.props.followup.q10,
                });
                const formData = {
                    'q1':this.props.followup.q1,
                    'q2':this.props.followup.q2,
                    'q3':this.props.followup.q3,
                    'q4':this.props.followup.q4,
                    'q5':this.props.followup.q5,
                    'q6':this.props.followup.q6,
                    'q7':this.props.followup.q7,
                    'q8':this.props.followup.q8,
                    'q9':this.props.followup.q9,
                    'q10':this.props.followup.q10,
                };
                this.props.initialize(formData);
        }
        if(this.props.location!==prevProps.location){
            this.props.getPatientFollowup({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }


    //Action Functions

    onSubmit (values){
        if(this.props.patient_id>0){
            var followup = {
                patient_id: this.props.patient_id,
                q1:values.q1,
                q2:values.q2,
                q3:values.q3,
                q4:values.q4,
                q5:values.q5,
                q6:values.q6,
                q7:values.q7,
                q8:values.q8,
                q9:values.q9,
                q10:values.q10,
                byPatient:this.props.byPatient,
            }
            this.props.setPatientFollowup(followup);
        }
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }



    //Render Functions


    renderRefreshDialog(){
        return(<Dialog
            fullScreen
            open={this.props.isLoading}
            PaperProps={{style:{background:'transparent'}}}
        >
            <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                <DialogContent>
                    <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                </DialogContent>
            </div>
        </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.setState({isError:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({isError:false})}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    handleCheckChange = name => event => {
        this.setState({[name]: event.target.value});
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    };

    render(){
        const { handleSubmit, pristine, submitting } = this.props;
        if(this.props.patient_id<1){
            return(<div></div>);
        }
        return(
            <Fragment>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>How did you find the experience of going through the consultation?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q1'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Have you interacted with your nominated Licenced Producer?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q2'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Is there any reason why you have not followed up and fulfilled your prescription?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q3'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Would there be anything that we could do to enhance or improve the experience?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q4'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Have you found anything confusing about the process?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q5'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Was the expense associated with purchasing Medical Cannabis an issue for you?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q6'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Are you still interested in receiving Medical Cannabis?</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q7'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Blank 1</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q8'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Blank 2</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q9'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{paddingBottom:'0px'}}>
                            <Typography variant='h6'>Notes</Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'0px',paddingBottom:'0px'}}>
                            <Field
                                autoFocus
                                name= 'q10'
                                component={renderTextField}
                                label= ''
                                margin='normal'
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    style: {border: '1px solid #ced4da'}
                                }}
                                multiline
                                rows='4'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                Save
                            </Button>
                            <Button disabled={pristine || submitting} onClick={()=>{this.props.reset();this.cancel()}} variant='contained'>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
                {this.renderRefreshDialog()}
            </Fragment>
        );
    }
}



const validate = values => {
    const errors = {}
    const requiredFields = [
        'how_negative',
        'used_cannabis',
        'allergies',
        'abuse_program',
        'history_side_affects',
        'prev_medical_treatments',
        'hope_to_gain',
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    })
    if(values.history_side_affects==='1'){
        if(!values.history_side_affects_details){
            errors.history_side_affects_details = 'Please enter side affect details';
        }
    }
    if(values.used_cannabis==='1'){
        if(!values.used_cannabis_how){
            errors.used_cannabis_how = 'Please enter how you used cannabis';
        }
    }
    if(values.allergies==='1'){
        if(!values.allergy_list){
            errors.allergy_list = 'Please enter your allergy details';
        }
    }
    if(values.abuse_program==='1'){
        if(!values.abuse_program_details){
            errors.abuse_program_details = 'Please enter details';
        }
    }

    if(!values.agree_to_treat){
        errors.agree_to_treat = 'Please accept the terms of treatment';
    }
    if(!values.citizen){
        errors.citizen = 'Please confirm your citizenship';
    }
    if(!values.consent_to_share){
        errors.consent_to_share = 'Please accept your consent to share';
    }
    if(!values.privacy){
        errors.privact = 'Please accept the privacy terms';
    }
    return errors
}

function mapStateToProps(state){
    return(
        {
            followup: state.followup,
            isLoading: state.followupIsLoading,
            isError: state.followupIsError,
            credentials: state.credentials,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getPatientFollowup,setPatientFollowup}, dispatch);
}


export default reduxForm({
    validate,
    form: 'editPatientFollowupForm'
})(connect(mapStateToProps,mapDispatchToProps)(PatientFollowup));

