import { BrowserRouter} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {Grid,withStyles,} from "@material-ui/core";
import {styles} from "../style";
import {PatientHeader, Header } from '../Components/Layouts';
import MyRoutes from './Routes';
import React, {Component, Fragment } from 'react';
import Chat from '../Components/Chat';
import classNames from 'classnames';

class App extends Component {

    constructor(props){
        super(props);
        this.state={
            patient_id:null,
            id_fetched:false,
            user_id:null,
            isUser:false,
        };
        //this.renderTabs=this.renderTabs.bind(this);
        //this.renderTabContent=this.renderTabContent.bind(this);
    }

    componentDidMount() {
        if(this.props.credentials.token>''){
            var jwt = this.props.credentials.details;
            let id = jwt.patient_id;
            if(id>0){
                this.setState({patient_id:id, id_fetched:true, isUser:false, user_id:null});
            }
            let userId = jwt.user_id;
            if(userId>0){
                this.setState({user_id:userId, id_fetched:true, isUser:true, patient_id:null});
            }
        } else {
            this.setState({user_id:null , patient_id:null, id_fetched:false, isUser:false});
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if(this.props.credentials.token>'' && this.props.credentials.token!== prevProps.credentials.token ){
            var jwt = this.props.credentials.details;
            let id = jwt.patient_id;
            if(id>0){
                this.setState({patient_id:id, id_fetched:true, isUser:false, user_id:null});
            }
            let userId = jwt.user_id;
            if(userId>0){
                this.setState({user_id:userId, id_fetched:true, isUser:true, patient_id:null});
            }
        }
        if((typeof(this.props.credentials.token)==='undefined' || this.props.credentials.token<='') && this.props.credentials.token!== prevProps.credentials.token){
            this.setState({user_id:null , patient_id:null, id_fetched:false, isUser:false});
        }
    }

    render() {
        const { classes } = this.props;
        return (

                <BrowserRouter>
                    <Fragment>
                    <div
                        className={classNames(classes.content, {
                            [classes.contentShift]: this.props.showChatWindow,
                        })}
                    >
                    <Grid container >

                        <Grid item xs={12}>
                            {this.state.id_fetched && !this.state.isUser && <PatientHeader {...this.props}/>}
                            {this.state.id_fetched && this.state.isUser && <Header {...this.props}/> }

                        </Grid>
                        <Grid item xs={12} style={{marginTop:'6px'}}>
                            <MyRoutes/>
                        </Grid>
                    </Grid>
                    </div>
                        {this.state.id_fetched && this.state.isUser &&
                            <Chat/>
                        }
                    </Fragment>
                </BrowserRouter>
        );

    }
}



function mapStateToProps(state){
    return(
        {
            credentials: state.credentials,
            showChatWindow: state.showChatWindow,
            chatToken: state.chatToken,
        });
}

export default connect(mapStateToProps)(withStyles(styles)(App));


