import * as TYPE from "../actions/PatientPhotos";


export function patient_photos (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_PATIENT_PHOTOS:
            return  action.payload.dataset;
        default:
            return state;
    }
}

export function patient_photo (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_PATIENT_PHOTO_DETAIL:
            return action.payload;
        case TYPE.ADD_PATIENT_PHOTO:
            return action.payload;
        default:
            return state;
    }
}

export function patientPhotoIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_PHOTO_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function patientPhotoIsError (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_PHOTO_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientPhotoNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.PATIENT_PHOTO_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
