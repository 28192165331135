import React, {Component, } from 'react';
import {connect} from 'react-redux';
import {Button,
    Grid,
    Card,  CardHeader, CardContent,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {addPatientLPInfo} from '../actions/PatientLPInfo';
import {renderTextField} from "../Components/Forms";
import {Field, reduxForm} from 'redux-form';
import {styles} from '../style';
import { withStyles } from '@material-ui/core/styles';

class PatientLPLoginDetail extends Component{

    constructor(props){
        super(props);

        this.state= {
            buttonText:'Add',
            lp_login_user: '',
            lp_user_id: ''
        };
        this.addPatientLPInfo = this.addPatientLPInfo.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.addPatientLPInfo=this.addPatientLPInfo.bind(this);
    }

    componentDidMount() {
        let data = this.props.data;
        let formData={};
        if(data.login>'' && data.user_id>''){
            formData = {
                "lp_login_user": data.login,
                "lp_user_id": data.user_id,
            };
            this.setState({buttonText:'Save',lp_login_user:data.login, lp_user_id: data.user_id});
        } else {
            formData = {
                "lp_login_user": 'Login User Name',
                "lp_user_id": 'Patient ID',
            };
            this.setState({buttonText:'Add', lp_login_user:'', lp_user_id: ''});
        }
        this.props.initialize(formData);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.data !== prevProps.data){
            let data = this.props.data;
            let formData={};
            if(data.login>'' && data.user_id>''){
                formData = {
                    "lp_login_user": data.login,
                    "lp_user_id": data.user_id,
                };
                this.setState({buttonText:'Save',lp_login_user:data.login, lp_user_id: data.user_id});
            } else {
                formData = {
                    "lp_login_user": 'Login User Name',
                    "lp_user_id": 'Patient ID',
                };
                this.setState({buttonText:'Add', lp_login_user:'', lp_user_id: ''});
            }
            this.props.initialize(formData);
        }
    }

    addPatientLPInfo(values){
        let params= {
            byPatient: this.props.byPatient,
            patient_id: this.props.patient_id,
            lp_id: this.props.data.lp_id,
            lp_login_user: values.lp_login_user,
            lp_user_id: values.lp_user_id,
            lp_transaction_id: 'Manual Entry',
        }
        this.props.addPatientLPInfo(params);
    }

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }

    //Dialog Functions



    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    //Render Functions

    render(){
        const { handleSubmit, classes } = this.props;

        return(
            <Grid item xs={12} sm={4}>
                <Card className={classes.spacedCard}>
                    <CardHeader title={this.props.data.lp_name}/>
                    <CardContent>
                        <form onSubmit={handleSubmit(this.addPatientLPInfo)}>
                            <Field autoFocus
                                   name='lp_login_user'
                                   component={renderTextField}
                                   label='Login user name'
                                   margin='normal'
                                   fullWidth
                                   onChange={this.handleChange}
                                   validate={[this.required]}
                            />
                            <Field
                                   name='lp_user_id'
                                   component={renderTextField}
                                   label='Patient/Client id'
                                   margin='normal'
                                   fullWidth
                                   onChange={this.handleChange}
                                   validate={[this.required]}
                            />
                            <Button type="submit" variant='contained' className={classes.rightSpacedButton}>
                                {this.state.buttonText}
                            </Button>
                            {this.props.automatedRegister && this.state.lp_login_user==='' && this.state.lp_user_id==='' &&
                            <Button variant='contained' onClick={this.props.automatedRegisterFn} className={classes.rightSpacedButton} >
                                Auto Generate
                            </Button>
                            }
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}


function mapStateToProps(state){
    return({});
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({addPatientLPInfo}, dispatch);
}

export default reduxForm({
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PatientLPLoginDetail)));
