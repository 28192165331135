import * as TYPE from "../actions/PatientLPInfo";


export function patientLpInfo (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_P_LP_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function patientLPInfoIsLoading (state = false, action){
    switch(action.type){
        case TYPE.P_LP_INFO_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function patientLPInfoIsError (state = false, action){
    switch(action.type){
        case TYPE.P_LP_INFO_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function patientLPInfoErrorMessage (state = '', action){
    switch(action.type){
        case TYPE.P_LP_INFO_ERROR_MSG :
            return action.msg;
        default:
            return state;
    }
}

export function patientLPInfoNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.P_LP_INFO_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
