import React, {Component, } from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Switch,Grid,Tooltip
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {fetchPatientAilments, addPatientAilment, deletePatientAilment} from '../actions/Patients';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../style';



class PatientAilment extends Component{
    constructor(props){
        super(props);

        this.state= {
            patient_ailments:[]
        };
        this.nextButton=this.nextButton.bind(this);
    }

    componentDidMount() {
        this.props.fetchPatientAilments({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.patient_ailments!==prevProps.patient_ailments){
            this.setState({patient_ailments:this.props.patient_ailments});
        }
        if(this.props.needsRefresh){
            this.props.fetchPatientAilments({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    //Dialog Functions


    //Action Functions

    switchAilment = ailment_id => event =>{
        if(event.target.checked){
            this.props.addPatientAilment({patient_id: this.props.patient_id, ailment_id:ailment_id, byPatient:this.props.byPatient});
        } else {
            this.props.deletePatientAilment({patient_id: this.props.patient_id, ailment_id:ailment_id, byPatient:this.props.byPatient})
        }

    }

    //Render Functions

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderList(){
        if(this.state.patient_ailments.length){
            return (
                this.state.patient_ailments.map((value) => {
                    return(
                        <Grid item  xs={12} sm={6} md={4} key={value.ailment_id}>
                            <Grid container style={{flexGrow:1, padding:'5px'}}>
                                <Grid item xs={3}>
                                    <Switch
                                        onChange={this.switchAilment(value.ailment_id)}
                                        checked={value.enabled==="1"}
                                        color='primary'/>
                                </Grid>
                                <Grid item xs={9}>
                                    <Tooltip title={value.description}>
                                    <Typography variant='h6' style={{paddingTop:'12px'}}>{value.name}</Typography>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </Grid>
                    )
                }))
        }
    }

    nextButton(){
        if(typeof this.props.dataSaved!=='undefined') {
            this.props.dataSaved();
        }
    }


    render(){
        if(this.props.patient_id<1){
            return(<div></div>);
        }

        return(
            <Grid container direction='row' style={{flexGrow:1}}>
                {this.props.byPatient &&
                <Grid item xs={12}>
                    <Typography variant='h6'>Purpose for seeking medical cannabis</Typography>
                    <Typography variant="subtitle1">Please select ailment(s) from below that are applicable to you.</Typography>
                </Grid>
                }
                          {this.renderList()}
                        {this.renderRefreshDialog()}
                        {this.renderErrorDialog()}
                        <Grid item xs={12}>
                            <Button color='secondary' variant='contained' onClick={this.nextButton}>
                                NEXT
                            </Button>

                        </Grid>
                </Grid>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            patient_ailments: state.patientAilments,
            isLoading: state.patientAilmentsIsLoading,
            isError: state.patientAilmentsIsError,
            needsRefresh: state.patientAilmentsNeedsRefresh
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPatientAilments, addPatientAilment, deletePatientAilment}, dispatch);
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PatientAilment));


