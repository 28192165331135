import React, { Component} from 'react';
import {Button} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {renderTextField} from '../Components/Forms'
import {bindActionCreators} from "redux";
import {changePassword} from "../actions/Users";
import {updatePage} from "../actions/Common";
import {connect} from 'react-redux';



class ChangePassword extends Component {


    onSubmit(values){
        const passwords = {oldPassword:values.oldPassword, newPassword:values.newPassword}
        this.props.changePassword(passwords);
    }

    componentDidMount(){
        this.props.updatePage('/changePassword');
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.passwordMessage!==prevProps.passwordMessage){
            alert(this.props.passwordMessage);
        }
        if(this.props.changingPassword!==prevProps.changingPassword){
            if(this.props.changingPassword){
            } else {
            }
        }

    }

    render(){
        const {handleSubmit} = this.props;
        const { classes } = this.props;
        return(

            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <div>
                  <Field
                      autoFocus
                    name= 'oldPassword'
                    component={renderTextField}
                    label= 'Old Password'
                      type='password'
                    className={classes.textField}
                    margin='normal'
                  />
                </div>
                <div>
                    <Field
                        name= 'newPassword'
                        component={renderTextField}
                        label='New Password'
                        type='password'
                        className={classes.textField}
                        margin='normal'
                    />
                </div>
                <div>
                    <Field
                        name= 'confirmPassword'
                        component={renderTextField}
                        label='Confirm New Password'
                        type='password'
                        className={classes.textField}
                        margin='normal'
                    />
                </div>
                <div>
                    <Button type="submit" color='secondary' variant='contained'>
                        Change Password
                    </Button>
                </div>
            </form>

        );
    }

}

const validate = values => {
    const errors = {}
    const requiredFields = [
        'oldPassword',
        'newPassword',
        'confirmPassword'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if(values.confirmPassword!==values.newPassword){
        errors.confirmPassword = 'Passwords do not Match'
    }
    return errors
}

function mapStateToProps(state){
    return(
        {
            credentials: state.credentials,
            passwordMessage: state.passwordMessage,
            changingPassword: state.changingPassword,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({changePassword, updatePage}, dispatch);
}


export default reduxForm({
    validate,
    form: 'userPasswordForm'
})(connect(mapStateToProps,mapDispatchToProps)(ChangePassword));