import React from 'react';
import { render } from 'react-dom';
import App from './Components/NewApp';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import 'typeface-work-sans';
import 'typeface-montserrat';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#262362',
        },
        secondary: {
            main: '#6ECDDD',
        },
    },
    typography: {
        useNextVariants:true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Work Sans"',
            '"Sans"',
        ].join(','),
    },
});


const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunk)(createStore);
render(
    <Provider store={createStoreWithMiddleware(rootReducer)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={ theme}>
                <App/>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root')
)



