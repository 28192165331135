import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    Typography,
    Grid, Tooltip,
    Card, CardHeader, CardContent, CardActions
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {bindActionCreators} from 'redux';
import {fetchPatientPhotos,addPatientPhoto,deletePatientPhoto,editPatientPhoto} from '../actions/PatientPhotos';
import {Field, reduxForm, } from 'redux-form';
import {renderImageUpload } from '../Components/Forms';
import ImageEditor from './imageEditor';
import Webcam from 'react-webcam';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import IDCardIcon from '@material-ui/icons/CreditCard';
import HealthcardIcon from '@material-ui/icons/LocalHospital';
import ContentSave from '@material-ui/icons/Save';
import ZoomIcon from '@material-ui/icons/ZoomIn';
import { withStyles } from '@material-ui/core/styles';
import {styles} from "../style";
import PatientHealthcardDocumentDetail from "./patient_healthcardPhoto-detail";
import PatientIdentificationDocumentDetail from "./patient_identificationPhoto-detail";


class PatientPhotos extends Component{

    constructor(props){
        super(props);

        this.state= {
            photo_id: 0,
            patient_id: this.props.patient_id,
            image: null,
            imageSrc: '',
            zoomImageDialog:false,
            zoomImage:null,
            zoomImgSrc:null,
            originalImage: null,
            webCamDialog:false,
            imageCounter: 0,
            patient_photos: [],
            deleteDialogOpen: false,
            classes: null,
            renderHealthDialog: false,
            renderIdDialog: false,
            selectedImage:{},
        };
        this.handleImageChange=this.handleImageChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.onDeleteDialogOpen = this.onDeleteDialogOpen.bind(this);
        this.onDeleteDialogClose = this.onDeleteDialogClose.bind(this);
        this.onDeleteConfirm=this.onDeleteConfirm.bind(this);
        this.revertImage=this.revertImage.bind(this);
        this.updateZoomImage=this.updateZoomImage.bind(this);
        this.cancel = this.cancel.bind(this);
        this.renderPhotos=this.renderPhotos.bind(this);
        this.zoomImage=this.zoomImage.bind(this);
        this.updateImage=this.updateImage.bind(this);
        this.renderHealthCard=this.renderHealthCard.bind(this);
        this.renderIdCard=this.renderIdCard.bind(this);
    }

    componentDidMount() {
        if(this.props.patient_id>0 && !this.props.passPhoto){
            this.props.fetchPatientPhotos({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        this.setState({classes:this.props.classes});
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.location!==prevProps.location){
            this.props.fetchPatientPhotos({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.patient_photos!==prevProps.patient_photos){
            this.setState({patient_photos: this.props.patient_photos});
        }
        if(this.props.needsRefresh && !prevProps.needsRefresh){
            this.props.fetchPatientPhotos({patient_id:this.props.patient_id, byPatient:this.props.byPatient});
        }
        if(this.props.healthcard_photo!==prevProps.healthcard_photo){
            this.setState({renderHealthDialog:false})
        }
        if(this.props.identification_photo !==prevProps.identification_photo){
            this.setState({renderIdDialog:false})
        }
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            },
            paper: {
                padding: '10px',
                margin:'10px'
            },
        });
    }

    cancel(){
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    //Dialog Functions

    onDeleteDialogOpen(id) {
        this.setState({photo_id : id});
        this.setState({ deleteDialogOpen: true });
    };

    onDeleteDialogClose() {
        this.setState({ deleteDialogOpen: false });
    };

    onDeleteConfirm (id){
        this.setState({ deleteDialogOpen: false });
        const delete_id = {patient_photos_id: id, patient_id: this.state.patient_id};
        this.props.deletePatientPhoto(delete_id);
        //also delete from state to update the list on screen
        let objIndex = this.state.patient_photos.findIndex((obj => obj.patient_photos_id === id));
        let patient_photos = this.state.patient_photos;
        patient_photos.splice( objIndex, 1 );
        this.setState({patient_photos});
    }

    selectHealthCard(image){
        this.setState({selectedImage:image, renderHealthDialog:true});
    }

    selectIDCard(image){
        this.setState({selectedImage:image, renderIdDialog:true});
    }

    //Render Functions

    renderDeleteDialog (){
        return (
            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={()=>{this.onDeleteDialogClose()}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete Photo ?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you confirm you are unable to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.onDeleteDialogClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {this.onDeleteConfirm(this.state.photo_id)}} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    //Action Functions

    onSubmit (values){
        var id_doc = {
            patient_id: this.props.patient_id,
            image:this.state.image,
            byPatient:this.props.byPatient,
        }
        this.props.addPatientPhoto(id_doc);
        if(typeof this.props.dataSaved!=='undefined'){
            this.props.dataSaved();
        }
    }

    updateImage(image){
        let doc= {
            patient_id: image.patient_id,
            image: image.image,
            patient_photos_id : image.patient_photos_id,
            byPatient: this.props.byPatient,
        }
        this.props.editPatientPhoto(doc);
    }

    revertImage(){
        this.setState({image:this.state.originalImage});
    }
    //Render Functions

    renderHealthCard(){
        return (
            <Dialog
                open={this.state.renderHealthDialog}
                onClose={()=>{this.setState({renderHealthDialog:false})}}
                maxWidth={false}
            >
                <DialogContent>
                    <PatientHealthcardDocumentDetail patient_id={this.props.patient_id} byPatient={this.props.byPatient} passPhoto={true} image={this.state.selectedImage.image}  form={'patientPhotosHealthcardForm'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({renderHealthDialog:false})}} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderIdCard (){
        return (
            <Dialog
                open={this.state.renderIdDialog}
                onClose={()=>{this.setState({renderIdDialog:false})}}
                maxWidth={false}
            >
                <DialogContent>
                    <PatientIdentificationDocumentDetail patient_id={this.props.patient_id} passPhoto={true} image={this.state.selectedImage.image}  form={'patientPhotosIdDocForm'} byPatient={this.props.byPatient}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({renderIdDialog:false})}} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.props.isError}
                onClose={()=>{this.props.history.push(`/`)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push(`/`)}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderZoomImage (){
        return (
            <Dialog
                open={this.state.zoomImageDialog}
                onClose={()=>{this.setState({zoomImageDialog:false})}}
                maxWidth={false}
            >
                <DialogTitle id="alert-dialog-title">{`Image Capture Viewer`}</DialogTitle>
                <DialogContent>
                    <ImageEditor originalImage={this.state.zoomImgSrc} updateCallback={this.updateZoomImage}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.setState({zoomImageDialog:false})}} color="primary">
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderWebcam(){
        const videoConstraints = {
            facingMode: 'user',
        };
        let imgWidth = Math.min(window.innerWidth -100,640);
        let imgHeight = imgWidth * 1.5;
        if(window.innerWidth > window.innerHeight){
            imgHeight = Math.min(window.innerHeight -200,640);
            imgWidth = imgHeight * 1.5;
        }
        return (
            <Dialog
                open={this.state.webCamDialog}
                onClose={()=>{this.setState({webCamDialog:false})}}
                maxWidth={false}
            >
                <DialogTitle>Capture Image</DialogTitle>
                <DialogContent>
                    <Grid container justify='space-between'>
                        <Grid item xs={12}>
                        <Webcam
                            audio={false}
                            ref={this.setRef}
                            screenshotFormat="image/jpeg"
                            width={imgWidth}
                            height={imgHeight}
                            videoConstraints={videoConstraints}
                        />
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={()=>{this.setState({webCamDialog:false})}} variant='contained' color='secondary'>Cancel</Button>
                        </Grid>
                        <Grid item xs={6}>
                        <Button onClick={this.capture} variant='contained' color='primary'>Capture photo</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    setRef = (webcam) => {
        this.webcam = webcam;
    }

    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        this.setState({image:imageSrc, webCamDialog:false})
    };



    renderPhotos(){
        //check to see if any captures have been done
        if(typeof this.refs.Captures === 'undefined'){
            return (<div/>);
        }

        if(this.state.imageCounter===0){
          //  return false;
        }
        let container = this.refs.Captures;
        let positionInfo = container.getBoundingClientRect();
        //let maxHeight = this.state.windowHeight-200;
        let maxWidth =   200 ; //positionInfo.width; //parseInt(this.state.windowWidth/4,10);
        if(positionInfo.width<600){
            maxWidth=positionInfo.width;
        } else {
            maxWidth=positionInfo.width/4;
        }
        //let cellHeight = parseInt(maxHeight/5,10);
        let imageWidth = maxWidth - 68;
        console.log("Photos", this.state.patient_photos);
        return(
            <Grid container>
                {
                    _.orderBy(_.values(this.state.patient_photos),'patient_photos_id','desc').map( image => {
                        return(
                            <Grid item xs={12} sm={3} key={image.patient_photos_id}>
                                <Card className={this.state.classes.spacedCard}>
                                    <CardHeader/>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <img src={image.image} width={imageWidth} alt=""/>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        {!this.props.byPatient &&
                                        <Fragment><Tooltip title='Delete'>
                                            <Button onClick={()=>{this.onDeleteDialogOpen(`${image.patient_photos_id}`)}} color="secondary" variant="fab" mini><DeleteIcon/></Button>
                                        </Tooltip></Fragment>
                                        }
                                        { this.props.byPatient && image.createdBy===this.props.patient_id*-1 &&
                                        <Fragment><Tooltip title='Delete'>
                                            <Button onClick={()=>{this.deleteCapture(`${image.patient_photos_id}`)}} color="secondary" variant="fab" mini><DeleteIcon/></Button>
                                        </Tooltip></Fragment>
                                        }
                                        <Fragment><Tooltip title='Zoom/Edit'>
                                            <Button onClick={()=>{this.zoomImage(`${image.patient_photos_id}`)}} color="primary" variant="fab" mini><ZoomIcon/></Button>
                                        </Tooltip></Fragment>
                                        <Fragment><Tooltip title='Save Changes'>
                                            <Button onClick={()=>{this.updateImage(image)}} color="primary" variant="fab" mini><ContentSave/></Button>
                                        </Tooltip></Fragment>
                                        <Fragment><Tooltip title='Set as Health Card'>
                                            <Button onClick={()=>{this.selectHealthCard(image)}} color="primary" variant="fab" mini><HealthcardIcon/></Button>
                                        </Tooltip></Fragment>
                                        <Fragment><Tooltip title='Set as ID Card'>
                                            <Button onClick={()=>{this.selectIDCard(image)}} color="primary" variant="fab" mini><IDCardIcon/></Button>
                                        </Tooltip></Fragment>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    }
                    )
                }
                {this.renderZoomImage()}
            </Grid>
        )
    }


    updateZoomImage(image){
        if(this.state.zoomImage.patient_photos_id===0){
            this.setState({image,zoomImageDialog:false});
        } else {
            let objIndex = this.state.patient_photos.findIndex((obj => obj.patient_photos_id === this.state.zoomImage.patient_photos_id));
            let patient_photos = this.state.patient_photos;
            patient_photos[objIndex].image=image;
            this.setState({patient_photos,zoomImageDialog:false});
        }

    }

    zoomImage(image_id){
        //open fullscreen dialog with this image as the zoomedImage
        let image = {image: this.state.image, patient_photos_id: 0};
        if(image_id!==0) {
            image = this.state.patient_photos.find(photo => {
                return photo.patient_photos_id === image_id
            })

        }
        this.setState({zoomImage: image, zoomImgSrc:image.image ,zoomImageDialog:true});
    }

    handleImageChange(event){
        this.setState({imageSrc: event.target.files[0]})
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            var image = new Image();
            image.onload = function(imageEvent) {
                let maxHeight = 2000;
                let maxWidth = 2000;
                let hr = image.height/maxHeight;
                let wr = image.width/maxWidth;
                let h = image.height;
                let w = image.width;
                if(hr>1 && hr>wr){
                    h=maxHeight;
                    w=parseInt(w/hr,10);
                }
                if(wr>1 && wr>hr){
                    w=maxWidth;
                    h=parseInt(h/wr,10);
                }
                var canvas = document.createElement('canvas');
                canvas.width=w;
                canvas.height=h;
                canvas.getContext('2d').drawImage(image, 0,0,w,h);
                var dataUrl = canvas.toDataURL('image/jpeg');
                this.setState({ image:dataUrl});
            }.bind(this);
            image.src=reader.result;
        }.bind(this);
        if(typeof this.props.dataChange!=='undefined'){
            this.props.dataChange();
        }
    }


    render(){
        const { handleSubmit, pristine, submitting } = this.props;
        if(this.props.patient_id<1){
            return(<div></div>);
        }
        let imgWidth = window.innerWidth -100;

        return(<Fragment>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <Grid container direction='row' style={{flexGrow:1}}  alignItems='stretch'>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="inherit"><u>Extra Images</u></Typography>
                        </Grid>
                        {this.props.byPatient &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Please upload any supporting documentation. (prescription records, medication bottles, etc.)</Typography>
                        </Grid>
                        }
                        <Grid item xs={6}>
                            <Button onClick={()=>{this.setState({webCamDialog:true})}} variant="fab" color="secondary"><CameraIcon/></Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name='imageSrc'
                                fname='imageSrc'
                                component={renderImageUpload}
                                onChange={this.handleImageChange}
                                label='Upload Extra Image'
                                margin='normal'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <img src={this.state.image} alt="" style={{maxWidth:`${imgWidth}px`}}/>
                            {this.state.image !== null &&
                            <Button onClick={()=>{this.zoomImage(0)}} color="primary" variant="fab" mini><EditIcon/></Button>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" color='secondary' variant='contained' style={{marginRight:'6px'}}>
                                SAVE
                            </Button>
                            <Button disabled={pristine || submitting} onClick={()=>{this.props.reset();this.cancel()}} variant='contained'>Cancel</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div ref="Captures"/>
                            {this.renderPhotos()}
                        </Grid>
                    </Grid>
                </form>
                {this.renderRefreshDialog()}
                {this.renderZoomImage()}
                {this.renderWebcam()}
                {this.renderDeleteDialog()}
                {this.renderHealthCard()}
                {this.renderIdCard()}
            </Fragment>
        );
    }
}

function mapStateToProps(state){
    return(
        {
            patient_photos: state.patient_photos,
            isLoading: state.patientPhotoIsLoading,
            isError: state.patientPhotoIsError,
            needsRefresh: state.patientPhotoNeedsRefresh,
            healthcard_photo: state.healthcard_photo,
            identification_photo: state.identification_photo,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchPatientPhotos,addPatientPhoto,deletePatientPhoto,editPatientPhoto}, dispatch);
}


export default reduxForm({})(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PatientPhotos)));

