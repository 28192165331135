import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Typography,
    Dialog,
    DialogContent,
    CircularProgress,
    Grid,
} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import {getPatientLPInfo,registerGreenRelief} from '../actions/PatientLPInfo';
import {fetchLps} from '../actions/Lps';
import _ from 'lodash';
import PatientLPLoginDetail from "./patientLPLoginDetail";


class PatientLPLoginList extends Component{

    constructor(props){
        super(props);

        this.state= {
            isLoading: false,
            providers:[],
            patientLpInfo:[],
        };


    }

    componentDidMount() {
        this.props.fetchLps({offset:0,limit:50,sortField:'name', sortDirection:'asc', byPatient:this.props.byPatient});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.needsRefresh) {
            this.props.getPatientLPInfo({byPatient:this.props.byPatient, patient_id:this.props.patient_id});
        }

        if(this.props.isLoading!==prevProps.isLoading || this.props.lpIsLoading!==prevProps.lpIsLoading){
            if(this.props.isLoading || this.props.lpIsLoading){
                this.setState({isLoading:true})
            } else {
                this.setState({isLoading:false})
            }
        }

        if(this.props.lps!==prevProps.lps){
            //create the array needed for the status selector
            let arr = [];
            this.props.lps.dataset.map((data)=>{
                arr.push({value:data.lp_id, label:data.name});
                return true;
            });
            this.setState({providers:arr});
            this.props.getPatientLPInfo({byPatient:this.props.byPatient, patient_id:this.props.patient_id});
        }

        if(this.props.patientLPInfo!==prevProps.patientLPInfo) {
            //build new array
            //loop through lps and add any info we have
            let patientLpInfo = [];
            let plp = _.keyBy(this.props.patientLPInfo.dataset,'lp_id');
            let providers = _.orderBy(this.state.providers,'value','asc');
            providers.map((data)=>{
                //check if info is available
                if(typeof plp[data.value]==='undefined'){
                    patientLpInfo.push({lp_id:data.value, lp_name:data.label, user_id: '', login: '' });
                } else {
                    patientLpInfo.push({lp_id:data.value, lp_name:data.label, user_id: plp[data.value].lp_user_id, login: plp[data.value].lp_login_user });
                }
                return true;
            });
            this.setState({patientLpInfo:patientLpInfo});
        }
    }


    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            }
        });
    }

    //Form Validation Functions:
    required(value) {
       return value ? undefined : 'Required'
    }

    isNumeric(value) {
        return isNaN(value) ? 'Must be a Number' : undefined;
    }

    automatedRegisterFn(data){
        if(data.lp_id==='1'){
            //now register with green relief
            let params = {
                byPatient : this.props.byPatient,
                patient_id : this.props.patient_id
            }
            this.props.registerGreenRelief(params);
        }
    }

    //Dialog Functions

    //Render Functions
    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.state.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderList(){
        if(_.isEmpty(this.state.patientLpInfo)){
            return(<Grid item xs={12}></Grid>);
        }
        return (
            this.state.patientLpInfo.map((data)=>{
                let automatedRegister = false;
                if(data.lp_id==='1'){
                    //automatedRegister = true;
                    automatedRegister = false; //permanently set to false until automated system properly tested.
                }
                return (
                    <PatientLPLoginDetail data={data}
                                          key={data.lp_id}
                                          patient_id={this.props.patient_id}
                                          byPatient={this.props.byPatient}
                                          form={'patientLPLogin_'+data.lp_id}
                                          automatedRegister={automatedRegister}
                                          automatedRegisterFn={()=>{this.automatedRegisterFn(data)}}/>
                );
            })

        )
    }



    render(){
         return(
            <Fragment>
                <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                    <Grid item xs={12}>
                        <Typography variant="h5">Licensed Provider Login Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction='row' style={{flexGrow:1}}  alignitems='stretch'>
                        {this.renderList()}
                        </Grid>
                    </Grid>
                </Grid>
            {this.renderRefreshDialog()}

            </Fragment>
        );
    }
}


function mapStateToProps(state){
    return(
        {
            lpIsLoading: state.lpIsLoading,
            lps: state.lps,
            patientLPInfo: state.patientLpInfo,
            isLoading: state.patientLPInfoIsLoading,
            isError: state.patientLPInfoIsError,
            errorMessage: state.patientLPInfoErrorMessage,
            needsRefresh: state.patientLPInfoNeedsRefresh,
            credentials: state.credentials,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({fetchLps,getPatientLPInfo,registerGreenRelief}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientLPLoginList);
