import * as TYPE from "../actions/Communication";


export function communications (state = [], action){
    switch(action.type) {
        case TYPE.FETCH_COMMUNICATIONS:
            return  action.payload.result;
        default:
            return state;
    }
}

export function communication (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_COMMUNICATION_DETAIL:
            return action.payload;
        case TYPE.ADD_SMS_COMMUNICATION:
            return {communication_id: action.communication_id, iAmNew: true};
        case TYPE.ADD_EMAIL_COMMUNICATION:
            return {communication_id: action.communication_id, iAmNew: true};
        default:
            return state;
    }
}

export function communicationIsLoading (state = false, action){
    switch(action.type){
        case TYPE.COMMUNICATION_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function communicationIsError (state = false, action){
    switch(action.type){
        case TYPE.COMMUNICATION_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}

export function communicationNeedsRefresh (state = false, action){
    switch(action.type){
        case TYPE.COMMUNICATION_NEEDS_REFRESH :
            return action.bool;
        default:
            return state;
    }
}
