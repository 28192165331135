import * as TYPE from "../actions/PatientMedicalInfo";


export function medical (state = {}, action){
    switch(action.type) {
        case TYPE.FETCH_PMI:
             return action.payload;
        default:
            return state;
    }
}

export function medicalIsLoading (state = false, action){
    switch(action.type){
        case TYPE.PMI_IS_LOADING :
            return action.bool;
        default:
            return state;
    }
}

export function medicalIsError (state = false, action){
    switch(action.type){
        case TYPE.PMI_IS_ERROR :
            return action.bool;
        default:
            return state;
    }
}
