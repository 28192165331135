import React, { Component} from 'react';
import {Button} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {renderTextField} from '../Components/Forms'
import {bindActionCreators} from "redux";
import {authUser} from "../actions/Users";
import {updatePage} from "../actions/Common";
import {connect} from 'react-redux';



class LoginDetail extends Component {


    onSubmit(values){
        const cred = {username:values.username, password:values.password}
        this.props.authUser(cred);
    }

    componentDidMount(){
        this.props.updatePage('/');
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.credentials!==prevProps.credentials){
            if(typeof (this.props.credentials.token) !=='undefined'){
                this.props.history.push('/patients');
            }
        }
    }

    render(){
        const {handleSubmit} = this.props;
        const { classes } = this.props;
        return(

            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <div>
                  <Field
                      autoFocus
                    name= 'username'
                    component={renderTextField}
                    label= 'Username'
                    className={classes.textField}
                    margin='normal'
                  />
                </div>
                <div>
                    <Field
                        name= 'password'
                        component={renderTextField}
                        label='Password'
                        type='password'
                        className={classes.textField}
                        margin='normal'
                    />
                </div>
                <div>
                    <Button type="submit" color='secondary' variant='contained'>
                        Login
                    </Button>
                </div>
            </form>

        );
    }

}

const validate = values => {
    const errors = {}
    const requiredFields = [
        'username',
        'password'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    return errors
}

function mapStateToProps(state){
    return(
        {
            credentials: state.credentials
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({authUser, updatePage}, dispatch);
}


export default reduxForm({
    validate,
    form: 'userLoginForm'
})(connect(mapStateToProps,mapDispatchToProps)(LoginDetail));