import React, {Component, Fragment} from 'react';
import {getHomePage} from '../constants_api';
import {  Route, Switch } from 'react-router-dom';

import AilmentDetails from './Ailments/ailmentDetail.js';
import Ailments from './Ailments';
import ApptStatusDetail from './ApptStatuses/statusDetail';
import ApptStatuses from './ApptStatuses';
import CancelReasonDetail from './CancelReasons/reasonDetail';
import CancelReasons from './CancelReasons';
import ChangePasswordPage from './ChangePassword';
import ConfirmationPage from './Signup/confirmation';
import DailyAppointments from './Appointments/day';
import DeliveryMethodDetails from './DeliveryMethods/deliveryMethodDetail';
import DeliveryMethods from './DeliveryMethods';
import ExpiringPrescriptionReport from './Patient/expiring';
import LoginPage from './Login';
import LpDetails from './Lps/lpDetail';
import Lps from './Lps';
import PatientDetail from './Patient/patientDetailNew.js';
import PatientLoginPage from './Login/PatientLogin';
import PatientOwnDetails from './Patient/patientOwnDetailNew';
import Patients from './Patient/patient.js';
import PractitionerDetailPage from './PractitionerDetail';
import PractitionerCalendar from './PractitionerSchedule/calendar';
import PractitionerSchedules from './PractitionerSchedule';
import PublicVideo from './Video';
import SignupPage from './Signup';
import SlotCalendar from './Appointments/calendar';
import StatusDetail from './Statuses/statusDetail';
import Statuses from './Statuses';
import Users from './User';
import UserDetails from './UserDetail';
import VideoWaitingList from './Patient/waiting';

import Test from './Test';

class Routes extends Component {


    render() {
        var HomePageComponent = LoginPage;
        if(getHomePage()==='patient') {
            HomePageComponent = PatientLoginPage;
        }

        return (

            <Fragment>
                        <Switch>
                            <Route path="/test"  render={(props) => <Test {...props}/>}/>
                            <Route path="/ailment/:ailment_id" component={AilmentDetails}/>
                            <Route path="/ailments" component={Ailments}/>
                            <Route path="/lp/:lp_id" component={LpDetails}/>
                            <Route path="/lps" component={Lps}/>
                            <Route path="/delivery_type/:delivery_id" component={DeliveryMethodDetails}/>
                            <Route path="/delivery_types" component={DeliveryMethods}/>
                            <Route path="/user/:user_id" component={UserDetails}/>
                            <Route path="/users" render={(props) => <Users {...props} filter='' />}/>
                            <Route path="/practitioners" render={(props) => <Users {...props} filter='practitioner' />}/>
                            <Route path="/patientsDosage" render={(props) => <Patients {...props} reportType='Dosage' />}/>
                            <Route path="/patient/:patient_id" component={PatientDetail}/>
                            <Route path="/patients" render={(props) => <Patients {...props} reportType='Standard' />}/>
                            <Route path="/status/:status_id" component={StatusDetail}/>
                            <Route path='/statuses/' component={Statuses}/>
                            <Route path="/cancel_reason/:reason_id" component={CancelReasonDetail}/>
                            <Route path='/cancel_reasons/' component={CancelReasons}/>
                            <Route path="/appt_status/:status_id" component={ApptStatusDetail}/>
                            <Route path='/appt_statuses/' component={ApptStatuses}/>
                            <Route path='/changePassword' component={ChangePasswordPage}/>
                            <Route path='/practitioner/:practitioner_id' component={PractitionerDetailPage}/>
                            <Route path='/video/:id' render={(props) => <PublicVideo {...props}/>}/>
                            <Route path='/confirmation/:code' render={(props) => <ConfirmationPage {...props} />}/>
                            <Route path='/signup/:code'  render={(props) => <SignupPage {...props} />}/>
                            <Route path='/signup' component={SignupPage}/>
                            <Route path='/patientlogin' component={PatientLoginPage}/>
                            <Route path='/client' component={PatientOwnDetails}/>
                            <Route path="/login" component={LoginPage}/>
                            <Route path='/schedule' component={PractitionerSchedules}/>
                            <Route path='/practitionercalendar/:id/:date' render={(props)=> <PractitionerCalendar {...props}/>}/>
                            <Route path='/practitionercalendar' render={(props)=> <PractitionerCalendar {...props}/>}/>
                            <Route path='/calendar/:id/:date' render={(props)=> <SlotCalendar {...props}/>}/>
                            <Route path='/calendar/:id' render={(props)=> <SlotCalendar {...props}/>}/>
                            <Route path='/calendar' render={(props)=> <SlotCalendar {...props}/>}/>
                            <Route path='/expiring' component={ExpiringPrescriptionReport}/>
                            <Route path='/waiting' component={VideoWaitingList}/>
                            <Route path='/dailyappointments/:date/:practitioner' render={(props)=> <DailyAppointments {...props}/>}/>
                            <Route path='/dailyappointments/:date/' render={(props)=> <DailyAppointments {...props}/>}/>
                            <Route path='/dailyappointments' component={DailyAppointments}/>
                            <Route path="/" component={HomePageComponent}/>
                        </Switch>
            </Fragment>
        );

    }
}



export default Routes;


