import axios from 'axios';
import {API_URL, UPDATE_TOKEN, PASSWORD_FAILED} from '../../constants_api'
import {reset} from 'redux-form';
import {COMMUNICATION_IS_LOADING, COMMUNICATION_NEEDS_REFRESH} from "../Communication";

export const FETCH_PATIENTS = 'FETCH_PATIENTS';
export const FETCH_PATIENT_DETAIL = 'FETCH_PATIENT_DETAIL';
export const ADD_PATIENT = 'ADD_PATIENT';
export const EDIT_PATIENT = 'EDIT_PATIENT';
export const PATIENT_IS_LOADING = 'PATIENT_IS_LOADING';
export const PATIENT_IS_ERROR = 'PATIENT_IS_ERROR';
export const PATIENT_NEEDS_REFRESH = 'PATIENT_NEEDS_REFRESH';
export const UPDATE_PATIENT_FILTER = 'UPDATE_PATIENT_FILTER';
export const FETCH_PATIENT_AILMENTS = 'FETCH_PATIENT_AILMENTS';
export const PATIENT_AILMENTS_IS_LOADING = 'PATIENT_AILMENTS_IS_LOADING';
export const PATIENT_AILMENT_IS_ERROR = 'PATIENT_AILMENT_IS_ERROR';
export const PATIENT_AILMENT_NEEDS_REFRESH = 'PATIENT_AILMENT_NEEDS_REFRESH';
export const FETCH_PATIENT_AILMENT = 'FETCH_PATIENT_AILMENT';
export const FETCH_PATIENT_NOTES = 'FETCH_PATIENT_NOTES';
export const PATIENT_NOTES_IS_LOADING = 'PATIENT_NOTES_IS_LOADING';
export const PATIENT_NOTES_IS_ERROR = 'PATIENT_NOTES_IS_ERROR';
export const PATIENT_NOTES_NEEDS_REFRESH = 'PATIENT_NOTES_NEEDS_REFRESH';
export const PATIENT_SMS_SENT = 'PATIENT_SMS_SENT';
export const PATIENT_SIGNUP_WAIT = 'PATIENT_SIGNUP_WAIT';
export const PATIENT_SIGNUP_ERROR = 'PATIENT_SIGNUP_ERROR';
export const UPDATE_PATIENT_TOKEN = 'UPDATE_PATIENT_TOKEN';
export const PATIENT_PASSWORD_UPDATED = 'PATIENT_PASSWORD_UPDATED';

export function updatePatientFilter(filter){
    return (dispatch) => {
        dispatch({type:UPDATE_PATIENT_FILTER, filter:filter});
    }
}

export function fetchPatients(params,type){
    return (dispatch) => {
        dispatch({type: PATIENT_IS_LOADING, bool :true});
        dispatch({type: PATIENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        var url=`${API_URL}patient/list`;
        switch (type){
            case "Dosage" :
                url = `${API_URL}patient/listWithDosage?XDEBUG_SESSION_START=PHPSTORM`;
                break;
            default:
                url = `${API_URL}patient/list?XDEBUG_SESSION_START=PHPSTORM`;
                break;
        }
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url, params, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PATIENTS, payload: response.data});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        });
    }
}
export function fetchPatientDetail(patient_id){
    return (dispatch) => {
        dispatch({type: PATIENT_IS_LOADING, bool :true});
        dispatch({type: PATIENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/fetch`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
            console.log("Got Patient Response", response.data);
            if(response.data.Success){
                //convert date string into Java Date
                //const d = new Date(response.data.answer.appt_dt);
                dispatch({type: FETCH_PATIENT_DETAIL, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addPatient(patient){
    return (dispatch) => {
        dispatch({type: PATIENT_IS_LOADING, bool :true});
        dispatch({type: PATIENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/add?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: ADD_PATIENT, patient_id: response.data.patient_id})
                dispatch({type: PATIENT_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: PATIENT_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
        });
    }
}

export function signupPatient(patient){
    return (dispatch) => {
        dispatch({type: PATIENT_SIGNUP_WAIT, bool: true});
        const url=`${API_URL}patient/signup?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient, header);
        request.then((response) => {
            if(response.data.Success){
                dispatch({type: ADD_PATIENT, patient_id: response.data.patient_id})
                dispatch({type: PATIENT_SIGNUP_WAIT, bool: false});
            } else {
                dispatch({type: PATIENT_SIGNUP_ERROR, bool:true});
                dispatch({type: PATIENT_SIGNUP_WAIT, bool: false});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_SIGNUP_ERROR, bool:true});
            dispatch({type: PATIENT_SIGNUP_WAIT, bool: false});
        });
    }
}


export function deletePatient(id){
    return (dispatch) => {
        dispatch({type: PATIENT_IS_LOADING, bool :true});
        dispatch({type: PATIENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/delete`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const patient_id = {patient_id:id};
        const request = axios.post(url,patient_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PATIENT_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: PATIENT_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
        });
    }
}

export function editPatient(patient){
    return (dispatch) => {
        dispatch({type: PATIENT_IS_LOADING, bool :true});
        dispatch({type: PATIENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NEEDS_REFRESH, bool: false});
        const url=`${API_URL}patient/edit?XDEBUG_SESSION_START=PHPSTORM`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient, header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PATIENT_NEEDS_REFRESH, bool: true});
            } else {
                dispatch({type: PATIENT_IS_ERROR, bool:true});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_IS_LOADING, bool: false});
        });
    }
}

export function fetchPatientAilments(patient_id){
    return (dispatch) => {
        dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool :true});
        dispatch({type: PATIENT_AILMENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/getPatientAilments`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool: false});
            dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PATIENT_AILMENT, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_AILMENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool: false});
            dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addPatientAilment(patient_ailment){
    return (dispatch) => {
        dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool :true});
        dispatch({type: PATIENT_AILMENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/addAilment`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_ailment,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_AILMENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool: false});
            dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
        });
    }
}

export function deletePatientAilment(patient_ailment){
    return (dispatch) => {
        dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool :true});
        dispatch({type: PATIENT_AILMENT_IS_ERROR, bool:false});
        dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/deleteAilment`;
        let token="";
        token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_ailment,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_AILMENT_IS_ERROR, bool:true});
            dispatch({type: PATIENT_AILMENTS_IS_LOADING, bool: false});
            dispatch({type: PATIENT_AILMENT_NEEDS_REFRESH, bool: false});
        });
    }
}

export function fetchPatientNotes(patient_id){
    return (dispatch) => {
        dispatch({type: PATIENT_NOTES_IS_LOADING, bool :true});
        dispatch({type: PATIENT_NOTES_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/getPatientNotes?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_id,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_NOTES_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
            if(response.data.Success){
                dispatch({type: FETCH_PATIENT_NOTES, payload: response.data.answer});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_NOTES_IS_ERROR, bool:true});
            dispatch({type: PATIENT_NOTES_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
        });
    }
}

export function addPatientNote(patient_note){
    return (dispatch) => {
        dispatch({type: PATIENT_NOTES_IS_LOADING, bool :true});
        dispatch({type: PATIENT_NOTES_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/addNote`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_note,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_NOTES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: true});
                dispatch(reset('addPatientNoteForm'));
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_NOTES_IS_ERROR, bool:true});
            dispatch({type: PATIENT_NOTES_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
        });
    }
}

export function deletePatientNote(patient_note){
    return (dispatch) => {
        dispatch({type: PATIENT_NOTES_IS_LOADING, bool :true});
        dispatch({type: PATIENT_NOTES_IS_ERROR, bool:false});
        dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
        const url = `${API_URL}patient/deleteNote`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient_note,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_NOTES_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: true});
            }
        }).catch((err)=>{
            dispatch({type: PATIENT_NOTES_IS_ERROR, bool:true});
            dispatch({type: PATIENT_NOTES_IS_LOADING, bool: false});
            dispatch({type: PATIENT_NOTES_NEEDS_REFRESH, bool: false});
        });
    }
}

export function sendReminderSMS(message){
    return (dispatch) => {
        const url = `${API_URL}patient/sendSMS?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,message,header);
        request.then((response) => {
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: PATIENT_SMS_SENT, bool: true});
        }).catch((err)=>{
            dispatch({type: PATIENT_SMS_SENT, bool: false});
        });
    }
}

export function getPatientTokenByUrl(code){
    return (dispatch) => {
        dispatch({type: PATIENT_SIGNUP_WAIT, bool: true});
        const url = `${API_URL}patient/getTokenByUrl?XDEBUG_SESSION_START=PHPSTORM`;
        const request = axios.post(url,code);
        request.then((response)=>{
            dispatch({type: PATIENT_SIGNUP_WAIT, bool: false});
            if(response.data.Success) {
                dispatch({type: UPDATE_PATIENT_TOKEN, token: response.data.Token});
                dispatch({type: UPDATE_TOKEN, token: response.data.Token});
                dispatch({type: FETCH_PATIENT_DETAIL, payload: response.data.answer});
            }
        })
    }
}

export function setPatientPassword(params){
    return (dispatch) => {
        dispatch({type: PATIENT_SIGNUP_WAIT, bool: true});
        dispatch({type: PATIENT_PASSWORD_UPDATED, bool: false});
        const url = `${API_URL}patient/setPassword?XDEBUG_SESSION_START=PHPSTORM`;
        const token = params.token;
        console.log('With token', token);
        const header = {headers: {"Authorization" : "Bearer " + token}};
        //delete params.token;
        const request = axios.post(url,params,header);
        request.then((response)=>{
            dispatch({type: PATIENT_SIGNUP_WAIT, bool: false});
            if(response.data.Success) {
                dispatch({type: PATIENT_PASSWORD_UPDATED, bool: true});
                dispatch({type: UPDATE_TOKEN, token: response.data.Token});
                dispatch({type: UPDATE_PATIENT_TOKEN, token: response.data.Token});
            }
        })
    }
}
export function logoutPatient(params){
    return (dispatch) => {
        localStorage.removeItem('token');
        //dispatch({type: UPDATE_PATIENT_TOKEN, token: ''});
        dispatch({type: UPDATE_TOKEN, token: ''});
        //todo update log in db to show patient logged out.
    }
}

export function updateTokenFromStorage(token){
    return (dispatch) => {
        dispatch({type: UPDATE_TOKEN, token: token});
    }
}

export function authPatient(cred){
    return (dispatch) => {
        dispatch({type: PASSWORD_FAILED, bool: false});
        const url = `${API_URL}patient/auth?XDEBUG_SESSION_START=PHPSTORM`;
        const request = axios.post(url,cred);
        request.then((response) => {
            if(response.data.Success){
                dispatch({type: UPDATE_PATIENT_TOKEN, token: response.data.Token});
                dispatch({type: UPDATE_TOKEN, token: response.data.Token});
                dispatch({type: PASSWORD_FAILED, bool: false});
            } else {
                dispatch({type: PASSWORD_FAILED, bool: true});
            }
        }).catch((err)=> {
            dispatch({type: PASSWORD_FAILED, bool: true});
        });
    }
}

export function forgotPassword(patient){
    return (dispatch) => {
        dispatch({type: COMMUNICATION_IS_LOADING, bool :true});
        const url=`${API_URL}patient/forgotPassword?XDEBUG_SESSION_START=PHPSTORM`;
        const token = localStorage.getItem("token");
        const header = {headers: {"Authorization" : "Bearer " + token}};
        const request = axios.post(url,patient, header);
        request.then((response) => {
            dispatch({type: UPDATE_PATIENT_TOKEN, token: response.data.Token});
            dispatch({type: UPDATE_TOKEN, token: response.data.Token});
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            if(response.data.Success){
                dispatch({type: COMMUNICATION_NEEDS_REFRESH, bool: true});
                dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
            }
        }).catch((err)=>{
            dispatch({type: COMMUNICATION_IS_LOADING, bool: false});
        });
    }
}

