import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Grid,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    CircularProgress,
    Typography,
    Table, TableHead, TableRow, TableCell, TableBody,
    Tooltip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import BackIcon from '@material-ui/icons/ArrowBack';
import {bindActionCreators} from 'redux';
import {getFreeAppointmentSlots,bookAppointmentSlot} from '../actions/Appointments';
import _ from 'lodash';
import moment from 'moment-timezone';
import {Field, reduxForm} from 'redux-form';
import {updatePage} from '../actions/Common';
import {RenderTimezoneSelect,renderTextField} from "../Components/Forms";

class CalendarWeekView extends Component{

    constructor(props){
        super(props);

        this.state= {
            deleteDialogOpen: false,
            data_id_inuse:0,
            isError:false,
            showMore:[],
            practitioner_id: null,
            newDialog: false,
            formTitle: 'Add New',
            isLoading: false,
            bgcolor: [],
            highlight:{},
            startTime:'09:00',
            endTime:'17:00',
            Calendar:false,
            selectArray:[],
            editPath:'patient',
            appt_id:0,
            bookShow:false,
            tz:'America/Toronto',
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.gotoPrevUrl=this.gotoPrevUrl.bind(this);
        this.gotoNextUrl=this.gotoNextUrl.bind(this);
        this.timezoneChange=this.timezoneChange.bind(this);
    }

    componentDidMount() {
        this.props.updatePage(this.state.editPath);
        let start = `${this.props.dateData.monday} 00:00:00`;
        let end= `${this.props.dateData.sunday} 23:45:00`;
        let s = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
        let e = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
        this.props.getFreeAppointmentSlots({start:s,end:e,byPatient:this.props.byPatient})
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.isError!==prevProps.isError){
            this.setState({isError:this.props.isError});
        }
        if(this.props.dateData!==prevProps.dateData || this.props.needsRefresh){
            let start = `${this.props.dateData.monday} 00:00:00`;
            let end= `${this.props.dateData.sunday} 23:45:00`;
            let s = moment(start).utc().format("YYYY-MM-DD HH:mm:ss");
            let e = moment(end).utc().format("YYYY-MM-DD HH:mm:ss");
            this.props.getFreeAppointmentSlots({start:s,end:e,byPatient:this.props.byPatient})
        }
        if(this.props.data!==prevProps.data || this.state.tz!==prevState.tz){
            //lets create the appts array that we need.
            let appts={};
            let times={};
            if(typeof this.props.data.dataset!=='undefined'){
                let randomArray = this.props.data.dataset.sort(function(a, b){return 0.5 - Math.random()});
                randomArray.map((data)=>{
                    let utm=moment.utc(data.appt_dt,'YYYY-MM-DD HH:mm:ss');
                    let localTime = utm.tz(this.state.tz).format('HH:mm');
                    let localDate= utm.tz(this.state.tz).format('YYYY-MM-DD');
                    if(typeof appts[localTime]==='undefined'){
                        appts[localTime]={};
                    }
                    times[localTime]=localTime;
                    appts[localTime][localDate]={appointment_id:data.appointment_id, utc:utm, time:localTime, date:localDate};
                    return true;
                })
            }
            let a = this.buildArraySlots(this.props.dateData,times,appts);
            this.setState({Calendar:a});
        }
        if(this.props.appointmentBooked && !prevProps.appointmentBooked){
            this.props.onBooked();
            //this.props.history.push(`/patient/${this.props.patient_id}`);
        }
    }

    buildArraySlots(dateData,times,appts){
        let timeArray=_.orderBy(_.values(times));
        let slots={};
        timeArray.map((t)=>{
            let a={};
            if(typeof appts !=='undefined'){
                if(typeof appts[t]!=='undefined'){
                    a=appts[t];
                }
            }
            slots[t]={
                time:t,
                [dateData.monday]:a[dateData.monday],
                [dateData.tuesday]:a[dateData.tuesday],
                [dateData.wednesday]:a[dateData.wednesday],
                [dateData.thursday]:a[dateData.thursday],
                [dateData.friday]:a[dateData.friday],
                [dateData.saturday]:a[dateData.saturday],
                [dateData.sunday]:a[dateData.sunday]
            };
            return true;
        });
        return slots;
    }

    bookAppointment(values){
        this.setState({bookShow:false});
        let params = {
            appointment_id:this.state.appt_id,
            patient_id: this.props.patient_id,
            notes: values.notes,
            byPatient: this.props.byPatient
        }
        this.props.bookAppointmentSlot(params);
    }

    Style(){
        return({
            refresh: {
                background: 'transparent',
                textAlign: 'center',
                margin: 'auto'
            },
            tableheader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 50,
                backgroundColor: '#fff'
            },
            tableLeftheader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 55,
                backgroundColor: '#fff'
            },
            tableLeftCell:{
                position: "sticky",
                left:0,
                zIndex: 45,
                backgroundColor: '#fff'
            },
            tablecell:{
                padding: '12px',
            }

        });
    }

    //Dialog Functions

    //Form Validation Functions:
    required(value) {
        return value ? undefined : 'Required'
    }


    onTextFieldChange = item => ({target : {value}}) => {
        this.setState({
            data : {
                ...this.state.data,
                [item]:value
            }
        });

    }

    noValidate(value) {
        return  undefined ;
    }


    handleTextChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    renderBookDialog (){
        const { handleSubmit } = this.props;
        return (
            <Dialog
                open={this.state.bookShow}
                onClose={()=>{this.setState({bookShow:false})}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Booking Appointment`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                            Please Enter any notes you would like to tell your practitioner ahead of the appointment
                    </DialogContentText>
                    <form onSubmit={handleSubmit(this.bookAppointment.bind(this))}>
                    <Field
                        name= 'notes'
                        component={renderTextField}
                        label= 'Notes'
                        margin='normal'
                        onChange={this.handleTextChange}
                        InputProps={{
                            disableUnderline: true,
                            style: {border: '1px solid #ced4da'}
                        }}
                        multiline
                        rows='4'
                        fullWidth
                        validate={[this.noValidate]}
                    />
                    <Button onClick={()=>{this.setState({bookShow:false})}} color="secondary" variant='contained'>
                        Cancel
                    </Button>
                    <Button type="submit" color='primary' variant='contained'>
                        Confirm
                    </Button>
                    </form>
                </DialogContent>
            </Dialog>

        );
    }

    renderRefreshDialog(){
        return(<Dialog
                fullScreen
                open={this.props.isLoading}
                PaperProps={{style:{background:'transparent'}}}
            >
                <div style={{margin: '35% 0 0 40%', position: 'absolute'}}>
                    <DialogContent>
                        <CircularProgress size={50} color='secondary' style={this.Style().refresh}/>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }

    renderErrorDialog (){
        return (
            <Dialog
                open={this.state.isError}
                onClose={()=>{this.props.history.push('/')}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Unable to Process Request`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We were unable to process your request at this time.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{this.props.history.push('/')}} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    gotoPrevUrl(){
        this.props.history.push(this.props.dateData.prevUrl);
    }

    gotoNextUrl(){
        this.props.history.push(this.props.dateData.nextUrl);
    }

    renderTitleBar(){
        return(
            <TableRow>
                <TableCell padding='dense' style={this.Style().tableLeftheader}>Local Time</TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Mon</Typography><Typography variant="subtitle1">{moment(this.props.dateData.monday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Tue</Typography><Typography variant="subtitle1">{moment(this.props.dateData.tuesday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Wed</Typography><Typography variant="subtitle1">{moment(this.props.dateData.wednesday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Thu</Typography><Typography variant="subtitle1">{moment(this.props.dateData.thursday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Fri</Typography><Typography variant="subtitle1">{moment(this.props.dateData.friday).format('MMM DD')}</Typography></TableCell>
            <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Sat</Typography><Typography variant="subtitle1">{moment(this.props.dateData.saturday).format('MMM DD')}</Typography></TableCell>
                <TableCell padding='dense' style={this.Style().tableheader}><Typography variant="subtitle1">Sun</Typography><Typography variant="subtitle1">{moment(this.props.dateData.sunday).format('MMM DD')}</Typography></TableCell>
            </TableRow>
        )
    }

    renderEarlierBar(){
        let startTime = '00:00';
        let endTime = '09:00';
        if(this.state.startTime!=='00:00'){
            if(this.state.startTime>'09:00') {
                startTime ='09:00';
                endTime = '17:00';
            }
            return(
                <TableRow>
                    <TableCell colSpan={8} style={{textAlign:'center'}}><Button onClick={()=>{this.setState({startTime, endTime})}}>Earlier</Button></TableCell>
                </TableRow>
            )
        }
        return false;
    }

    renderLaterBar(){
        let endTime = '23:45'
        let startTime = '17:00'
        if(this.state.endTime!=='23:45'){
            if(this.state.endTime<'17:00') {
                endTime ='17:00';
                startTime = '09:00';
            }
            return(
                <TableRow>
                    <TableCell colSpan={8} style={{textAlign:'center'}}><Button onClick={()=>{this.setState({endTime,startTime})}}>Later</Button></TableCell>
                </TableRow>
            )
        }
        return false;
    }

    renderRows(){
        if(!this.state.Calendar){
            return(<TableRow/>)
        }
        let calendar = _.values(this.state.Calendar);
        return(
            calendar.map((data)=>{
                if(data.time>=this.state.startTime && data.time<=this.state.endTime){
                    return(
                        <TableRow key={data.time}>
                            <TableCell padding='dense' style={this.Style().tableLeftCell}>{data.time}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.monday],this.props.dateData.monday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.tuesday],this.props.dateData.tuesday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.wednesday],this.props.dateData.wednesday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.thursday],this.props.dateData.thursday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.friday],this.props.dateData.friday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.saturday],this.props.dateData.saturday,data.time)}</TableCell>
                            <TableCell padding='dense'>{this.renderCell(data[this.props.dateData.sunday],this.props.dateData.sunday,data.time)}</TableCell>
                        </TableRow>
                    )
                }
                return false;
            })
        )
    }

    renderCell(data,date,time){
        let a_dt = date + ' ' +time + ":00";
        let appt = moment(a_dt).utc();
        let inPast = appt < moment.utc() ? true :false;
        //let appt_dt = appt.format('YYYY-MM-DD HH:mm:ss');
        if(inPast || typeof data ==='undefined'){
            return false;
        }
        return(
            <Tooltip id="tooltip-fab" title={`${time}`}>
            <Button color="primary" variant="fab" mini onClick={()=>{this.setState({bookShow:true,appt_id:data.appointment_id})}}>
                <DoneIcon/>
            </Button>
            </Tooltip>
        )
    }

    timezoneChange(event) {
        this.setState({tz: event.target.value});
    };

    render(){
        return(
                <Grid container>
                    <Grid item xs={2}><Button onClick={()=>this.props.weekChange(this.props.dateData.prevMonday)} color="primary" variant="fab"><BackIcon/></Button></Grid>
                    <Grid item xs={8} style={{textAlign:'center'}}>
                        <RenderTimezoneSelect value={this.state.tz} label='Timezone' onChange={this.timezoneChange}/><Button variant='contained' onClick={this.props.onBooked}>Back</Button>
                    </Grid>
                    <Grid item xs={2} style={{textAlign:'right'}}><Button onClick={()=>this.props.weekChange(this.props.dateData.nextMonday)} color="primary" variant="fab"><ForwardIcon/></Button></Grid>
                    <Grid item xs={12}>
                        <div style={{overflowX:'auto', overflowY:'auto', height:'98vh'}}>
                        <Table style={{overflow:'unset'}}>
                            <TableHead>
                                {this.renderTitleBar()}
                            </TableHead>
                            <TableBody>
                                {this.renderEarlierBar()}
                                {this.renderRows()}
                                {this.renderLaterBar()}
                            </TableBody>

                        </Table>
                        </div>
                    </Grid>

                    {this.renderBookDialog()}
                    {this.renderRefreshDialog()}
                    {this.renderErrorDialog()}
                </Grid>

        );
    }
}


function mapStateToProps(state){
    return(
        {
            data: state.calendar,
            isLoading: state.calendarIsLoading,
            isError: state.calendarIsError,
            needsRefresh: state.calendarNeedsRefresh,
            appointmentBooked : state.appointmentBooked,
        });
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({getFreeAppointmentSlots,bookAppointmentSlot, updatePage }, dispatch);
}

export default reduxForm({form:'addAppointment'})(connect(mapStateToProps, mapDispatchToProps)(CalendarWeekView));
